import React, { useState } from "react";
import TextField from "@mui/material/TextField";
// import { LocalizationProvider, DatePicker, MobileDatePicker } from "@mui/lab";
import {
  LocalizationProvider,
  DatePicker,
  MobileDatePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useField } from "formik";
import { FormControl, FormHelperText } from "@mui/material";

export default function DatePickerBox(props) {
  const [field, meta, helpers] = useField(props.name);
  const [value, setValue] = useState(
    meta.value === "" ? null : new Date(meta.value)
  );
  const [matches] = useState(window.matchMedia("(max-width: 767px)").matches);

  const prepareDate = (date) => {
    if (date) {
      const year = date.getFullYear();
      const day = date.getDate();
      const monthIndex = date.getMonth();

      const addZero = (num) => `${num}`.padStart(2, "0");

      return addZero(monthIndex + 1) + "/" + addZero(day) + "/" + year;
    }
  };

  const handleChange = (newValue) => {
    setValue(newValue);
    if (newValue !== null) {
      helpers.setValue(prepareDate(newValue));
    } else {
      helpers.setValue(null);
    }
  };

  return (
    <FormControl component="fieldset">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label={props.label}
          inputFormat="MM/dd/yyyy"
          value={value}
          maxDate={props.maxDate}
          onChange={handleChange}
          allowSameDateSelection={true}
          okText="OK"
          cancelText="Cancel"
          disabled={props.disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              autoComplete="true"
              name={field.name}
              // fullWidth={true}

              style={{width:props.maxWidth}}
              margin="normal"
              variant="standard"
            />
          )}
        />
      </LocalizationProvider>
      <FormHelperText error>
        {meta.touched && meta.error ? meta.error : undefined}
      </FormHelperText>
    </FormControl>
  );
}
