import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import * as Http from "src/services/Http";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListSubheader,
  MenuItem,
  Paper,
  Select,
  Table,
  TableCell,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import Cancelbtn from "src/components/common/Cancelbtn";
import * as Messages from "src/constants/Messages/index";
import GoogleAddressBox from "src/components/GoogleAddressBox";
import CustomPhoneInput from "src/components/common/CustomPhoneInput";
import CustomfaxInput from "src/components/common/CustomFaxInput";
import HomeGoogleAddressBox from "src/components/HomeGoogleAddressBox";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import ConformationDialog from "src/components/ConformationDialog";

const useStyles = makeStyles((theme) => ({
  title: {
    margin: theme.spacing(2, 2, 0),
  },
}));

const AddCompanySchema = Yup.object().shape({
  company: Yup.string()
    .min(2, Messages.TOO_SHORT)
    .required(Messages.REQUIRED),
  address: Yup.string().required(Messages.REQUIRED),
  category_id: Yup.string().required(Messages.REQUIRED),
  email: Yup.string()
    .lowercase()
    .email(Messages.INVALID_EMAIL)
    .required(Messages.REQUIRED),
  phone: Yup.string()
    .required(Messages.REQUIRED)
    .min(5, Messages.REQUIRED),
  fax: Yup.string()
    .required(Messages.REQUIRED)
    .min(5, Messages.REQUIRED),
});
const CompaniesDetails = ({
  mode,
  setSnackbar,
  formValues,
  userList,
  contactCategory,
  assignUserList,
  fetchUserList,
  fetchAssignUserList,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [Address, setAddress] = useState({});
  const [checkPhoneNumber, setCheckPhoneNumber] = useState(0);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option.name + option.desc,
  });
  const [open, setOpen] = useState(false);
  const [companyId, setcompanyId] = useState();
  const [userId, setuserId] = useState();
  const [appStatusId, setappStatusId] = useState();
  const handleDelClose = () => setOpen(false);
  const delStatus = (userId, companyId) => {
    setOpen(true);
    setcompanyId(companyId);
    setuserId(userId);
  };
  const onConform = async () => {
    let StatusId = 0;
    try {
      await Http.call("POST", "admin/company/unassign_staff_company", {
        user_id: userId,
        company_id: companyId,
      }).then(async (response) => {
        setSnackbar(true, response.status, response.message);
      });

      fetchUserList();
      fetchAssignUserList(formValues.id);
      handleDelClose();
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Formik
      initialValues={formValues}
      validationSchema={AddCompanySchema}
      onSubmit={async (values, actions) => {
        let method = "";
        let url = "";
        if (formValues.id === undefined) {
          method = "POST";
          url = "admin/company";
        } else {
          method = "PATCH";
          url = "admin/company/" + formValues.id;
        }
        await Http.call(method, url, {
          ...values,
          fulladdress: Address,
        }).then(async ({ status, message, data }) => {
          if (status === 200) {
            navigate("/app/companies");
            setSnackbar(true, status, message);
          } else if (status === 422) {
            actions.setErrors(data);
          } else {
            setSnackbar(true, status, message);
          }
        });
        actions.setSubmitting(false);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        values,
      }) => {
        return (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Card>
              <CardHeader title={mode + " Company"} />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={12}>
                    <Grid item md={12} xs={12}>
                      <TextField
                        fullWidth
                        name="company"
                        label="Company"
                        variant="outlined"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.company}
                        error={Boolean(touched.company && errors.company)}
                        margin="normal"
                        helperText={touched.company && errors.company}
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <HomeGoogleAddressBox
                        name="address"
                        placeholder="Address"
                        address={values.fulladdress}
                        setAddress={setAddress}
                        setFieldValue={setFieldValue}
                      />
                    </Grid>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormControl
                      variant="outlined"
                      className={classes.selectControl}
                      fullWidth
                      margin="normal"
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Category
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="category_id"
                        label="Category"
                        name="category_id"
                        value={values.category_id}
                        onChange={handleChange}
                        error={Boolean(
                          touched.category_id && errors.category_id
                        )}
                        fullWidth
                      >
                        <MenuItem value="">
                          <em>Select Category</em>
                        </MenuItem>

                        {contactCategory.map((contactCategory, index) => (
                          <MenuItem key={index} value={contactCategory.id}>
                            {contactCategory.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {Boolean(touched.category_id && errors.category_id) ? (
                        <FormHelperText className="Mui-error">
                          {errors.category_id}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Email"
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      type="email"
                      variant="outlined"
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <CustomPhoneInput
                      name={"phone"}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      setCheckPhoneNumber={setCheckPhoneNumber}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <CustomfaxInput
                      name="fax"
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Autocomplete
                      multiple
                      id="staffusers"
                      options={userList}
                      name="staffusers"
                      value={
                        values.staffusers !== undefined ? values.staffusers : []
                      }
                      disableCloseOnSelect
                      filterOptions={filterOptions}
                      onChange={(e, newValue) => {
                        setFieldValue("staffusers", newValue);
                      }}
                      getOptionLabel={(option) => option.name}
                      renderOption={(option, { selected }) =>
                        option.isUser && (
                          <React.Fragment>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={
                                values.id !== undefined
                                  ? values.staffusers.find(
                                      (v) =>
                                        v.id === option.id &&
                                        v.role_flag === option.role_flag
                                    )
                                    ? true
                                    : false
                                  : selected
                              }
                            />
                            <div
                              key={option.id}
                              className="MuiListItemText-root MuiListItemText-multiline"
                            >
                              <span className="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                                {option.name}
                              </span>
                              <div
                                className="MuiTypography-root MuiListItemText-secondary MuiTypography-body2 MuiTypography-colorTextSecondary MuiTypography-displayBlock"
                                dangerouslySetInnerHTML={{
                                  __html: option.desc,
                                }}
                                style={{
                                  maxWidth: 500,
                                }}
                              ></div>
                            </div>
                          </React.Fragment>
                        )
                      }
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Staff"
                          placeholder="Search Staff"
                          margin="normal"
                        />
                      )}
                    />
                    {Boolean(touched.staffusers && errors.staffusers) ? (
                      <FormHelperText className="Mui-error">
                        {errors.staffusers}
                      </FormHelperText>
                    ) : null}
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box display="flex" justifyContent="flex-end" p={2}>
                <Cancelbtn />
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </Box>
              {formValues.id !== undefined && (
                <>
                  <Divider />
                  <CardContent>
                    <TableContainer
                      component={Paper}
                      className={classes.container}
                    >
                      <Table
                        className={classes.table}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={6}>
                              Company Staff List
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell style={{ width: 300 }}>Email</TableCell>
                            <TableCell>Phone</TableCell>
                            <TableCell>Fax</TableCell>
                            <TableCell>Position</TableCell>
                            <TableCell style={{ width: 80 }}>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {assignUserList.length !== 0 ? (
                            assignUserList.map((s, index) => (
                              <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                  {s.name}
                                </TableCell>
                                <TableCell>{s.email}</TableCell>
                                <TableCell>{s.phone}</TableCell>
                                <TableCell>{s.fax}</TableCell>
                                <TableCell>{s.designation}</TableCell>
                                <TableCell>
                                  <Tooltip
                                    disableFocusListener
                                    title="Delete Staff"
                                  >
                                    <Button
                                      style={{ minWidth: 40 }}
                                      size="small"
                                      color="primary"
                                      onClick={() => {
                                        delStatus(s.id, s.company_id);
                                      }}
                                    >
                                      <DeleteOutlineOutlinedIcon />
                                    </Button>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell align="center" colSpan={6}>
                                No record found
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardContent>
                  <ConformationDialog
                    className={classes.paper}
                    id="confirmation-dialog-title"
                    keepMounted
                    open={open}
                    onClose={handleDelClose}
                    onConformAction={onConform}
                    // value={delequestionTitles}
                  />
                </>
              )}
            </Card>
          </form>
        );
      }}
    </Formik>
  );
};

export default CompaniesDetails;
