import React from "react";
import { Grid } from "@mui/material";
import BorderLinearProgress from "../../components/QuestionTemplates/BorderLinearProgress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import "../../styles/sectionBox.css";
export default function SectionBox({
  section,
  getSection,
  progress,
  pageType,
  isFromAdmin,
}) {
  let bgImage = localStorage.getItem("PostBgImage");
  let PostFont = localStorage.getItem("PostBgFont");
  return (
    <Grid
      container
      alignItem="center"
      justifyContent="center"
      direction="row"
      style={{
        backgroundImage: `url("${bgImage}")`,
        backgroundSize: "cover",
        height: "100%",
        padding: 5,
      }}
      className="scroll-md list-que"
    >
      <Grid item md={12} xs={12}>
        <BorderLinearProgress variant="determinate" value={progress} />
      </Grid>
      <Grid item md={12} xs={12}>
        <ul style={{ padding: 10 }}>
          {section.map((item, index) => (
            <li
              key={index}
              className={
                isFromAdmin
                  ? "attent"
                  : !section[section.length - 2].is_complete
                  ? item.section_lable === "Confirm"
                    ? "disabled"
                    : "attent"
                  : section[section.length - 1].is_complete
                  ? item.section_lable !== "Review"
                    ? "disabled"
                    : "attent"
                  : "attent"
              }
              onClick={() => {
                isFromAdmin
                  ? getSection(
                      ["Review", "Confirm"].includes(item.section_lable)
                        ? item.section_lable
                        : item.section_id
                    )
                  : !section[section.length - 1].is_complete
                  ? getSection(
                      ["Review", "Confirm"].includes(item.section_lable)
                        ? item.section_lable
                        : item.section_id
                    )
                  : ["Review"].includes(item.section_lable)
                  ? getSection(item.section_lable)
                  : "";
              }}
              style={{ color: localStorage.getItem("PqBgFont") }}
            >
              <span
                className={
                  (item.is_active &&
                    !["review", "confirm"].includes(pageType)) ||
                  ("review" === pageType && "Review" === item.section_lable) ||
                  ("confirm" === pageType && "Confirm" === item.section_lable)
                    ? "shadow"
                    : ""
                }
                style={{
                  color: PostFont,
                  backgroundColor:
                    (item.is_active &&
                      !["review", "confirm"].includes(pageType)) ||
                    ("review" === pageType &&
                      "Review" === item.section_lable) ||
                    ("confirm" === pageType && "Confirm" === item.section_lable)
                      ? "rgba(206, 102, 50, 0.5)"
                      : "transparent",
                  boxShadow:
                    (item.is_active &&
                      !["review", "confirm"].includes(pageType)) ||
                    ("review" === pageType &&
                      "Review" === item.section_lable) ||
                    ("confirm" === pageType && "Confirm" === item.section_lable)
                      ? "0 0.5rem 1rem rgba(255, 0, 0, 0.15)"
                      : "unset",
                }}
              >
                {item.is_complete && <FontAwesomeIcon icon={faCheck} />}
                {item.section_lable}
              </span>
            </li>
          ))}
        </ul>
      </Grid>
    </Grid>
  );
}
// item.is_active && !["review", "confirm"].includes(pageType)
//   ? " shadow"
//   : "review" === pageType && "Review" === item.section_lable
//   ? " shadow"
//   : "confirm" === pageType && "Confirm" === item.section_lable
//   ? " shadow"
//   : "";
