import "react-perfect-scrollbar/dist/css/styles.css";
import React, { useEffect } from "react";
import { useRoutes } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import { Provider as StoreProvider } from "react-redux";
import { configureStore } from "./store";
import { init } from 'commandbar';
import GlobalStyles from "src/components/GlobalStyles";
import "src/mixins/chartjs";
import theme from "src/theme";
import routes from "src/routes";

import "./styles/index.css";

init('94e97491');

const store = configureStore();

const App = () => {

  const isLoggedIn = store.getState().session.loggedIn;
  const routing = useRoutes(routes(isLoggedIn));

  useEffect(() => {
    const loggedInUserId = '12345'; // example
    window.CommandBar.boot(loggedInUserId).then(() => {});

    return () => {
      window.CommandBar.shutdown();
    }
  }, []);

  return (
    <StoreProvider store={store}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {routing}
      </ThemeProvider>
    </StoreProvider>
  );
};

export default App;
