import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as Http from "src/services/Http";
import { setSnackbar } from "src/actions";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Grid, makeStyles } from "@material-ui/core";
import Page from "src/components/Page";
// import ContactForm from "./ContactForm";
import ScreenLoader from "src/components/ScreenLoader";
// import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";
import AddContactForm from "./AddContactForm";
/*import Toolbar from "./Toolbar";*/

// const breadUrlList = [
//   { name: "Home", url: "/dashboard" },
//   { name: "Contacts", url: "/app/contacts" },
// ];
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    // paddingBottom: theme.spacing(3),
    // paddingTop: theme.spacing(3),
  },
  userDetail: {
    marginTop: theme.spacing(3),
  },
}));

const Add_conatact = (props) => {
  const { id } = useParams();
  let userId = "";
  if (id === undefined) {
    userId = localStorage.getItem("rand_token");
    userId = !isNaN(+userId) === false ? undefined : userId;
  } else{
    userId = id;
  }

  const classes = useStyles();
  const navigate = useNavigate();
  const [Loading, setLoading] = useState(true);
  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    middle_name:"",
    email: "",
    mobile: "",
    fax: "",
    company: "",
    designation: "",
    address: "",
    notes: "",
    contact_category_id: "",
  });

  const [contactCompany, setContactCompany] = useState([]);
  const [staffPositions, setStaffPositions] = useState([]);

  const fetchContactCompanies = async (category_id = "") => {
    try {
      let url;
      url = "admin/company/get_company_by_category";
      if (category_id !== "" && category_id !== null) {
        url = "admin/company/get_company_by_category/" + category_id;
      }
      const response = await Http.call("GET", url);
      await setContactCompany(response.data);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchStaffPositions = async () => {
    try {
      let url = "admin/designations";

      const response = await Http.call("GET", url);
      await setStaffPositions(response.data);

      //setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        if (userId !== undefined) {
          await Http.call("GET", "admin/lookup_dirs/" + userId).then(
            async (response) => {
              await setValues(response.data);
              await fetchContactCompanies();
              await fetchStaffPositions();
              setLoading(false);
            }
          );
        } else {
          await fetchContactCompanies();
          await fetchStaffPositions();
          setLoading(false);
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetchUsers();
  }, [userId]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  
  const addUser = async (data, actions) => {
    await Http.call("POST", "admin/lookup_dirs", {
      ...data,
    }).then(async (response) => {
      if (response.status === 200) {
        props.setSnackbar(true, response.status, response.message);
        props.fetchUserList()
        props.onClose()
        // navigate(-1);
      } else {
        actions.setErrors(response.data);
      }
    });
  };


  // const updateUser = async (data, actions) => {
  //   await Http.call("PUT", "admin/lookup_dirs/" + userId, {
  //     ...data,
  //     _method: "PUT",
  //   }).then(async (response) => {
  //     if (response.status === 200) {
  //       props.setSnackbar(true, response.status, response.message);
  //       navigate("/app/contacts");
  //     } else {
  //       actions.setErrors(response.data);
  //     }
  //     // props.setImage(response.data.image);
  //   });
  // };
  if (Loading) {
    return <ScreenLoader />;
  }
  return (
    <Page
      className={classes.root}
      title={userId !== undefined ? "Edit Contact" : "Add Contact"}
    >
      {/* <Container maxWidth="lg"> */}
        {/* <BreadcrumbsAdmin
          urlList={
            id !== undefined
              ? [...breadUrlList, { name: "Edit", url: "/" }]
              : [...breadUrlList, { name: "Add", url: "/" }]
          }
        /> */}
        {/* <Grid container spacing={3} className={classes.userDetail}> */}
          <Grid item lg={12} md={12} xs={12}>
            <AddContactForm
              id={userId}
              handleModalClose={ props.onClose}
              formValues={values}
              contactCompany={contactCompany}
              handleChange={handleChange}
              // updateUser={id !== undefined ? updateUser : addUser}
              updateUser={addUser}
              fetchContactCompanies={fetchContactCompanies}
              staffPositions={staffPositions}
            />
          </Grid>
        {/* </Grid> */}
      {/* </Container> */}
    </Page>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(Add_conatact);
