import React, { useState, useEffect } from "react";
import { Box, makeStyles, Container } from "@material-ui/core";
import Page from "src/components/Page";
import Results from "./Results";
import * as Http from "src/services/Http";
import { connect, useSelector } from "react-redux";
import ScreenLoader from "src/components/ScreenLoader";
import { setSnackbar } from "src/actions";
import ConformationDialog from "src/components/ConformationDialog";
import Toolbar from "./Toolbar";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";
import TableView from "./TableView.js";

const breadUrlList = [
  { name: "Home", url: "/dashboard" },
  { name: "Status List", url: "/" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const StatusListView = (props) => {
  const classes = useStyles();
  const authuser = useSelector((state) => state?.session?.user);
  const [open, setopen] = useState(false);
  const [statusId, setstatus] = useState([]);
  const [delestatusTitles, setstatusTitles] = useState([]);
  const handleClose = () => setopen(false);

  const [statusList, setStatusList] = useState({
    id: "Loading",
    s: "Loading",
  });
  const [Loading, setLoading] = useState(true);

  const fetchStatusList = async () => {
    try {
      const response = await Http.call("GET", "admin/status_mgmt");
      setStatusList(response.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const deleteStatus = (idsToDelete, titlesToDelete) => {
    setopen(true);
    setstatus(idsToDelete);
    setstatusTitles(titlesToDelete);
  };
  const onConform = async () => {
    try {
      await Http.call("POST", "admin/status_mgmt/multidelete", {
        id: statusId,
      }).then(async (response) => {
        props.setSnackbar(true, response.status, response.message);
      });
      fetchStatusList();
      handleClose();
    } catch (e) {
      console.log(e);
    }
  };

  const changeSequance = async (data) => {
    setLoading(true);
    try {
      await Http.call("POST", "admin/status_mgmt/update_sequence", data).then(
        async (response) => {
          setLoading(false);
          props.setSnackbar(true, response.status, response.message);
        }
      );
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchStatusList();
  }, []);

  if (Loading) {
    return <ScreenLoader />;
  }

  return (
    <Page className={classes.root} title="Status">
      <Container maxWidth="lg">
        <BreadcrumbsAdmin urlList={breadUrlList} />
        <Toolbar authuser={authuser} />
        <Box mt={3}>
          <TableView
            records={statusList}
            setRecordsList={setStatusList}
            changesequance={changeSequance}
            deleteRecords={deleteStatus}
            authuser={authuser}
          />
          {/*<Results
            statuslist={statusList}
            pageLoading={setLoading}
            fetchStatus={fetchStatusList}
            deletest={deleteStatus}
          />*/}
        </Box>
      </Container>
      <ConformationDialog
        classes={{
          paper: classes.paper,
        }}
        id="confirmation-dialog-title"
        keepMounted
        open={open}
        onClose={handleClose}
        onConformAction={onConform}
        value={delestatusTitles}
      />
    </Page>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(StatusListView);
