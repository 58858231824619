import React from "react";
import * as Http from "src/services/Http";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import Cancelbtn from "src/components/common/Cancelbtn";
import * as Messages from "src/constants/Messages/index";
import DatePicker from "src/components/common/DatePicker";

// const unit = [
//   {
//     id: 0,
//     value: 0,
//     label: "Inactive",
//   },
//   {
//     id: 1,
//     value: 1,
//     label: "Active",
//   },
// ];

const AddUserSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, Messages.TOO_SHORT)
    .required(Messages.REQUIRED),
  promocode: Yup.string().required(Messages.REQUIRED),
  percentage: Yup.number()
    .min(1, Messages.PERCENTAGE_BETWEEN)
    .max(100, Messages.PERCENTAGE_BETWEEN)
    .required(Messages.REQUIRED),
  // status: Yup.string().required(Messages.REQUIRED),
  start_date: Yup.date()

    .required(Messages.REQUIRED)
    .nullable(),
  end_date: Yup.date()
    .required(Messages.REQUIRED)
    .min(Yup.ref("start_date"), Messages.TO_DATE)
    .nullable(),
});
// const getDate = () => {
//   let d = new Date(),
//     month = "" + (d.getMonth() + 1),
//     day = "" + d.getDate(),
//     year = d.getFullYear();

//   if (month.length < 2) month = "0" + month;
//   if (day.length < 2) day = "0" + day;

//   return [year, month, day].join("-");
// };
const PromoDetails = ({ setSnackbar, formValues, promoClass }) => {
  const navigate = useNavigate();
  //console.log(formValues.id);
  return (
    <Formik
      initialValues={formValues}
      validationSchema={AddUserSchema}
      onSubmit={async (values, actions) => {
        let method = "";
        let url = "";
        if (formValues.id === undefined) {
          method = "POST";
          url = "admin/discounts";
        } else {
          method = "PATCH";
          url = "admin/discounts/" + formValues.id;
        }
        await Http.call(method, url, values).then(
          async ({ status, message, data }) => {
            if (status === 200) {
              setSnackbar(true, status, message);
              navigate("/app/discount");
            } else if (status === 422) {
              // console.log(data.title);
              if (data.title) {
                actions.setFieldError("title", data.title);
              }
              if (data.promocode) {
                actions.setFieldError("promocode", data.promocode);
              }
              if (data.percentage) {
                actions.setFieldError("percentage", data.percentage);
              }
              setSnackbar(true, status, message);
            }
          }
        );
        actions.setSubmitting(false);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        values,
      }) => {
        return (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Grid item md={12} xs={12} className={promoClass}>
              <Card>
                {}
                <CardHeader
                  title={
                    formValues.id === undefined
                      ? "Add Service Discount"
                      : "Edit Service Discount"
                  }
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={12} xs={12}>
                      <TextField
                        fullWidth
                        name="title"
                        label="Title"
                        variant="outlined"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.title}
                        error={Boolean(touched.title && errors.title)}
                        margin="normal"
                        helperText={touched.title && errors.title}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Service Discount"
                        name="promocode"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.promocode}
                        margin="normal"
                        variant="outlined"
                        error={Boolean(touched.promocode && errors.promocode)}
                        helperText={touched.promocode && errors.promocode}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Percentage"
                        margin="normal"
                        name="percentage"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.percentage}
                        type="number"
                        variant="outlined"
                        error={Boolean(touched.percentage && errors.percentage)}
                        helperText={touched.percentage && errors.percentage}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      {/* <TextField
                        fullWidth
                        label="Start Date"
                        name="start_date"
                        type="date"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.start_date}
                        InputProps={{
                          inputProps: { min: getDate() },
                        }}
                        margin="normal"
                        variant="outlined"
                        error={Boolean(touched.start_date && errors.start_date)}
                        helperText={touched.start_date && errors.start_date}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      /> */}
                      <DatePicker
                        name="start_date"
                        lable="Start Date"
                        placeholder="Start Date"
                        value={values.start_date}
                        onChange={(e) => setFieldValue("start_date", e)}
                        error={Boolean(touched.start_date && errors.start_date)}
                        helperText={touched.start_date && errors.start_date}
                        //maxDate={new Date()}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      {/* <TextField
                        fullWidth
                        label="Expiration Date"
                        name="end_date"
                        type="date"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.end_date}
                        InputProps={{
                          inputProps: { min: getDate() },
                        }}
                        margin="normal"
                        variant="outlined"
                        error={Boolean(touched.end_date && errors.end_date)}
                        helperText={touched.end_date && errors.end_date}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      /> */}
                      <DatePicker
                        name="end_date"
                        lable="Expiration Date"
                        placeholder="Expiration Date"
                        value={values.end_date}
                        onChange={(e) => setFieldValue("end_date", e)}
                        error={Boolean(touched.end_date && errors.end_date)}
                        helperText={touched.end_date && errors.end_date}
                        //maxDate={new Date()}
                      />
                    </Grid>
                    {/* <Grid item md={12} xs={12}> */}
                    {/* <FormControlLabel
                        control={
                          <Switch
                            fullWidth
                            label="Status"
                            name="status"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={Boolean(touched.status && errors.status)}
                            helperText={touched.status && errors.status}
                            checked={values.status}
                            color="primary"
                          />
                        }
                        labelPlacement="right"
                        label="Active"
                      /> */}
                    {/* <TextField>
                        {unit.map((option) => (
                          <MenuItem key={option.id} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField> */}
                    {/* </Grid> */}
                  </Grid>
                </CardContent>
                <Divider />
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Cancelbtn />
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </Box>
              </Card>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default PromoDetails;
