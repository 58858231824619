import React, { useState, useEffect } from "react";
import { Container, Grid, Card, makeStyles } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import Page from "src/components/Page";
import DocumentFormDetails from "../Components/DocumentFormDetails";
import Toolbar from "../Components/Toolbar";
import { setSnackbar } from "src/actions";
import { connect } from "react-redux";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";

const breadUrlList = [
  { name: "Home", url: "/dashboard" },
  { name: "Document Form List", url: "/app/document-form" },
  { name: "Add Document Form", url: "/" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  QuesDetail: {
    marginTop: theme.spacing(3),
  },
}));

const AddDocumentFormView = (props) => {
  const classes = useStyles();
  const [Loading] = useState(false);
  const [values, setValues] = useState({
    name: "",
    editor: "",
    // field_name: [""], 
    field_array: [{ field_name: "", x_point: "" ,y_point: "", page_no: "" }],
  });

  useEffect(() => {}, []);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  if (Loading) {
    return (
      <Card style={{ height: 300 }}>
        <LinearProgress />
      </Card>
    );
  }
  return (
    <Page className={classes.root} title="Add Document Form">
      <Container maxWidth="lg">
        <BreadcrumbsAdmin urlList={breadUrlList} />
        <Toolbar mode={"Add"} />
        <Grid container spacing={3} className={classes.QuesDetail}>
          <Grid item lg={12} md={12} xs={12}>
            <DocumentFormDetails
              // emailtemplists={emailTemp}
              // userlistdata={userlist}
              formValues={values}
              setvalues={setValues}
              handleChange={handleChange}
              mode={"Add"}
              setSnackbar={props.setSnackbar}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddDocumentFormView);
