import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Page from "src/components/Page";
import { connect } from "react-redux";
import { setSnackbar } from "src/actions";
import * as Http from "src/services/Http";
import * as Messages from "src/constants/Messages/index";
import ScreenLoader from "src/components/ScreenLoader";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .lowercase()
    .email(Messages.INVALID_EMAIL)
    .required(Messages.REQUIRED),
});

const ForgotPasswordView = (props) => {
  const classes = useStyles();
  const [Loading, setLoading] = useState(false);

  const forgotPasswordRequest = async (data, actions) => {
    setLoading(true);
    await Http.call("POST", "admin/auth/sendPasswordResetLink", data).then(
      async (response) => {
        if (response.status === 200) {
          props.setSnackbar(true, response.status, response.message);
        } else {
          actions.setErrors({ email: response.message });
        }
        actions.setSubmitting(false);
        setLoading(false);
      }
    );
  };

  if (Loading) {
    return <ScreenLoader />;
  }

  return (
    <Page className={classes.root} title="Forgot Password">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={forgotPasswordSchema}
            onSubmit={(event, actions) => {
              forgotPasswordRequest(event, actions);
              //actions.setSubmitting(false);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography color="textPrimary" align="center" variant="h2">
                    Forgot Password
                  </Typography>
                </Box>

                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />

                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Submit
                  </Button>
                </Box>
                <Typography color="textSecondary" variant="body1">
                  <Link component={RouterLink} to="/login" variant="h6">
                    Back to Login
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordView);
//export default ForgotPasswordView;
