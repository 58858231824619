import React, { useCallback }  from "react";
import PropTypes from 'prop-types'
import { IMaskInput } from "react-imask";

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { inputRef, onChange, ...other } = props;

  return (
    <IMaskInput
      {...other}
      mask={"+{1} (000) 000-0000"}
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={useCallback(value => onChange({ target: { name: props.name, value } }))}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default TextMaskCustom;
