import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Button, Typography } from "@material-ui/core";
import { chkRoutePerm } from "src/services/Helper";

const Toolbar = ({ className, ...rest }) => {
  let chkRoutPerm = chkRoutePerm("/app/Price/add", rest.authuser);
  return (
    <div {...rest}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h3" component="h3">
          Price List
        </Typography>
        {chkRoutPerm ? (
          <Button
            color="primary"
            variant="contained"
            component={RouterLink}
            to="/app/price/add"
          >
            Add Price
          </Button>
        ) : (
          ""
        )}
      </Box>
    </div>
  );
};

export default Toolbar;
