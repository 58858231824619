import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  ListSubheader,
  FormLabel,
  Checkbox,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import * as Http from "src/services/Http";
import * as Messages from "src/constants/Messages/index";
import Cancelbtn from "src/components/common/Cancelbtn";
import { ListItemText } from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import AutoSuggessionField from "src/components/common/AutoSuggessionField";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectControl: {
    minWidth: "100%",
    margin: "15px 0",
  },
  selectControlFile: {
    minWidth: "49%",
    margin: "15px 5px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const AddStatusSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, Messages.TOO_SHORT)
    .required(Messages.REQUIRED),
  // type: Yup.string().required(Messages.REQUIRED),
  // doc: Yup.string().when("type", {
  //   is: (type) => /^(1)$/.test(type),
  //   then: Yup.string().required(Messages.REQUIRED),
  // }),

  on_email_template: Yup.string().when(["on_status", "on_send_type"], {
    is: (on_status, on_send_type) =>
      /^(1)$/.test(on_status) && /^(0)$/.test(on_send_type),
    then: Yup.string().required(Messages.REQUIRED),
  }),
  on_fax_message: Yup.string().when(["on_status", "on_send_type"], {
    is: (on_status, on_send_type) =>
      /^(1)$/.test(on_status) && /^(1)$/.test(on_send_type),
    then: Yup.string().required(Messages.REQUIRED),
  }),
  // on_send_type: Yup.number().when("on_status", {
  //   is: (on_status) => /^(1)$/.test(on_status),
  //   then: Yup.number().required(Messages.REQUIRED),
  // }),
  on_position_id: Yup.array().when("on_status", {
    is: (on_status) => /^(1)$/.test(on_status),
    then: Yup.array()
      .of(Yup.string().required(Messages.REQUIRED))
      .min(1, "Minimum 1 field is required"),
  }),
  // on_form_id: Yup.array().when("on_status", {
  //   is: (on_status) => /^(1)$/.test(on_status),
  //   then: Yup.array()
  //     .of(Yup.string().required(Messages.REQUIRED))
  //     .min(1, "Minimum 1 field is required"),
  // }),
  // off_send_type: Yup.number().when("off_status", {
  //   is: (off_status) => /^(2)$/.test(off_status),
  //   then: Yup.number().required(Messages.REQUIRED),
  // }),
  // off_email_template: Yup.string().when("off_status", {
  //   is: (off_status) => /^(2)$/.test(off_status),
  //   then: Yup.string().required(Messages.REQUIRED),
  // }),
  off_email_template: Yup.string().when(["off_status", "off_send_type"], {
    is: (off_status, off_send_type) =>
      /^(1)$/.test(off_status) && /^(0)$/.test(off_send_type),
    then: Yup.string().required(Messages.REQUIRED),
  }),
  off_fax_message: Yup.string().when(["off_status", "off_send_type"], {
    is: (off_status, off_send_type) =>
      /^(1)$/.test(off_status) && /^(1)$/.test(off_send_type),
    then: Yup.string().required(Messages.REQUIRED),
  }),
  off_position_id: Yup.array().when("off_status", {
    is: (off_status) => /^(2)$/.test(off_status),
    then: Yup.array()
      .of(Yup.string().required(Messages.REQUIRED))
      .min(1, "Minimum 1 field is required"),
  }),
  // off_form_id: Yup.array().when("off_status", {
  //   is: (off_status) => /^(2)$/.test(off_status),
  //   then: Yup.array()
  //     .of(Yup.string().required(Messages.REQUIRED))
  //     .min(1, "Minimum 1 field is required"),
  // }),
});

const StatusDetails = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    mode,
    emailtemplists,
    userlistdata,
    formValues,
    setSnackbar,
    docuforms,
    staffPositions,
  } = props;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const initialValues = {
    id: formValues.id,
    name: formValues.name,
    auto_status:
      formValues.id === undefined || formValues.id === ""
        ? 0
        : formValues.auto_status,
    on_status:
      formValues.id === undefined || formValues.id === ""
        ? 0
        : formValues.on_status,
    on_send_type:
      formValues.id === undefined || formValues.id === ""
        ? 0
        : formValues.on_send_type,
    on_email_template: formValues.on_email_template,
    on_position_id: formValues.on_position_id,
    on_form_id: formValues.on_form_id,
    off_status:
      formValues.id === undefined ||
      formValues.id === "" ||
      formValues.off_status === ""
        ? 0
        : formValues.off_status,
    off_send_type:
      formValues.id === undefined || formValues.id === ""
        ? 0
        : formValues.off_send_type,
    off_email_template: formValues.off_email_template,
    off_position_id: formValues.off_position_id,
    off_form_id: formValues.off_form_id,
    is_compulsory:
      formValues.id === undefined || formValues.id === ""
        ? 0
        : formValues.is_compulsory,
    on_fax_message: formValues.on_fax_message,
    off_fax_message: formValues.off_fax_message,
  };

  const positionfilterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option.title,
  });

  //console.log(initialValues);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={AddStatusSchema}
      onSubmit={async (values, actions) => {
        console.log(values, "values");
        // return;
        let method = "";
        let url = "";
        if (formValues.id === undefined) {
          method = "POST";
          url = "admin/status_mgmt";
        } else {
          method = "PATCH";
          url = "admin/status_mgmt/" + formValues.id;
        }
        await Http.call(method, url, values).then(
          async ({ status, message, data }) => {
            if (status === 200) {
              navigate("/app/status");
            } else if (status === 422) {
              if (data) {
                actions.setFieldError("name", data.name[0]);
              }
            }
            setSnackbar(true, status, message);
          }
        );
        actions.setSubmitting(false);
        return;
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => {
        return (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Card>
              <CardHeader
                subheader={
                  "The information can be " + mode.toLowerCase() + " status"
                }
                title={mode + " Status"}
              />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      name="name"
                      label="Name"
                      variant="outlined"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      margin="normal"
                      helperText={touched.name && errors.name}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormLabel style={{ margin: "0 10px 0 0" }}>
                      On Status:
                    </FormLabel>
                    <Checkbox
                      name="on_status"
                      value={values.on_status}
                      inputProps={{
                        "aria-labelledby": "On status",
                      }}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setFieldValue("on_status", 1);
                        } else {
                          setFieldValue("on_position_id", []);
                          setFieldValue("on_status", 0);
                          setFieldValue("on_send_type", 0);
                          setFieldValue("on_email_template", "");
                          setFieldValue("on_fax_message", "");
                          setFieldValue("on_form_id", []);
                          const on_position_input = document.querySelector(
                            "#on_position_id"
                          );
                          Object.getOwnPropertyDescriptor(
                            window.HTMLInputElement.prototype,
                            "value"
                          ).set.call(on_position_input, "");
                          on_position_input.dispatchEvent(
                            new Event("change", { bubbles: true })
                          );
                        }
                      }}
                      checked={values.on_status === 0 ? false : true}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormLabel style={{ margin: "0 10px 0 0" }}>
                      On Send Type:
                    </FormLabel>
                    <FormControlLabel
                      control={
                        <Switch
                          label="On Send Type"
                          name="on_send_type"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue("on_send_type", 1);
                              setFieldValue("on_email_template", "");
                            } else {
                              setFieldValue("on_send_type", 0);
                              setFieldValue("on_fax_message", "");
                            }
                          }}
                          checked={values.on_send_type === 1 ? 1 : 0}
                          color="primary"
                          disabled={
                            values.on_status === "" ||
                            values.on_status === 0 ||
                            values.on_status === undefined
                          }
                        />
                      }
                      label={values.on_send_type === 1 ? "Fax" : "Email"}
                    />
                    {Boolean(touched.on_send_type && errors.on_send_type) ? (
                      <FormHelperText className="Mui-error">
                        {errors.on_send_type}
                      </FormHelperText>
                    ) : null}
                  </Grid>
                  {values.on_send_type === 1 ? (
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Fax Message"
                        name="on_fax_message"
                        value={values.on_fax_message}
                        onChange={handleChange}
                        margin="normal"
                        variant="outlined"
                        error={Boolean(
                          touched.on_fax_message && errors.on_fax_message
                        )}
                        helperText={
                          touched.on_fax_message && errors.on_fax_message
                        }
                        disabled={
                          values.on_status === "" ||
                          values.on_status === 0 ||
                          values.on_status === undefined
                        }
                      />
                    </Grid>
                  ) : (
                    <Grid item md={6} xs={12}>
                      <FormControl
                        variant="outlined"
                        className={classes.selectControl}
                      >
                        <InputLabel id="on_email_template">
                          Email Template
                        </InputLabel>
                        <Select
                          labelId="on_email_template"
                          id="on_email_template"
                          label="Email Template"
                          name="on_email_template"
                          value={
                            values.on_status !== 0
                              ? values.on_email_template
                              : ""
                          }
                          onChange={handleChange}
                          disabled={
                            values.on_status === "" ||
                            values.on_status === 0 ||
                            values.on_status === undefined
                          }
                          error={Boolean(
                            touched.on_email_template &&
                              errors.on_email_template
                          )}
                          fullWidth
                        >
                          <MenuItem value="">
                            <em>Select Email Template</em>
                          </MenuItem>

                          {emailtemplists.map((emailtemplist, index) => (
                            <MenuItem key={index} value={emailtemplist.id}>
                              {emailtemplist.email_subject}
                            </MenuItem>
                          ))}
                        </Select>
                        {Boolean(
                          touched.on_email_template && errors.on_email_template
                        ) ? (
                          <FormHelperText className="Mui-error">
                            {errors.on_email_template}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item md={6} xs={12}>
                    <Autocomplete
                      multiple
                      id="on_position_id"
                      options={staffPositions}
                      name="on_position_id"
                      value={values.on_position_id}
                      disableCloseOnSelect
                      filterOptions={createFilterOptions({
                        matchFrom: "any",
                      })}
                      onChange={(e, newValue) => {
                        setFieldValue("on_position_id", newValue);
                      }}
                      getOptionLabel={(option) => (option ? option.title : [])}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            // checked={selected}
                            checked={
                              values.on_position_id !== undefined
                                ? values.on_position_id.find(
                                    (v) => v.id === option.id
                                  )
                                  ? true
                                  : false
                                : selected
                            }
                          />
                          <div
                            key={option.id}
                            className="MuiListItemText-root MuiListItemText-multiline"
                          >
                            <span className="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                              {option.title}
                            </span>
                          </div>
                        </React.Fragment>
                      )}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="On Staff Position"
                          placeholder="Search Position"
                          margin="normal"
                        />
                      )}
                      disabled={
                        values.on_status === "" ||
                        values.on_status === 0 ||
                        values.on_status === undefined
                      }
                    />
                    {Boolean(
                      touched.on_position_id && errors.on_position_id
                    ) ? (
                      <FormHelperText className="Mui-error">
                        {errors.on_position_id}
                      </FormHelperText>
                    ) : null}
                  </Grid>
                  <Grid item xs={6} md={6} style={{ padding: "0 12px" }}>
                    <FormControl
                      variant="outlined"
                      className="statusdocformselectControl"
                    >
                      <InputLabel id="status_forms">
                        On Document Form
                      </InputLabel>
                      <Select
                        id="on_form_id"
                        label="On Document Form"
                        name="on_form_id"
                        fullWidth
                        error={Boolean(touched.on_form_id && errors.on_form_id)}
                        multiple
                        value={values.on_status !== 0 ? values.on_form_id : []}
                        onChange={(e) => {
                          setFieldValue("on_form_id", e.target.value);
                        }}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <em>Placeholder</em>;
                          }
                          return selected.length + " selected";
                        }}
                        inputProps={{
                          name: "users",
                          id: "outlined-age-native-simple",
                        }}
                        disabled={
                          values.on_status === "" ||
                          values.on_status === 0 ||
                          values.on_status === undefined
                        }
                      >
                        <MenuItem value="">
                          <em>Select Document Form</em>
                        </MenuItem>
                        {docuforms.map((docuformslist, frm_index) => (
                          <MenuItem
                            key={`form_${frm_index}`}
                            value={docuformslist.id}
                          >
                            <Checkbox
                              checked={
                                values.on_form_id.indexOf(docuformslist.id) > -1
                              }
                            />
                            <div className="MuiListItemText-root MuiListItemText-multiline">
                              <span className="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                                {docuformslist.name}
                              </span>
                            </div>
                          </MenuItem>
                        ))}
                      </Select>
                      {Boolean(touched.on_form_id && errors.on_form_id) ? (
                        <FormHelperText className="Mui-error">
                          {errors.on_form_id}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} md={6} style={{ padding: "0 12px" }}></Grid>
                  <Grid item md={6} xs={12}>
                    <FormLabel style={{ margin: "0 10px 0 0" }}>
                      Off Status:
                    </FormLabel>
                    <Checkbox
                      name="off_status"
                      value={values.off_status}
                      inputProps={{
                        "aria-labelledby": "Off status",
                      }}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setFieldValue("off_status", 2);
                        } else {
                          setFieldValue("off_position_id", []);
                          setFieldValue("off_status", 0);
                          setFieldValue("off_send_type", 0);
                          setFieldValue("off_email_template", "");
                          setFieldValue("off_fax_message", "");
                          setFieldValue("off_form_id", []);
                          const off_position_input = document.querySelector(
                            "#off_position_id"
                          );
                          Object.getOwnPropertyDescriptor(
                            window.HTMLInputElement.prototype,
                            "value"
                          ).set.call(off_position_input, "");
                          off_position_input.dispatchEvent(
                            new Event("change", { bubbles: true })
                          );
                        }
                      }}
                      checked={values.off_status === 0 ? false : true}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormLabel style={{ margin: "0 10px 0 0" }}>
                      Off Send Type:
                    </FormLabel>
                    <FormControlLabel
                      control={
                        <Switch
                          label="Off Send Type"
                          name="off_send_type"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue("off_send_type", 1);
                              setFieldValue("off_email_template", "");
                            } else {
                              setFieldValue("off_send_type", 0);
                              setFieldValue("off_fax_message", "");
                            }
                          }}
                          checked={values.off_send_type === 1 ? 1 : 0}
                          color="primary"
                        />
                      }
                      label={values.off_send_type === 1 ? "Fax" : "Email"}
                      disabled={
                        values.off_status === "" ||
                        values.off_status === 0 ||
                        values.off_status === undefined
                      }
                    />
                  </Grid>
                  {values.off_send_type === 1 ? (
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Fax Message"
                        name="off_fax_message"
                        value={values.off_fax_message}
                        onChange={handleChange}
                        margin="normal"
                        variant="outlined"
                        error={Boolean(
                          touched.off_fax_message && errors.off_fax_message
                        )}
                        helperText={
                          touched.off_fax_message && errors.off_fax_message
                        }
                      />
                    </Grid>
                  ) : (
                    <Grid item md={6} xs={12}>
                      <FormControl
                        variant="outlined"
                        className={classes.selectControl}
                      >
                        <InputLabel id="off_email_template">
                          Email Template
                        </InputLabel>
                        <Select
                          labelId="off_email_template"
                          id="off_email_template"
                          label="Email Template"
                          name="off_email_template"
                          value={
                            values.off_status !== 0
                              ? values.off_email_template
                              : ""
                          }
                          onChange={handleChange}
                          disabled={
                            values.off_status === "" ||
                            values.off_status === 0 ||
                            values.off_status === undefined
                          }
                          error={Boolean(
                            touched.off_email_template &&
                              errors.off_email_template
                          )}
                          fullWidth
                        >
                          <MenuItem value="">
                            <em>Select Email Template</em>
                          </MenuItem>

                          {emailtemplists.map((emailtemplist, index) => (
                            <MenuItem key={index} value={emailtemplist.id}>
                              {emailtemplist.email_subject}
                            </MenuItem>
                          ))}
                        </Select>
                        {Boolean(
                          touched.off_email_template &&
                            errors.off_email_template
                        ) ? (
                          <FormHelperText className="Mui-error">
                            {errors.off_email_template}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                  )}
                  {/* <Grid item md={6} xs={12}>
                    <AutoSuggessionField
                      autosugid="off_position_id"
                      idKey={"id"}
                      labelKey={"title"}
                      options={staffPositions}
                      name="off_position_id"
                      label="Off Staff Position"
                      disabled={
                        values.off_status === "" ||
                        values.off_status === 0 ||
                        values.off_status === undefined
                      }
                    />
                  </Grid> */}
                  <Grid item md={6} xs={12}>
                    <Autocomplete
                      multiple
                      id="off_position_id"
                      options={staffPositions}
                      name="off_position_id"
                      value={values.off_position_id}
                      disableCloseOnSelect
                      filterOptions={createFilterOptions({
                        matchFrom: "any",
                      })}
                      onChange={(e, newValue) => {
                        setFieldValue("off_position_id", newValue);
                      }}
                      getOptionLabel={(option) => (option ? option.title : [])}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            // checked={selected}
                            checked={
                              values.off_position_id !== undefined
                                ? values.off_position_id.find(
                                    (v) => v.id === option.id
                                  )
                                  ? true
                                  : false
                                : selected
                            }
                          />
                          <div
                            key={option.id}
                            className="MuiListItemText-root MuiListItemText-multiline"
                          >
                            <span className="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                              {option.title}
                            </span>
                          </div>
                        </React.Fragment>
                      )}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Off Staff Position"
                          placeholder="Search Position"
                          margin="normal"
                        />
                      )}
                      disabled={
                        values.off_status === "" ||
                        values.off_status === 0 ||
                        values.off_status === undefined
                      }
                    />
                    {Boolean(
                      touched.off_position_id && errors.off_position_id
                    ) ? (
                      <FormHelperText className="Mui-error">
                        {errors.off_position_id}
                      </FormHelperText>
                    ) : null}
                  </Grid>
                  <Grid item xs={6} md={6} style={{ padding: "0 12px" }}>
                    <FormControl
                      variant="outlined"
                      className="statusdocformselectControl"
                    >
                      <InputLabel id="status_forms">
                        Off Document Form
                      </InputLabel>
                      <Select
                        id="off_form_id"
                        label="Off Document Form"
                        name="off_form_id"
                        fullWidth
                        error={Boolean(
                          touched.off_form_id && errors.off_form_id
                        )}
                        multiple
                        value={
                          values.off_status !== 0 ? values.off_form_id : []
                        }
                        onChange={(e) => {
                          setFieldValue("off_form_id", e.target.value);
                        }}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <em>Placeholder</em>;
                          }
                          return selected.length + " selected";
                        }}
                        inputProps={{
                          name: "users",
                          id: "outlined-age-native-simple",
                        }}
                        disabled={
                          values.off_status === "" ||
                          values.off_status === 0 ||
                          values.off_status === undefined
                        }
                      >
                        <MenuItem value="">
                          <em>Select Document Form</em>
                        </MenuItem>
                        {docuforms.map((docuformslist, frm_index) => (
                          <MenuItem
                            key={`form_${frm_index}`}
                            value={docuformslist.id}
                          >
                            <Checkbox
                              checked={
                                values.off_form_id.indexOf(docuformslist.id) >
                                -1
                              }
                            />
                            <div className="MuiListItemText-root MuiListItemText-multiline">
                              <span className="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                                {docuformslist.name}
                              </span>
                            </div>
                          </MenuItem>
                        ))}
                      </Select>
                      {Boolean(touched.off_form_id && errors.off_form_id) ? (
                        <FormHelperText className="Mui-error">
                          {errors.off_form_id}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} md={6} style={{ padding: "0 12px" }}></Grid>
                  {formValues.id !== undefined ? (
                    <Grid item md={6} xs={12}>
                      <FormLabel>Auto Status:</FormLabel>
                      <Checkbox
                        name="auto_status"
                        value={values.auto_status}
                        inputProps={{
                          "aria-labelledby": "Auto status",
                        }}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setFieldValue("auto_status", 1);
                          } else {
                            setFieldValue("auto_status", 0);
                          }
                        }}
                        checked={values.auto_status === 0 ? false : true}
                        disabled={formValues.id !== undefined}
                      />
                    </Grid>
                  ) : (
                    ""
                  )}
                  <Grid item md={6} xs={12}>
                    <FormLabel style={{ margin: "0 10px 0 0" }}>
                      Compulsory:
                    </FormLabel>
                    <FormControlLabel
                      control={
                        <Switch
                          label="Compulsory"
                          name="is_compulsory"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue("is_compulsory", 1);
                            } else {
                              setFieldValue("is_compulsory", 0);
                            }
                          }}
                          checked={values.is_compulsory === 1 ? 1 : 0}
                          color="primary"
                        />
                      }
                      label={values.is_compulsory === 1 ? "Yes" : "No"}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box display="flex" justifyContent="flex-end" p={2}>
                <Cancelbtn />
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </Box>
            </Card>
          </form>
        );
      }}
    </Formik>
  );
};

export default StatusDetails;
