import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import Results from "./Results";
import * as Http from "src/services/Http";
import { connect, useSelector } from "react-redux";
import ScreenLoader from "src/components/ScreenLoader";
import { setSnackbar } from "src/actions";

const ListSectionView = (props) => {
  const [sectionList, setSectionList] = useState({
    id: "Loading",
    name: "Loading",
    description: "Loading",
    sequence: "Loading",
  });
  const [Loading, setLoading] = useState(true);
  const authuser = useSelector((state) => state?.session?.user);

  const fetchSectionList = async () => {
    try {
      // const response = await Http.call("GET", "admin/section");
      // setSectionList(response.data);
      // setLoading(false);
      await Http.call("POST", "admin/get_all_section", {
        type: 1,
      }).then(async (response) => {
        setSectionList(response.data);
        setLoading(false);
      });
    } catch (e) {
      console.log(e);
    }
  };

  const deleteSections = async (data) => {
    setLoading(true);
    try {
      await Http.call("POST", "admin/section/multidelete", { ids: data }).then(
        async (response) => {
          fetchSectionList();
          props.setSnackbar(true, response.status, response.message);
        }
      );
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchSectionList();
  }, []);

  if (Loading) {
    return <ScreenLoader />;
  }

  return (
    <div>
      <Box>
        <Results
          sections={sectionList}
          pageLoading={setLoading}
          fetchSection={fetchSectionList}
          deleteSections={deleteSections}
          authuser={authuser}
        />
      </Box>
    </div>
  );
};

//export default ListSectionView;
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListSectionView);
