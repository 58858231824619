import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";
import { useLocation } from "react-router";

const useStyles = makeStyles(() => ({
  root: {},
}));

const Header = (props) => {
  const { className, ...rest } = props;
  const location = useLocation();
  const paths = location.pathname.split("/");
  const email_type = paths[3];
  const email_mode_type = paths[4];

  const classes = useStyles();
  const breadUrlList = [
    { name: "Home", url: "/dashboard" },
    {
      name:
        email_type === "manual"
          ? "Custom Email Template"
          : "System Email Template",
      url:
        email_type === "manual"
          ? "/app/emailtemplate/manual"
          : "/app/emailtemplate",
    },
    {
      name:
        email_type === "manual"
          ? email_mode_type === "add"
            ? "Add Custom Email Template"
            : "Edit Custom Email Template"
          : "Edit System Email Template",
      url: "/",
    },
  ];
  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <BreadcrumbsAdmin urlList={breadUrlList} />
      <Typography component="h1" variant="h3">
        {email_type === "manual"
          ? email_mode_type === "add"
            ? "Add Custom Email Template"
            : "Edit Custom Email Template"
          : "Edit System Email Template"}
      </Typography>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
