import React, { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography,
  makeStyles,
  FormHelperText,
} from "@material-ui/core";
import Page from "src/components/Page";
import ScreenLoader from "src/components/ScreenLoader";
import * as Http from "src/services/Http";

import { login } from "src/actions";
import PasswordInput from "src/components/common/PasswordInput";
import * as Messages from "src/constants/Messages/index";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const LoginView = (props) => {
  const classes = useStyles();
  const [errormessage, setErrorMessage] = useState([]);
  const [Loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const makeLogin = async (data) => {
    // await localStorage.setItem(
    //   "token",
    //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC8xLjIyLjE2MS4yNjo5ODc1XC9jcmVtYXRpb25fd2Vic2VydmljZXNcL2FwaVwvYWRtaW5cL2F1dGhcL2xvZ2luIiwiaWF0IjoxNjE3OTY1OTcxLCJleHAiOjE2MTgxODE5NzEsIm5iZiI6MTYxNzk2NTk3MSwianRpIjoiOFpOSDRTVkdiWGl0dVczcSIsInN1YiI6MSwicHJ2IjoiY2YyODRjMmIxZTA2ZjMzYzI2YmQ1Nzk3NTY2ZDlmZDc0YmUxMWJmNSJ9.FdOSyZSdw1f2aYCOQerOEeqXi86TnBJOZ3K9FQuL3mg"
    // );
    // // await localStorage.setItem(
    // //   "full_name",
    // //   response.data.data.first_name + " " + response.data.data.last_name
    // // );
    // await props.login(
    //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC8xLjIyLjE2MS4yNjo5ODc1XC9jcmVtYXRpb25fd2Vic2VydmljZXNcL2FwaVwvYWRtaW5cL2F1dGhcL2xvZ2luIiwiaWF0IjoxNjE3OTY1OTcxLCJleHAiOjE2MTgxODE5NzEsIm5iZiI6MTYxNzk2NTk3MSwianRpIjoiOFpOSDRTVkdiWGl0dVczcSIsInN1YiI6MSwicHJ2IjoiY2YyODRjMmIxZTA2ZjMzYzI2YmQ1Nzk3NTY2ZDlmZDc0YmUxMWJmNSJ9.FdOSyZSdw1f2aYCOQerOEeqXi86TnBJOZ3K9FQuL3mg",
    //   {}
    // );
    // navigate("/app/dashboard");
    setLoading(true);
    /*let status = 401;*/
    await Http.Login("POST", "admin/auth/login", data).then(
      async (response) => {
        // console.log(response.status);
        setLoading(false);
        /*status = response.status;*/
        if (response.status === 200) {
          await localStorage.setItem("token", response.data.access_token);
          // await localStorage.setItem(
          //   "full_name",
          //   response.data.data.first_name + " " + response.data.data.last_name
          // );
          await props.login(response.data.access_token, response.data);
          navigate("/app/dashboard");
        } else {
          setErrorMessage(response.message);
        }
      }
    );
    /*if (status === 200) {
      setLoading(false);
      navigate("/app/dashboard");
    }*/
  };

  if (Loading) {
    return <ScreenLoader />;
  }
  return (
    <Page className={classes.root} title="Login">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email(Messages.VALID_EMAIL)
                .max(255)
                .required(Messages.REQUIRED),
              password: Yup.string()
                .max(255)
                .required(Messages.REQUIRED),
            })}
            onSubmit={(event, actions) => {
              makeLogin(event);
              actions.setSubmitting(false);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography color="textPrimary" align="center" variant="h2">
                    Sign in
                  </Typography>
                </Box>

                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <PasswordInput label="Password" name="password" />
                <FormHelperText error>{errormessage}</FormHelperText>
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
                </Box>
                <Typography color="textSecondary" variant="body1">
                  <Link
                    component={RouterLink}
                    to="/forgotpassword"
                    variant="h6"
                  >
                    Forgot Password
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { login };

export default connect(mapStateToProps, mapDispatchToProps)(LoginView);
