import React, { useEffect, useState } from "react";
import {
  Button,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Grid,
  Card,
  CardContent,
  Box,
  FormHelperText,
  Checkbox,
  Container,
  Typography,
  CardHeader,
  FormLabel,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as Http from "src/services/Http";
import ConformationDialog from "src/components/ConformationDialog";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import * as Messages from "src/constants/Messages/index";
import ScreenLoader from "src/components/ScreenLoader";
import * as Yup from "yup";
import { Formik } from "formik";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";
import Page from "src/components/Page";
import RichEditor from "src/components/RichEditor";
import { connect } from "react-redux";
import { setSnackbar } from "src/actions";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",

    paddingTop: theme.spacing(3),
  },
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
  },
  selectControl: {
    minWidth: "100%",
    margin: "15px 0",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  container: {
    maxHeight: 740,
  },
}));
const AddQuestionSchema = Yup.object().shape({
  action_title: Yup.string().required(Messages.REQUIRED),
  // selected_user: Yup.array()
  //   .of(Yup.string().required(Messages.REQUIRED))
  //   .min(1, Messages.MINONEREQUIRED),
  position_id: Yup.array()
    .required(Messages.REQUIRED)
    .min(1, "Minimum 1 field is required"),
  position_users: Yup.array().when("form_id", {
    is: (form_id) =>
      form_id.length > 0 && form_id !== "" && form_id !== undefined,
    then: Yup.array()
      .of(Yup.string().required(Messages.REQUIRED))
      .min(1, Messages.MINONEREQUIRED),
  }),
  email_message: Yup.string().when("send_type", {
    is: (send_type) => /^(0)$/.test(send_type),
    then: Yup.string()
      .min(20, Messages.TOO_SHORT)
      .required(Messages.REQUIRED),
  }),
  fax_message: Yup.string().when("send_type", {
    is: (send_type) => /^(1)$/.test(send_type),
    then: Yup.string().required(Messages.REQUIRED),
  }),
  // email_message: Yup.string()
  //   .min(20, Messages.TOO_SHORT)
  //   .required(Messages.REQUIRED),
});
const ManualAction = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [Loading, setLoading] = useState();
  const location = useLocation();
  const paths = location.pathname.split("/");
  const appid = paths[3];
  const initialValues = {
    action_title: "",
    // selected_user: [],
    // form_users: [],
    send_type: 0,
    email_message: "",
    fax_message: "",
    form_id: [],
    position_id: [],
    position_users: [],
  };
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const [statusdata, setStatusData] = useState({});
  const [statusaction, setStatusAction] = useState({});

  const [openStatusBox, setOpenStatusBox] = useState(false);
  const handleCloseStatusBox = () => setOpenStatusBox(false);
  const handleStatusOpen = (data) => {
    setOpenStatusBox(true);
  };

  const breadUrlList = [
    { name: "Home", url: "/dashboard" },
    { name: "Counsellor Status", url: "/app/counselor/" + appid },
    { name: "Manual Action", url: "/" },
  ];
  const fetchUserListData = async (position, setFieldValue) => {
    try {
      if (position !== "") {
        await Http.call("POST", "admin/lookup_dir", {
          positions: position,
          application: appid,
        }).then(async (response) => {
          if (response.status === 200) {
            await setFieldValue("position_users", response.data);
            return response.data;
          } else {
            console.log(response);
          }
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  const [docuforms, setDocuForms] = useState([]);
  const fetchDocumentForms = async () => {
    try {
      const responsedocuforms = await Http.call("GET", "admin/forms");
      setDocuForms(responsedocuforms.data);
    } catch (e) {
      console.log(e);
    }
  };

  const [appCounsStatus, setAppCounsStatus] = useState({
    user_name: "",
    couns_id: "",
    app_id: "",
    status: [],
  });
  const fetchData = async (StatusId = "") => {
    let status_data = {
      app_id: appid,
      status_id: StatusId !== "" ? StatusId : "",
    };
    await Http.call("post", "admin/show_app_status", status_data).then(
      async (response) => {
        if (response.status === 200) {
          setAppCounsStatus(response.data);
        } else {
          console.log(response);
        }
      }
    );
  };
  const [onPositionSelected, setOnPositionSelected] = useState([]);

  const [onuserlistdata, setOnUserlistdata] = useState([]);

  const fetchPositionUserList = async (position) => {
    if (position !== "") {
      await Http.call("POST", "admin/lookup_dir", {
        positions: position,
      }).then(async (response) => {
        if (response.status === 200) {
          await setOnUserlistdata(response.data);
          return response.data;
        } else {
          console.log(response);
        }
      });
    }
  };
  const [staffPositions, setStaffPositions] = useState([]);

  const fetchStaffPositions = async () => {
    try {
      let url = "admin/designations";
      const response = await Http.call("GET", url);
      await setStaffPositions(response.data);
    } catch (e) {
      console.log(e);
    }
  };

  const onStatusSubmit = async (e) => {
    let method = "";
    let url = "";
    method = "POST";
    url = "admin/update_status";
    setLoading(true);
    handleCloseStatusBox();
    console.log(statusdata, "statusdata");
    await Http.call(method, url, {
      ...statusdata,
      app_id: appid,
    }).then(async ({ status, message, data }) => {
      if (status === 200) {
        setLoading(false);
        props.setSnackbar(true, status, message);
        navigate("/app/counselor/" + appid);
        // fetchData(appCounsStatus.current_status_id);
      } else if (status === 422) {
        if (data.name) {
          setLoading(false);
          statusaction.setFieldError("status_id", data.name[0]);
        }
      }
    });
    statusaction.setSubmitting(false);
  };

  useEffect(() => {
    fetchData();
    fetchDocumentForms();
    fetchStaffPositions();
  }, []);

  if (Loading) {
    return <ScreenLoader />;
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={AddQuestionSchema}
      handleStatusOpen={handleStatusOpen}
      onSubmit={async (values, actions) => {
        // let selected_users = [];
        // let form_users = [];
        // values.selected_user.map((value, index) => {
        //   selected_users.push(value.role_flag + "_" + value.id);
        // });

        // values.form_users.map((value, index) => {
        //   form_users.push(value.role_flag + "_" + value.id);
        // });

        // const finalvalues = {
        //   ...values,
        //   selected_user: selected_users,
        //   form_users: form_users,
        // };
        // console.log(finalvalues, "finalvalues");
        // return;
        setStatusData(values);
        setStatusAction(actions);
        setOpenStatusBox(true);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => {
        return (
          <Page className={classes.root} title="Manual Action">
            <form className={classes.form} onSubmit={handleSubmit}>
              <Container maxWidth="lg">
                <BreadcrumbsAdmin urlList={breadUrlList} />
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={3}
                >
                  <Grid container spacing={3}>
                    <Grid item>
                      <Typography variant="h3" component="h3">
                        Manual Action
                      </Typography>
                    </Grid>
                  </Grid>
                  {/* <Grid item style={{ textAlign: "right" }}>
                    <Button
                      color="primary"
                      variant="contained"
                      component={RouterLink}
                      to={"/app/counselor/" + appid}
                    >
                      Back
                    </Button>
                  </Grid> */}
                </Box>
                <Card>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Action Title"
                          name="action_title"
                          value={values.action_title}
                          onChange={handleChange}
                          margin="normal"
                          variant="outlined"
                          error={Boolean(
                            touched.action_title && errors.action_title
                          )}
                          helperText={
                            touched.action_title && errors.action_title
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                        style={{ margin: "20px 0px 0 0" }}
                      >
                        <FormLabel style={{ margin: "0 10px 0 0" }}>
                          Send Type:
                        </FormLabel>
                        <FormControlLabel
                          control={
                            <Switch
                              label="Send Type"
                              name="send_type"
                              onBlur={handleBlur}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setFieldValue("send_type", 1);
                                  setFieldValue("email_template", "");
                                } else {
                                  setFieldValue("send_type", 0);
                                  setFieldValue("fax_message", "");
                                }
                              }}
                              checked={values.send_type === 1 ? 1 : 0}
                              color="primary"
                            />
                          }
                          label={values.send_type === 1 ? "Fax" : "Email"}
                        />
                        {Boolean(touched.send_type && errors.send_type) ? (
                          <FormHelperText className="Mui-error">
                            {errors.send_type}
                          </FormHelperText>
                        ) : null}
                      </Grid>
                      {values.send_type === 1 ? (
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Fax Message"
                            name="fax_message"
                            value={values.fax_message}
                            onChange={handleChange}
                            margin="normal"
                            variant="outlined"
                            error={Boolean(
                              touched.fax_message && errors.fax_message
                            )}
                            helperText={
                              touched.fax_message && errors.fax_message
                            }
                          />
                        </Grid>
                      ) : (
                        <Grid item md={12} xs={12}>
                          <CardHeader title="Email Description" />

                          <RichEditor
                            name="email_message"
                            label="Template Description*"
                            placeholder="Please Type conntent."
                            defaultValue={values.email_message}
                            onChange={(event) => {
                              // setTemplate(event);
                              setFieldValue("email_message", event);
                            }}
                            minheight="250px"
                          />
                          {errors.email_message && touched.email_message && (
                            <div style={{ color: "red", margin: ".8rem 0" }}>
                              {errors.email_message}
                            </div>
                          )}
                        </Grid>
                      )}
                      <Grid item xs={6} md={6}>
                        <FormControl
                          variant="outlined"
                          className="statusdocformselectControl"
                        >
                          <InputLabel id="status_forms">
                            Document Form
                          </InputLabel>
                          <Select
                            id="form_id"
                            label="Document Form"
                            name="form_id"
                            fullWidth
                            error={Boolean(touched.form_id && errors.form_id)}
                            multiple
                            value={values.status !== 0 ? values.form_id : []}
                            onChange={(e) => {
                              setFieldValue("form_id", e.target.value);
                            }}
                            renderValue={(selected) => {
                              if (selected.length === 0) {
                                return <em>Placeholder</em>;
                              }
                              return selected.length + " selected";
                            }}
                            inputProps={{
                              name: "users",
                              id: "outlined-age-native-simple",
                            }}
                            disabled={
                              appCounsStatus.is_documents_signed ===
                                undefined ||
                              appCounsStatus.is_documents_signed === 0
                                ? true
                                : false
                            }
                          >
                            <MenuItem value="">
                              <em>Select Document Form</em>
                            </MenuItem>
                            {docuforms.map((docuformslist, frm_index) => (
                              <MenuItem
                                key={`form_${frm_index}`}
                                value={docuformslist.id}
                              >
                                <Checkbox
                                  checked={
                                    values.form_id.indexOf(docuformslist.id) >
                                    -1
                                  }
                                />
                                <div className="MuiListItemText-root MuiListItemText-multiline">
                                  <span className="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                                    {docuformslist.name}
                                  </span>
                                </div>
                              </MenuItem>
                            ))}
                          </Select>
                          {Boolean(touched.form_id && errors.form_id) ? (
                            <FormHelperText className="Mui-error">
                              {errors.form_id}
                            </FormHelperText>
                          ) : null}
                        </FormControl>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Autocomplete
                          multiple
                          id="position_id"
                          options={staffPositions}
                          name="position_id"
                          value={onPositionSelected}
                          disableCloseOnSelect
                          filterOptions={createFilterOptions({
                            matchFrom: "any",
                          })}
                          onChange={(e, value) => {
                            if (value) {
                              let positionids = [];

                              value.map((val, index) => {
                                positionids.push(val.id);
                              });
                              setOnPositionSelected(value);
                              setFieldValue("position_id", value);
                              fetchPositionUserList(positionids);
                              fetchUserListData(positionids, setFieldValue);
                            } else {
                              setOnPositionSelected([]);
                              setFieldValue("position_id", []);
                            }
                          }}
                          getOptionLabel={(option) =>
                            option ? option.title : []
                          }
                          renderOption={(option, { selected }) => (
                            <React.Fragment>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                // checked={selected}
                                checked={
                                  values.position_id !== undefined
                                    ? values.position_id.find(
                                        (v) => v.id === option.id
                                      )
                                      ? true
                                      : false
                                    : selected
                                }
                              />
                              <div
                                key={option.id}
                                className="MuiListItemText-root MuiListItemText-multiline"
                              >
                                <span className="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                                  {option.title}
                                </span>
                              </div>
                            </React.Fragment>
                          )}
                          fullWidth
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Search Position"
                              label="Staff Position"
                              variant="outlined"
                              margin="normal"
                              helperText={
                                touched.position_id && errors.position_id
                              }
                              error={Boolean(
                                touched.position_id && errors.position_id
                              )}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Autocomplete
                          multiple
                          id="position_users"
                          options={onuserlistdata}
                          name="position_users"
                          value={values.position_users}
                          disableCloseOnSelect
                          filterOptions={createFilterOptions({
                            matchFrom: "any",
                          })}
                          onChange={(e, newValue) => {
                            setFieldValue("position_users", newValue);
                          }}
                          getOptionLabel={(option) =>
                            option
                              ? option.first_name + " " + option.last_name
                              : []
                          }
                          renderOption={(option, { selected }) => (
                            <React.Fragment>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={
                                  values.position_users !== undefined
                                    ? values.position_users.find(
                                        (v) => v.id === option.id
                                      )
                                      ? true
                                      : false
                                    : selected
                                }
                              />
                              <div
                                key={option.id}
                                className="MuiListItemText-root MuiListItemText-multiline"
                              >
                                <span className="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                                  {option.first_name + " " + option.last_name}
                                </span>
                              </div>
                            </React.Fragment>
                          )}
                          fullWidth
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Users"
                              placeholder="Search Users"
                              margin="normal"
                            />
                          )}
                        />
                        {Boolean(
                          touched.position_users && errors.position_users
                        ) ? (
                          <FormHelperText className="Mui-error">
                            {errors.position_users}
                          </FormHelperText>
                        ) : null}
                      </Grid>
                      {/* <Grid item md={6} xs={12}>
                        <Autocomplete
                          multiple
                          id="selected_user"
                          options={userlistdata}
                          name="selected_user"
                          value={
                            values.selected_user !== undefined
                              ? values.selected_user
                              : []
                          }
                          disableCloseOnSelect
                          filterOptions={filterOptions}
                          onChange={(e, newValue) => {
                            setFieldValue("selected_user", newValue);
                          }}
                          groupBy={(option) =>
                            !option.isUser && (
                              <ListSubheader
                                key={option.role_flag}
                                disableSticky={true}
                              >
                                {option.name}
                              </ListSubheader>
                            )
                          }
                          getOptionLabel={(option) => option.name}
                          renderOption={(option, { selected }) =>
                            option.isUser && (
                              <React.Fragment>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={
                                    values.id !== undefined
                                      ? values.selected_user.find(
                                          (v) =>
                                            v.id === option.id &&
                                            v.role_flag === option.role_flag
                                        )
                                        ? true
                                        : false
                                      : selected
                                  }
                                />
                                <div
                                  key={option.id}
                                  className="MuiListItemText-root MuiListItemText-multiline"
                                >
                                  <span className="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                                    {option.name}
                                  </span>
                                  <div
                                    className="MuiTypography-root MuiListItemText-secondary MuiTypography-body2 MuiTypography-colorTextSecondary MuiTypography-displayBlock"
                                    dangerouslySetInnerHTML={{
                                      __html: option.desc,
                                    }}
                                    style={{
                                      maxWidth: 500,
                                    }}
                                  ></div>
                                </div>
                              </React.Fragment>
                            )
                          }
                          fullWidth
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Users"
                              placeholder="Search Users"
                              margin="normal"
                            />
                          )}
                        />
                        {Boolean(
                          touched.selected_user && errors.selected_user
                        ) ? (
                          <FormHelperText className="Mui-error">
                            {errors.selected_user}
                          </FormHelperText>
                        ) : null}
                      </Grid> */}

                      {/* <Grid
                        item
                        style={{ padding: "0 12px" }}
                        container
                        spacing={3}
                      >
                        <Grid item xs={12} md={6} style={{ padding: "0 12px" }}>
                          <FormControl
                            variant="outlined"
                            className="statusdocformselectControl"
                            disabled={
                              appCounsStatus.is_documents_signed !==
                                undefined &&
                              appCounsStatus.is_documents_signed === 0
                                ? true
                                : false
                            }
                          >
                            <InputLabel id="status_forms">
                              Document Form
                            </InputLabel>
                            <Select
                              id="form_id"
                              label="Document Form"
                              name={`form_id`}
                              value={values.form_id}
                              onChange={handleChange}
                              fullWidth
                              error={Boolean(touched.form_id && errors.form_id)}
                              disabled={
                                appCounsStatus.is_documents_signed !==
                                  undefined &&
                                appCounsStatus.is_documents_signed === 0
                                  ? true
                                  : false
                              }
                            >
                              <MenuItem value="">
                                <em>Select Document Form</em>
                              </MenuItem>
                              {docuforms.map((docuformslist, frm_index) => (
                                <MenuItem
                                  key={`form_${frm_index}`}
                                  value={docuformslist.id}
                                >
                                  {docuformslist.name}
                                </MenuItem>
                              ))}
                            </Select>
                            {Boolean(touched.form_id && errors.form_id) ? (
                              <FormHelperText className="Mui-error">
                                {errors.form_id}
                              </FormHelperText>
                            ) : null}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} style={{ padding: "0 12px" }}>
                          <Autocomplete
                            multiple
                            id="form_users"
                            options={userlistdata}
                            name="form_users"
                            value={
                              values.form_users !== undefined
                                ? values.form_users
                                : []
                            }
                            disableCloseOnSelect
                            filterOptions={filterOptions}
                            onChange={(e, newValue) => {
                              setFieldValue("form_users", newValue);
                            }}
                            groupBy={(option) =>
                              !option.isUser && (
                                <ListSubheader
                                  key={option.role_flag}
                                  disableSticky={true}
                                >
                                  {option.name}
                                </ListSubheader>
                              )
                            }
                            getOptionLabel={(option) => option.name}
                            renderOption={(option, { selected }) =>
                              option.isUser && (
                                <React.Fragment>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={
                                      values.id !== undefined
                                        ? values.form_users.find(
                                            (v) =>
                                              v.id === option.id &&
                                              v.role_flag === option.role_flag
                                          )
                                          ? true
                                          : false
                                        : selected
                                    }
                                  />
                                  <div
                                    key={option.id}
                                    className="MuiListItemText-root MuiListItemText-multiline"
                                  >
                                    <span className="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                                      {option.name}
                                    </span>
                                    <div
                                      className="MuiTypography-root MuiListItemText-secondary MuiTypography-body2 MuiTypography-colorTextSecondary MuiTypography-displayBlock"
                                      dangerouslySetInnerHTML={{
                                        __html: option.desc,
                                      }}
                                      style={{
                                        maxWidth: 500,
                                      }}
                                    ></div>
                                  </div>
                                </React.Fragment>
                              )
                            }
                            fullWidth
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Form Users"
                                placeholder="Search Users"
                                margin="normal"
                              />
                            )}
                          />
                          {Boolean(touched.form_users && errors.form_users) ? (
                            <FormHelperText className="Mui-error">
                              {errors.form_users}
                            </FormHelperText>
                          ) : null}
                        </Grid>
                      </Grid> */}
                    </Grid>
                  </CardContent>
                  <Box display="flex" justifyContent="flex-end" p={2}>
                    <Button
                      style={{ marginRight: "7px" }}
                      variant="contained"
                      component={RouterLink}
                      to={"/app/counselor/" + appid}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={isSubmitting}
                      // onClick={handleStatusOpen}
                    >
                      Save
                    </Button>
                  </Box>
                  <ConformationDialog
                    className={classes.paper}
                    id="confirmation-dialog-title"
                    keepMounted
                    open={openStatusBox}
                    onClose={handleCloseStatusBox}
                    onConformAction={onStatusSubmit}
                    mode={"app_status"}
                  />
                </Card>
              </Container>
            </form>
          </Page>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManualAction);
