import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import * as Yup from "yup";
import { FieldArray, Formik } from "formik";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
} from "@material-ui/core";
import Cancelbtn from "src/components/common/Cancelbtn";
import * as Messages from "src/constants/Messages/index";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const AddRoleSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, Messages.TOO_SHORT)
    .required(Messages.REQUIRED),
});
const RoleDetail = (props) => {
  const classes = useStyles();
  const { modules, formValues, addRole } = props;
  // console.log(modules)
  const initialValues = {
    name: formValues.name,
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={AddRoleSchema}
      onSubmit={async (values, actions) => {
        await addRole(values, actions);
        //actions.setSubmitting(false);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Card>
            <CardHeader
              subheader="The information can be add"
              title="Add Role"
            />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    name="name"
                    label="Role Title"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.first_name}
                    error={Boolean(touched.name && errors.name)}
                    margin="normal"
                    helperText={touched.name && errors.name}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Module Name</TableCell>
                        <TableCell align="center">View</TableCell>
                        <TableCell align="center">Add</TableCell>
                        <TableCell align="center">Edit</TableCell>
                        <TableCell align="center">Delete</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {modules.map((module, index) => {
                        return (
                          <FieldArray
                            key={index}
                            name={`modules`}
                            render={(arrayHelpers) => (
                              <TableRow key={index}>
                                <TableCell>{module.name}</TableCell>
                                <TableCell align="center">
                                  <Checkbox
                                    name={`modules.${index}.view`}
                                    color="primary"
                                    value={1}
                                    checked={
                                      values.modules === undefined
                                        ? false
                                        : values.modules[index] === undefined
                                        ? false
                                        : values.modules[index].view ===
                                          undefined
                                        ? false
                                        : values.modules[index].view !== 1
                                        ? false
                                        : true
                                    }
                                    onChange={(e) => {
                                      let is_view =
                                        values.modules === undefined
                                          ? 0
                                          : values.modules[index] === undefined
                                          ? 0
                                          : values.modules[index].view ===
                                            undefined
                                          ? 0
                                          : values.modules[index].view;
                                      let is_add =
                                        values.modules === undefined
                                          ? 0
                                          : values.modules[index] === undefined
                                          ? 0
                                          : values.modules[index].add ===
                                            undefined
                                          ? 0
                                          : values.modules[index].add;
                                      let is_edit =
                                        values.modules === undefined
                                          ? 0
                                          : values.modules[index] === undefined
                                          ? 0
                                          : values.modules[index].edit ===
                                            undefined
                                          ? 0
                                          : values.modules[index].edit;
                                      let is_delete =
                                        values.modules === undefined
                                          ? 0
                                          : values.modules[index] === undefined
                                          ? 0
                                          : values.modules[index].delete ===
                                            undefined
                                          ? 0
                                          : values.modules[index].delete;

                                      if (e.target.checked) {
                                        is_view = 1;
                                        if (
                                          values.modules === undefined ||
                                          values.modules[index] === undefined
                                        ) {
                                          arrayHelpers.replace(index, {
                                            view: is_view,
                                            add: is_add,
                                            edit: is_edit,
                                            delete: is_delete,
                                          });
                                        } else if (
                                          values.modules[index].view ===
                                          undefined
                                        ) {
                                          arrayHelpers.replace(index, {
                                            view: is_view,
                                            add: is_add,
                                            edit: is_edit,
                                            delete: is_delete,
                                          });
                                        } else {
                                          arrayHelpers.replace(index, {
                                            view: is_view,
                                            add: is_add,
                                            edit: is_edit,
                                            delete: is_delete,
                                          });
                                        }
                                      } else {
                                        is_view = 0;
                                        if (
                                          is_add === 0 &&
                                          is_edit === 0 &&
                                          is_delete === 0
                                        ) {
                                          arrayHelpers.replace(index, {
                                            view: is_view,
                                            add: is_add,
                                            edit: is_edit,
                                            delete: is_delete,
                                          });
                                        }
                                      }
                                    }}
                                  />
                                </TableCell>
                                <TableCell align="center">
                                  <Checkbox
                                    name={`modules.${index}.add`}
                                    color="primary"
                                    value={1}
                                    disabled={
                                      module.is_add !== 1 ? true : false
                                    }
                                    checked={
                                      values.modules === undefined
                                        ? false
                                        : values.modules[index] === undefined
                                        ? false
                                        : values.modules[index].add ===
                                          undefined
                                        ? false
                                        : values.modules[index].add !== 1
                                        ? false
                                        : true
                                    }
                                    onChange={(e) => {
                                      let is_view =
                                        values.modules === undefined
                                          ? 0
                                          : values.modules[index] === undefined
                                          ? 0
                                          : values.modules[index].view ===
                                            undefined
                                          ? 0
                                          : values.modules[index].view;
                                      let is_add =
                                        values.modules === undefined
                                          ? 0
                                          : values.modules[index] === undefined
                                          ? 0
                                          : values.modules[index].add ===
                                            undefined
                                          ? 0
                                          : values.modules[index].add;
                                      let is_edit =
                                        values.modules === undefined
                                          ? 0
                                          : values.modules[index] === undefined
                                          ? 0
                                          : values.modules[index].edit ===
                                            undefined
                                          ? 0
                                          : values.modules[index].edit;
                                      let is_delete =
                                        values.modules === undefined
                                          ? 0
                                          : values.modules[index] === undefined
                                          ? 0
                                          : values.modules[index].delete ===
                                            undefined
                                          ? 0
                                          : values.modules[index].delete;

                                      if (e.target.checked) {
                                        is_add = 1;
                                        is_view = 1;
                                        if (
                                          values.modules === undefined ||
                                          values.modules[index] === undefined
                                        ) {
                                          arrayHelpers.replace(index, {
                                            view: is_view,
                                            add: is_add,
                                            edit: is_edit,
                                            delete: is_delete,
                                          });
                                        } else if (
                                          values.modules[index].view ===
                                          undefined
                                        ) {
                                          arrayHelpers.replace(index, {
                                            view: is_view,
                                            add: is_add,
                                            edit: is_edit,
                                            delete: is_delete,
                                          });
                                        } else {
                                          arrayHelpers.replace(index, {
                                            view: is_view,
                                            add: is_add,
                                            edit: is_edit,
                                            delete: is_delete,
                                          });
                                        }
                                      } else {
                                        is_add = 0;
                                        arrayHelpers.replace(index, {
                                          view: is_view,
                                          add: is_add,
                                          edit: is_edit,
                                          delete: is_delete,
                                        });
                                      }
                                    }}
                                  />
                                </TableCell>
                                <TableCell align="center">
                                  <Checkbox
                                    name={`modules.${index}.edit`}
                                    color="primary"
                                    value={1}
                                    disabled={
                                      module.is_edit !== 1 ? true : false
                                    }
                                    checked={
                                      values.modules === undefined
                                        ? false
                                        : values.modules[index] === undefined
                                        ? false
                                        : values.modules[index].edit ===
                                          undefined
                                        ? false
                                        : values.modules[index].edit !== 1
                                        ? false
                                        : true
                                    }
                                    onChange={(e) => {
                                      let is_view =
                                        values.modules === undefined
                                          ? 0
                                          : values.modules[index] === undefined
                                          ? 0
                                          : values.modules[index].view ===
                                            undefined
                                          ? 0
                                          : values.modules[index].view;
                                      let is_add =
                                        values.modules === undefined
                                          ? 0
                                          : values.modules[index] === undefined
                                          ? 0
                                          : values.modules[index].add ===
                                            undefined
                                          ? 0
                                          : values.modules[index].add;
                                      let is_edit =
                                        values.modules === undefined
                                          ? 0
                                          : values.modules[index] === undefined
                                          ? 0
                                          : values.modules[index].edit ===
                                            undefined
                                          ? 0
                                          : values.modules[index].edit;
                                      let is_delete =
                                        values.modules === undefined
                                          ? 0
                                          : values.modules[index] === undefined
                                          ? 0
                                          : values.modules[index].delete ===
                                            undefined
                                          ? 0
                                          : values.modules[index].delete;

                                      if (e.target.checked) {
                                        is_edit = 1;
                                        is_view = 1;
                                        if (
                                          values.modules === undefined ||
                                          values.modules[index] === undefined
                                        ) {
                                          arrayHelpers.replace(index, {
                                            view: is_view,
                                            add: is_add,
                                            edit: is_edit,
                                            delete: is_delete,
                                          });
                                        } else if (
                                          values.modules[index].view ===
                                          undefined
                                        ) {
                                          arrayHelpers.replace(index, {
                                            view: is_view,
                                            add: is_add,
                                            edit: is_edit,
                                            delete: is_delete,
                                          });
                                        } else {
                                          arrayHelpers.replace(index, {
                                            view: is_view,
                                            add: is_add,
                                            edit: is_edit,
                                            delete: is_delete,
                                          });
                                        }
                                      } else {
                                        is_edit = 0;
                                        arrayHelpers.replace(index, {
                                          view: is_view,
                                          add: is_add,
                                          edit: is_edit,
                                          delete: is_delete,
                                        });
                                      }
                                    }}
                                  />
                                </TableCell>
                                <TableCell align="center">
                                  <Checkbox
                                    name={`modules.${index}.delete`}
                                    color="primary"
                                    value={1}
                                    disabled={
                                      module.is_delete !== 1 ? true : false
                                    }
                                    checked={
                                      values.modules === undefined
                                        ? false
                                        : values.modules[index] === undefined
                                        ? false
                                        : values.modules[index].delete ===
                                          undefined
                                        ? false
                                        : values.modules[index].delete !== 1
                                        ? false
                                        : true
                                    }
                                    onChange={(e) => {
                                      let is_view =
                                        values.modules === undefined
                                          ? 0
                                          : values.modules[index] === undefined
                                          ? 0
                                          : values.modules[index].view ===
                                            undefined
                                          ? 0
                                          : values.modules[index].view;
                                      let is_add =
                                        values.modules === undefined
                                          ? 0
                                          : values.modules[index] === undefined
                                          ? 0
                                          : values.modules[index].add ===
                                            undefined
                                          ? 0
                                          : values.modules[index].add;
                                      let is_edit =
                                        values.modules === undefined
                                          ? 0
                                          : values.modules[index] === undefined
                                          ? 0
                                          : values.modules[index].edit ===
                                            undefined
                                          ? 0
                                          : values.modules[index].edit;
                                      let is_delete =
                                        values.modules === undefined
                                          ? 0
                                          : values.modules[index] === undefined
                                          ? 0
                                          : values.modules[index].delete ===
                                            undefined
                                          ? 0
                                          : values.modules[index].delete;

                                      if (e.target.checked) {
                                        is_delete = 1;
                                        is_view = 1;
                                        if (
                                          values.modules === undefined ||
                                          values.modules[index] === undefined
                                        ) {
                                          arrayHelpers.replace(index, {
                                            view: is_view,
                                            add: is_add,
                                            edit: is_edit,
                                            delete: is_delete,
                                          });
                                        } else if (
                                          values.modules[index].view ===
                                          undefined
                                        ) {
                                          arrayHelpers.replace(index, {
                                            view: is_view,
                                            add: is_add,
                                            edit: is_edit,
                                            delete: is_delete,
                                          });
                                        } else {
                                          arrayHelpers.replace(index, {
                                            view: is_view,
                                            add: is_add,
                                            edit: is_edit,
                                            delete: is_delete,
                                          });
                                        }
                                      } else {
                                        is_delete = 0;
                                        arrayHelpers.replace(index, {
                                          view: is_view,
                                          add: is_add,
                                          edit: is_edit,
                                          delete: is_delete,
                                        });
                                      }
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            )}
                          />
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Cancelbtn />
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={isSubmitting}
              >
                Save
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default RoleDetail;
