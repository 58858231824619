import React from "react";
import { Grid, Button } from "@mui/material";
import { yellow } from "@mui/material/colors";

export default function index({ setSecureArea }) {
  return (
    <Grid container className="this-area" direction={"column"}>
      <h2>This is a Secure Area </h2>
      <p>
        Thank you for choosing Hollywood Forever. Your Privacy and security are
        important to us.
      </p>
      <p>
        if you are using the shared computer, please remember to logout after
        each session.
      </p>
      <Grid item md={2}>
        <Button
          variant="filled"
          style={{ backgroundColor: "#D8B34F", color: "#fff" }}
          onClick={setSecureArea}
          // color="warning"
        >
          Continue
        </Button>
      </Grid>
    </Grid>
  );
}
