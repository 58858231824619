import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent } from "@material-ui/core";
import Page from "src/components/Page";
import { useParams } from "react-router";
import * as Http from "src/services/Http";
import ScreenLoader from "src/components/ScreenLoader";
import ShippingDetails from "./ShippingDetails";
// import { setSnackbar } from "src/actions";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {
    marginLeft: theme.spacing(2),
  },
}));

const Shipping = () => {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    userId: "",
    shippmentType: "",
    recipient: "",
    shipmentAddress: "",
    weight: "",
    trackingId: "",
    shipmentStatus: "Pending",
    shipmentOrderDate: "",
    sheduleCollectionDate: "",
    dateCollected: "",
  });
  const [shipmentTypeList, setShipmentTypeList] = useState([]);
  const [isShipmentDone, setIsShipmentDone] = useState(0);
  const [Loading, setLoading] = useState(true);
  const { id } = useParams();
  let userID = "";
  if (id === undefined) {
    userID = localStorage.getItem("rand_token");
    userID = !isNaN(+userID) === false ? undefined : userID;
  } else{
    userID = id;
  }

  const fetchData = async () => {
    try {
      if(userID){
      await Http.call("GET", `admin/ship/` + userID).then(async (res) => {
        const responseData = await res.data;

        setShipmentTypeList(responseData.collection_type_list);

        setIsShipmentDone(responseData.status);

        setFormData({
          userId: userID,
          shippmentType: responseData.collect_type_selected,
          recipient: responseData?.collection?.collection_recipient,
          shipmentAddress: responseData?.delivery?.collection_address,
          weight: "",
          trackingId: responseData?.delivery?.urn_tracking_number,
          shipmentStatus: responseData?.delivery?.shipment_status,
          shipmentOrderDate: 
            responseData?.delivery?.ship_order_date
          ,
          sheduleCollectionDate: formatDateTimeLocal(
            responseData?.collection?.shedule_collection_date
          ),
          dateCollected: formatDateTimeLocal(
            responseData?.collection?.date_collected
          ),
        });
      });
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const saveShippingData = async (data) => {
    setLoading(true);
    try {
      await Http.call("POST", "admin/shippinginfo", data).then(async (res) => {
        await fetchData();
        setLoading(false);
      });
    } catch (error) {
      console.error("Error updating weight:", error.message);
      // setSnackbar(true, "Pelase add a weight");

    }
  };

  const formatDateTimeLocal = (dateString) => {
    if (!dateString) return ""; // Return empty string if dateString is undefined or null
    const dateTimeParts = dateString.split(" ");
    if (dateTimeParts.length !== 3) return ""; // Check if the format is as expected
    const [datePart, timePart, meridiem] = dateTimeParts;
    const [month, day, year] = datePart.split("-");
    const [hours, minutes, seconds] = timePart.split(":");

    // Convert hours to 24-hour format
    let formattedHours = hours;
    if (meridiem.toLowerCase() === "pm" && hours !== "12") {
      formattedHours = String(parseInt(hours, 10) + 12);
    } else if (meridiem.toLowerCase() === "am" && hours === "12") {
      formattedHours = "00";
    }

    const formattedDate = `${year}-${month}-${day}T${formattedHours}:${minutes}`;
    return formattedDate;
  };

  const formatDateForServer = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Add leading zero if single digit
    const day = date
      .getDate()
      .toString()
      .padStart(2, "0"); // Add leading zero if single digit
    const hours = date
      .getHours()
      .toString()
      .padStart(2, "0"); // Add leading zero if single digit
    const minutes = date
      .getMinutes()
      .toString()
      .padStart(2, "0"); // Add leading zero if single digit
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };



  if (Loading) {
    return <ScreenLoader />;
  } else {
    return (
      <Page title="Shipping" className={classes.root}>
        <Card>
          <CardContent>
            <ShippingDetails
              formData={formData}
              shipmentTypeList={shipmentTypeList}
              saveShippingData={saveShippingData}
              formatDateForServer={formatDateForServer}
              isShipmentDone={isShipmentDone}
            />
          </CardContent>
        </Card>
      </Page>
    );
  }
};

export default Shipping;
