import { combineReducers } from "redux";
import globalReducer from "./globalReducer";
import sessionReducer from "./sessionReducer";
import bannerReducer from "./bannerReducer";
import packageReducer from "./packageReducer";
import questionnairReducer from "./questionnairReducer";
import postQuestionnairReducer from "./postQuestionnairReducer";
import greatQuestionnairReducer from "./greatWesternQuestionnairReducer";

const rootReducer = combineReducers({
  session: sessionReducer,
  global: globalReducer,
  banners: bannerReducer,
  package: packageReducer,
  questionslist: questionnairReducer,
  postQuestionnair: postQuestionnairReducer,
  greatQuestionnair: greatQuestionnairReducer,
});

export default rootReducer;
