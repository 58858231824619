import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import * as Http from "src/services/Http";
import { makeStyles } from "@material-ui/core";
import Page from "src/components/Page";
import { Header, EmailDetails } from "./components";
import ScreenLoader from "src/components/ScreenLoader";
import { setSnackbar } from "src/actions";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: "100%",
    margin: "0 auto",
    padding: theme.spacing(3, 3, 6, 3),
  },
  aboutAuthor: {
    marginTop: theme.spacing(3),
  },
  emailDetails: {
    marginTop: theme.spacing(3),
  },
  projectCover: {
    marginTop: theme.spacing(3),
  },
  projectDetails: {
    marginTop: theme.spacing(3),
  },
  preferences: {
    marginTop: theme.spacing(3),
  },
  actions: {
    marginTop: theme.spacing(3),
  },
}));

const EditEmailtemplate = (props) => {
  const classes = useStyles();
  const { id } = useParams();
  const location = useLocation();
  const paths = location.pathname.split("/");
  const email_type = paths[3];
  const email_mode_type = paths[4];

  const [templateData, setTemplateData] = useState({
    code: "",
    doc_form_id: [],
    email_message: "",
    email_subject: "",
    email_title: "",
    type: email_type !== "manual" ? 0 : 1,
    id: "",
  });
  const [Loading, setLoading] = useState(true);
  const title =
    email_mode_type === "add" ? "Add Email Template " : "Edit Email Template";
  useEffect(() => {
    const fetchTemplate = async () => {
      try {
        const response = await Http.call("GET", "admin/email_templates/" + id);
        setTemplateData(response.data);
        setLoading(false);
        return response;
      } catch (e) {
        console.log(e);
      }
    };
    if (id !== undefined) {
      fetchTemplate();
    } else {
      setLoading(false);
    }
  }, [id]);
  const handleChange = (event) => {
    setTemplateData({
      ...templateData,
      [event.target.name]: event.target.value,
    });
  };
  const navigate = useNavigate();
  const updateEmailtemplatedetails = async (data) => {
    let status = 401;
    await Http.call("PUT", "admin/email_templates/" + templateData.id, {
      ...data,
    }).then(async (response) => {
      status = response.status;
      props.setSnackbar(true, response.status, response.message);
      if (status === 200 || status === 201) {
        email_type !== "manual"
          ? navigate("/app/emailtemplate")
          : navigate("/app/emailtemplate/manual");
      }
    });
  };
  const addEmailtemplatedetails = async (data) => {
    let status = 401;
    await Http.call("POST", "admin/email_templates", {
      ...data,
    }).then(async (response) => {
      status = response.status;
      props.setSnackbar(true, response.status, response.message);
      if (status === 200 || status === 201) {
        navigate("/app/emailtemplate/manual");
      }
    });
  };
  // const [customers] = useState(data);
  if (Loading) {
    return <ScreenLoader />;
  }
  return (
    <Page className={classes.root} title={title}>
      <Header />
      <EmailDetails
        className={classes.emailDetails}
        handleChange={handleChange}
        templateData={templateData}
        updateEmailtemplatedetails={updateEmailtemplatedetails}
        addEmailtemplatedetails={addEmailtemplatedetails}
      />
    </Page>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditEmailtemplate);
