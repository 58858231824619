import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
} from "@material-ui/core";
import "src/styles/custom.css";
import { useNavigate, useParams } from "react-router-dom";

export const ThankYouView = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();

  let is_Preneed = "";

  if (id === undefined) {
    is_Preneed = localStorage.getItem("isPreneed");
  } else {
    is_Preneed = localStorage.getItem("isPreneed_" + id);
  }

  function startQuestionnary() {
    props.setTabval("step5");
    props.setdisableTab([
      "step2",
      "step3",
      "step4",
      parseInt(is_Preneed) === 1 ? "" : "step6",
      "step7",
    ]);
  }

  function Complete() {
    if (id === undefined) {
      localStorage.removeItem("rand_token");
      localStorage.removeItem("isPreneed");
    } else {
      localStorage.removeItem("isPreneed_" + id);
    }

    navigate("/app/counselors");
  }

  return (
    <Card>
      <CardHeader title="" />
      <CardContent>
        <Grid container spacing={3} justify="center">
          <Grid item md={9}>
            <div className="thank-box">
              <h2>Thank You For Choosing Fernwood Cemetery</h2>
              <p>Your payment has been successfully processed.</p>
              <br />
            </div>
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="center">
          {props.paymentFlag == "payment" ? (
            <Button
              color="primary"
              variant="contained"
              type="button"
              onClick={startQuestionnary}
            >
              Start Questionnaire
            </Button>
          ) : (
            <Button
              color="primary"
              variant="contained"
              type="button"
              onClick={Complete}
            >
              Complete
            </Button>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default ThankYouView;
