import React, { useState } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Button,
  ListItem,
  makeStyles,
  // ListItemIcon,
  // ListItemText,
  Collapse,
  List,
} from "@material-ui/core";
import { ExpandLess, ExpandMore /*,StarBorder*/ } from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: "flex-start",
    letterSpacing: 0,
    padding: "10px 8px",
    textTransform: "none",
    width: "100%",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  title: {
    marginRight: "auto",
  },
  active: {
    color: theme.palette.text.secondary,
    backgroundColor: "rgba(38, 50, 56, 0.04)",
    padding: "10px 8px",
    "& $title": {
      fontWeight: theme.typography.fontWeightMedium,
      textTransform: "none",
      marginRight: "auto",
    },
    "& $icon": {
      color: theme.palette.text.secondary,
    },
    width: "100%",
  },
  nested: {
    paddingLeft: theme.spacing(4),
    textTransform: "none",
    fontWeight: "normal",
  },
}));

const NavItem = ({ lists, pathname, ...rest }) => {
  const { className, href, icon: Icon, id, children, title, isActive } = lists;
  const getlowerpath = (str) => str.toLowerCase();
  const classes = useStyles();
  const [open, setOpen] = useState(isActive !== undefined && isActive);
  const handleClick = () => {
    setOpen(!open);
  };
  // console.log("lists ->" + JSON.stringify(lists.children));
  return (
    <>
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        {...rest}
      >
        {children && children !== undefined ? (
          <Button
            // activeClassName={classes.active}
            className={classes.button}
            // component={RouterLink}
            // to={href}
            onClick={handleClick}
          >
            {Icon && <Icon className={classes.icon} size="20" />}
            <span className={classes.title}>{title}</span>
            {lists.children ? open ? <ExpandLess /> : <ExpandMore /> : ""}
          </Button>
        ) : (
          <Button
            // activeClassName={classes.active}
            className={
              getlowerpath(href) === pathname ? classes.active : classes.button
            }
            component={RouterLink}
            to={href}
            onClick={handleClick}
          >
            {Icon && <Icon className={classes.icon} size="20" />}
            <span className={classes.title}>{title}</span>
            {lists.children ? open ? <ExpandLess /> : <ExpandMore /> : ""}
          </Button>
        )}
      </ListItem>
      {lists.children &&
        lists.children.map((item) => (
          <Collapse key={item.title} in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Button
                // activeClassName={classes.active}
                className={
                  getlowerpath(item.href) === pathname
                    ? [classes.active, classes.nested]
                    : [classes.button, classes.nested]
                }
                component={RouterLink}
                to={item.href}
                // className={classes.nested}
              >
                {item.icon && item.icon.displayName === "Home" ? (
                  <FontAwesomeIcon
                    icon={faBuilding}
                    className={classes.icon}
                    size="lg"
                  />
                ) : (
                  <item.icon className={classes.icon} size="20" />
                )}
                <span className={classes.title}>{item.title}</span>
              </Button>
            </List>
          </Collapse>
        ))}
    </>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string,
};

export default NavItem;
