import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import Cancelbtn from "src/components/common/Cancelbtn";
import "react-phone-input-2/lib/material.css";
import CustomPhoneInput from "src/components/common/CustomPhoneInput";
import PasswordInput from "src/components/common/PasswordInput";
import * as Messages from "src/constants/Messages/index";
import TextMaskCustom from "src/components/TextMaskCustom";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectControl: {
    minWidth: "100%",
  },
}));

//const phoneRegExp = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;

const AddUserSchema = Yup.object().shape({
  first_name: Yup.string()
    // .min(2, Messages.TOO_SHORT)
    // .matches(/^[A-Za-z ]*$/, Messages.ONLY_ALPABATES)
    .required(Messages.REQUIRED),
  last_name: Yup.string()
    // .min(2, Messages.TOO_SHORT)
    // .matches(/^[A-Za-z ]*$/, Messages.ONLY_ALPABATES)
    .required(Messages.REQUIRED),
  email: Yup.string()
    .lowercase()
    .email(Messages.VALID_EMAIL)
    .required(Messages.REQUIRED),
  password: Yup.string()
    .required(Messages.REQUIRED)
    .min(6, Messages.PASSWORD_MIN_6)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/,
      Messages.INVALID_PASSWORD
    ),
  confirmPassword: Yup.string()
    .required(Messages.REQUIRED)
    .oneOf([Yup.ref("password"), null], Messages.PASSWORD_SAME),
  // mobile: Yup.string().required(Messages.REQUIRED),
  role: Yup.string().required(Messages.REQUIRED),
});
const UserDetail = (props) => {
  const classes = useStyles();
  const { roles, formValues, addUser } = props;
  const [checkPhoneNumber, setCheckPhoneNumber] = useState(0);
  const initialValues = {
    first_name: formValues.first_name,
    last_name: formValues.last_name,
    email: formValues.email,
    mobile: formValues.mobile,
    password: formValues.password,
    confirmPassword: "",
    role: formValues.role,
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={AddUserSchema}
      onSubmit={async (values, actions) => {
        if (checkPhoneNumber !== 1) {
          await addUser(values, actions);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => (
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Card>
            <CardHeader
              subheader="Please complete the fields below to add a User"
              title="Add Admin User"
            />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    name="first_name"
                    label="First Name"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.first_name}
                    error={Boolean(touched.first_name && errors.first_name)}
                    margin="normal"
                    helperText={touched.first_name && errors.first_name}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Last Name"
                    name="last_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.last_name}
                    margin="normal"
                    variant="outlined"
                    error={Boolean(touched.last_name && errors.last_name)}
                    helperText={touched.last_name && errors.last_name}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Email"
                    margin="normal"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    type="email"
                    variant="outlined"
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                      fullWidth
                      label="Phone Number"
                      margin="normal"
                      name="mobile"
                      variant="outlined"
                      value={values.mobile}
                      onBlur={handleBlur}
                      onChange={(event) => {
                          setFieldValue("mobile", event.target.value);
                      }}
                      InputProps={{
                          inputComponent: TextMaskCustom,
                      }}
                  />
                  {/* <CustomPhoneInput
                    name={"mobile"}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    setCheckPhoneNumber={setCheckPhoneNumber}
                  /> */}
                </Grid>
                <Grid item md={6} xs={12}>
                  <PasswordInput label="Password" name="password" />
                </Grid>
                <Grid item md={6} xs={12}>
                  <PasswordInput
                    label="Confirm Password"
                    name="confirmPassword"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl
                    variant="outlined"
                    className={classes.selectControl}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Role
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      label="Role"
                      name="role"
                      value={values.role}
                      onChange={handleChange}
                      error={Boolean(touched.role && errors.role)}
                      fullWidth
                    >
                      <MenuItem value="">
                        <em>Select Role</em>
                      </MenuItem>

                      {roles.map((role, index) => (
                        <MenuItem key={index} value={role.id}>
                          {role.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Cancelbtn />
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={isSubmitting}
              >
                Save
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default UserDetail;
