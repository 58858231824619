import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Grid, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { setSnackbar } from "src/actions";
import * as Http from "src/services/Http";
import ScreenLoader from "src/components/ScreenLoader";
import Page from "src/components/Page";
import PromoDetails from "../Components/PromoDetails";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";
/*import Toolbar from "./Toolbar";*/

const breadUrlList = [
  { name: "Home", url: "/dashboard" },
  { name: "Service Discount", url: "/app/discount" },
  { name: "Edit Service Discount", url: "/" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  discountDetail: {
    marginTop: theme.spacing(3),
  },
}));

const EditDiscountView = (props) => {
  const { id } = useParams();
  const classes = useStyles();
  const [Loading, setLoading] = useState(true);
  const [values, setValues] = useState({
    title: "",
    promocode: "",
    percentage: "",
    start_date: "",
    end_date: "",
    status: "",
  });
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await Http.call("GET", "admin/discounts/" + id);
      setValues(data);
      setLoading(false);
    };
    fetchData();
  }, [id]);

  if (Loading) {
    return <ScreenLoader />;
  }
  return (
    <Page className={classes.root} title="Edit Discount">
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          {/* <Grid item md={2} xs={12}></Grid> */}
          <Grid item md={12} xs={12}>
            <BreadcrumbsAdmin urlList={breadUrlList} />
            {/*<Toolbar />*/}
            <PromoDetails
              formValues={values}
              setSnackbar={props.setSnackbar}
              promoClass={classes.discountDetail}
            />
          </Grid>
          {/* <Grid item md={2} xs={12}></Grid> */}
        </Grid>
      </Container>
    </Page>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDiscountView);
