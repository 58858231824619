export const SET_CRT_PRICE = "SET_CRT_PRICE";
export const SET_PACKAGE_DATA = "SET_PACKAGE_DATA";
export const EMPTY_PACKAGE_DATA = "EMPTY_PACKAGE_DATA";
export const SET_PACKAGE_SELECT = "SET_PACKAGE_SELECT";

export const setCertificatePrice = (arg) => (dispatch) => {
  dispatch({
    type: SET_CRT_PRICE,
    payload: {
      price: arg,
    },
  });
};
export const setPackageData = (value) => (dispatch) => {
  dispatch({
    type: SET_PACKAGE_DATA,
    payload: {
      value: value,
    },
  });
};
export const emptyPackageData = () => (dispatch) => {
  dispatch({
    type: EMPTY_PACKAGE_DATA,
    payload: {},
  });
};
export const setPackageSelect = (arg) => (dispatch) => {
  dispatch({
    type: SET_PACKAGE_SELECT,
    payload: {
      isPackageSelected: arg,
    },
  });
};
