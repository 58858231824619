import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import * as Http from "src/services/Http";
import ScreenLoader from "src/components/ScreenLoader";
import PackageBody from "./Components/PackageBody/index";
import { useParams } from "react-router-dom";
import Page from "src/components/Page";
import {
  setCertificatePrice,
  setPackageData,
  emptyPackageData,
} from "src/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
  },
}));

export const PackageInfoView = (props) => {
  const classes = useStyles();
  const [data, setData] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [certificate, setCertificate] = useState(1);
  const {
    setCertificatePrice,
    setPackageData,
    emptyPackageData,
    packageData,
  } = props;
  const { id } = useParams();

  let user_id = "";
  let is_Preneed = "";

  if (id === undefined) {
    user_id = localStorage.getItem("rand_token");
    is_Preneed = localStorage.getItem("isPreneed");
  } else {
    user_id = id;
    is_Preneed = localStorage.getItem("isPreneed_" + id);
  }

  const getPackagedata = async () => {
    emptyPackageData();

    const { data, status, message } = await Http.call(
      "POST",
      "packages-services",
      { user_id: user_id, flag: "admin" }
    );
    if (status === 200) {
      setCirtificateCount(parseInt(data.certificate_count));
      setData(data);
      setCertificatePrice(parseInt(data.certificate_rate));

      data.addonCategory.map((item, index) => {
        item.addOns.map((additem, addindex) => {
          if (additem.is_selected) {
            setPackageData({
              id: additem.id,
              catId: item.id,
              name: additem.service_name,
              price: additem.price,
              type: item.selection_type,
            });
          }
        });
      });
      setLoading(false);
    } else {
      alert("API ERO+ROR" + message);
    }
  };

  const setCirtificateCount = (arg) => {
    setCertificate(arg);
  };

  const savePackage = async () => {
    let addOns = [];
    // packageData.categoryData.map((item, index) => (addOns[index] = item.id));
    packageData.categoryData.map((item, index) => {
      // if (!selectedCategories.includes(item.catId)) {
      //   selectedCategories.push(item.catId);
      // }

      // if (item.address !== undefined && item.address !== "") {
      //   deliveryAddress = item.address;
      // }

      return addOns.push({
        id: item.id,
        quantity: item.quantity ? item.quantity : 0,
        state: item.state !== undefined ? item.state : "",
        address: item.address !== undefined ? item.address : "",
      });
    });
    await Http.call("POST", "add_subscription_details", {
      user_id: user_id,
      package_id: data.id,
      no_of_cert: certificate,
      addons_ids: addOns,
    }).then(async ({ status, message, data }) => {
      if (status === 200) {
        if (parseInt(is_Preneed) === 1) {
          props.setTabval("step6");
          props.setdisableTab(["step2", "step4", "step5", "step7","step12"]);
          // props.setdisableTab({
          //   tab_2:true,
          //   tab_3:false,
          //   tab_4:true,
          //   tab_5:true,
          //   tab_6:false,
          //   tab_7:true,
          // })
        } else {
          props.setTabval("step4");
          props.setdisableTab(["step2", "step5", "step6", "step7","step12"]);
          // props.setdisableTab({
          //   tab_2:true,
          //   tab_3:false,
          //   tab_4:false,
          //   tab_5:true,
          //   tab_6:true,
          //   tab_7:true,
          // })
        }
      } else if (status === 400) {
        console.log(message);
      }
    });
  };

  useEffect(() => {
    getPackagedata();
  }, []);

  const getTotal = () => {
    let packagePrice = data.price;
    let addOnstotal = parseFloat(packagePrice);
    packageData.categoryData.map((item, index) => {
      addOnstotal = addOnstotal + item.price;
    });
    let total =
      addOnstotal +
      certificate * packageData.certificatePrice -
      packageData.certificatePrice;
    return total;
  };

  if (isLoading) {
    return <ScreenLoader />;
  }

  return (
    <Page title="Add Services" className={classes.root}>
      <PackageBody
        data={data}
        total={getTotal}
        certificate={certificate}
        setCertificate={setCirtificateCount}
        crtPrice={packageData.certificatePrice}
        selected={packageData.categoryData}
        setTabval={props.setTabval}
        /*setdisableTab={props.setdisableTab}
        disableTabVal={props.disableTabVal}*/
        savePackage={savePackage}
      />
    </Page>
  );
};

const mapStateToProps = (state) => ({
  packageData: state.package,
});

const mapDispatchToProps = {
  setCertificatePrice,
  setPackageData,
  emptyPackageData,
};

export default connect(mapStateToProps, mapDispatchToProps)(PackageInfoView);
