import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Box, Button, makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { chkRoutePerm } from "src/services/Helper";

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

const Toolbar = ({ className, ...rest }) => {
  const classes = useStyles();
  let chkRoutPerm = chkRoutePerm("/app/questionnaire/add", rest.authuser);

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {/* <Typography variant="h3" component="h3">
          Questions List
        </Typography> */}
        {chkRoutPerm ? (
          <Button
            color="primary"
            variant="contained"
            component={RouterLink}
            to="/app/questionnaire/add"
          >
            Add Question
          </Button>
        ) : (
          ""
        )}
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
