import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import { useField } from "formik";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";

const AutoSuggessionField = ({ options, idKey, labelKey, ...props }) => {
  const [field, meta, helpers] = useField(props);

  const found = options.find((obj) => {
    return field.value == obj[idKey];
  });

  const [selected, setSelected] = useState(found);

  return (
    <Autocomplete
      id={props.autosugid ? props.autosugid : "combo-box-demo"}
      name={field.name}
      options={options}
      getOptionLabel={(option) => (option ? option[labelKey] : "")}
      getOptionSelected={(option, value) => option[idKey] === value[idKey]}
      filterOptions={createFilterOptions({
        matchFrom: "start",
      })}
      value={selected}
      onChange={(e, value) => {
        if (value) {
          setSelected(value);
          helpers.setValue(value[idKey]);
          if (props.addFieldName) {
            props.setAddress(props.addFieldName, value[props.addFieldName]);
          }
        } else {
          setSelected({});
          helpers.setValue("");
          if (props.addFieldName) {
            props.setAddress(props.addFieldName, "");
          }
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          variant="outlined"
          margin="normal"
          error={meta.touched && Boolean(meta.error)}
          helperText={meta.touched && meta.error}
        />
      )}
      disabled={props.disabled ? props.disabled : false}
    />
  );
};

export default AutoSuggessionField;
