import { Box, Button, Card } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import MUIDataTable from "mui-datatables";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link as RouterLink } from "react-router-dom";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import { chkRoutePerm } from "src/services/Helper";

const Results = ({ data, multipleDelete, ...rest }) => {
  const responsive = "standard";
  const chkEditRoutPerm = chkRoutePerm(
    "/app/activation-tokens/edit",
    rest.authuser
  );
  const chkDelRoutPerm = chkRoutePerm(
    "/app/activation-tokens/delete",
    rest.authuser
  );
  const options = {
    download: false,
    print: false,
    filter: false,
    filterType: "dropdown",
    rowsPerPageOptions: [],
    rowsPerPage: 100,
    tableBodyMaxHeight: "740px",
    // rowsPerPageOptions: [10, 25, 50, 100, 500],
    responsive,
    onRowsDelete: async (rowsDeleted) => {
      const idsToDelete = rowsDeleted.data.map((d) => data[d.dataIndex].id);
      const titlesToDelete = rowsDeleted.data.map(
        (d) =>
          data[d.dataIndex].first_name +
          " " +
          data[d.dataIndex].last_name +
          " - " +
          data[d.dataIndex].business_name +
          " ( " +
          data[d.dataIndex].domain_name +
          " ) "
      );
      multipleDelete(idsToDelete, titlesToDelete);
    },
    isRowSelectable: () => {
      return chkDelRoutPerm ? true : false;
    },
  };

  const columns = [
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        display: chkEditRoutPerm ? true : false,
        viewColumns: false,
        customBodyRenderLite: (dataIndex) => (
          <Tooltip disableFocusListener title="Edit">
            <Button
              color="primary"
              component={RouterLink}
              size="small"
              to={"/app/activation-tokens/" + data[dataIndex].id}
            >
              <EditOutlinedIcon />
            </Button>
          </Tooltip>
        ),
      },
    },
    {
      name: "first_name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => (
          <p>{data[dataIndex].first_name + " " + data[dataIndex].last_name}</p>
        ),
      },
    },
    {
      name: "business_name",
      label: "Business Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "domain_name",
      label: "Domain",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "token",
      label: "Token",
      options: {
        filter: false,
        sort: false,
      },
    },
  ];
  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <MUIDataTable data={data} columns={columns} options={options} />
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Results);
