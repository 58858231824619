import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Grid, makeStyles } from "@material-ui/core";
import Page from "src/components/Page";
import QuestionDetails from "../Components/QuestionDetails";
import Toolbar from "../Components/Toolbar";
import * as Http from "src/services/Http";
import ScreenLoader from "src/components/ScreenLoader";
import { setSnackbar } from "src/actions";
import { connect } from "react-redux";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";

const breadUrlList = [
  { name: "Home", url: "/dashboard" },
  { name: "Questionnaire List", url: "/app/questionnaire" },
  { name: "Edit Questionnaire", url: "/" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  QuesDetail: {
    marginTop: theme.spacing(3),
  },
}));

const EditQuestionnaireView = (props) => {
  const { id } = useParams();
  const classes = useStyles();
  const [Loading, setLoading] = useState(true);
  const [parentquestion, setParentQuestions] = useState([]);
  const [parentQueAns, setparentQueAns] = useState([]);
  const [control, setControl] = useState([]);
  const [sectionlist, setSectionlist] = useState([]);
  const [values, setValues] = useState({
    question: "",
    description: "",
    section_id: "",
    type: "",
    type_id: "",
    parent_id: "",
    answer_id: "",
    control_id: "",
    placeholder: "",
    answers: [""],
    control_handler: "",
    sequence: "",
    is_skip: false,
    status: false,
    ext: [],
    que_forms_fields_arr: [{ form: "", fields: [] }],
  });

  const fetchParentQuestions = async () => {
    try {
      const responseparentques = await Http.call(
        "GET",
        "admin/get_all_questions"
      );
      setParentQuestions(responseparentques.data);
      return responseparentques.data;
    } catch (e) {
      console.log(e);
      return [];
    }
  };

  const fetchControl = async () => {
    try {
      const response = await Http.call("GET", "admin/get_controls");
      setControl(response.data);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchSectionList = async () => {
    try {
      const responseSection = await Http.call("GET", "admin/get_section_list");
      setSectionlist(responseSection.data);
    } catch (e) {
      console.log(e);
    }
  };

  const [docuforms, setDocuForms] = useState([]);
  const fetchDocumentForms = async () => {
    try {
      const responsedocuforms = await Http.call("GET", "admin/forms");
      setDocuForms(responsedocuforms.data);
    } catch (e) {
      console.log(e);
    }
  };

  const parentQuestionAns = (pid) => {
    if (pid !== "") {
      setLoading(true);
      let pans = [];
      parentquestion.map((queans) =>
        pid !== undefined && pid === queans.id
          ? queans.answers !== undefined && queans.answers.length > 0
            ? queans.answers.map((ans, ansindex) => {
                pans.push(ans);
              })
            : ""
          : ""
      );
      setparentQueAns(pans);
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await Http.call("GET", "admin/questionnaire/" + id).then(
        async (response) => {
          const { data } = response;
          setValues({
            id: id,
            question: data.question,
            description: data.description,
            section_id: data.section_id,
            type: data.type,
            type_id: data.type,
            parent_id: data.parent_id,
            answer_id: data.answer_id,
            control_id: data.control_id,
            placeholder: data.placeholder,
            answers: data.answers,
            control_handler: data.control_handler,
            sequence: data.sequence,
            is_skip: data.is_skip,
            status: data.status,
            ext: data.ext,
            is_parent: data.is_parent,
            que_forms_fields_arr: data.que_forms_fields_arr,
          });

          fetchSectionList();
          fetchControl();
          fetchParentQuestions().then(async (parentsQuestions) => {
            if (parentsQuestions.length > 0) {
              let pans = [];
              parentsQuestions.map((queans) =>
                data.parent_id !== undefined && data.parent_id === queans.id
                  ? queans.answers !== undefined && queans.answers.length > 0
                    ? queans.answers.map((ans, ansindex) => {
                        pans.push(ans);
                      })
                    : ""
                  : ""
              );
              setparentQueAns(pans);
            }
          });
          fetchDocumentForms();
          setLoading(false);
        }
      );
    };

    fetchData();
  }, [id]);

  if (Loading) {
    return <ScreenLoader />;
  }

  return (
    <Page className={classes.root} title="Edit Questionnaire">
      <Container maxWidth="lg">
        <BreadcrumbsAdmin urlList={breadUrlList} />
        <Toolbar mode={"Edit"} />
        <Grid container spacing={3} className={classes.QuesDetail}>
          <Grid item lg={12} md={12} xs={12}>
            <QuestionDetails
              parentquestion={parentquestion}
              parentQueAns={parentQueAns}
              setparentQuestionAns={parentQuestionAns}
              controllists={control}
              formValues={values}
              // handleChange={handleChange}
              sectionlistdata={sectionlist}
              mode={"Edit"}
              setSnackbar={props.setSnackbar}
              docuforms={docuforms}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditQuestionnaireView);
