import React, { useEffect } from "react";
import { connect } from "react-redux";
import { logout } from "src/actions";
import { useNavigate } from "react-router-dom";

export const LogoutView = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    props.logout();
    navigate("/login");
  }, [props, navigate]);
  return <div></div>;
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { logout };

export default connect(mapStateToProps, mapDispatchToProps)(LogoutView);
