import React, { useState } from "react";
import { TextField } from "@mui/material";
import { useField } from "formik";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
const AutoCompleteBox = ({ textFieldProps, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const { name } = field;

  const [inputOptions, setInputOptions] = React.useState(
    meta.value.length > 0
      ? [{ answer: meta.value }]
      : [props.options[0], props.options[1], props.options[2]]
  );
  const [inputValue, setInputValue] = useState("");

  return (
    <Autocomplete
      id="disable-clearable"
      renderInput={(params) => (
        <TextField
          {...params}
          {...textFieldProps}
          label={props.label}
          margin="normal"
          error={meta.touched && Boolean(meta.error)}
          helperText={meta.touched && meta.error}
        />
      )}
      forcePopupIcon={false}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        if (newInputValue !== meta.value && newInputValue.length > 1) {
          setInputOptions(props.options);
        } else {
          setInputOptions([
            props.options[0],
            props.options[1],
            props.options[2],
          ]);
        }
      }}
      defaultValue={{ answer: meta.value }}
      onChange={(event, newValue) => {
        helpers.setValue(newValue.answer);
        helpers.setError("");
      }}
      filterOptions={createFilterOptions({
        matchFrom: "start",
        stringify: (option) => option.answer,
      })}
      style={{ width: "240px" }}
      disableClearable
      options={inputOptions}
      getOptionLabel={(option) => option.answer || ""}
    />
  );
};

export default AutoCompleteBox;
