import React, { useState, useEffect } from "react";
import { Box, Container, makeStyles } from "@material-ui/core";
import Page from "src/components/Page";
import Results from "./Results";
import Toolbar from "./Toolbar";
import * as Http from "src/services/Http";
import { connect } from "react-redux";
import ScreenLoader from "src/components/ScreenLoader";
import { setSnackbar } from "src/actions";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";

const breadUrlList = [
  { name: "Home", url: "/dashboard" },
  { name: "Payment Record List", url: "/" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const SubscriptionListView = (props) => {
  const classes = useStyles();

  const [subscriptionList, setSubscriptionList] = useState({
    id: "Loading",
    transaction_id: "Loading",
    customer_name: "Loading",
    email: "Loading",
    package_name: "Loading",
    star: "Loading",
    status: "Loading",
    created_at: "Loading",
  });
  const [Loading, setLoading] = useState(true);

  const fetchSubscriptionList = async () => {
    try {
      const response = await Http.call("GET", "admin/subscription");
      setSubscriptionList(response.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchSubscriptionList();
  }, []);

  if (Loading) {
    return <ScreenLoader />;
  }

  return (
    <Page className={classes.root} title="Payment Records">
      <Container maxWidth={false}>
        <BreadcrumbsAdmin urlList={breadUrlList} />
        <Toolbar />
        <Box mt={3}>
          <Results
            subscriptions={subscriptionList}
            pageLoading={setLoading}
            fetchSubscription={fetchSubscriptionList}
          />
        </Box>
      </Container>
    </Page>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionListView);
