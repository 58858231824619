import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Grid, Card, makeStyles } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import Page from "src/components/Page";
import UserDetail from "./UserDetail";
import * as Http from "src/services/Http";
import { setSnackbar } from "src/actions";
import { connect } from "react-redux";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";
/*import Toolbar from "./Toolbar";*/

const breadUrlList = [
  { name: "Home", url: "/dashboard" },
  { name: "Users List", url: "/app/users" },
  { name: "Add User", url: "/" },
];
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  userDetail: {
    marginTop: theme.spacing(3),
  },
}));

const AddUserView = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [Loading, setLoading] = useState(true);
  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    // password: "",
    role: "",
    image: null,
    file: null,
  });

  const [roles, setRoles] = useState({
    id: "Loading",
    name: "Loading",
  });

  const fetchRoles = async () => {
    try {
      const response = await Http.call("GET", "admin/roles");
      setRoles(response.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const addUser = async (data, actions) => {
    await Http.call("POST", "admin/front_user", {
      ...data,
      //image: values.image,
    }).then(async (response) => {
      if (response.status === 200) {
        props.setSnackbar(true, response.status, response.message);
        navigate("/app/users");
      } else {
        //console.log(response.data);
        actions.setErrors(response.data);
      }
    });
  };
  if (Loading) {
    return (
      <Card style={{ height: 300 }}>
        <LinearProgress />
      </Card>
    );
  }
  return (
    <Page className={classes.root} title="Add User">
      <Container maxWidth="lg">
        <BreadcrumbsAdmin urlList={breadUrlList} />
        {/*<Toolbar />*/}
        <Grid container spacing={3} className={classes.userDetail}>
          {/* <Grid item lg={4} md={6} xs={12}>
            <Profile values={values} setvalues={setValues} />
          </Grid> */}
          <Grid item lg={12} md={12} xs={12}>
            <UserDetail
              roles={roles}
              formValues={values}
              handleChange={handleChange}
              addUser={addUser}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUserView);
