import React, { useState } from "react";
import { useField } from "formik";
import { Grid, TextField } from "@material-ui/core";
import { usePlacesWidget } from "react-google-autocomplete";

const HomeGoogleAddressBox = ({ address, ...props }) => {
  const [field, meta, helpers] = useField(props);

  const { setValue } = helpers;
  const [building, setBuildingName] = useState(
    address.house_name !== undefined ? address.house_name : ""
  );
  const [address_line_1, setAddressLine1] = useState(
    address.address_line_1 !== undefined ? address.address_line_1 : ""
  );
  const [city, setCity] = useState(
    address.city !== undefined ? address.city : ""
  );
  const [state, setState] = useState(
    address.state !== undefined ? address.state : ""
  );
  const [country, setCountry] = useState(
    address.country !== undefined ? address.country : ""
  );
  const [zip, setZip] = useState(address.zip !== undefined ? address.zip : "");

  const removeAddressData = (e) => {
    if (e.keyCode === 8 || e.keyCode === 46) {
      setBuildingName("");
      setAddressLine1("");
      setCity("");
      setState("");
      setCountry("");
      setZip("");
    }
    setValue(e.target.value);
  };

  const setAddressData = ({
    address_components,
    geometry,
    formatted_address,
    name,
    business_status,
  }) => {
    let addressComponent = {};
    if (address_components.length > 0) {
      addressComponent.address_line_1 = formatted_address;
      setAddressLine1(addressComponent.address_line_1);
      props.setFieldValue("address_line_1", addressComponent.address_line_1);
      addressComponent.locationAddress = formatted_address;
      addressComponent.lat = geometry.location.lat();
      addressComponent.long = geometry.location.lng();
      setBuildingName(
        business_status !== undefined && business_status === "OPERATIONAL"
          ? name
          : ""
      );
      addressComponent.house_name =
        business_status !== undefined && business_status === "OPERATIONAL"
          ? name
          : "";
      props.setFieldValue("house_name", addressComponent.house_name);
      address_components.map((address_component) => {
        if (address_component.types.includes("administrative_area_level_1")) {
          addressComponent.state = address_component.long_name;
          setState(addressComponent.state);
          props.setFieldValue("state", addressComponent.state);
        } else if (address_component.types.includes("locality")) {
          addressComponent.city = address_component.long_name;
          setCity(addressComponent.city);
          props.setFieldValue("city", addressComponent.city);
        } else if (address_component.types.includes("postal_code")) {
          addressComponent.zip = address_component.long_name;
          setZip(addressComponent.zip);
          props.setFieldValue("zip", addressComponent.zip);
        } else if (address_component.types.includes("country")) {
          addressComponent.country = address_component.long_name;
          setCountry(addressComponent.country);
          props.setFieldValue("country", addressComponent.country);
        }
      });
    }

    return addressComponent;
  };

  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyAW9PMZKxsJ3iAZ249oFM6k8M1xYKhMXhw",
    options: {
      types: ["geocode", "establishment"],
      fields: ["ALL"],
    },
    onPlaceSelected: (place) => {
      props.setAddress(setAddressData(place));
      setValue(place.formatted_address);
    },
  });

  return (
    <Grid container direction="row" justifyContent="center" spacing={3}>
      <Grid item md={12} xs={12}>
        <TextField
          label="Address"
          fullWidth
          variant="outlined"
          inputRef={ref}
          placeholder={props.placeholder}
          multiline
          error={meta.touched && Boolean(meta.error)}
          helperText={meta.touched && meta.error}
          {...field}
          {...props}
          onKeyDown={removeAddressData}
          margin="normal"
        />
      </Grid>
      {/* <Grid item md={12} xs={12}>
        <TextField
          fullWidth
          name="house_name"
          label="House Name"
          value={building}
          variant="outlined"
          onChange={(event) => {
            //props.setFieldValue("house_name", addressComponent.country);
            setBuildingName(event.target.value);
            props.setFieldValue("house_name", event.target.value);
          }}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <TextField
          fullWidth
          label="Address 1"
          name="address_line_1"
          value={address_line_1}
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid> */}
      <Grid item md={12} xs={12}>
        <TextField
          fullWidth
          name="city"
          label="City"
          variant="outlined"
          value={city}
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <TextField
          fullWidth
          name="state"
          label="State"
          variant="outlined"
          value={state}
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <TextField
          fullWidth
          name="country"
          label="Country"
          variant="outlined"
          value={country}
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <TextField
          fullWidth
          name="zip"
          label="Zip"
          variant="outlined"
          value={zip}
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default HomeGoogleAddressBox;
