import React, { useState } from "react";
import {
  Button,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  TableCell,
  TextField,
  Grid,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableHead,
  Paper,
  Card,
  Divider,
  CardContent,
  Box,
  FormHelperText,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  FormLabel,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import * as Http from "src/services/Http";
import ConformationDialog from "src/components/ConformationDialog";
import { Link as RouterLink } from "react-router-dom";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import * as Messages from "src/constants/Messages/index";
import ScreenLoader from "src/components/ScreenLoader";
import * as Yup from "yup";
import { Formik } from "formik";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import DropBox from "src/components/DropBox";
const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    // width: "fit-content",
    marginTop: "12px",
    paddingTop: "12px",
  },
  selectControl: {
    minWidth: "100%",
    margin: "15px 0",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  container: {
    maxHeight: 740,
  },
}));
const AddCounsellorStatusSchema = Yup.object().shape({
  status_id: Yup.string().required(Messages.REQUIRED),
  // urn_weight: Yup.number().required(Messages.REQUIRED),
  status_note: Yup.string().required(Messages.REQUIRED),
  urn_weight: Yup.number().when("status_id", {
    is: "16",
    then: Yup.number()
      .required(Messages.REQUIRED)
      .min(1, Messages.INVALID_NUMBERS),
  }),
  identify_image: Yup.string().when("status_id", {
    is: "7",
    then: Yup.string()
      .required("Required")
      .nullable(),
  }),
  on_email_template: Yup.string().when(["on_status", "on_send_type"], {
    is: (on_status, on_send_type) =>
      /^(1)$/.test(on_status) && /^(0)$/.test(on_send_type),
    then: Yup.string().required(Messages.REQUIRED),
  }),
  on_fax_message: Yup.string().when(["on_status", "on_send_type"], {
    is: (on_status, on_send_type) =>
      /^(1)$/.test(on_status) && /^(1)$/.test(on_send_type),
    then: Yup.string().required(Messages.REQUIRED),
  }),
  // on_send_type: Yup.number().when("on_status", {
  //   is: (on_status) => /^(1)$/.test(on_status),
  //   then: Yup.number().required(Messages.REQUIRED),
  // }),
  on_position_id: Yup.array().when("on_status", {
    is: (on_status) => /^(1)$/.test(on_status),
    then: Yup.array()
      .of(Yup.string().required(Messages.REQUIRED))
      .min(1, "Minimum 1 field is required"),
  }),
  on_position_users: Yup.array().when("on_status", {
    is: (on_status) => /^(1)$/.test(on_status),
    then: Yup.array()
      .of(Yup.string().required(Messages.REQUIRED))
      .min(1, "Minimum 1 field is required"),
  }),
  on_form_id: Yup.array().when("on_status", {
    is: (on_status) => /^(1)$/.test(on_status),
    then: Yup.array()
      .of(Yup.string().required(Messages.REQUIRED))
      .min(1, "Minimum 1 field is required"),
  }),
  // off_send_type: Yup.number().when("off_status", {
  //   is: (off_status) => /^(2)$/.test(off_status),
  //   then: Yup.number().required(Messages.REQUIRED),
  // }),
  off_email_template: Yup.string().when(["off_status", "off_send_type"], {
    is: (off_status, off_send_type) =>
      /^(1)$/.test(off_status) && /^(0)$/.test(off_send_type),
    then: Yup.string().required(Messages.REQUIRED),
  }),
  off_fax_message: Yup.string().when(["off_status", "off_send_type"], {
    is: (off_status, off_send_type) =>
      /^(1)$/.test(off_status) && /^(1)$/.test(off_send_type),
    then: Yup.string().required(Messages.REQUIRED),
  }),
  off_position_id: Yup.array().when("off_status", {
    is: (off_status) => /^(2)$/.test(off_status),
    then: Yup.array()
      .of(Yup.string().required(Messages.REQUIRED))
      .min(1, "Minimum 1 field is required"),
  }),
  off_form_id: Yup.array().when("off_status", {
    is: (off_status) => /^(2)$/.test(off_status),
    then: Yup.array()
      .of(Yup.string().required(Messages.REQUIRED))
      .min(1, "Minimum 1 field is required"),
  }),
  off_position_users: Yup.array().when("off_status", {
    is: (off_status) => /^(2)$/.test(off_status),
    then: Yup.array()
      .of(Yup.string().required(Messages.REQUIRED))
      .min(1, "Minimum 1 field is required"),
  }),
});
const UpdateCounsellorStatus = ({
  setSnackbar,
  popupStatus,
  hidePopupStatus,
  appCounsStatus,
  fetchData,
  // delStatus,
  userRole,
  staffPositions,
  emailtemplists,
  docuforms,
  ...rest
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [Loading, setLoading] = useState(false);
  const isReadMore = useState(true);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  // const [updateState, setUpdateState] = React.useState(
  //   appCounsStatus.current_status_id
  // );
  const initialValues = {
    first_name: appCounsStatus.application_code
      ? appCounsStatus.application_code
      : "",
    user_name: appCounsStatus.user_name ? appCounsStatus.user_name : "",
    status_id: "",
    urn_weight: "",
    status_note: "",
    on_status: 0,
    on_send_type: 0,
    on_email_template: "",
    on_fax_message: "",
    on_position_id: [],
    on_form_id: [],
    on_position_users: [],
    off_status: 0,
    off_send_type: 0,
    off_email_template: "",
    off_fax_message: "",
    off_position_id: [],
    off_form_id: [],
    off_position_users: [],
    prev_off_status: 0,
    prev_off_status_title: "",
    prev_off_send_type: 0,
    prev_off_email_template: "",
    prev_off_fax_message: "",
    prev_off_position_id: [],
    prev_off_form_id: [],
    prev_off_position_users: [],
    identify_image: "",
  };
  // const [updateState, setUpdateState] = useState("");
  const [onPositionSelected, setOnPositionSelected] = useState([]);
  const [offPositionSelected, setOffPositionSelected] = useState([]);

  // const [prevoffPositionSelected, setPrevOffPositionSelected] = useState([]);

  const [statusdata, setStatusData] = useState({});
  const [statusaction, setStatusAction] = useState({});
  const [logDescription, setLogDescription] = useState("");
  const [statusNoteOpen, setStatusNoteOpen] = useState(false);
  // const [isSubmitting, setisSubmitting] = useState(false);
  const handleClickOpen = (event, index, value) => {
    setLogDescription(event.currentTarget.value);
    setStatusNoteOpen(true);
  };
  const handleDelClose = () => setOpen(false);
  const handleCloseStatusNote = () => {
    setStatusNoteOpen(false);
  };
  const [openStatusBox, setOpenStatusBox] = useState(false);
  const handleCloseStatusBox = () => setOpenStatusBox(false);
  const handleStatusOpen = (data) => {
    setOpenStatusBox(true);
  };
  const [onuserlistdata, setOnUserlistdata] = useState([]);
  const [offuserlistdata, setOffUserlistdata] = useState([]);

  const fetchPositionUserList = async (type, position) => {
    if (position !== "") {
      await Http.call("POST", "admin/lookup_dir", {
        positions: position,
      }).then(async (response) => {
        if (response.status === 200) {
          type === "on"
            ? await setOnUserlistdata(response.data)
            : await setOffUserlistdata(response.data);
          return response.data;
        } else {
          console.log(response);
        }
      });
    }
  };

  const onStatusSubmit = async (e) => {
    let method = "";
    let url = "";
    method = "POST";
    url = "admin/update_status";
    setLoading(true);
    handleCloseStatusBox();
    console.log(statusdata, "statusdata");
    // return;
    await Http.call(method, url, {
      ...statusdata,
      app_id: appCounsStatus.app_id,
    }).then(async ({ status, message, data }) => {
      if (status === 200) {
        setLoading(false);
        statusaction.resetForm();
        fetchData(appCounsStatus.current_status_id);
        setSnackbar(true, status, message);
      } else if (status === 422) {
        if (data.name) {
          setLoading(false);
          statusaction.setFieldError("status_id", data.name[0]);
        }
      } else {
        setLoading(false);
        setSnackbar(true, status, message);
      }
    });
    statusaction.setSubmitting(false);
  };
  const [appid, setappid] = useState();
  const [statusId, setstatusid] = useState();
  const [appStatusId, setappStatusId] = useState();
  const delStatus = (
    appidToDelete,
    statusidToDelete = "",
    app_status_id = ""
  ) => {
    setOpen(true);
    setappid(appidToDelete);
    setstatusid(statusidToDelete);
    setappStatusId(app_status_id);
  };
  const onConform = async () => {
    let StatusId = 0;
    try {
      await Http.call("POST", "admin/status_mgmt/delete_app_status", {
        app_id: appid,
        status_id: statusId,
        app_status_id: appStatusId,
      }).then(async (response) => {
        setLoading(false);
        setSnackbar(true, response.status, response.message);
        StatusId = response.data.status_id;
      });

      fetchData(StatusId);
      handleDelClose();
    } catch (e) {
      console.log(e);
    }
  };

  const fetchStatusDetails = async (stid = "", setFieldValue) => {
    if (stid !== "") {
      try {
        await Http.call(
          "GET",
          "admin/status_mgmt/" + stid + "/" + appCounsStatus.app_id
        ).then(async (response) => {
          const { data } = response;
          let on_position_ids = [];
          let off_position_ids = [];

          data.on_position_id.map((value, index) => {
            on_position_ids.push(value.id);
          });
          data.off_position_id.map((value, index) => {
            off_position_ids.push(value.id);
          });
          await fetchPositionUserList("on", on_position_ids);
          // return;
          await fetchPositionUserList("off", off_position_ids);

          await setFieldValue(
            "on_status",
            data.on_status === "" ? 0 : data.on_status
          );
          await setFieldValue(
            "on_send_type",
            data.on_send_type === "" ? 0 : data.on_send_type
          );
          await setFieldValue("on_email_template", data.on_email_template);
          await setFieldValue("on_fax_message", data.on_fax_message);
          await setFieldValue(
            "on_position_id",
            data.on_position_id !== "" ? data.on_position_id : ""
          );
          await setOnPositionSelected(
            data.on_position_id !== "" ? data.on_position_id : []
          );

          await setFieldValue(
            "on_position_users",
            data.on_position_users !== "" ? data.on_position_users : []
          );
          //current off status
          await setFieldValue(
            "off_status",
            data.off_status === "" ? 0 : data.off_status
          );
          await setFieldValue(
            "off_send_type",
            data.off_send_type === "" ? 0 : data.off_send_type
          );
          await setFieldValue("off_email_template", data.off_email_template);
          await setFieldValue("off_fax_message", data.off_fax_message);

          await setFieldValue(
            "off_position_id",
            data.off_position_id !== "" ? data.off_position_id : []
          );
          await setOffPositionSelected(
            data.off_position_id !== "" ? data.off_position_id : []
          );

          await setFieldValue(
            "on_form_id",
            data.on_form_id !== "" ? data.on_form_id : []
          );
          await setFieldValue(
            "off_form_id",
            data.off_form_id !== "" ? data.off_form_id : []
          );
          await setFieldValue(
            "off_position_users",
            data.off_position_users !== "" ? data.off_position_users : []
          );

          //prev off status
          let prev_off_position_ids = [];

          data.prev_off_position_id.map((value, index) => {
            prev_off_position_ids.push(value.id);
          });
          await fetchPositionUserList("off", prev_off_position_ids);
          await setFieldValue(
            "prev_off_status",
            data.prev_off_status === "" ? 0 : data.prev_off_status
          );
          await setFieldValue(
            "prev_off_status_title",
            data.prev_off_status_title !== "" ? data.prev_off_status_title : ""
          );
          await setFieldValue(
            "prev_off_send_type",
            data.prev_off_send_type === "" ? 0 : data.prev_off_send_type
          );
          await setFieldValue(
            "prev_off_email_template",
            data.prev_off_email_template
          );
          await setFieldValue(
            "prev_off_fax_message",
            data.prev_off_fax_message
          );

          await setFieldValue(
            "prev_off_position_id",
            data.prev_off_position_id !== "" ? data.prev_off_position_id : []
          );
          // await setPrevOffPositionSelected(
          //   data.prev_off_position_id !== "" ? data.prev_off_position_id : []
          // );

          await setFieldValue(
            "prev_off_form_id",
            data.prev_off_form_id !== "" ? data.prev_off_form_id : []
          );
          await setFieldValue(
            "prev_off_position_users",
            data.prev_off_position_users !== ""
              ? data.prev_off_position_users
              : []
          );
        });
      } catch (e) {
        console.log(e, "error");
      }
    } else {
      let on_position_ids = [];
      let off_position_ids = [];
      await fetchPositionUserList("on", on_position_ids);
      // return;
      await fetchPositionUserList("off", off_position_ids);

      await setFieldValue("on_status", "");
      await setFieldValue("on_send_type", 0);
      await setFieldValue("on_email_template", "");
      await setFieldValue("on_position_id", []);
      await setOnPositionSelected([]);

      await setFieldValue("on_position_users", []);

      await setFieldValue("off_status", "");
      await setFieldValue("off_send_type", 0);
      await setFieldValue("off_email_template", "");

      await setFieldValue("off_position_id", []);
      await setOffPositionSelected([]);

      await setFieldValue("on_form_id", []);
      await setFieldValue("off_form_id", []);
      await setFieldValue("off_position_users", []);

      await setFieldValue("prev_off_status", "");
      await setFieldValue("prev_off_status_title", "");
      await setFieldValue("prev_off_send_type", 0);
      await setFieldValue("prev_off_email_template", "");

      await setFieldValue("prev_off_position_id", []);
      await setOffPositionSelected([]);

      await setFieldValue("prev_on_form_id", []);
      await setFieldValue("prev_off_form_id", []);
      await setFieldValue("prev_off_position_users", []);
    }
    setLoading(false);
  };

  if (Loading) {
    return <ScreenLoader />;
  } else {
    // handleSubmit
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={AddCounsellorStatusSchema}
        handleStatusOpen={handleStatusOpen}
        onSubmit={async (values, actions) => {
          // let selected_users = [];
          // let form_users = [];

          // values.selected_user.map((value, index) => {
          //   selected_users.push(value.id);
          // });

          // values.form_users.map((value, index) => {
          //   form_users.push(value.role_flag + "_" + value.id);
          // });

          // const finalvalues = {
          //   ...values,
          //   selected_user: selected_users,
          //   form_users: form_users,
          // };
          setStatusData(values);
          setStatusAction(actions);
          setOpenStatusBox(true);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
          resetForm,
        }) => {
          return (
            <form className={classes.form} onSubmit={handleSubmit}>
              <Grid item md={12} xs={12} lg={12}>
                {/* <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={3}
                >
                  <Grid item md={6}>
                    <Grid item>
                      <Typography variant="h3" component="h3">
                        Application Status
                      </Typography>
                    </Grid>
                  </Grid>
                  <Button
                    color="primary"
                    variant="contained"
                    component={RouterLink}
                    to={`/app/counselor/${appCounsStatus.app_id}/manual-action`}
                  >
                    Manual Action
                  </Button>
                </Box> */}
                <Card>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item md={6} xs={12}>
                        <FormControl
                          variant="outlined"
                          margin="normal"
                          fullWidth
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            Application Status
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            label="Application Status"
                            name="status_id"
                            value={values.status_id}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              if (e.target.value !== "") {
                                setFieldValue("status_id", e.target.value);
                                fetchStatusDetails(
                                  e.target.value,
                                  setFieldValue
                                );
                              } else {
                                setFieldValue("status_id", "");
                                fetchStatusDetails("", setFieldValue);
                              }
                            }}
                            fullWidth
                          >
                            <MenuItem value="">
                              <em>Select Application Status</em>
                            </MenuItem>

                            {appCounsStatus.status.length !== 0 ? (
                              appCounsStatus.status.map((s, index) => (
                                <MenuItem
                                  key={index}
                                  value={s.id}
                                  disabled={s.isDisabled ? true : false}
                                >
                                  {s.name}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem>No record found..</MenuItem>
                            )}
                          </Select>
                          {Boolean(touched.status_id && errors.status_id) ? (
                            <FormHelperText className="Mui-error">
                              {errors.status_id}
                            </FormHelperText>
                          ) : null}
                        </FormControl>
                      </Grid>
                      {values.status_id === 16 ? (
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Urn Weight"
                            name="urn_weight"
                            value={
                              // appCounsStatus.urn_weight
                              //   ? appCounsStatus.urn_weight
                              //   :
                              values.urn_weight
                            }
                            // onChange={updateStausValue}
                            onChange={handleChange}
                            margin="normal"
                            variant="outlined"
                            type="number"
                            error={Boolean(
                              touched.urn_weight && errors.urn_weight
                            )}
                            helperText={touched.urn_weight && errors.urn_weight}
                          />
                        </Grid>
                      ) : (
                        ""
                      )}
                      {values.status_id === 7 ? (
                        <Grid item md={12} xs={12}>
                          <DropBox name="files" setFieldValue={setFieldValue} />
                        </Grid>
                      ) : (
                        ""
                      )}
                      {values.status_id !== "" ? (
                        <>
                          {values.status_id !== 7 && values.status_id !== 16 ? (
                            <Grid
                              item
                              md={6}
                              xs={12}
                              style={{ margin: "20px 0px 0 0" }}
                            ></Grid>
                          ) : (
                            ""
                          )}
                          <Grid
                            item
                            md={6}
                            xs={12}
                            style={{ margin: "20px 0px 0 0" }}
                          >
                            <FormLabel style={{ margin: "0 10px 0 0" }}>
                              On Status:
                            </FormLabel>
                            <Checkbox
                              name="on_status"
                              value={values.on_status}
                              inputProps={{
                                "aria-labelledby": "On status",
                              }}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setFieldValue("on_status", 1);
                                } else {
                                  setFieldValue("on_position_id", []);
                                  setFieldValue("on_status", 0);
                                  setFieldValue("on_send_type", 0);
                                  setFieldValue("on_email_template", "");
                                  setFieldValue("on_fax_message", "");
                                  setFieldValue("on_form_id", []);
                                  const on_position_input = document.querySelector(
                                    "#on_position_id"
                                  );
                                  Object.getOwnPropertyDescriptor(
                                    window.HTMLInputElement.prototype,
                                    "value"
                                  ).set.call(on_position_input, "");
                                  on_position_input.dispatchEvent(
                                    new Event("change", { bubbles: true })
                                  );
                                }
                              }}
                              checked={values.on_status === 0 ? false : true}
                            />
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={12}
                            style={{ margin: "20px 0px 0 0" }}
                          >
                            <FormLabel style={{ margin: "0 10px 0 0" }}>
                              On Send Type:
                            </FormLabel>
                            <FormControlLabel
                              control={
                                <Switch
                                  label="On Send Type"
                                  name="on_send_type"
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setFieldValue("on_send_type", 1);
                                      setFieldValue("on_email_template", "");
                                    } else {
                                      setFieldValue("on_send_type", 0);
                                      setFieldValue("on_fax_message", "");
                                    }
                                  }}
                                  checked={values.on_send_type === 1 ? 1 : 0}
                                  color="primary"
                                  disabled={
                                    values.on_status === "" ||
                                    values.on_status === 0 ||
                                    values.on_status === undefined
                                  }
                                />
                              }
                              label={
                                values.on_send_type === 1 ? "Fax" : "Email"
                              }
                            />
                            {Boolean(
                              touched.on_send_type && errors.on_send_type
                            ) ? (
                              <FormHelperText className="Mui-error">
                                {errors.on_send_type}
                              </FormHelperText>
                            ) : null}
                          </Grid>
                          {values.on_send_type === 1 ? (
                            <Grid item md={6} xs={12}>
                              <TextField
                                fullWidth
                                label="Fax Message"
                                name="on_fax_message"
                                value={values.on_fax_message}
                                onChange={handleChange}
                                margin="normal"
                                variant="outlined"
                                error={Boolean(
                                  touched.on_fax_message &&
                                    errors.on_fax_message
                                )}
                                helperText={
                                  touched.on_fax_message &&
                                  errors.on_fax_message
                                }
                              />
                            </Grid>
                          ) : (
                            <Grid item md={6} xs={12}>
                              <FormControl
                                variant="outlined"
                                className={classes.selectControl}
                              >
                                <InputLabel id="on_email_template">
                                  Email Template
                                </InputLabel>
                                <Select
                                  labelId="on_email_template"
                                  id="on_email_template"
                                  label="Email Template"
                                  name="on_email_template"
                                  value={
                                    values.on_status !== 0
                                      ? values.on_email_template
                                      : ""
                                  }
                                  onChange={handleChange}
                                  disabled={
                                    values.on_status === "" ||
                                    values.on_status === 0 ||
                                    values.on_status === undefined
                                  }
                                  error={Boolean(
                                    touched.on_email_template &&
                                      errors.on_email_template
                                  )}
                                  fullWidth
                                >
                                  <MenuItem value="">
                                    <em>Select Email Template</em>
                                  </MenuItem>

                                  {emailtemplists.map(
                                    (emailtemplist, index) => (
                                      <MenuItem
                                        key={index}
                                        value={emailtemplist.id}
                                      >
                                        {emailtemplist.email_subject}
                                      </MenuItem>
                                    )
                                  )}
                                </Select>
                                {Boolean(
                                  touched.on_email_template &&
                                    errors.on_email_template
                                ) ? (
                                  <FormHelperText className="Mui-error">
                                    {errors.on_email_template}
                                  </FormHelperText>
                                ) : null}
                              </FormControl>
                            </Grid>
                          )}
                          <Grid item xs={6} md={6}>
                            <FormControl
                              variant="outlined"
                              className="statusdocformselectControl"
                            >
                              <InputLabel id="status_forms">
                                On Document Form
                              </InputLabel>
                              <Select
                                id="on_form_id"
                                label="On Document Form"
                                name="on_form_id"
                                fullWidth
                                error={Boolean(
                                  touched.on_form_id && errors.on_form_id
                                )}
                                multiple
                                value={
                                  values.on_status !== 0
                                    ? values.on_form_id
                                    : []
                                }
                                onChange={(e) => {
                                  setFieldValue("on_form_id", e.target.value);
                                }}
                                renderValue={(selected) => {
                                  if (selected.length === 0) {
                                    return <em>Placeholder</em>;
                                  }
                                  return selected.length + " selected";
                                }}
                                inputProps={{
                                  name: "users",
                                  id: "outlined-age-native-simple",
                                }}
                                disabled={
                                  values.on_status === "" ||
                                  values.on_status === 0 ||
                                  values.on_status === undefined
                                    ? true
                                    : appCounsStatus.is_documents_signed ===
                                        undefined ||
                                      appCounsStatus.is_documents_signed === 0
                                    ? true
                                    : false
                                }
                              >
                                <MenuItem value="">
                                  <em>Select Document Form</em>
                                </MenuItem>
                                {docuforms.map((docuformslist, frm_index) => (
                                  <MenuItem
                                    key={`form_${frm_index}`}
                                    value={docuformslist.id}
                                  >
                                    <Checkbox
                                      checked={
                                        values.on_form_id.indexOf(
                                          docuformslist.id
                                        ) > -1
                                      }
                                    />
                                    <div className="MuiListItemText-root MuiListItemText-multiline">
                                      <span className="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                                        {docuformslist.name}
                                      </span>
                                    </div>
                                  </MenuItem>
                                ))}
                              </Select>
                              {Boolean(
                                touched.on_form_id && errors.on_form_id
                              ) ? (
                                <FormHelperText className="Mui-error">
                                  {errors.on_form_id}
                                </FormHelperText>
                              ) : null}
                            </FormControl>
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Autocomplete
                              multiple
                              id="on_position_id"
                              options={staffPositions}
                              name="on_position_id"
                              value={onPositionSelected}
                              disableCloseOnSelect
                              filterOptions={createFilterOptions({
                                matchFrom: "any",
                              })}
                              // onChange={(e, newValue) => {
                              //   setFieldValue("on_position_id", newValue);
                              // }}
                              onChange={(e, value) => {
                                // console.log(value, "on_position_id_selected");
                                if (value) {
                                  let on_positionids = [];

                                  value.map((val, index) => {
                                    on_positionids.push(val.id);
                                  });
                                  setOnPositionSelected(value);
                                  setFieldValue("on_position_id", value);
                                  fetchPositionUserList("on", on_positionids);
                                } else {
                                  setOnPositionSelected([]);
                                  setFieldValue("on_position_id", []);
                                }
                              }}
                              getOptionLabel={(option) =>
                                option ? option.title : []
                              }
                              renderOption={(option, { selected }) => (
                                <React.Fragment>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    // checked={selected}
                                    checked={
                                      values.on_position_id !== undefined
                                        ? values.on_position_id.find(
                                            (v) => v.id === option.id
                                          )
                                          ? true
                                          : false
                                        : selected
                                    }
                                  />
                                  <div
                                    key={option.id}
                                    className="MuiListItemText-root MuiListItemText-multiline"
                                  >
                                    <span className="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                                      {option.title}
                                    </span>
                                  </div>
                                </React.Fragment>
                              )}
                              fullWidth
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Search Position"
                                  label="On Staff Position"
                                  variant="outlined"
                                  margin="normal"
                                  helperText={
                                    touched.on_position_id &&
                                    errors.on_position_id
                                  }
                                  error={Boolean(
                                    touched.on_position_id &&
                                      errors.on_position_id
                                  )}
                                />
                              )}
                              disabled={
                                values.on_status === "" ||
                                values.on_status === 0 ||
                                values.on_status === undefined
                              }
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Autocomplete
                              multiple
                              id="on_position_users"
                              options={onuserlistdata}
                              name="on_position_users"
                              value={values.on_position_users}
                              disableCloseOnSelect
                              filterOptions={createFilterOptions({
                                matchFrom: "any",
                              })}
                              onChange={(e, newValue) => {
                                setFieldValue("on_position_users", newValue);
                              }}
                              getOptionLabel={(option) =>
                                option
                                  ? option.first_name + " " + option.last_name
                                  : []
                              }
                              renderOption={(option, { selected }) => (
                                <React.Fragment>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={
                                      values.on_position_users !== undefined
                                        ? values.on_position_users.find(
                                            (v) => v.id === option.id
                                          )
                                          ? true
                                          : false
                                        : selected
                                    }
                                  />
                                  <div
                                    key={option.id}
                                    className="MuiListItemText-root MuiListItemText-multiline"
                                  >
                                    <span className="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                                      {option.first_name +
                                        " " +
                                        option.last_name}
                                    </span>
                                  </div>
                                </React.Fragment>
                              )}
                              fullWidth
                              disabled={
                                values.on_status === "" ||
                                values.on_status === 0 ||
                                values.on_status === undefined
                                  ? true
                                  : false
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Users"
                                  placeholder="Search Users"
                                  margin="normal"
                                />
                              )}
                            />
                            {Boolean(
                              touched.on_position_users &&
                                errors.on_position_users
                            ) ? (
                              <FormHelperText className="Mui-error">
                                {errors.on_position_users}
                              </FormHelperText>
                            ) : null}
                          </Grid>
                          {/* current off status  */}
                          <Grid
                            item
                            md={6}
                            xs={12}
                            style={{ margin: "20px 0px 0 0" }}
                          >
                            <FormLabel style={{ margin: "0 10px 0 0" }}>
                              Off Status:
                            </FormLabel>
                            <Checkbox
                              name="off_status"
                              value={values.off_status}
                              inputProps={{
                                "aria-labelledby": "Off status",
                              }}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setFieldValue("off_status", 2);
                                } else {
                                  setFieldValue("off_status", 0);
                                  setFieldValue("off_send_type", 0);
                                  setFieldValue("off_email_template", "");
                                  setFieldValue("off_position_id", []);
                                  setFieldValue("off_fax_message", "");
                                  setFieldValue("off_form_id", []);
                                  const off_position_input = document.querySelector(
                                    "#off_position_id"
                                  );
                                  Object.getOwnPropertyDescriptor(
                                    window.HTMLInputElement.prototype,
                                    "value"
                                  ).set.call(off_position_input, "");
                                  off_position_input.dispatchEvent(
                                    new Event("change", { bubbles: true })
                                  );
                                }
                              }}
                              checked={values.off_status === 0 ? false : true}
                            />
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={12}
                            style={{ margin: "20px 0px 0 0" }}
                          >
                            <FormLabel style={{ margin: "0 10px 0 0" }}>
                              Off Send Type:
                            </FormLabel>
                            <FormControlLabel
                              control={
                                <Switch
                                  label="Off Send Type"
                                  name="off_send_type"
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setFieldValue("off_send_type", 1);
                                    } else {
                                      setFieldValue("off_send_type", 0);
                                    }
                                  }}
                                  checked={values.off_send_type === 1 ? 1 : 0}
                                  color="primary"
                                />
                              }
                              label={
                                values.off_send_type === 1 ? "Fax" : "Email"
                              }
                              disabled={
                                values.off_status === "" ||
                                values.off_status === 0 ||
                                values.off_status === undefined
                              }
                            />
                          </Grid>
                          {values.off_send_type === 1 ? (
                            <Grid item md={6} xs={12}>
                              <TextField
                                fullWidth
                                label="Fax Message"
                                name="off_fax_message"
                                value={values.off_fax_message}
                                onChange={handleChange}
                                margin="normal"
                                variant="outlined"
                                error={Boolean(
                                  touched.off_fax_message &&
                                    errors.off_fax_message
                                )}
                                helperText={
                                  touched.off_fax_message &&
                                  errors.off_fax_message
                                }
                              />
                            </Grid>
                          ) : (
                            <Grid item md={6} xs={12}>
                              <FormControl
                                variant="outlined"
                                className={classes.selectControl}
                              >
                                <InputLabel id="off_email_template">
                                  Email Template
                                </InputLabel>
                                <Select
                                  labelId="off_email_template"
                                  id="off_email_template"
                                  label="Email Template"
                                  name="off_email_template"
                                  value={
                                    values.off_status !== 0
                                      ? values.off_email_template
                                      : ""
                                  }
                                  onChange={handleChange}
                                  disabled={
                                    values.off_status === "" ||
                                    values.off_status === 0 ||
                                    values.off_status === undefined
                                  }
                                  error={Boolean(
                                    touched.off_email_template &&
                                      errors.off_email_template
                                  )}
                                  fullWidth
                                >
                                  <MenuItem value="">
                                    <em>Select Email Template</em>
                                  </MenuItem>

                                  {emailtemplists.map(
                                    (emailtemplist, index) => (
                                      <MenuItem
                                        key={index}
                                        value={emailtemplist.id}
                                      >
                                        {emailtemplist.email_subject}
                                      </MenuItem>
                                    )
                                  )}
                                </Select>
                                {Boolean(
                                  touched.off_email_template &&
                                    errors.off_email_template
                                ) ? (
                                  <FormHelperText className="Mui-error">
                                    {errors.off_email_template}
                                  </FormHelperText>
                                ) : null}
                              </FormControl>
                            </Grid>
                          )}
                          <Grid item xs={6} md={6}>
                            <FormControl
                              variant="outlined"
                              className="statusdocformselectControl"
                            >
                              <InputLabel id="status_forms">
                                Off Document Form
                              </InputLabel>
                              <Select
                                id="off_form_id"
                                label="Off Document Form"
                                name="off_form_id"
                                fullWidth
                                error={Boolean(
                                  touched.off_form_id && errors.off_form_id
                                )}
                                multiple
                                value={
                                  values.off_status !== 0
                                    ? values.off_form_id
                                    : []
                                }
                                onChange={(e) => {
                                  setFieldValue("off_form_id", e.target.value);
                                }}
                                renderValue={(selected) => {
                                  if (selected.length === 0) {
                                    return <em>Placeholder</em>;
                                  }
                                  return selected.length + " selected";
                                }}
                                inputProps={{
                                  name: "users",
                                  id: "outlined-age-native-simple",
                                }}
                                disabled={
                                  values.off_status === "" ||
                                  values.off_status === 0 ||
                                  values.off_status === undefined
                                    ? true
                                    : appCounsStatus.is_documents_signed ===
                                        undefined ||
                                      appCounsStatus.is_documents_signed === 0
                                    ? true
                                    : false
                                }
                              >
                                <MenuItem value="">
                                  <em>Select Document Form</em>
                                </MenuItem>
                                {docuforms.map((docuformslist, frm_index) => (
                                  <MenuItem
                                    key={`form_${frm_index}`}
                                    value={docuformslist.id}
                                  >
                                    <Checkbox
                                      checked={
                                        values.off_form_id.indexOf(
                                          docuformslist.id
                                        ) > -1
                                      }
                                    />
                                    <div className="MuiListItemText-root MuiListItemText-multiline">
                                      <span className="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                                        {docuformslist.name}
                                      </span>
                                    </div>
                                  </MenuItem>
                                ))}
                              </Select>
                              {Boolean(
                                touched.off_form_id && errors.off_form_id
                              ) ? (
                                <FormHelperText className="Mui-error">
                                  {errors.off_form_id}
                                </FormHelperText>
                              ) : null}
                            </FormControl>
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Autocomplete
                              multiple
                              id="off_position_id"
                              options={staffPositions}
                              name="off_position_id"
                              value={offPositionSelected}
                              disableCloseOnSelect
                              filterOptions={createFilterOptions({
                                matchFrom: "any",
                              })}
                              onChange={(e, value) => {
                                if (value) {
                                  let off_positionids = [];

                                  value.map((val, index) => {
                                    off_positionids.push(val.id);
                                  });
                                  setOffPositionSelected(value);
                                  setFieldValue("off_position_id", value);
                                  fetchPositionUserList("off", off_positionids);
                                } else {
                                  setOffPositionSelected([]);
                                  setFieldValue("off_position_id", []);
                                  fetchPositionUserList("off", []);
                                }
                              }}
                              getOptionLabel={(option) =>
                                option ? option.title : []
                              }
                              renderOption={(option, { selected }) => (
                                <React.Fragment>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    // checked={selected}
                                    checked={
                                      values.off_position_id !== undefined
                                        ? values.off_position_id.find(
                                            (v) => v.id === option.id
                                          )
                                          ? true
                                          : false
                                        : selected
                                    }
                                  />
                                  <div
                                    key={option.id}
                                    className="MuiListItemText-root MuiListItemText-multiline"
                                  >
                                    <span className="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                                      {option.title}
                                    </span>
                                  </div>
                                </React.Fragment>
                              )}
                              fullWidth
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Search Position"
                                  label="Off Staff Position"
                                  variant="outlined"
                                  margin="normal"
                                  helperText={
                                    touched.off_position_id &&
                                    errors.off_position_id
                                  }
                                  error={Boolean(
                                    touched.off_position_id &&
                                      errors.off_position_id
                                  )}
                                />
                              )}
                              disabled={
                                values.off_status === "" ||
                                values.off_status === 0 ||
                                values.off_status === undefined
                              }
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Autocomplete
                              multiple
                              id="off_position_users"
                              options={offuserlistdata}
                              name="off_position_users"
                              value={values.off_position_users}
                              disableCloseOnSelect
                              filterOptions={createFilterOptions({
                                matchFrom: "any",
                              })}
                              onChange={(e, newValue) => {
                                setFieldValue("off_position_users", newValue);
                              }}
                              getOptionLabel={(option) =>
                                option
                                  ? option.first_name + " " + option.last_name
                                  : []
                              }
                              renderOption={(option, { selected }) => (
                                <React.Fragment>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={
                                      values.off_position_users !== undefined
                                        ? values.off_position_users.find(
                                            (v) => v.id === option.id
                                          )
                                          ? true
                                          : false
                                        : selected
                                    }
                                  />
                                  <div
                                    key={option.id}
                                    className="MuiListItemText-root MuiListItemText-multiline"
                                  >
                                    <span className="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                                      {option.first_name +
                                        " " +
                                        option.last_name}
                                    </span>
                                  </div>
                                </React.Fragment>
                              )}
                              fullWidth
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Users"
                                  placeholder="Search Users"
                                  margin="normal"
                                />
                              )}
                              disabled={
                                values.off_status === "" ||
                                values.off_status === 0 ||
                                values.off_status === undefined
                              }
                            />
                            {Boolean(
                              touched.off_position_users &&
                                errors.off_position_users
                            ) ? (
                              <FormHelperText className="Mui-error">
                                {errors.off_position_users}
                              </FormHelperText>
                            ) : null}
                          </Grid>
                          <Divider />
                          {/* PRevious Off Status */}
                          <Grid item md={12}>
                            <Grid item>
                              <Typography variant="h4" component="h4">
                                Previous Off Status
                                {values.prev_off_status_title !== "" &&
                                values.prev_off_status_title !== undefined
                                  ? " (" + values.prev_off_status_title + ")"
                                  : ""}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={12}
                            style={{ margin: "20px 0px 0 0" }}
                          >
                            <FormLabel style={{ margin: "0 10px 0 0" }}>
                              Off Status:
                            </FormLabel>
                            <Checkbox
                              name="prev_off_status"
                              value={values.prev_off_status}
                              inputProps={{
                                "aria-labelledby": "Off status",
                              }}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setFieldValue("prev_off_status", 2);
                                } else {
                                  setFieldValue("prev_off_status", 0);
                                  setFieldValue("prev_off_send_type", 0);
                                  setFieldValue("prev_off_email_template", "");
                                  setFieldValue("prev_off_position_id", []);
                                  setFieldValue("prev_off_fax_message", "");
                                  setFieldValue("prev_off_form_id", []);
                                  setFieldValue("prev_off_position_users", []);
                                  const prev_off_position_input = document.querySelector(
                                    "#prev_off_position_id"
                                  );
                                  Object.getOwnPropertyDescriptor(
                                    window.HTMLInputElement.prototype,
                                    "value"
                                  ).set.call(prev_off_position_input, "");
                                  prev_off_position_input.dispatchEvent(
                                    new Event("change", { bubbles: true })
                                  );
                                }
                              }}
                              checked={
                                values.prev_off_status === 0 ? false : true
                              }
                              disabled={true}
                            />
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={12}
                            style={{ margin: "20px 0px 0 0" }}
                          >
                            <FormLabel style={{ margin: "0 10px 0 0" }}>
                              Off Send Type:
                            </FormLabel>
                            <FormControlLabel
                              control={
                                <Switch
                                  label="Off Send Type"
                                  name="prev_off_send_type"
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setFieldValue("prev_off_send_type", 1);
                                    } else {
                                      setFieldValue("prev_off_send_type", 0);
                                    }
                                  }}
                                  checked={
                                    values.prev_off_send_type === 1 ? 1 : 0
                                  }
                                  color="primary"
                                  readOnly
                                />
                              }
                              label={
                                values.prev_off_send_type === 1
                                  ? "Fax"
                                  : "Email"
                              }
                              // disabled={
                              //   values.prev_off_status === "" ||
                              //   values.prev_off_status === 0 ||
                              //   values.prev_off_status === undefined
                              // }
                              disabled={true}
                            />
                          </Grid>
                          {values.prev_off_send_type === 1 ? (
                            <Grid item md={6} xs={12}>
                              <TextField
                                fullWidth
                                label="Fax Message"
                                name="prev_off_fax_message"
                                value={values.prev_off_fax_message}
                                onChange={handleChange}
                                margin="normal"
                                variant="outlined"
                                error={Boolean(
                                  touched.prev_off_fax_message &&
                                    errors.prev_off_fax_message
                                )}
                                helperText={
                                  touched.prev_off_fax_message &&
                                  errors.prev_off_fax_message
                                }
                                // disabled={
                                //   values.prev_off_status === "" ||
                                //   values.prev_off_status === 0 ||
                                //   values.prev_off_status === undefined
                                //     ? true
                                //     : false
                                // }
                                disabled={true}
                              />
                            </Grid>
                          ) : (
                            <Grid item md={6} xs={12}>
                              <FormControl
                                variant="outlined"
                                className={classes.selectControl}
                              >
                                <InputLabel id="prev_off_email_template">
                                  Email Template
                                </InputLabel>
                                <Select
                                  labelId="prev_off_email_template"
                                  id="prev_off_email_template"
                                  label="Email Template"
                                  name="prev_off_email_template"
                                  value={
                                    values.prev_off_status !== 0
                                      ? values.prev_off_email_template
                                      : ""
                                  }
                                  onChange={handleChange}
                                  // disabled={
                                  //   values.prev_off_status === "" ||
                                  //   values.prev_off_status === 0 ||
                                  //   values.prev_off_status === undefined
                                  // }
                                  disabled={true}
                                  error={Boolean(
                                    touched.prev_off_email_template &&
                                      errors.prev_off_email_template
                                  )}
                                  fullWidth
                                >
                                  <MenuItem value="">
                                    <em>Select Email Template</em>
                                  </MenuItem>

                                  {emailtemplists.map(
                                    (emailtemplist, index) => (
                                      <MenuItem
                                        key={index}
                                        value={emailtemplist.id}
                                      >
                                        {emailtemplist.email_subject}
                                      </MenuItem>
                                    )
                                  )}
                                </Select>
                                {Boolean(
                                  touched.prev_off_email_template &&
                                    errors.prev_off_email_template
                                ) ? (
                                  <FormHelperText className="Mui-error">
                                    {errors.prev_off_email_template}
                                  </FormHelperText>
                                ) : null}
                              </FormControl>
                            </Grid>
                          )}
                          <Grid item xs={6} md={6}>
                            <FormControl
                              variant="outlined"
                              className="statusdocformselectControl"
                            >
                              <InputLabel id="status_forms">
                                Off Document Form
                              </InputLabel>
                              <Select
                                id="prev_off_form_id"
                                label="Off Document Form"
                                name="prev_off_form_id"
                                fullWidth
                                error={Boolean(
                                  touched.prev_off_form_id &&
                                    errors.prev_off_form_id
                                )}
                                multiple
                                value={
                                  values.prev_off_status !== 0
                                    ? values.prev_off_form_id
                                    : []
                                }
                                onChange={(e) => {
                                  setFieldValue(
                                    "prev_off_form_id",
                                    e.target.value
                                  );
                                }}
                                renderValue={(selected) => {
                                  if (selected.length === 0) {
                                    return <em>Placeholder</em>;
                                  }
                                  return selected.length + " selected";
                                }}
                                inputProps={{
                                  name: "users",
                                  id: "outlined-age-native-simple",
                                }}
                                // disabled={
                                //   values.prev_off_status === "" ||
                                //   values.prev_off_status === 0 ||
                                //   values.prev_off_status === undefined
                                //     ? true
                                //     : appCounsStatus.is_documents_signed ===
                                //         undefined ||
                                //       appCounsStatus.is_documents_signed === 0
                                //     ? true
                                //     : false
                                // }
                                disabled={true}
                              >
                                <MenuItem value="">
                                  <em>Select Document Form</em>
                                </MenuItem>
                                {docuforms.map((docuformslist, frm_index) => (
                                  <MenuItem
                                    key={`form_${frm_index}`}
                                    value={docuformslist.id}
                                  >
                                    <Checkbox
                                      checked={
                                        values.prev_off_form_id.indexOf(
                                          docuformslist.id
                                        ) > -1
                                      }
                                    />
                                    <div className="MuiListItemText-root MuiListItemText-multiline">
                                      <span className="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                                        {docuformslist.name}
                                      </span>
                                    </div>
                                  </MenuItem>
                                ))}
                              </Select>
                              {Boolean(
                                touched.prev_off_form_id &&
                                  errors.prev_off_form_id
                              ) ? (
                                <FormHelperText className="Mui-error">
                                  {errors.prev_off_form_id}
                                </FormHelperText>
                              ) : null}
                            </FormControl>
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Autocomplete
                              multiple
                              id="prev_off_position_id"
                              options={staffPositions}
                              name="prev_off_position_id"
                              value={values.prev_off_position_id}
                              disableCloseOnSelect
                              filterOptions={createFilterOptions({
                                matchFrom: "any",
                              })}
                              onChange={(e, value) => {
                                if (value) {
                                  let off_positionids = [];

                                  value.map((val, index) => {
                                    off_positionids.push(val.id);
                                  });
                                  // setOffPositionSelected(value);
                                  setFieldValue("prev_off_position_id", value);
                                  fetchPositionUserList("off", off_positionids);
                                } else {
                                  // setOffPositionSelected([]);
                                  setFieldValue("prev_off_position_id", []);
                                  fetchPositionUserList("off", []);
                                  setFieldValue("prev_off_position_users", []);
                                }
                              }}
                              getOptionLabel={(option) =>
                                option ? option.title : []
                              }
                              renderOption={(option, { selected }) => (
                                <React.Fragment>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    // checked={selected}
                                    checked={
                                      values.prev_off_position_id !== undefined
                                        ? values.prev_off_position_id.find(
                                            (v) => v.id === option.id
                                          )
                                          ? true
                                          : false
                                        : selected
                                    }
                                  />
                                  <div
                                    key={option.id}
                                    className="MuiListItemText-root MuiListItemText-multiline"
                                  >
                                    <span className="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                                      {option.title}
                                    </span>
                                  </div>
                                </React.Fragment>
                              )}
                              fullWidth
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Search Position"
                                  label="Off Staff Position"
                                  variant="outlined"
                                  margin="normal"
                                  helperText={
                                    touched.prev_off_position_id &&
                                    errors.prev_off_position_id
                                  }
                                  error={Boolean(
                                    touched.prev_off_position_id &&
                                      errors.prev_off_position_id
                                  )}
                                />
                              )}
                              // disabled={
                              //   values.prev_off_status === "" ||
                              //   values.prev_off_status === 0 ||
                              //   values.prev_off_status === undefined
                              // }
                              disabled={true}
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Autocomplete
                              multiple
                              id="prev_off_position_users"
                              options={offuserlistdata}
                              name="prev_off_position_users"
                              value={values.prev_off_position_users}
                              disableCloseOnSelect
                              filterOptions={createFilterOptions({
                                matchFrom: "any",
                              })}
                              onChange={(e, newValue) => {
                                setFieldValue(
                                  "prev_off_position_users",
                                  newValue
                                );
                              }}
                              getOptionLabel={(option) =>
                                option
                                  ? option.first_name + " " + option.last_name
                                  : []
                              }
                              renderOption={(option, { selected }) => (
                                <React.Fragment>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={
                                      values.prev_off_position_users !==
                                      undefined
                                        ? values.prev_off_position_users.find(
                                            (v) => v.id === option.id
                                          )
                                          ? true
                                          : false
                                        : selected
                                    }
                                  />
                                  <div
                                    key={option.id}
                                    className="MuiListItemText-root MuiListItemText-multiline"
                                  >
                                    <span className="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                                      {option.first_name +
                                        " " +
                                        option.last_name}
                                    </span>
                                  </div>
                                </React.Fragment>
                              )}
                              fullWidth
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Users"
                                  placeholder="Search Users"
                                  margin="normal"
                                />
                              )}
                              // disabled={
                              //   values.prev_off_status === "" ||
                              //   values.prev_off_status === 0 ||
                              //   values.prev_off_status === undefined
                              //     ? true
                              //     : false
                              // }
                              disabled={true}
                            />
                            {Boolean(
                              touched.prev_off_position_users &&
                                errors.prev_off_position_users
                            ) ? (
                              <FormHelperText className="Mui-error">
                                {errors.prev_off_position_users}
                              </FormHelperText>
                            ) : null}
                          </Grid>
                        </>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <TextField
                        fullWidth
                        label="Notes"
                        name="status_note"
                        value={values.status_note}
                        // onChange={updateStausNote}
                        onChange={handleChange}
                        margin="normal"
                        variant="outlined"
                        multiline
                        minRows={3}
                        error={Boolean(
                          touched.status_note && errors.status_note
                        )}
                        helperText={touched.status_note && errors.status_note}
                      />
                    </Grid>
                  </CardContent>
                  <Box display="flex" justifyContent="flex-end" p={2}>
                    <Button
                      style={{ marginRight: "7px" }}
                      variant="contained"
                      component={RouterLink}
                      to={`/app/counselors`}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={isSubmitting}
                      // onClick={handleStatusOpen}
                    >
                      Save
                    </Button>
                  </Box>
                  <Divider />
                  <CardContent>
                    <TableContainer
                      component={Paper}
                      className={classes.container}
                    >
                      <Table
                        className={classes.table}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">Action</TableCell>
                            <TableCell>Complete Status</TableCell>
                            <TableCell>Assigned Counselor</TableCell>
                            <TableCell align="center">Modify By</TableCell>
                            <TableCell align="center">
                              Complete Status Date
                            </TableCell>
                            <TableCell align="center">Notes</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {appCounsStatus.complated_status.length !== 0 ? (
                            appCounsStatus.complated_status.map((s, index) => (
                              <TableRow key={index}>
                                <TableCell
                                  style={{ width: 200 }}
                                  align="center"
                                >
                                  <Tooltip
                                    disableFocusListener
                                    title="Status Log"
                                  >
                                    <Button
                                      style={{ minWidth: 40 }}
                                      size="small"
                                      color="primary"
                                      component={RouterLink}
                                      to={`/app/statuslog/show/${appCounsStatus.app_id}`}
                                      target="_blank"
                                    >
                                      <DescriptionOutlinedIcon />
                                    </Button>
                                  </Tooltip>
                                  {s.auto_status !== 1 &&
                                  s.complete_flag !== true &&
                                  0 === index ? (
                                    <Tooltip
                                      disableFocusListener
                                      title="Delete Status"
                                    >
                                      <Button
                                        style={{ minWidth: 40 }}
                                        size="small"
                                        color="primary"
                                        onClick={() => {
                                          delStatus(
                                            appCounsStatus.app_id,
                                            s.id,
                                            s.app_status_id
                                          );
                                        }}
                                      >
                                        <DeleteOutlineOutlinedIcon />
                                      </Button>
                                    </Tooltip>
                                  ) : null}
                                </TableCell>
                                <TableCell
                                  style={{ width: 200 }}
                                  component="th"
                                  scope="row"
                                >
                                  {s.name}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {s.couns_name}
                                </TableCell>
                                <TableCell
                                  style={{ width: 200 }}
                                  align="center"
                                >
                                  {s.auto_status !== 1 ? s.modify_by : ""}
                                </TableCell>
                                <TableCell
                                  style={{ width: 200 }}
                                  align="center"
                                >
                                  {s.date}
                                </TableCell>
                                <TableCell style={{ width: 200 }} align="left">
                                  {s.status_note !== "" &&
                                  s.status_note !== undefined
                                    ? isReadMore
                                      ? s.status_note.slice(0, 50)
                                      : s.status_note
                                    : null}
                                  {s.status_note.length > 50 && (
                                    <Button
                                      style={{ minWidth: 40 }}
                                      size="small"
                                      color="primary"
                                      onClick={handleClickOpen}
                                      value={s.status_note}
                                    >
                                      {isReadMore ? "...Read More" : ""}
                                    </Button>
                                  )}
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>No record found</TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardContent>
                  <ConformationDialog
                    className={classes.paper}
                    id="confirmation-dialog-title"
                    keepMounted
                    open={open}
                    onClose={handleDelClose}
                    onConformAction={onConform}
                  />
                  <ConformationDialog
                    className={classes.paper}
                    id="confirmation-dialog-title"
                    keepMounted
                    open={openStatusBox}
                    onClose={handleCloseStatusBox}
                    onConformAction={onStatusSubmit}
                    mode={"app_status"}
                  />
                </Card>
                <Dialog
                  open={statusNoteOpen}
                  onClose={handleCloseStatusNote}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth={true}
                >
                  <DialogTitle id="alert-dialog-title">Note</DialogTitle>
                  <DialogContent dividers>
                    {logDescription.replace(/<[^>]+>/g, "")}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={handleCloseStatusNote}
                      color="primary"
                      variant="contained"
                      autoFocus
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            </form>
          );
        }}
      </Formik>
    );
  }
};

export default UpdateCounsellorStatus;
