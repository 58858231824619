import React from "react";
import { useField } from "formik";
import PhoneInput from "react-phone-input-2";
import * as Messages from "src/constants/Messages/index";
const CustomPhoneInput = ({
  name,
  handleChange,
  handleBlur,
  setCheckPhoneNumber,
  setFieldError,
  ...otherProps
}) => {
  const [field, mata, helpers] = useField(name);
  return (
    <>
      <PhoneInput
        style={{
          marginTop: "16px",
          marginBottom: "8px",
          borderColor: "#f64538",
        }}
        inputStyle={{ width: "inherit" }}
        specialLabel="Phone"
        country={"us"}
        // disableDropdown={true}
        //countryCodeEditable={false}
        inputProps={{
          name: name,
        }}
        name={name}
        onChange={(e) => {
          helpers.setValue(e);
          handleChange(e);
        }}
        onBlur={handleBlur}
        value={field.value}
        //autoFormat={false}
        enableLongNumbers={true}
        isValid={(inputNumber, country, countries) => {
          let length = inputNumber.length;
          if (length === 0) {
            if (!mata.error) {
              helpers.setError(Messages.REQUIRED);
            }
          } else if (
            country.countryCode.length < length &&
            country.format.match(/\./g).length !== length
          ) {
            if (!mata.error) {
              helpers.setError(Messages.INVALID_MOBILE);
              setCheckPhoneNumber(1);
            }
            if (mata.touched) {
              return false;
            }
          } else {
            setCheckPhoneNumber(0);
          }
          /*else if (!(length > 5 && length <= 10)) {
            if (!mata.error) {
              helpers.setError(Messages.INVALID_MOBILE);
            }
            if (mata.touched) {
              return false;
            }
          }*/
          return true;
        }}
      />
      {mata.touched && mata.error && <div className="error">{mata.error}</div>}
    </>
  );
};

export default CustomPhoneInput;
