import React, { useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Field } from "formik";
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Page from "src/components/Page";
import { connect } from "react-redux";
import { setSnackbar } from "src/actions";
import * as Http from "src/services/Http";
import * as Messages from "src/constants/Messages/index";
import ScreenLoader from "src/components/ScreenLoader";
import PasswordInput from "src/components/common/PasswordInput";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const resetPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .lowercase()
    .email(Messages.INVALID_EMAIL)
    .required(Messages.REQUIRED),
  password: Yup.string()
    .required(Messages.REQUIRED)
    .min(6, Messages.PASSWORD_MIN_6)
    .matches(
      // /^(?=.*[A-Za-z])(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]$/,
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/,
      Messages.PASSWORD_MATCH
    ),
  password_confirmation: Yup.string()
    .required(Messages.REQUIRED)
    .oneOf([Yup.ref("password"), null], Messages.PASSWORD_SAME),
});

const ResetPasswordView = (props) => {
  const classes = useStyles();
  const resetPasswordToken = new URLSearchParams(useLocation().search).get(
    "token"
  );
  const navigate = useNavigate();
  const [Loading, setLoading] = useState(false);

  const resetPassword = async (data, actions) => {
    setLoading(true);
    await Http.call("POST", "admin/auth/resetPassword", data).then(
      async (response) => {
        if (response.status === 200) {
          navigate("/login");
        } else {
          actions.setErrors({ email: response.message });
        }
        props.setSnackbar(true, response.status, response.message);

        actions.setSubmitting(false);
        setLoading(false);
      }
    );
  };
  if (Loading) {
    return <ScreenLoader />;
  }
  return (
    <Page className={classes.root} title="Reset Password">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              token: resetPasswordToken,
              email: "",
              password: "",
              password_confirmation: "",
            }}
            validationSchema={resetPasswordSchema}
            onSubmit={(event, actions) => {
              resetPassword(event, actions);
              //actions.setSubmitting(false);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <Field type="hidden" name="token" value={values.token} />
                <Box mb={3}>
                  <Typography color="textPrimary" align="center" variant="h2">
                    Reset Password
                  </Typography>
                </Box>

                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <PasswordInput label="Password" name="password" />
                <PasswordInput
                  label="Confirm Password"
                  name="password_confirmation"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Submit
                  </Button>
                </Box>
                <Typography color="textSecondary" variant="body1">
                  <Link component={RouterLink} to="/login" variant="h6">
                    Back to Login
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordView);
//export default ForgotPasswordView;
