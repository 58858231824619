import { Box, Breadcrumbs, Typography } from "@material-ui/core";
import React from "react";
import { Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

function BreadcrumbsAdmin({ urlList }) {
  const rowLen = urlList.length;
  return (
    <Box display="flex" justifyContent="flex-end" alignItems="right" mb={3}>
      <Breadcrumbs aria-label="breadcrumb">
        {urlList.map((listData, i) => {
          if (rowLen === i + 1) {
            return <Typography key={i}>{listData.name}</Typography>;
          } else {
            // not last one
            return (
              <Link key={i} component={RouterLink} to={listData.url}>
                {listData.name}
              </Link>
            );
          }
        })}
      </Breadcrumbs>
    </Box>
  );
}
export default BreadcrumbsAdmin;
