import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CategorySelection from "../CategorySelection/index";
import * as Http from "src/services/Http";
import { useDispatch } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  DialogActions,
  Button,
  TextField,
} from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import ImportantNotice from "../ImportantNotice";
import { setPackageSelected } from "src/actions";
import CenterLoader from "src/components/Loader/CenterLoader";
// import CustomStepper from "src/Components/Stepper";

// Define validation schema using Yup
const validationSchema = Yup.object().shape({
  custom_service_title: Yup.string().required("Title is required"),
  quantity: Yup.number()
    .min(1, "Quantity must be greater than 0")
    .max(10, "Quantity cannot be greater than 10")
    .required("Quantity is required"),
  singlePrice: Yup.number()
    .min(1, "Single Price must be greater than 0")
    .required("Single Price is required"),
  tax: Yup.number()
    .min(0, "Tax percentage must be 0 or more")
    .required("Tax percentage is required"),
});

export default function PackageBody({
  data,
  setData,
  crtPrice,
  QuestionConditionChk,
  PreneedDoneChk,
  setTabval,
  setdisableTab,
  setPackageData,
  packageData,

  getExtraMailesCharges,
  extraMileServicePrice,
  setExtraMileServicePrice,
  ...otherProps
}) {
  const { name, price, description, addonCategory } = data;
  const navigate = useNavigate();
  const [isPaymentLoading, setisPaymentLoading] = useState(false);
  const [errorMessageForUrn, setErrorMessageForUrn] = useState("");
  const [showImportantNotice, setShowImportantNotice] = useState(false);
  const [
    errorMessageForAdditionServices,
    setErrorMessageForAdditionServices,
  ] = useState("");
  const [errorMessageForDelivery, setErrorMessageForDelivery] = useState("");
  const [
    errorMessageForDeliveryAddress,
    setErrorMessageForDeliveryAddress,
  ] = useState("");

  const [errorMessageForPeacemaker, setErrorMessageForPeacemaker] = useState(
    ""
  );
  const [errorMessageForServiceArea, setErrorMessageForServiceArea] = useState(
    ""
  );
  const [
    errorMessageForServiceAreaAddress,
    setErrorMessageForServiceAreaAddress,
  ] = useState("");

  const [refundPlus, setRefundPlus] = useState(otherProps.certificate);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  const [lastUsedId, setLastUsedId] = useState(0); // Initialize with 0
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  let disablePlusCertificate = false;
  let disableMiuxCertificate = false;

  const savePackages = async () => {
    setisPaymentLoading(true);
    let addOns = [];
    //let addOnsData = [];

    let isErrro = false;
    let selectedCategories = [];

    let deliveryAddress = "";
    let serviceAreaAddress = "";
    setErrorMessageForUrn("");
    setErrorMessageForAdditionServices("");
    setErrorMessageForDelivery("");
    setErrorMessageForDeliveryAddress("");

    setErrorMessageForPeacemaker("");
    setErrorMessageForServiceArea("");
    setErrorMessageForServiceAreaAddress("");
    otherProps.selected.map((item, index) => {
      if (!selectedCategories.includes(item.catId)) {
        selectedCategories.push(item.catId);
      }

      if (item.address !== undefined && item.address !== "") {
        deliveryAddress = item.address;
      }
      if (
        item.address !== undefined &&
        item.address !== "" &&
        item.catId === 32
      ) {
        serviceAreaAddress = item.address;
      }

      return addOns.push({
        id: item.id,
        quantity: item.quantity ? item.quantity : 0,
        state: item.state !== undefined ? item.state : "",
        address: item.address !== undefined ? item.address : "",

        name:  item.service_name ,
        custom_addons:  item.custom_addons , 
        tax:item.tax_per,
        price:item.price,

        city: item.city !== undefined ? item.city : "",
        country: item.country !== undefined ? item.country : "",
        lat: item.lat !== undefined ? item.lat : "",
        long: item.long !== undefined ? item.long : "",
        zip: item.zip !== undefined ? item.zip : "",
      });
    });

    const caterror = document.getElementById("caterror");
    if (!otherProps.adjustPayment) {
      if (!selectedCategories.includes(28) && caterror) {
        isErrro = true;
        caterror.scrollIntoView({ behavior: "smooth" });
        setErrorMessageForAdditionServices(
          "To proceed, please choose an Additional Service."
        );
      } else if (!selectedCategories.includes(2) && caterror) {
        isErrro = true;
        caterror.scrollIntoView({ behavior: "smooth" });
        setErrorMessageForDelivery("Choose a Delivery method to proceed.");
      } else if (
        selectedCategories.includes(2) &&
        deliveryAddress === "" &&
        caterror
      ) {
        isErrro = true;
        caterror.scrollIntoView({ behavior: "smooth" });
        setErrorMessageForDeliveryAddress(
          "Enter the Shipping address to proceed."
        );
      } else if (!selectedCategories.includes(31) && caterror) {
        isErrro = true;
        caterror.scrollIntoView({ behavior: "smooth" });
        setErrorMessageForPeacemaker(
          "A response to the Pacemaker question is required."
        );
      } else if (!selectedCategories.includes(32) && caterror) {
        isErrro = true;
        caterror.scrollIntoView({ behavior: "smooth" });
        setErrorMessageForServiceArea(
          "To proceed, please provide a Service Area information."
        );
      } else if (
        selectedCategories.includes(32) &&
        serviceAreaAddress === "" &&
        caterror
      ) {
        isErrro = true;
        caterror.scrollIntoView({ behavior: "smooth" });
        setErrorMessageForServiceAreaAddress(
          "Enter the Service Area address to proceed."
        );
      }
    }

    if (!isErrro) {
      const package_id = JSON.parse(localStorage.getItem("packageData")).id;

      let local_discount = localStorage.getItem("discount");

      let discount_id = local_discount
        ? JSON.parse(local_discount).discount_id
        : "";

      let body = {
        package_id: package_id,
        addons_ids: addOns,
        // discount_id: discount_id,
        // user_id: JSON.parse(localStorage.getItem("state")).session.user.id,
        user_id: otherProps.user_id,
        no_of_cert: otherProps.certificate,
        is_edit:
          otherProps.refundStatus === true || otherProps.adjustPayment === true
            ? true
            : false,
        is_refund: otherProps.refundStatus === true ? true : false,
        is_adjustpayment: otherProps.adjustPayment === true ? true : false,
      };
      await Http.call("POST", "add_subscription_details", body).then(
        (response) => {
          const subscription_id = response?.data?.subscription_id;
          const is_adjustpayment = response?.data?.is_adjustpayment;

          otherProps.setSubscription_id(subscription_id);
          otherProps.setAdjuestpaymentFlag(is_adjustpayment);

          const message = response?.message;
          if (response.status === 200) {
            // Set the dialog message if it exists
            setDialogMessage(
              message || "your additional package selection successfully!"
            );
            dispatch(setPackageSelected(true));

            if (parseInt(otherProps.is_Preneed) === 1) {
              setTabval("step6"); //gwquest show
              setdisableTab(["step2", "step4", "step5", "step7", "step12"]);
            } else {
              if (!otherProps.adjustPayment) {
                setShowImportantNotice(true);
              }
            }
          } else {
            alert(response.message);
          }
        }
      );
    }
    setisPaymentLoading(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // Function to calculate total price including tax
  const calculateTotalPrice = (quantity, singlePrice, tax) => {
    const priceWithoutTax = quantity * singlePrice;
    const taxAmount = (priceWithoutTax * tax) / 100;
    return priceWithoutTax + taxAmount;
  };

  // Function to handle form submission
  const handleAddAddon = (values) => {
    const totalPrice = calculateTotalPrice(
      values.quantity,
      values.singlePrice,
      values.tax
    );

    const newAddon = {
      id: lastUsedId + 1,
      catId: 33,
      service_name: values.custom_service_title,
      name:values.custom_service_title,
      price: parseFloat(values.singlePrice),
      // placeholder_price: parseFloat(totalPrice),
      tax: 0,
      tax_per: values.tax,
      quantity: parseInt(values.quantity),
      type: 1,
      image:"",
      address: "",
      state: "",
      city: "",
      country: "",
      lat: "",
      long: "",
      zip: "",
      selected: true,
      included: false,
      custom_addons: true, // Custom addon flag
    };
    setPackageData(newAddon);

  setData((prevData) => ({
    ...prevData,
    addonCategory: prevData.addonCategory.map((category) => {
      if(category.id === newAddon.catId){
        return {
          ...category,
          addOns: [...category.addOns, newAddon] // Append new add-on to addOns array
        };
      }
      return category;
    }),
  }));
      
    // setData(newAddon)
    setLastUsedId(lastUsedId + 1);
    handleClose();
  };


  if (isPaymentLoading) {
    return (
      <Grid
        style={{
          display: "flex",
          justifyContent: "center",
          backgroundImage: `url("/img/Rectangle24.png")`,
          backgroundSize: "cover",
          height: "100vh",
          position: "relative",
          zIndex: "0",
        }}
        className="scrollable-section"
      >
        <CenterLoader minheight="250px" />
      </Grid>
    );
  }

  return (
    <div>
      {showImportantNotice ? (
        <ImportantNotice
          setTabval={setTabval}
          setdisableTab={setdisableTab}
          is_Preneed={otherProps.is_Preneed}
        />
      ) : (
        <Grid
          className="scrollable-section"
          style={{
            backgroundImage: `url("/img/Rectangle24.png")`,
            backgroundSize: "cover",
            backgroundAttachment: "fixed",
            backgroundPosition: "center",
            position: "relative",
            zIndex: "0",
          }}
        >
          <Grid className="container">
            <div className="adjust_exit_button">
              <div className="col-md-12">
                {otherProps.refundStatus || otherProps.adjustPayment ? (
                  <button
                    className="btn-apply-summary quote-button-custom"
                    onClick={() => {
                      setTabval("step12");
                    }}
                  >
                    EXIT &nbsp;
                    <i className="fa fa-arrow-right" aria-hidden="true"></i>
                  </button>
                ) : null}
              </div>
              {
                otherProps.adjustPayment ?
              <div>
                <button
                 className="btn-apply-summary quote-button-custom"
                  variant="outlined"
                  color="primary"
                  onClick={handleClickOpen}
                >
                  Custom Addons
                </button>
              </div>
              :null }
            </div>


            <Grid className="row package-main package-custom-main">
              <Grid
                item
                //  className="col-lg-8 col-md-12 mb-4"
                xs={12}
                md={12}
                lg={8}
                style={{ margin: "0 auto" }}
              >
                <div className="selectServices selectServices-custom ">
                  <div className="first-content">
                    <h1>Select Services</h1>
                  </div>
                  <ul className="que-list ">
                    <li>
                      Our standard package includes a simple container to honor
                      your loved one. To create a truly personal tribute,
                      consider exploring our alternative range of beautiful
                      urns, pendants, votives, and other meaningful keepsakes
                      you and your loved ones can treasure.
                    </li>
                  </ul>
                </div>

                {!otherProps.adjustPayment && (
                  <div className="first-payment selectServices-custom">
                    <div className="payment-op1 ">
                      <h3>{`${name}`}</h3>
                      <h6>${`${parseFloat(price).toFixed(2)}`}</h6>
                    </div>
                    <ul className="que-list ">
                      <li>{`${description}`}</li>
                    </ul>
                  </div>
                )}

                {addonCategory.map((item, index) => {
                  if (item.addOns.length > 0) {
                    let selectedData = otherProps.selected.filter(
                      ({ catId }) => catId === item.id
                    );
                    return (
                      <CategorySelection
                        key={index}
                        data={item}
                        index={index}
                        selected={selectedData}
                        getShippingTotal={otherProps.getShippingTotal}
                        errorMessageForPeacemaker={errorMessageForPeacemaker}
                        setErrorMessageForPeacemaker={
                          setErrorMessageForPeacemaker
                        }
                        errorMessageForServiceArea={errorMessageForServiceArea}
                        setErrorMessageForServiceArea={
                          setErrorMessageForServiceArea
                        }
                        errorMessageForServiceAreaAddress={
                          errorMessageForServiceAreaAddress
                        }
                        setErrorMessageForServiceAreaAddress={
                          setErrorMessageForServiceAreaAddress
                        }
                        getExtraMailesCharges={getExtraMailesCharges}
                        extraMileServicePrice={extraMileServicePrice}
                        setExtraMileServicePrice={setExtraMileServicePrice}
                        errorMessageForUrn={errorMessageForUrn}
                        setErrorMessageForUrn={setErrorMessageForUrn}
                        errorMessageForAdditionServices={
                          errorMessageForAdditionServices
                        }
                        setErrorMessageForAdditionServices={
                          setErrorMessageForAdditionServices
                        }
                        errorMessageForDelivery={errorMessageForDelivery}
                        setErrorMessageForDelivery={setErrorMessageForDelivery}
                        errorMessageForDeliveryAddress={
                          errorMessageForDeliveryAddress
                        }
                        setErrorMessageForDeliveryAddress={
                          setErrorMessageForDeliveryAddress
                        }
                        refundStatus={otherProps.refundStatus}
                        adjustPayment={otherProps.adjustPayment}
                        handleShippingChange={otherProps.handleShippingChange}
                        shippingAmount={otherProps.shippingAmount}
                      />
                    );
                  }
                })}
                <div className="bill-container-custom">
                  <div className="payment-op1 bill-container bill-content-custom">
                    <p>Select no. of death certificates</p>
                    <div className="que-option  que-option-for-mobile">
                      <div className="number-input">
                        <button
                          onClick={() => {
                            if (
                              otherProps.certificate <= refundPlus &&
                              otherProps.adjustPayment === true
                            ) {
                              disableMiuxCertificate = true;
                              return;
                            }
                            if (otherProps.certificate > 1) {
                              otherProps.setCertificate(
                                otherProps.certificate - 1
                              );
                            }
                            if (
                              otherProps.certificate > 0 &&
                              otherProps.adjustPayment
                            ) {
                              otherProps.setCertificate(
                                otherProps.certificate - 1
                              );
                            }
                          }}
                          className="minus"
                          disabled={disableMiuxCertificate}
                        >
                          -
                        </button>
                        <input
                          className="quantity"
                          min="0"
                          name="quantity"
                          value={otherProps.certificate}
                          type="number"
                          // onChange={(e) => otherProps.setCertificate(e.target.value)}
                          readOnly={true}
                        />
                        {/* <button
                          onClick={() =>
                            otherProps.setCertificate(
                              parseInt(otherProps.certificate) + 1
                            )
                          }
                          className="plus"
                        >
                          +
                        </button> */}
                        <button
                          onClick={() => {
                            if (
                              refundPlus <= otherProps.certificate &&
                              otherProps.refundStatus === true
                            ) {
                              disablePlusCertificate = true;
                              return;
                            }

                            otherProps.setCertificate(
                              parseInt(otherProps.certificate) + 1
                            );
                          }}
                          className="plus"
                          disabled={disablePlusCertificate}
                        >
                          +
                        </button>
                      </div>
                    </div>
                    <div className="certificate-amount">
                      {/* <h6>
                        $
                        {parseFloat(
                          otherProps.certificate * crtPrice - crtPrice
                        ).toFixed(2)}
                      </h6> */}
                      {/* new adjust vise */}
                      <h6>
                        $
                        {parseFloat(
                          otherProps.adjustPayment ||
                            otherProps.refundAdditional
                            ? otherProps.certificate * crtPrice
                            : otherProps.certificate * crtPrice - crtPrice
                        ).toFixed(2)}
                      </h6>
                    </div>
                  </div>
                  <div className="payment-op1 bill-container bill-content-custom bill-content2">
                    <p>Tax Calculation</p>
                    <h6
                    // className="text-white"
                    >
                      ${`${parseFloat(otherProps.getTotalTax()).toFixed(2)}`}
                    </h6>
                  </div>
                  <div className="payment-op1 bill-container bill-content-custom bill-content3">
                    <p>Total amount due</p>
                    <h6
                      //  className="text-white"
                      className="total-Price"
                    >
                      <b>${`${parseFloat(otherProps.total()).toFixed(2)}`}</b>
                      {/* ${`${parseFloat(otherProps.total).toFixed(2)}`} */}
                    </h6>
                  </div>

                  <div
                    className="cat-error error-background-color"
                    id="caterror"
                  >
                    {errorMessageForUrn !== "" && <h3>{errorMessageForUrn}</h3>}
                    {errorMessageForAdditionServices !== "" && (
                      <h3>{errorMessageForAdditionServices}</h3>
                    )}
                    {errorMessageForDelivery !== "" && (
                      <h3>{errorMessageForDelivery}</h3>
                    )}
                    {errorMessageForDeliveryAddress !== "" && (
                      <h3>{errorMessageForDeliveryAddress}</h3>
                    )}
                    {errorMessageForPeacemaker !== "" && (
                      <h3>{errorMessageForPeacemaker}</h3>
                    )}
                    {errorMessageForServiceArea !== "" && (
                      <h3>{errorMessageForServiceArea}</h3>
                    )}
                    {errorMessageForServiceAreaAddress !== "" && (
                      <h3>{errorMessageForServiceAreaAddress}</h3>
                    )}
                  </div>
                  <div className="payment-op1">
                    <button
                      type="text"
                      className="btn-apply btn-continue mt-4 mx-0 mb-4 bill-container-button-custom"
                      // onClick={() => savePackages()}
                      onClick={async () => {
                        try {
                          if (
                            otherProps.refundStatus ||
                            otherProps.adjustPayment
                          ) {
                            await savePackages();

                            setOpenDialog(true);

                            setTimeout(() => {
                              setTabval("step12");
                            }, 3000);
                          } else {
                            await savePackages();
                          }
                        } catch (error) {
                          console.error(
                            "Error occurred during process:",
                            error
                          );
                        }
                      }}
                      disabled={otherProps.disableButton}
                    >
                      Continue
                    </button>
                  </div>
                </div>

                {/* <div className="amount-note">
              <p>There are no additional costs or hidden charges.</p>
            </div> */}
              </Grid>

              <Grid
                style={{ margin: "32px auto 0px auto" }}
                item
                xs={12}
                md={12}
                lg={8}
              >
                {/* ----------------------------------------NEW-------------------------------------------- */}
                <div className="quote quote-custom ">
                  <h6 className="text-white">Summary</h6>
                  {!otherProps.adjustPayment && (
                    <div className="servicre-package">
                      <h3>{`${name}`}</h3>
                      <span>
                        {" "}
                        <b>${`${parseFloat(price).toFixed(2)}`}</b>
                      </span>
                    </div>
                  )}
                  <>
                    {addonCategory.map((category, index) => {
                      // if (category.id != 28 && category.id != 2) {
                      if (category.id != 2) {
                        if (category.addOns.length > 0) {
                          let selectedServices = otherProps.selected.filter(
                            ({ catId }) => catId === category.id
                          );

                          if (selectedServices.length > 0) {
                            // Filter out services that should not be displayed
                            const filteredServices = selectedServices.filter(
                              (selectedService) => {
                                return !(
                                  category.id === 31 &&
                                  selectedService.name !== "Yes"
                                );
                              }
                            );

                            if (filteredServices.length > 0) {
                              return (
                                <div key={index} className="service-category">
                                  {(category.id === 32 ||
                                    (category.id === 31 &&
                                      selectedServices.some(
                                        (service) => service.name === "Yes"
                                      ))) && <p>{category.name}</p>}

                                  {filteredServices.map(
                                    (selectedService, idx) => (
                                      <div key={idx} className="quote-service">
                                        <span className="service-name">
                                          {category.id === 31 &&
                                          selectedService.name === "Yes" ? (
                                            `Implant Disposition (${selectedService.quantity})`
                                          ) : category.id === 32 ? (
                                            //  && selectedService.name === "No"
                                            <>
                                              {selectedService.quantity > 0
                                                ? selectedService.quantity
                                                : // : selectedService.quantity <= 20
                                                  // ? "0"
                                                  "0"}{" "}
                                              miles,
                                              <br />
                                              {selectedService.address}
                                            </>
                                          ) : (
                                            selectedService.name
                                          )}
                                          {category.id !== 28 &&
                                            category.id !== 31 &&
                                            category.id !== 32 &&
                                            `(${selectedService?.quantity})`}
                                        </span>
                                        <span>
                                          {category.id !== 28 ? (
                                            <b>
                                              {" "}
                                              {category.id === 32 &&
                                              selectedService.id === 77
                                                ? "$" +
                                                  parseFloat(
                                                    selectedService.quantity <=
                                                      20
                                                      ? "0"
                                                      : extraMileServicePrice
                                                  ).toFixed(2)
                                                : "$" +
                                                  parseFloat(
                                                    selectedService.price *
                                                      selectedService.quantity
                                                  ).toFixed(2)}
                                            </b>
                                          ) : category.id === 28 ? (
                                            <b>
                                              {" "}
                                              {selectedService.placeholder_price >
                                              0
                                                ? ""
                                                : selectedService.price > 0
                                                ? "$" +
                                                  parseFloat(
                                                    selectedService.price
                                                  ).toFixed(2)
                                                : ""}
                                            </b>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </div>
                                    )
                                  )}
                                </div>
                              );
                            }
                          }
                          return null; // Return null if no services to display
                        }
                      }
                      return null;
                    })}

                    {/* <-----------shipping-------- */}
                    {addonCategory.map((category, index) => {
                      if (category.id === 2) {
                        if (category.addOns.length > 0) {
                          let selectedServices = otherProps.selected.filter(
                            ({ catId }) => catId === category.id
                          );

                          if (selectedServices.length > 0) {
                            return (
                              <div key={index} className="service-category">
                                <p>{category.name}</p>

                                {selectedServices.map(
                                  (selectedService, idx) => (
                                    <div key={idx} className="quote-service">
                                      <span className="service-name">
                                        {selectedService.name} <br />
                                        {selectedService.address}
                                      </span>
                                      <span>
                                        <b>
                                          $
                                          {parseFloat(
                                            otherProps.getShippingTotal()
                                          ).toFixed(2)}
                                        </b>
                                      </span>
                                    </div>
                                  )
                                )}
                              </div>
                            );
                          }
                        }
                      }
                      return null;
                    })}

                    <div className="service-payment-main">
                      <div className="quote-service-part">
                        <span>
                          Death certificates ({otherProps.certificate})
                        </span>
                        <span>
                          <b>
                            {/* $
                            {parseFloat(
                              otherProps.certificate * crtPrice - crtPrice
                            ).toFixed(2)} */}
                            $
                            {parseFloat(
                              otherProps.adjustPayment
                                ? otherProps.certificate * crtPrice
                                : otherProps.certificate * crtPrice - crtPrice
                            ).toFixed(2)}
                          </b>
                        </span>
                      </div>
                      <div className="quote-service">
                        <span style={{ color: "black" }}>Tax Calculation</span>
                        <span>
                          <b>
                            $
                            {`${parseFloat(otherProps.getTotalTax()).toFixed(
                              2
                            )}`}
                          </b>
                        </span>
                      </div>
                    </div>

                    <div className="quote-service quote-service-custom ">
                      <p>Your Quote</p>
                      <h4 className="total-Price">
                        <b>${`${parseFloat(otherProps.total()).toFixed(2)}`}</b>
                      </h4>
                    </div>
                  </>

                  {/* <p className="text-white">
                  ${`${parseFloat(otherProps.total).toFixed(2)}`}
                </p> */}
                  <button
                    className="btn-apply-summary quote-button-custom"
                    onClick={async () => {
                      try {
                        if (
                          otherProps.refundStatus ||
                          otherProps.adjustPayment
                        ) {
                          await savePackages();

                          setOpenDialog(true);

                          setTimeout(() => {
                            setTabval("step12");
                          }, 3000);
                        } else {
                          await savePackages();
                        }
                      } catch (error) {
                        console.error("Error occurred during process:", error);
                      }
                    }}
                    disabled={otherProps.disableButton}
                  >
                    CONTINUE &nbsp;
                    <i className="fa fa-arrow-right" aria-hidden="true"></i>
                  </button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      {/* Dialog Component */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Success</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {dialogMessage ? dialogMessage : "Processing..."}
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {/* custome addons Dialog Box */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Custom Addon</DialogTitle>

        <Formik
          initialValues={{
            custom_service_title: "",
            quantity: 1,
            tax: 0,
            singlePrice: 0,
            totalPrice: 0,
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setFieldValue }) => {
            // Calculate total price and add addon
            const totalPrice = calculateTotalPrice(
              values.quantity,
              values.singlePrice,
              values.tax
            );
            setFieldValue("totalPrice", totalPrice.toFixed(2)); // Update total price in form state
            handleAddAddon(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <TextField
                  margin="dense"
                  name="custom_service_title"
                  label="Title"
                  type="text"
                  fullWidth
                  value={values.custom_service_title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.custom_service_title &&
                    !!errors.custom_service_title
                  }
                  helperText={
                    touched.custom_service_title && errors.custom_service_title
                  }
                />
                <TextField
                  margin="dense"
                  name="quantity"
                  label="Quantity"
                  type="number"
                  fullWidth
                  value={values.quantity}
                  onChange={(e) => {
                    handleChange(e);
                    const totalPrice = calculateTotalPrice(
                      e.target.value,
                      values.singlePrice,
                      values.tax
                    );
                    setFieldValue("totalPrice", totalPrice.toFixed(2));
                  }}
                  onBlur={handleBlur}
                  error={touched.quantity && !!errors.quantity}
                  helperText={touched.quantity && errors.quantity}
                />
                <TextField
                  margin="dense"
                  name="singlePrice"
                  label="Single Price"
                  type="number"
                  fullWidth
                  value={values.singlePrice}
                  onChange={(e) => {
                    handleChange(e);
                    const totalPrice = calculateTotalPrice(
                      values.quantity,
                      e.target.value,
                      values.tax
                    );
                    setFieldValue("totalPrice", totalPrice.toFixed(2));
                  }}
                  onBlur={handleBlur}
                  error={touched.singlePrice && !!errors.singlePrice}
                  helperText={touched.singlePrice && errors.singlePrice}
                />
                <TextField
                  margin="dense"
                  name="tax"
                  label="Tax Percentage"
                  type="number"
                  fullWidth
                  value={values.tax}
                  onChange={(e) => {
                    handleChange(e);
                    const totalPrice = calculateTotalPrice(
                      values.quantity,
                      values.singlePrice,
                      e.target.value
                    );
                    setFieldValue("totalPrice", totalPrice.toFixed(2));
                  }}
                  onBlur={handleBlur}
                  error={touched.tax && !!errors.tax}
                  helperText={touched.tax && errors.tax}
                />
                <TextField
                  margin="dense"
                  name="totalPrice"
                  label="Total Price"
                  type="number"
                  fullWidth
                  value={values.totalPrice}
                  disabled
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Add Addon
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
}
