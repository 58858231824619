import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import * as Http from "src/services/Http";
import ScreenLoader from "src/components/ScreenLoader";

import { useParams } from "react-router";
import Page from "src/components/Page";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
  },
  card:{
    marginBottom: "8px",
  },
  tableCellHeading: {
    fontSize: "14px",
    fontWeight: "bold",
    borderBottom: "none",
  },
  tableCell: {
    borderBottom: "none",
    padding: theme.spacing(1, 2),
    fontSize: "14px",
  },
  tableCellRed: {
    borderBottom: "none",
    padding: theme.spacing(1, 2),
    fontSize: "14px",
    color: "red",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
  },
}));

export default function PackagesPayments(props) {
  const classes = useStyles();
  const { id } = useParams();
  let userId = "";
  if (id === undefined) {
    userId = localStorage.getItem("rand_token");
    userId = !isNaN(+userId) === false ? undefined : userId;
  } else {
    userId = id;
  }
  const [transactionDetails, setTransactionDetails] = useState({
    transactionId: "",
    id:"",
    status: "",
    purchaseAt: "",
    docusignUrl: "",
    packageInfo: {
      name: "",
      price: (0.0).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      }),
    },
    products: [],
    services: [],
    deathCertificateInfo: {
      numberOfCertificate: 0,
      totalCertificatePrice: (0.0).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      }),
    },
    extraMileInfromation: {
      address: "",
      price: (0.0).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      }),
    },
    promocodeDetails: {
      promocode: "",
      discountAmount: (0.0).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      }),
    },
    totalAmountPaid: (0.0).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    }),
  });
  const [Loading, setLoading] = useState(false);

  const handleRefundRedirect = (id_refund,refund_additional) => {
    if (id_refund) {
      props.setRefundSelectedId(id_refund);
      props.setTabval("step3");
      props.setRefundStatus(true);
      props.setRefundAdditional(refund_additional)
    } else {
      console.error("ID is not defined");
    }
    // props.setTabval("step3");
    // props.setRefundStatus(true);
  };

  const handleRedirectPayment = () => {
    props.setTabval("step3");
    props.setAdjustPayment(true);
  };

  const handleRedirectApplicationUrl = (docusignUrl) => {
    window.open(docusignUrl, '_blank');
  };

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        setLoading(true);
        if (userId) {
          await Http.call("GET", `admin/user/${userId}/subscription`).then(
            async (response) => {
              if (response.status === 200 && Array.isArray(response.data)) {
                // Handle multiple subscriptions
                const allSubscriptions = response.data.map((subscription) => {
                  let products = [];
                  let services = [];
                  let delivery = [];
                  let peacemaker = [];

                  // Process 'products' based on the service category
                  subscription?.services.forEach((serviceItem) => {
                    if (
                      ["Urns", "Pendant","Custom"].includes(serviceItem.category_name)
                    ) {
                      products.push({
                        name: serviceItem.service_name,
                        address: serviceItem.address,
                        price:
                          serviceItem.price !== 0
                            ? serviceItem.price.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })
                            : null,
                        quantity: serviceItem.quantity,
                      });
                    }
                  });

                  // Process 'services' based on the service category
                  subscription?.services.forEach((serviceItem) => {
                    if (
                      ["Additional Services"].includes(
                        serviceItem.category_name
                      )
                    ) {
                      services.push({
                        name: serviceItem.service_name,
                        address: serviceItem.address,
                        price:
                          serviceItem.price !== 0
                            ? serviceItem.price.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })
                            : null,
                      });
                    }
                  });

                  // Process 'delivery' services
                  subscription?.services.forEach((serviceItem) => {
                    if (["Delivery"].includes(serviceItem.category_name)) {
                      delivery.push({
                        name: serviceItem.service_name,
                        address: serviceItem.address,
                        price:
                          serviceItem.price !== 0
                            ? serviceItem.price.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })
                            : "$0.00",
                      });
                    }
                  });

                  // Process 'peacemaker' services
                  subscription?.services.forEach((serviceItem) => {
                    if (["Pacemaker"].includes(serviceItem.category_name)) {
                      peacemaker.push({
                        id: serviceItem.service_id,
                        name: serviceItem.service_name,
                        address: serviceItem.address,
                        quantity: serviceItem.quantity,
                        price:
                          serviceItem.price !== 0
                            ? serviceItem.price.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })
                            : null,
                      });
                    }
                  });

                  // Return processed subscription data
                  return {
                    transactionId: subscription.transaction_id,
                    refund_id: subscription.id,
                    refund_additional:subscription.refund_additional, //for check if this is refund
                    status: subscription.status,
                    purchaseAt: subscription.purchase_at,
                    docusignUrl: subscription.docusign_url,
                    packageInfo: {
                      name: subscription.package_name,
                      price:
                        subscription.package_amount !== 0
                          ? subscription.package_amount.toLocaleString(
                              "en-US",
                              { style: "currency", currency: "USD" }
                            )
                          : null,
                    },
                    products: products,
                    services: services,
                    peacemaker: peacemaker,
                    deathCertificateInfo: {
                      numberOfCertificate: subscription.certificate_count,
                      totalCertificatePrice:
                        subscription.certificate_total_price !== 0
                          ? subscription.certificate_total_price.toLocaleString(
                              "en-US",
                              { style: "currency", currency: "USD" }
                            )
                          : null,
                    },
                    delivery: delivery,
                    extraMileInfromation: {
                      address: subscription.extra_mile_destination_address,
                      price:
                        subscription.extra_mile_surcharge !== 0
                          ? subscription.extra_mile_surcharge.toLocaleString(
                              "en-US",
                              { style: "currency", currency: "USD" }
                            )
                          : null,
                      quantity: subscription.extra_mile_qty,
                    },
                    totalTaxAmount: subscription.total_tax.toLocaleString(
                      "en-US",
                      { style: "currency", currency: "USD" }
                    ),
                    promocodeDetails: {
                      promocode: subscription.promocode,
                      discountAmount:
                        subscription.discount !== 0
                          ? subscription.discount.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })
                          : "",
                    },
                    totalAmountPaid: subscription.total_amount_payable.toLocaleString(
                      "en-US",
                      { style: "currency", currency: "USD" }
                    ),
                  };
                });

                // Optionally set state or process all subscriptions together
                setTransactionDetails(allSubscriptions);
              }
            }
          );
        }
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    };

    fetchSubscription();

    props.setRefundStatus(false);
    props.setAdjustPayment(false);
  }, [userId]);

  if (Loading) {
    return <ScreenLoader />;
  }

  return (
    <Page title="Package & Payment" className={classes.root}>
      {transactionDetails && transactionDetails.length > 0 ? (
        transactionDetails.map((transaction, index) => (
          <Card key={transaction.transactionId || index} className={classes.card}>
            <CardHeader
              title={`Package & Payment Information - Transaction ${index + 1}`}
            />
            <CardContent>
              <Grid container>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table aria-label="payment details table">
                      <TableBody>
                        <TableRow>
                          <TableCell className={classes.tableCellHeading}>
                            TRANSACTION ID:
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {transaction.transactionId
                              ? transaction.transactionId
                              : "-"}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell className={classes.tableCellHeading}>
                            STATUS:
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {transaction.status ? transaction.status : "-"}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell className={classes.tableCellHeading}>
                            DATE OF PURCHASE:
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {transaction.purchaseAt
                              ? transaction.purchaseAt
                              : "-"}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell className={classes.tableCellHeading}>
                            PACKAGE TYPE:
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {transaction.packageInfo?.name
                              ? transaction.packageInfo.name
                              : "-"}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {transaction.packageInfo?.price
                              ? transaction.packageInfo.price
                              : ""}
                          </TableCell>
                        </TableRow>

                        {/* Products Section */}
                        {transaction.products?.length > 0 ? (
                          transaction.products.map((product, i) => (
                            <TableRow key={`${product?.name}-${i}`}>
                              <TableCell
                                className={classes.tableCellHeading}
                                style={{ textTransform: "uppercase" }}
                              >
                                {i < 1 ? "PRODUCTS:" : ""}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {product?.name} ({product?.quantity})
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {product?.price ? product?.price : ""}
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell
                              className={classes.tableCellHeading}
                              style={{ textTransform: "uppercase" }}
                            >
                              {"PRODUCTS:"}
                            </TableCell>
                            <TableCell
                              colSpan={3}
                              className={classes.tableCell}
                            >
                              No product selected
                            </TableCell>
                          </TableRow>
                        )}

                        {/* Services Section */}
                        {transaction.services?.length > 0 ? (
                          transaction.services.map((service, i) => (
                            <TableRow key={`${service?.name}-${i}`}>
                              <TableCell
                                className={classes.tableCellHeading}
                                style={{ textTransform: "uppercase" }}
                              >
                                {i < 1 ? "SERVICES:" : ""}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {service?.name}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {service?.price ? service?.price : ""}
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell
                              className={classes.tableCellHeading}
                              style={{ textTransform: "uppercase" }}
                            >
                              {"SERVICES:"}
                            </TableCell>
                            <TableCell
                              colSpan={3}
                              className={classes.tableCell}
                            >
                              No services available
                            </TableCell>
                          </TableRow>
                        )}

                        <TableRow>
                          <TableCell className={classes.tableCellHeading}>
                            DEATH CERTIFICATES:
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {transaction.deathCertificateInfo
                              ?.numberOfCertificate
                              ? transaction.deathCertificateInfo
                                  .numberOfCertificate
                              : "-"}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {transaction.deathCertificateInfo
                              ?.totalCertificatePrice
                              ? transaction.deathCertificateInfo
                                  .totalCertificatePrice
                              : ""}
                          </TableCell>
                        </TableRow>

                        {/* Delivery Section */}
                        {transaction.delivery?.length > 0 ? (
                          transaction.delivery.map((service, i) => (
                            <TableRow key={`${service?.name}-${i}`}>
                              <TableCell
                                className={classes.tableCellHeading}
                                style={{ textTransform: "uppercase" }}
                              >
                                {i < 1 ? "DELIVERY:" : ""}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {service?.name}
                                <br />
                                {service?.address}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {service?.price ? service?.price : ""}
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell
                              className={classes.tableCellHeading}
                              style={{ textTransform: "uppercase" }}
                            >
                              {"DELIVERY:"}
                            </TableCell>
                            <TableCell
                              colSpan={3}
                              className={classes.tableCell}
                            >
                              Delivery option not selected
                            </TableCell>
                          </TableRow>
                        )}

                        {/* Pacemaker Section */}
                        {transaction.peacemaker?.length > 0
                          ? transaction.peacemaker.map((service, i) =>
                              service.id === 74 ? (
                                <TableRow key={`${service?.name}-${i}`}>
                                  <TableCell
                                    className={classes.tableCellHeading}
                                    style={{ textTransform: "uppercase" }}
                                  >
                                    {i < 1 ? "Implant Disposition:" : ""}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    {service?.name}({service?.quantity})<br />
                                    {service?.address}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    {service?.price ? service?.price : ""}
                                  </TableCell>
                                </TableRow>
                              ) : null
                            )
                          : null}

                        <TableRow>
                          <TableCell className={classes.tableCellHeading}>
                            Taxes:
                          </TableCell>
                          <TableCell className={classes.tableCell}></TableCell>
                          <TableCell className={classes.tableCell}>
                            {transaction.totalTaxAmount
                              ? transaction.totalTaxAmount
                              : ""}
                          </TableCell>
                        </TableRow>

                        {transaction.promocodeDetails?.promocode ? (
                          <TableRow>
                            <TableCell className={classes.tableCellHeading}>
                              SERVICE DISCOUNT:
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {transaction.promocodeDetails.promocode}
                            </TableCell>
                            <TableCell className={classes.tableCellRed}>
                              -{transaction.promocodeDetails.discountAmount}
                            </TableCell>
                          </TableRow>
                        ) : null}

                        <TableRow>
                          <TableCell className={classes.tableCellHeading}>
                            EXTRA MILES CHARGE:
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {transaction.extraMileInfromation?.quantity > 0
                              ? `${transaction.extraMileInfromation.quantity} Miles`
                              : ""}
                            <br />
                            {transaction.extraMileInfromation?.address}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {transaction.extraMileInfromation?.quantity <= 20
                              ? "$0.00"
                              : transaction.extraMileInfromation?.price}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell className={classes.tableCellHeading}>
                            TOTAL AMOUNT:
                          </TableCell>
                          <TableCell className={classes.tableCell}></TableCell>
                          <TableCell className={classes.tableCellHeading}>
                            {transaction.totalAmountPaid
                              ? transaction.totalAmountPaid
                              : "-"}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box className={classes.buttonContainer}>
                    <Button
                      variant="contained"
                      disabled= {transaction.status !== "Paid" ? true : false} 
                      color="primary"
                      onClick={() => handleRefundRedirect
                        (
                          transaction.refund_id ? transaction.refund_id : 0,
                          transaction.refund_additional ? transaction.refund_additional : 0
                      )
                    }
                    >
                      REFUND
                    </Button>
                    <Button variant="contained" color="primary" disabled= {transaction.docusignUrl === "" || transaction.docusignUrl === undefined ? true : false} onClick={ () => handleRedirectApplicationUrl(transaction.docusignUrl
                        ? transaction.docusignUrl
                        : "") }>
                      EDIT APPLICATION DOCUMENTS
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ))
      ) : (
        <Typography variant="h6">No transactions found.</Typography>
      )}
      <Box className={classes.buttonContainer}>
        <Button
          variant="contained"
          onClick={handleRedirectPayment}
          color="primary"
        >
          ADDITIONAL PAYMENT
        </Button>
      </Box>
    </Page>
  );
}