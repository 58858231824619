import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Grid, makeStyles } from "@material-ui/core";
import Page from "src/components/Page";
import RoleDetail from "./RoleDetail";
/*import Toolbar from "./Toolbar";*/
import * as Http from "src/services/Http";
import { setSnackbar } from "src/actions";
import { connect } from "react-redux";
import { TramRounded } from "@material-ui/icons";
import ScreenLoader from "src/components/ScreenLoader";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";

const breadUrlList = [
  { name: "Home", url: "/dashboard" },
  { name: "Role List", url: "/app/roles" },
  { name: "Add Role", url: "/" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  roleDetail: {
    marginTop: theme.spacing(3),
  },
}));

const AddRoleView = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [Loading, setLoading] = useState(TramRounded);
  const [modules, setModules] = useState([]);
  const [values, setValues] = useState({
    name: "",
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const getModules = async () => {
    try {
      const response = await Http.call("GET", "admin/modules");
      setModules(response.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };
  const addRole = async (data, actions) => {
    await Http.call("POST", "admin/roles", {
      ...data,
      //image: values.image,
    }).then(async (response) => {
      if (response.status === 200) {
        props.setSnackbar(true, response.status, response.message);
        navigate("/app/roles");
      } else {
        actions.setErrors(response.data);
      }
    });
  };

  useEffect(() => {
    getModules();
  }, []);
  if (Loading) {
    return <ScreenLoader />;
  }
  return (
    <Page className={classes.root} title="Add Role">
      <Container maxWidth="lg">
        <BreadcrumbsAdmin urlList={breadUrlList} />
        {/*<Toolbar />*/}
        <Grid container spacing={3} className={classes.roleDetail}>
          {/* <Grid item lg={4} md={6} xs={12}>
            <Profile values={values} setvalues={setValues} />
          </Grid> */}
          <Grid item lg={12} md={12} xs={12}>
            <RoleDetail
              modules={modules}
              formValues={values}
              handleChange={handleChange}
              addRole={addRole}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddRoleView);
