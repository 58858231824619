import React, { useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
} from "@mui/material";

export default function ConfirmBox({ getConfirmation, getSection }) {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <Grid container component={Paper} style={{ marginTop: 30 }}>
      <Grid container>
        <Grid
          item
          md={12}
          xs={12}
          style={{
            padding: 15,
            backgroundColor: "#b1d4e038",
          }}
        >
          {/* Confirm */}
          Please note: the following information will appear on the official
          legal paperwork and certificate of death. Please check your answers
          carefully. Any information entered incorrectly here will subsequently
          be incorrect on the official documents.
        </Grid>
        <Grid
          item
          md={12}
          style={{
            padding: 10,
          }}
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
              }
              // label="I acknowledge that this questionnaire will be used to generate legal official documents. I have completed this questionnaire to the best of my ability and understand that these responses will be used to generate official legal documentation."
              label="I confirm that the information I provide will be used on the official Certificate Of Death and legal documentation and that this information is correct."
            />
          </FormGroup>
        </Grid>
        <Grid
          item
          md={12}
          xs={12}
          style={{
            padding: 15,
            backgroundColor: "#b1d4e038",
          }}
        >
          <Grid container justifyContent={"space-between"}>
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => getSection("Review")}
                className="btn-color"
              >
                Back
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                onClick={getConfirmation}
                className="btn-color"
                disabled={!isChecked}
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
