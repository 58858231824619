import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Grid, Card, makeStyles } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import Page from "src/components/Page";
import QuestionDetails from "../Components/QuestionDetails";
import Toolbar from "../Components/Toolbar";
import * as Http from "src/services/Http";
import { setSnackbar } from "src/actions";
import { connect } from "react-redux";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";

const breadUrlList = [
  { name: "Home", url: "/dashboard" },
  { name: "Questionnaire List", url: "/app/questionnaire" },
  { name: "Add Questionnaire", url: "/" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  QuesDetail: {
    marginTop: theme.spacing(3),
  },
}));

const AddQuestionnaireView = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [Loading, setLoading] = useState(true);
  const [values, setValues] = useState({
    question: "",
    description: "",
    section_id: "",
    type_id: "",
    type: "",
    parent_id: "",
    answer_id: "",
    control_id: "",
    placeholder: "",
    answers: [""],
    control_handler: "",
    sequence: "",
    is_skip: 0,
    status: 0,
    ext: [],
    que_forms_fields_arr: [{ form: "", fields: [] }],
  });

  const [parentquestion, setParentQuestions] = useState([]);

  const fetchParentQuestions = async () => {
    try {
      const responseparentques = await Http.call(
        "GET",
        "admin/get_all_questions"
      );
      setParentQuestions(responseparentques.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const [control, setControl] = useState([]);

  const fetchControl = async () => {
    try {
      const response = await Http.call("GET", "admin/get_controls");
      setControl(response.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const [sectionlist, setSectionlist] = useState([]);

  const fetchSectionList = async () => {
    try {
      const responseSection = await Http.call("GET", "admin/get_section_list");
      setSectionlist(responseSection.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const [docuforms, setDocuForms] = useState([]);
  const fetchDocumentForms = async () => {
    try {
      const responsedocuforms = await Http.call("GET", "admin/forms");
      setDocuForms(responsedocuforms.data);
    } catch (e) {
      console.log(e);
    }
  };

  const [parentQueAns, setparentQueAns] = useState([]);
  const parentQuestionAns = (pid) => {
    if (pid !== "") {
      setLoading(true);
      let pans = [];
      parentquestion.map((queans) =>
        pid !== undefined && pid === queans.id
          ? queans.answers !== undefined && queans.answers.length > 0
            ? queans.answers.map((ans, ansindex) => {
                pans.push(ans);
              })
            : ""
          : ""
      );
      setparentQueAns(pans);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSectionList();
    fetchParentQuestions();
    fetchControl();
    fetchDocumentForms();
  }, []);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const addQuestion = async (data, actions) => {
    await Http.call("POST", "admin/questionnaire", {
      ...data,
    }).then(async (response) => {
      if (response.status === 200) {
        props.setSnackbar(true, response.status, response.message);
        navigate("/app/questionnaire");
      } else {
        actions.setErrors(response.data[0].errors);
        console.log(response.data[0].errors);
      }
    });
  };

  if (Loading) {
    return (
      <Card style={{ height: 300 }}>
        <LinearProgress />
      </Card>
    );
  }
  return (
    <Page className={classes.root} title="Add Question">
      <Container maxWidth="lg">
        <BreadcrumbsAdmin urlList={breadUrlList} />
        <Toolbar mode={"Add"} />
        <Grid container spacing={3} className={classes.QuesDetail}>
          <Grid item lg={12} md={12} xs={12}>
            <QuestionDetails
              parentquestion={parentquestion}
              parentQueAns={parentQueAns}
              setparentQuestionAns={parentQuestionAns}
              controllists={control}
              formValues={values}
              setvalues={setValues}
              handleChange={handleChange}
              addQuestion={addQuestion}
              sectionlistdata={sectionlist}
              mode={"Add"}
              setSnackbar={props.setSnackbar}
              docuforms={docuforms}
              setLoading={setLoading}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddQuestionnaireView);
