import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import Results from "./Results";
import * as Http from "src/services/Http";
import { connect, useSelector } from "react-redux";
import ScreenLoader from "src/components/ScreenLoader";
import { setSnackbar } from "src/actions";

const UserListView = (props) => {
  const [userList, setUserList] = useState({
    id: "Loading",
    first_name: "Loading",
    last_name: "Loading",
    email: "Loading",
    mobile: "Loading",
  });
  const [Loading, setLoading] = useState(true);
  const authuser = useSelector((state) => state?.session?.user);

  const fetchUserList = async () => {
    try {
      const response = await Http.call("GET", "admin/users");
      setUserList(response.data);
      // console.log(response);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const deleteUsers = async (data) => {
    setLoading(true);
    try {
      await Http.call("POST", "admin/users/delete", { id: data }).then(
        async (response) => {
          fetchUserList();
          props.setSnackbar(true, response.status, response.message);
        }
      );
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchUserList();
  }, []);

  if (Loading) {
    return <ScreenLoader />;
  }

  return (
    <Box>
      <Results
        users={userList}
        pageLoading={setLoading}
        fetchUser={fetchUserList}
        deleteUsers={deleteUsers}
        authuser={authuser}
      />
    </Box>
  );
};

//export default UserListView;
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserListView);
