import React, { useState } from "react";
import { Container, Grid, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { setSnackbar } from "src/actions";
import Page from "src/components/Page";
import BannerDetails from "../Components/BannerDetails";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";
import Toolbar from "./Toolbar";

const breadUrlList = [
    { name: "Home", url: "/dashboard" },
    { name: "Banners", url: "/app/banners" },
    { name: "Add Banner", url: "/" },
];
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: "100%",
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    discountDetail: {
        marginTop: theme.spacing(3),
    },
}));

const AddBannerView = (props) => {
    const classes = useStyles();
    const [values] = useState({
        step_id: "",
        font_color: "",
        is_default: false,
        image:null,
    });
    return (
        <Page className={classes.root} title="Add Banner">
            <Container maxWidth="lg">
                <Grid container spacing={3}>
                    {/* <Grid item md={2} xs={12}></Grid> */}
                    <Grid item md={12} xs={12}>
                        <BreadcrumbsAdmin urlList={breadUrlList} />
                        <Toolbar />
                        <BannerDetails 
                            formValues={values} 
                            setSnackbar={props.setSnackbar} 
                            promoClass={classes.discountDetail} />
                    </Grid>
                    {/* <Grid item md={2} xs={12}></Grid> */}
                </Grid>
            </Container>
        </Page>
    );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
    setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddBannerView);
