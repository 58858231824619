import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Grid, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { setSnackbar } from "src/actions";
import * as Http from "src/services/Http";
import ScreenLoader from "src/components/ScreenLoader";
import Page from "src/components/Page";
import PriceDetails from "../Components/PriceDetails";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";
/*import Toolbar from "./Toolbar";*/

const breadUrlList = [
  { name: "Home", url: "/dashboard" },
  { name: "Price List", url: "/app/price" },
  { name: "Edit Price List", url: "/" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  discountDetail: {
    marginTop: theme.spacing(3),
  },
}));

const EditDiscountView = (props) => {
  const { id } = useParams();
  const classes = useStyles();
  const [Loading, setLoading] = useState(true);
  const [values, setValues] = useState({
    id: "",
    file_name: "",
    file_path: "",
    price_date: "",
    file_name_edit: "",
  });
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await Http.call("GET", "admin/price/" + id);
      setValues({
        id: data.id,
        file_name: "",
        file_path: data.file_path,
        price_date: data.price_date,
        file_name_edit: data.file_name_edit,
      });
      setLoading(false);
    };
    fetchData();
  }, [id]);

  if (Loading) {
    return <ScreenLoader />;
  }
  return (
    <Page className={classes.root} title="Edit Price List">
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item md={12} xs={12}>
            <BreadcrumbsAdmin urlList={breadUrlList} />
            {/*<Toolbar />*/}
            <PriceDetails
              formValues={values}
              setSnackbar={props.setSnackbar}
              promoClass={classes.discountDetail}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDiscountView);
