import React, { useState, useEffect } from "react";
import { Box, makeStyles } from "@material-ui/core";
import * as Http from "src/services/Http";
import { connect } from "react-redux";
import ScreenLoader from "src/components/ScreenLoader";
import {
  setSnackbar,
  setPostTableData,
  setTableLoading,
  setPostPageNumber,
  setPostPageLimit,
} from "src/actions";
import ConformationDialog from "src/components/ConformationDialog";
import QuestionTable from "./QuestionTable";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const PostQuestionnaireListView = (props) => {
  const classes = useStyles();

  const [open, setopen] = useState(false);
  const [openSeqConfBox, setOpenSeqCofBox] = useState(false);
  const [questionId, setquestion] = useState([]);
  const [seqData, setSeqData] = useState({});
  const [delequestionTitle, setquestionTitles] = useState([]);
  const [selItems, setSelItems] = useState([]);
  const handleClose = () => setopen(false);
  const handleCloseSeqConfBox = () => setOpenSeqCofBox(false);

  // const [questionnaireList, setQuestionnaireList] = useState({
  //   id: "Loading",
  //   s: "Loading",
  // });
  // const [Loading, setLoading] = useState(true);
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [drive, setDrive] = React.useState(null); // the lifted state
  // const [drive1, setDrive1] = React.useState(null); // the lifted state
  const {
    setPostTableData,
    tableData,
    setPostPageNumber,
    setPostPageLimit,
  } = props;

  const { isLoading, data, pageNo, pageLimit, totalQuestion } = tableData;
  const fetchQuestionnaireList = async () => {
    setTableLoading(true);
    try {
      await Http.call("POST", "admin/get_question_answer_listing", {
        type: 1,
        page_no: pageNo,
        page_limit: pageLimit,
        search_query: "",
      }).then(async (response) => {
        setPostTableData(response.data);
        setTableLoading(false);
      });
    } catch (e) {
      console.log(e);
    }
  };

  const changeSequance = async () => {
    setTableLoading(true);
    try {
      await Http.call(
        "POST",
        "admin/questionnaire/updateSequence",
        seqData
      ).then(async (response) => {
        handleCloseSeqConfBox();
        fetchQuestionnaireList();
        props.setSnackbar(true, response.status, response.message);
      });
    } catch (e) {
      console.log(e);
    }
  };

  const deleteQuestionnaire = (idsToDelete, titlesToDelete) => {
    setopen(true);
    setquestion(idsToDelete);
    setquestionTitles(titlesToDelete);
  };

  const changeSequenceQuestionnaire = (data) => {
    setOpenSeqCofBox(true);
    setSeqData(data);
  };

  const onConform = async () => {
    try {
      await Http.call("POST", "admin/questionnaire/multidelete", {
        ids: questionId,
      }).then(async (response) => {
        props.setSnackbar(true, response.status, response.message);
        setSelItems([]);
      });
      fetchQuestionnaireList();
      handleClose();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(async () => {
    await fetchQuestionnaireList();
  }, [pageNo, pageLimit, ""]);

  if (isLoading) {
    return <ScreenLoader />;
  }

  return (
    <div>
      <Box>
        <QuestionTable
          questions={data}
          setquestionnairelist={setPostTableData}
          changesequance={changeSequance}
          selectedItems={selItems}
          setSelItems={setSelItems}
          deleteQuestion={deleteQuestionnaire}
          fetchQuestionnaireList={fetchQuestionnaireList}
          page_no={pageNo}
          rowsPerPage={pageLimit}
          setPage={setPostPageNumber}
          setRowsPerPage={setPostPageLimit}
          totalquestion={totalQuestion}
          changeSequenceQuestionnaire={changeSequenceQuestionnaire}
          authuser={props.authuser}
        />
        {/* <Results 
          questions={questionnaireList} 
          pageLoading={setLoading}
          fetchQuestions={fetchQuestionnaireList}
          deleteQuestionnaire={deleteQuestionnaire}  
          deleteQuestionnaire={deleteQuestionnaire}
          setquestionnairelist={setQuestionnaireList} 
          changesequance={changeSequance}
        /> */}
      </Box>

      <ConformationDialog
        classes={{
          paper: classes.paper,
        }}
        id="confirmation-dialog-title"
        keepMounted
        open={open}
        onClose={handleClose}
        onConformAction={onConform}
        value={delequestionTitle}
      />

      <ConformationDialog
        classes={{
          paper: classes.paper,
        }}
        id="confirmation-dialog-title"
        keepMounted
        open={openSeqConfBox}
        onClose={handleCloseSeqConfBox}
        onConformAction={changeSequance}
        mode={"sequence"}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({ tableData: state.postQuestionnair });

const mapDispatchToProps = {
  setSnackbar,
  setTableLoading,
  setPostTableData,
  setPostPageNumber,
  setPostPageLimit,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PostQuestionnaireListView);
