import React from "react";
import { connect } from "react-redux";
import RadioOption from "../RadioOption";

export const CategorySelection = ({ data, selected, ...otherProps }) => {
  const { id, name, addOns, selection_type } = data;
  const price = selected.reduce((a, { price }) => a + price, 0);
  
  return (
    <div>
      <div className="payment-op1">
        <h3>{`${name}`}</h3>
        <h6>${parseFloat(price).toFixed(2)}</h6>
      </div>
      <div className="que-option">
        {addOns.map((item, index) => { 
          return (
            <RadioOption
              key={index}
              data={item}
              categoryKey={id}
              selected={selected.find(({ id }) => item.id === id)}
              type={selection_type}
            />
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CategorySelection);
