import React, { useState, useEffect } from "react";
import { Box, makeStyles, Container } from "@material-ui/core";
import Page from "src/components/Page";
import Results from "./Results";
import * as Http from "src/services/Http";
import { connect, useSelector } from "react-redux";
import ScreenLoader from "src/components/ScreenLoader";
import { setSnackbar } from "src/actions";
import ConformationDialog from "src/components/ConformationDialog";
import Toolbar from "./Toolbar";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";

const breadUrlList = [
  { name: "Home", url: "/dashboard" },
  { name: "Document Form List", url: "/" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const DocumentFormListView = (props) => {
  const classes = useStyles();
  const authuser = useSelector((state) => state?.session?.user);
  const [open, setopen] = useState(false);
  const [docformId, setdocForm] = useState([]);
  const [deledocformTitles, setdocFormTitles] = useState([]);
  const handleClose = () => setopen(false);

  const [docformList, setDocFormList] = useState({
    id: "Loading",
    s: "Loading",
  });
  const [Loading, setLoading] = useState(true);

  const fetchDocFormList = async () => {
    try {
      const response = await Http.call("GET", "admin/forms");
      setDocFormList(response.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const deleteDocForm = (idsToDelete, titlesToDelete) => {
    setopen(true);
    setdocForm(idsToDelete);
    setdocFormTitles(titlesToDelete);
  };
  const onConform = async () => {
    try {
      await Http.call("POST", "admin/forms/delete", {
        id: docformId,
      }).then(async (response) => {
        props.setSnackbar(true, response.status, response.message);
      });
      fetchDocFormList();
      handleClose();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchDocFormList();
  }, []);

  if (Loading) {
    return <ScreenLoader />;
  }

  return (
    <Page className={classes.root} title="Document Form">
      <Container maxWidth="lg">
        <BreadcrumbsAdmin urlList={breadUrlList} />
        <Toolbar authuser={authuser} />
        <Box mt={3}>
          <Results
            docformlist={docformList}
            pageLoading={setLoading}
            fetchStatus={fetchDocFormList}
            deletest={deleteDocForm}
            authuser={authuser}
          />
        </Box>
      </Container>
      <ConformationDialog
        classes={{
          paper: classes.paper,
        }}
        id="confirmation-dialog-title"
        keepMounted
        open={open}
        onClose={handleClose}
        onConformAction={onConform}
        value={deledocformTitles}
      />
    </Page>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentFormListView);
