import React, { useState, useEffect } from "react";
import { Container, Grid, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { setSnackbar } from "src/actions";
import * as Http from "src/services/Http";
import Page from "src/components/Page";
import BannerDetails from "../Components/BannerDetails";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";
import { useParams } from "react-router-dom";
import ScreenLoader from "src/components/ScreenLoader";

const breadUrlList = [
  { name: "Home", url: "/dashboard" },
  { name: "Banners", url: "/app/banners" },
  { name: "Edit Banner", url: "/" },
];
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  discountDetail: {
    marginTop: theme.spacing(3),
  },
}));

const EditBannerView = (props) => {
  const classes = useStyles();
  const { id } = useParams();
  const [Loading, setLoading] = useState(true);
  const [values, setValues] = useState({
    step_id: "",
    font_color: "#000000",
    is_default: false,
    image: "",
    banner_image: "",
  });

  useEffect(() => {
    const fetchBanner = async () => {
      try {
        const response = await Http.call("GET", "admin/banners/" + id);
        setValues(response.data);
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    };

    fetchBanner();
  }, [id]);

  if (Loading) {
    return <ScreenLoader />;
  }
  return (
    <Page className={classes.root} title="Edit Banner">
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          {/* <Grid item md={2} xs={12}></Grid> */}
          <Grid item md={12} xs={12}>
            <BreadcrumbsAdmin urlList={breadUrlList} />
            <BannerDetails
              formValues={values}
              setSnackbar={props.setSnackbar}
              promoClass={classes.discountDetail}
            />
          </Grid>
          {/* <Grid item md={2} xs={12}></Grid> */}
        </Grid>
      </Container>
    </Page>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditBannerView);
