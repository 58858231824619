import React from "react";
import { connect } from "react-redux";
import { setSnackbar } from "src/actions";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import EnhancedTableHead from "src/components/common/DragDropTable/EnhancedTableHead";
import EnhancedTableToolbar from "src/components/common/DragDropTable/EnhancedTableToolbar";
import DraggableComponent from "src/components/common/DragDropTable/DraggableComponent";
import DroppableComponent from "src/components/common/DragDropTable/DroppableComponent";
import { Button } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import { chkRoutePerm } from "src/services/Helper";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  container: {
    maxHeight: 740,
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  // console.log("Remove Element:");
  // console.log(removed);
  result.splice(endIndex, 0, removed);
  // console.log("final Result");
  // console.log(result);
  return result;
};

const TableView = ({ records, setRecordsList, deleteRecords, ...rest }) => {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("sequence");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const chkEditRoutPerm = chkRoutePerm("/app/status/edit", rest.authuser);
  const chkDelRoutPerm = chkRoutePerm("/app/status/delete", rest.authuser);
  const headCells = [
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: "Actions",
      display: chkEditRoutPerm ? true : false,
    },
    {
      id: "statusname",
      numeric: false,
      disablePadding: true,
      label: "Status",
      display: true,
    },
    {
      id: "sequence",
      numeric: false,
      disablePadding: true,
      label: "Sequence",
      display: true,
    },
    {
      id: "auto_status",
      numeric: false,
      disablePadding: false,
      label: "Auto Status",
      display: true,
    },
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = records.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    console.log(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, records.length - page * rowsPerPage);

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    let last_index = records.findIndex((e) => e.is_last === 1);

    console.log(
      `dragEnd ${result.source.index} to  ${result.destination.index}`
    );

    if (
      ![0, 1, 2].includes(result.source.index) ||
      ![0, 1, 2].includes(result.destination.index)
    ) {
      console.log(
        `dragEnd ${result.source.index} to  ${result.destination.index}`
      );
    }
    if (
      [0, 1, 2].includes(result.source.index) ||
      [0, 1, 2, 3].includes(result.destination.index)
    ) {
      rest.setSnackbar(
        true,
        401,
        "You can not change the sequence of auto complete status."
      );
    } else if (
      result.source.index !== last_index &&
      result.destination.index !== last_index
    ) {
      let sequanceData = [];

      const items = reorder(
        records,
        result.source.index,
        result.destination.index
      );

      items.map((item, index) => {
        setRecordsList([...items], ([...items][index].sequence = index + 1));

        sequanceData.push({ id: item.id, sequence: index + 1 });
      });

      rest.changesequance({ data: sequanceData });
    } else {
      rest.setSnackbar(
        true,
        401,
        "You can not change the sequence in last status."
      );
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          tableName={"Status List"}
          numSelected={selected.length}
          selectedItems={selected}
          deleteItems={deleteRecords}
          chkDelRoutPerm={chkDelRoutPerm}
        />
        <TableContainer className={classes.container}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              headCells={headCells}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={records.length}
            />
            <TableBody component={DroppableComponent(onDragEnd)}>
              {stableSort(records, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      component={DraggableComponent(
                        row.sequence.toString(),
                        index
                      )}
                      hover
                      //onClick={(event) => handleClick(event, row.question)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.question}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                          onClick={(event) => handleClick(event, row.id)}
                          disabled={chkDelRoutPerm ? false : true}
                        />
                      </TableCell>
                      {chkEditRoutPerm ? (
                        <TableCell width={"10%"}>
                          <Tooltip disableFocusListener title="Edit">
                            <Button
                              color="primary"
                              size="small"
                              component={RouterLink}
                              to={`/app/status/${row.id}`}
                            >
                              <EditOutlinedIcon />
                            </Button>
                          </Tooltip>
                        </TableCell>
                      ) : (
                        ""
                      )}
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.name}
                      </TableCell>
                      <TableCell>{row.sequence}</TableCell>
                      <TableCell>{row.auto_status}</TableCell>
                    </TableRow>
                  );
                })}
              {/* {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[]}
          // rowsPerPageOptions={[
          //   5,
          //   10,
          //   25,
          //   50,
          //   100,
          //   500,
          //   { value: -1, label: "All" },
          // ]}
          component="div"
          count={records.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(TableView);
