import React from "react";
import { makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Backdrop } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  loader: {
    display: "flex",
    backgroundColor: "#fff0",
    minHeight: "100%",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
export default function ScreenLoader() {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
  // return (
  //   <div className={classes.loader}>
  //     <CircularProgress />
  //   </div>
  // );
}
