import React from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Box, Button, Card, makeStyles } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Tooltip from "@material-ui/core/Tooltip";

const swal = withReactContent(Swal);

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const Results = ({
  className,
  categories,
  pageLoading,
  fetchCategory,
  deleteCategories,
  ...rest
}) => {
  const classes = useStyles();
  const responsive = "standard";
  const options = {
    download: false,
    print: false,
    filter: false,
    filterType: "dropdown",
    responsive,
    onRowsDelete: (rowsDeleted) => {
      swal
        .fire({
          type: "question",
          title: "Are you sure?",
          text: "You want to delete these Category(s)!",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "No",
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.value) {
            let idsToDelete = rowsDeleted.data.map(
              (d) => categories[d.dataIndex].id
            );
            // idsToDelete = idsToDelete.join(",");
            // const idsToDelete = rowsDeleted.data.map((d) => data[d.dataIndex].id);
            deleteCategories(idsToDelete);
          } else {
            pageLoading(true);
            fetchCategory();
          }
        });
    },
    rowsPerPageOptions: [10, 25, 50, 100, 500],
    downloadOptions: { filename: "Categories.csv" },
  };

  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        download: false,
        print: false,
        // viewColumns:'disabled',
        customBodyRenderLite: (dataIndex) => (
          <Tooltip disableFocusListener title="Edit">
            <Button
              color="primary"
              component={RouterLink}
              size="small"
              to={"/app/categories/" + categories[dataIndex].id}
            >
              <EditOutlinedIcon />
            </Button>
          </Tooltip>
        ),
      },
    },
  ];

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <MUIDataTable
            title=""
            data={categories}
            columns={columns}
            options={options}
          />
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  categories: PropTypes.array.isRequired,
};

export default Results;
