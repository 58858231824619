import React from "react";
import { Box } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useParams } from "react-router";

const ApplicationContactTable = ({ contacts, deleteContact, ...rest }) => {
  const responsive = "standard";
  const options = {
    filter: false,
    filterType: "dropdown",
    responsive,
    onRowsDelete: async (rowsDeleted) => {
      const idsToDelete = rowsDeleted.data.map((d) => contacts[d.dataIndex].id);
      deleteContact(idsToDelete);
    },
    rowsPerPageOptions: [],
    rowsPerPage: 100,
    tableBodyMaxHeight: "740px",
    // rowsPerPageOptions: [10, 25, 50, 100, 500],
    download: false,
    print: false,
    downloadOptions: { filename: "Application Contact.csv" },
    isRowSelectable: () => {
      return true;
    },
  };

  const columns = [
    {
      name: "application_code",
      label: "Application",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "position_title",
      label: "Position",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "company_name",
      label: "Company",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "user_name",
      label: "User Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email Address",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "mobile",
      label: "Mobile Number",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "fax",
      label: "Fax Number",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  return (
    <PerfectScrollbar>
      <Box minWidth={1050}>
        <MUIDataTable
          title={""}
          data={contacts}
          columns={columns}
          options={options}
        />
      </Box>
    </PerfectScrollbar>
  );
};

export default ApplicationContactTable;
