import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import { Grid, TextField, Button, FormHelperText } from "@material-ui/core";
import { FieldArray } from "formik";


const useStyles = makeStyles((theme) => ({
  textWidth: {
    width: "42%",
    "@media (maxWidth: '575px')": {
      width: "71%",
    }
  },
}));

const RadioButtonboxDetails = (props) => {
  const classes = useStyles();
  const { nameval, touchTextval, errorTextval, isparent } = props;

  return (
    <FieldArray
      name={`answers`}
      render={(arrayHelpers) => (
        <Grid item md={12} xs={12}>
          {nameval.length > 0 &&
            nameval.map((optionval, index) => {
              if (index === 0) {
                return (
                  <div key={index}>
                    <TextField
                      name={`answers.${index}`}
                      label={`Option`}
                      variant="outlined"
                      value={optionval.answer}
                      onBlur={(e) => {
                        if (optionval.id !== undefined) {
                          arrayHelpers.replace(index, {
                            id: optionval.id,
                            answer: e.target.value,
                          });
                        } else {
                          arrayHelpers.replace(index, e.target.value);
                        }
                      }}
                      onChange={(e) => {
                        if (optionval.id !== undefined) {
                          arrayHelpers.replace(index, {
                            id: optionval.id,
                            answer: e.target.value,
                          });
                        } else {
                          arrayHelpers.replace(index, e.target.value);
                        }
                      }}
                      error={Boolean(
                        (touchTextval &&
                          touchTextval[index] &&
                          errorTextval &&
                          _.isArray(errorTextval) &&
                          errorTextval[index]) ||
                          (errorTextval &&
                            _.isString(errorTextval) &&
                            touchTextval &&
                            _.isArray(touchTextval))
                      )}
                      margin="normal"
                      className={classes.textWidth}
                      helperText={
                        touchTextval &&
                        touchTextval[index] &&
                        errorTextval &&
                        _.isArray(errorTextval) &&
                        errorTextval[index]
                      }
                      disabled={isparent === 1}
                    />

                    <Button
                      color="secondary"
                      variant="contained"
                      margin="normal"
                      style={{ margin: "15px 10px", padding: "15px" }}
                      onClick={() => {
                        arrayHelpers.push("");
                      }}
                      disabled={isparent === 1}
                    >
                      {" "}
                      +{" "}
                    </Button>

                    {errorTextval &&
                      _.isString(errorTextval) &&
                      touchTextval &&
                      _.isArray(touchTextval) && (
                        <FormHelperText className="Mui-error">
                          {errorTextval}
                        </FormHelperText>
                      )}
                  </div>
                );
              } else {
                return (
                  <div key={index}>
                    <TextField
                      name={`answers.${index}`}
                      label={`Option`}
                      variant="outlined"
                      value={optionval.answer}
                      onBlur={(e) => {
                        if (optionval.id !== undefined) {
                          arrayHelpers.replace(index, {
                            id: optionval.id,
                            answer: e.target.value,
                          });
                        } else {
                          arrayHelpers.replace(index, e.target.value);
                        }
                      }}
                      onChange={(e) => {
                        if (optionval.id !== undefined) {
                          arrayHelpers.replace(index, {
                            id: optionval.id,
                            answer: e.target.value,
                          });
                        } else {
                          arrayHelpers.replace(index, e.target.value);
                        }
                      }}
                      margin="normal"
                      error={Boolean(
                        touchTextval &&
                          touchTextval[index] &&
                          errorTextval &&
                          _.isArray(errorTextval) &&
                          errorTextval[index]
                      )}
                      className={classes.textWidth}
                      helperText={
                        touchTextval &&
                        touchTextval[index] &&
                        errorTextval &&
                        errorTextval[index]
                      }
                      disabled={isparent === 1}
                    />

                    <Button
                      onClick={() => arrayHelpers.remove(index)}
                      color="secondary"
                      variant="contained"
                      margin="normal"
                      style={{ margin: "15px 10px", padding: "15px" }}
                      disabled={isparent === 1}
                    >
                      {" "}
                      -{" "}
                    </Button>
                  </div>
                );
              }
            })}
        </Grid>
      )}
    />
  );
};

export default RadioButtonboxDetails;
