import React, { useState, useEffect } from "react";
import { Box, Container, makeStyles } from "@material-ui/core";
import Page from "src/components/Page";
import Results from "./Results";
import Toolbar from "./Toolbar";
import * as Http from "src/services/Http";
import { connect, useSelector } from "react-redux";
import ScreenLoader from "src/components/ScreenLoader";
import { setSnackbar } from "src/actions";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";
import ConformationDialog from "src/components/ConformationDialog";

import { useLocation } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const EmailTemplateListView = (props) => {
  const [open, setopen] = useState(false);
  const [emailtemplatelist, setEmailtemplist] = useState({
    id: "Loading",
    email_subject: "Loading",
    code: "Loading",
  });
  const [Loading, setLoading] = useState(true);
  const [deletable, setdeletable] = useState([]);
  const [deletableTitles, setdeletableTitles] = useState([]);
  const classes = useStyles();
  const authuser = useSelector((state) => state?.session?.user);
  const location = useLocation();
  const paths = location.pathname.split("/");
  const email_type = paths[3];

  const handleClose = () => setopen(false);

  const breadUrlList = [
    { name: "Home", url: "/dashboard" },
    {
      name:
        email_type === "manual"
          ? "Custom Email Template"
          : "System Email Template",
      url:
        email_type === "manual"
          ? "/app/emailtemplate/manual"
          : "/app/emailtemplate",
    },
    // { name: "Email Template", url: "/app/emailtemplate" },
  ];


  const deletePrice = (idsToDelete, titlesToDelete) => {
    setopen(true);
    setdeletable(idsToDelete);
    setdeletableTitles(titlesToDelete);
  };
  const onConform = async () => {
    try {
      await Http.call("POST", "admin/email_templates/multidelete", {
        ids: deletable,
        type:1,
      }).then(async (response) => {
        props.setSnackbar(true, response.status, response.message);
      });
      fetchEmailtemplates();
      handleClose();
    } catch (e) {
      console.log(e);
    }
  };


  const fetchEmailtemplates = async () => {
    try {
      setLoading(true);
      const response = await Http.call("GET", "admin/email_templates");
      if (email_type === "manual") {
        const manualEmailList = response.data.filter((item) => item.type === 1);
        setEmailtemplist(manualEmailList);
      } else {
        const manualEmailList = response.data.filter((item) => item.type === 0);
        setEmailtemplist(manualEmailList);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };


  useEffect(() => {
    fetchEmailtemplates();
  }, [email_type]);

  if (Loading) {
    return <ScreenLoader />;
  }
  return (
    <Page className={classes.root} title="Email Templates">
      <Container maxWidth={false}>
        <BreadcrumbsAdmin urlList={breadUrlList} />
        <Toolbar authuser={authuser} />
        <Box mt={3}>
          <Results
            emaillists={emailtemplatelist}
            pageLoading={setLoading}
            authuser={authuser}
            deletePrice={deletePrice}

            email_type={email_type}
          />
        </Box>
      </Container>
      <ConformationDialog
        classes={{
          paper: classes.paper,
        }}
        id="confirmation-dialog-title"
        keepMounted
        open={open}
        onClose={handleClose}
        onConformAction={onConform}
        value={deletableTitles}
      />
    </Page>
  );
};

// export default EmailTemplateListView;
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailTemplateListView);
