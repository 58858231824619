import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  makeStyles,
  TextField,
} from "@material-ui/core";
import CustomPhoneInput from "src/components/common/CustomPhoneInput";
import { CircularProgress } from "@material-ui/core";
import { Backdrop } from "@material-ui/core";
import PasswordInput from "src/components/common/PasswordInput";
import * as Messages from "src/constants/Messages/index";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectControl: {
    minWidth: "100%",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

/*const phoneRegExp = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;*/

const AddUserSchema = Yup.object().shape({
  first_name: Yup.string()
    // .min(2, Messages.TOO_SHORT)
    // .matches(/^[A-Za-z ]*$/, Messages.ONLY_ALPABATES)
    .required(Messages.REQUIRED),
  last_name: Yup.string()
    // .min(2, Messages.TOO_SHORT)
    // .matches(/^[A-Za-z ]*$/, Messages.ONLY_ALPABATES)
    .required(Messages.REQUIRED),
  email: Yup.string()
    .lowercase()
    .email(Messages.VALID_EMAIL)
    .required(Messages.REQUIRED),
  // password: Yup.string()
  //   .required(Messages.REQUIRED)
  //   .min(6, "This Password must be at least 6 characters.")
  //   .matches(
  //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/,
  //     "Must contain at least 1 capital letter, 1 small letter, 1 special character and 1 number"
  //   ),
  // confirmPassword: Yup.string()
  //   .required(Messages.REQUIRED)
  //   .oneOf(
  //     [Yup.ref("password"), null],
  //     "Password and confirm password do not match"
  //   ),
  mobile: Yup.string().required(Messages.REQUIRED),
});
const UserDetail = (props) => {
  const { formValues, addUser, setTabval } = props;
  const initialValues = {
    ...formValues,
    // confirmPassword: "",
  };
  const classes = useStyles();
  const [checkPhoneNumber, setCheckPhoneNumber] = useState(0);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={AddUserSchema}
      onSubmit={async (values, actions) => {
        if (checkPhoneNumber !== 1) {
          actions.setSubmitting(true);
          await addUser(values, actions);
          actions.setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => (
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Card>
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    name="first_name"
                    label="First Name"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.first_name}
                    error={Boolean(touched.first_name && errors.first_name)}
                    margin="normal"
                    helperText={touched.first_name && errors.first_name}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Last Name"
                    name="last_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.last_name}
                    margin="normal"
                    variant="outlined"
                    error={Boolean(touched.last_name && errors.last_name)}
                    helperText={touched.last_name && errors.last_name}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Email"
                    margin="normal"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    type="email"
                    variant="outlined"
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomPhoneInput
                    name={"mobile"}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    setCheckPhoneNumber={setCheckPhoneNumber}
                  />
                </Grid>
                {/* <Grid item md={6} xs={12}>
                  <PasswordInput
                    label="Password"
                    name="password"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <PasswordInput
                    label="Confirm Password"
                    name="confirmPassword"
                  />
                </Grid> */}
              </Grid>
            </CardContent>
            <Box display="flex" justifyContent="space-between" p={2}>
              <Button
                color="primary"
                variant="contained"
                type="button"
                onClick={() => {
                  setTabval("step1");
                }}
              >
                Back
              </Button>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={isSubmitting}
              >
                Save & Next
              </Button>
            </Box>
          </Card>
          <Backdrop
            className={classes.backdrop}
            open={isSubmitting}
            //onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </form>
      )}
    </Formik>
  );
};

export default UserDetail;
