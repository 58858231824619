import React from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Button,
  Card,
  makeStyles,
  InputAdornment,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import { chkRoutePerm } from "src/services/Helper";

const swal = withReactContent(Swal);

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const Results = ({
  className,
  users,
  pageLoading,
  fetchUser,
  deleteUsers,
  ...rest
}) => {
  const classes = useStyles();
  const responsive = "standard";
  const chkRoutPerm = chkRoutePerm("/app/users/edit", rest.authuser);
  const chkDelRoutPerm = chkRoutePerm("/app/users/delete", rest.authuser);
  const options = {
    filter: false,
    filterType: "dropdown",
    responsive,
    onRowsDelete: (rowsDeleted) => {
      swal
        .fire({
          type: "question",
          title: "Are you sure?",
          text: "You want to delete these user(s)!",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "No",
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.value) {
            let idsToDelete = rowsDeleted.data.map(
              (d) => users[d.dataIndex].id
            );
            idsToDelete = idsToDelete.join(",");
            deleteUsers(idsToDelete);
          } else {
            pageLoading(true);
            fetchUser();
          }
        });
    },
    rowsPerPageOptions: [],
    rowsPerPage: 100,
    tableBodyMaxHeight: "740px",
    // rowsPerPageOptions: [10, 25, 50, 100, 500],
    downloadOptions: { filename: "Users.csv" },
    isRowSelectable: () => {
      return chkDelRoutPerm ? true : false;
    },
  };

  const columns = [
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        download: false,
        print: false,
        // viewColumns:'disabled',
        display: chkRoutPerm ? true : false,
        customBodyRenderLite: (dataIndex) =>
          chkRoutPerm ? (
            <Tooltip disableFocusListener title="Edit">
              <Button
                color="primary"
                component={RouterLink}
                size="small"
                to={"/app/users/" + users[dataIndex].id}
              >
                <EditOutlinedIcon />
              </Button>
            </Tooltip>
          ) : (
            ""
          ),
      },
    },
    {
      name: "first_name",
      label: "First Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "last_name",
      label: "Last Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "mobile",
      label: "Phone",
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => (
          <InputAdornment position="start">
            {users[dataIndex].mobile}
          </InputAdornment>
        ),
      },
    },
    {
      name: "role_name",
      label: "Role",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "created_at",
      label: "Registration Date",
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <MUIDataTable
            title=""
            data={users}
            columns={columns}
            options={options}
          />
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired,
};

export default Results;
