import React from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Box, Card, Button, makeStyles } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  margin: {
    marginRight: theme.spacing(3),
  },
}));

const Results = ({
  className,
  subscriptions,
  pageLoading,
  fetchSubscription,
  ...rest
}) => {
  const classes = useStyles();
  const responsive = "standard";

  const options = {
    filter: false,
    filterType: "dropdown",
    responsive,
    selectableRowsHeader: true,
    selectableRowsHideCheckboxes: true,
    rowsPerPageOptions: [],
    rowsPerPage: 100,
    tableBodyMaxHeight: "740px",
    // rowsPerPageOptions: [10, 25, 50, 100, 500],
    downloadOptions: { filename: "Payment_Records.csv" },
  };

  const columns = [
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        download: false,
        print: false,
        customBodyRenderLite: (dataIndex) => (
          <div>
            <Tooltip disableFocusListener title="View Details">
              <Button
                color="primary"
                size="small"
                component={RouterLink}
                to={"/app/subscriptions/" + subscriptions[dataIndex].id}
              >
                <VisibilityOutlinedIcon />
              </Button>
            </Tooltip>
          </div>
        ),
      },
    },
    {
      name: "transaction_id",
      label: "Transaction Id",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "username",
      label: "Customer Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "package_name",
      label: "Package",
      options: {
        filter: true,
        sort: true,
        filterOptions: {
          fullWidth: true,
        },
      },
    },
    {
      name: "amount",
      label: "Total Amount",
      options: {
        filter: false,
        sort: true,
      },
    },
    /*{
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        //  customBodyRenderLite: (dataIndex) => subscriptions[dataIndex].status === 1 ? "Paid" : "aid",
      },
    },*/

    {
      name: "created_at",
      label: "Date",
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) =>
          moment(subscriptions[dataIndex].created_at).format(
            "DD/MM/YYYY, h:mm a"
          ),
      },
    },
  ];

  return (
    <div>
      <Card className={clsx(classes.root, className)} {...rest}>
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <MUIDataTable
              data={subscriptions}
              columns={columns}
              options={options}
            />
          </Box>
        </PerfectScrollbar>
      </Card>
    </div>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  subscriptions: PropTypes.array.isRequired,
};

export default Results;
