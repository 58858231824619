import * as actionTypes from "../actions";

const initialState = {
  snackBar: false,
  snackBarType: 0,
  snackBarMessage: "",
};

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SNACKBAR: {
      // console.log(action.paylaod);
      return {
        ...state,
        snackBar: action.payload.snackBar,
        snackBarType: action.payload.snackBarType,
        snackBarMessage: action.payload.snackBarMessage,
      };
    }
    default: {
      return state;
    }
  }
};

export default globalReducer;
