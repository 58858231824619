import React from "react";
import { Container, Grid, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { setSnackbar } from "src/actions";
import Page from "src/components/Page";
import PriceDetails from "../Components/PriceDetails";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";
/*import Toolbar from "./Toolbar";*/

const breadUrlList = [
  { name: "Home", url: "/dashboard" },
  { name: "Price List", url: "/app/price" },
  { name: "Add Price List", url: "/" },
];
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,

    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  discountDetail: {
    marginTop: theme.spacing(3),
  },
}));

const AddDiscountView = (props) => {
  const classes = useStyles();
  const values = {
    price_date: null,
    file_name: "",
    file: null,
  };
  return (
    <Page className={classes.root} title="Add Price List">
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item md={12} xs={12}>
            <BreadcrumbsAdmin urlList={breadUrlList} />
            {/*<Toolbar />*/}
            <PriceDetails
              formValues={values}
              setSnackbar={props.setSnackbar}
              promoClass={classes.discountDetail}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDiscountView);
