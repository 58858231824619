import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Box, Button, Card, makeStyles } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import { chkRoutePerm } from "src/services/Helper";

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const Results = ({
  className,
  docformlist,
  pageLoading,
  fetchStatus,
  deletest,
  ...rest
}) => {
  const classes = useStyles();
  const [responsive] = useState("standard");
  const chkEditRoutPerm = chkRoutePerm(
    "/app/document-form/edit",
    rest.authuser
  );
  const chkDelRoutPerm = chkRoutePerm(
    "/app/document-form/delete",
    rest.authuser
  );

  const options = {
    filter: false,
    filterType: "dropdown",
    responsive,
    onRowsDelete: async (rowsDeleted) => {
      const idsToDelete = rowsDeleted.data.map(
        (d) => docformlist[d.dataIndex].id
      );
      const titlesToDelete = rowsDeleted.data.map(
        (d) => docformlist[d.dataIndex].title
      );
      deletest(idsToDelete, titlesToDelete);
    },
    rowsPerPageOptions: [],
    rowsPerPage: 100,
    tableBodyMaxHeight: "740px",
    // rowsPerPageOptions: [10, 25, 50, 100, 500],
    downloadOptions: { filename: "Status.csv" },
    isRowSelectable: () => {
      return chkDelRoutPerm ? true : false;
    },
  };

  const [columns] = useState([
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        download: false,
        print: false,
        display: chkEditRoutPerm ? true : false,
        viewColumns: false,
        customBodyRenderLite: (dataIndex) => (
          <Tooltip disableFocusListener title="Edit">
            <Button
              color="primary"
              component={RouterLink}
              size="small"
              to={"/app/document-form/" + docformlist[dataIndex].id}
            >
              <EditOutlinedIcon />
            </Button>
          </Tooltip>
        ),
      },
    },
    {
      name: "name",
      label: "Form Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "auto_sequence",
      label: "Sequence",
      options: {
        sort: true,
      },
    },
  ]);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <MUIDataTable
            data={docformlist}
            columns={columns}
            options={options}
          />
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  docformlist: PropTypes.array.isRequired,
};

export default Results;
