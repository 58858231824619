import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import { Container } from "@material-ui/core";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";
import Page from "src/components/Page";
import TabPanel from "src/components/TabPanel";
import QuestionnaireListView from "src/views/Questionnaire/QuestionnaireListView";
import PostQuestionnaireListView from "src/views/Questionnaire/PostQuestionnaireListView";
import GreatWesternListView from "src/views/Questionnaire/GreatWesternListView";
import QuestionnaireToolbar from "src/views/Questionnaire/QuestionnaireListView/Toolbar";
import PostQuestionnaireToolbar from "src/views/Questionnaire/PostQuestionnaireListView/Toolbar";
import GreatWesternToolbar from "src/views/Questionnaire/GreatWesternListView/Toolbar";
import { SET_QUESTION_ACTIVE_TAB } from "src/actions";
import { useDispatch, useSelector } from "react-redux";

const breadUrlList = [
  { name: "Home", url: "/dashboard" },
  { name: "Questions List", url: "/" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "10px",
  },
  page: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

export default function QuestionnaireTabView() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const value = useSelector((state) => state?.session?.questionActiveTab);
  const authuser = useSelector((state) => state?.session?.user);
  // const [value, setValue] = useState("one");

  const handleChange = (event, newValue) => {
    // setValue(newValue);
    dispatch({
      type: SET_QUESTION_ACTIVE_TAB,
      payload: { questionActiveTab: newValue },
    });
  };

  return (
    <Page title="Questions List" className={classes.page}>
      <Container maxWidth={false}>
        <BreadcrumbsAdmin urlList={breadUrlList} />
        {value === "pre_quest" ? (
          <QuestionnaireToolbar authuser={authuser} />
        ) : (
          <PostQuestionnaireToolbar authuser={authuser} />
        )}
        <Paper className={classes.root}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="wrapped label tabs example"
          >
            <Tab
              value="pre_quest"
              label="Pre-Questionnaire"
              wrapped
              {...a11yProps("pre_quest")}
            />
            <Tab
              value="post_quest"
              wrapped
              label="Questionnaire"
              {...a11yProps("post_quest")}
            />
            <Tab
              value="great_quest"
              wrapped
              label="Great Western"
              {...a11yProps("great_quest")}
            />
          </Tabs>
        </Paper>
        <TabPanel value={value} index="pre_quest">
          <QuestionnaireListView authuser={authuser} />
        </TabPanel>
        <TabPanel value={value} index="post_quest">
          <PostQuestionnaireListView authuser={authuser} />
        </TabPanel>
        <TabPanel value={value} index="great_quest">
          <GreatWesternListView authuser={authuser} />
        </TabPanel>
      </Container>
    </Page>
  );
}
