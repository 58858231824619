import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import {
  Grid,
  makeStyles,
  TablePagination,
} from "@material-ui/core";
import Page from "src/components/Page";
import { setSnackbar } from "src/actions";
import { connect } from "react-redux";
import * as Http from "src/services/Http";
import ScreenLoader from "src/components/ScreenLoader";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { useParams } from "react-router";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(2),
  },
}));
const History_f = (props) => {
  // const [data, setData] = React.useState([
  //   {
  //     id: 1,
  //     role: "Admin",
  //     name: "John Doe",
  //     age: 30,
  //     email: "john@example.com",
  //   },
  //   {
  //     id: 2,
  //     role: "User",
  //     name: "Jane Smith",
  //     age: 25,
  //     email: "jane@example.com",
  //   },
  //   {
  //     id: 3,
  //     role: "Manager",
  //     name: "Alice Johnson",
  //     age: 35,
  //     email: "alice@example.com",
  //   },
  //   {
  //     id: 4,
  //     role: "Guest",
  //     name: "Bob Brown",
  //     age: 40,
  //     email: "bob@example.com",
  //   },
  //   {
  //     id: 6,
  //     role: "Guest2",
  //     name: "Bob Brown",
  //     age: 40,
  //     email: "bob@example.com",
  //   },
  //   {
  //     id: 7,
  //     role: "Guest2",
  //     name: "Bob Brown",
  //     age: 40,
  //     email: "bob@example.com",
  //   },
  //   {
  //     id: 8,
  //     role: "Guest2",
  //     name: "Bob Brown",
  //     age: 40,
  //     email: "bob@example.com",
  //   },
  //   {
  //     id: 9,
  //     role: "Guest2",
  //     name: "Bob Brown",
  //     age: 40,
  //     email: "bob@example.com",
  //   },
  //   {
  //     id: 10,
  //     role: "Guest2",
  //     name: "Bob Brown",
  //     age: 40,
  //     email: "bob@example.com",
  //   },
  //   {
  //     id: 11,
  //     role: "Guest2",
  //     name: "Bob Brown",
  //     age: 40,
  //     email: "bob@example.com",
  //   },
  // ]);
  const [Loading, setLoading] = useState(true);

  const classes = useStyles();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { id } = useParams(1);
  let userId = "";
  if (id === undefined) {
    userId = localStorage.getItem("rand_token");
    userId = !isNaN(+userId) === false ? undefined : userId;
  } else{
    userId = id;
  }

  const [statuslogList, setStatusLogList] = useState([]);
  const StatusLogList = async () => {
    try {
      if(userId){
        setLoading(true);
        const response = await Http.call("GET", "admin/status_log/" + userId);
        await setStatusLogList(response.data);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    StatusLogList();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

    
  if (Loading) {
    return <ScreenLoader />;
  }

  return (

    <Page title="Fernwood Cemetery pre-quotes" >
      <Grid
        style={{
          // padding: 25,
          backgroundSize: "cover",
          zIndex: 0,
          backgroundPosition: "center",
          position: "relative",
        }}
      >
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>DATE</TableCell>
                <TableCell>ACTION</TableCell>
                <TableCell>CHANGED BY</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {statuslogList.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    No data available
                  </TableCell>
                </TableRow>
              ) : (
              statuslogList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.created_at ?row.created_at:"-"}</TableCell>
                    <TableCell>{row.title ?<div
              dangerouslySetInnerHTML={{
                __html: row.title,
              }}
            ></div>:"-"}</TableCell>
                    <TableCell>{row.created_by ?row.created_by:"-"}</TableCell>
                  </TableRow>
                )))}
            </TableBody>
          </Table>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={statuslogList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Grid>
    
    </Page>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(History_f);
