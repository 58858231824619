import React, { useRef, useEffect } from "react";
import { Button, Typography } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";

export default function ConformationDialog(props) {
  const { open, value, onClose, onConformAction, mode, ...other } = props;
  const radioGroupRef = useRef(null);

  useEffect(() => {
    if (!open) {
    }
  }, [open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onConformAction();
  };

  return (
    <Dialog
      // disableBackdropClick
      backdropclick="true"
      disableEscapeKeyDown
      maxWidth="md"
      onEntering={handleEntering}
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">
        <Typography variant="button">
          {mode === "sequence"
            ? "Are you sure you want to change the sequence?"
            : mode === "app_status"
            ? "Are you sure you want to change the application status?"
            : "Are you sure you want to delete this items?"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {value !== undefined
          ? value.map((item, index) => (
              <Typography key={index} variant="h6">
                {item}
              </Typography>
            ))
          : null}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleOk} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
