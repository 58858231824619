import { Pie } from "react-chartjs-2";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  colors,
  useTheme,
} from "@material-ui/core";

const Piechart = (props) => {
  const theme = useTheme();

  let label_1 = "Total Customers";
  let label_2 = "Total Visitors";

  if (props.title !== "Total Users") {
    label_1 = "Applications in progress";
    label_2 = "Completed Applications";
  }

  const data = {
    datasets: [
      {
        data: [props.data_1, props.data_2],
        backgroundColor: ["#22696d", "#D8B34F"],
      },
    ],
    labels: [label_1, label_2],
  };

  const options = {
    /*animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    maintainAspectRatio: false,*/
    legend: {
      display: true,
      onClick: function(event) {
        return false;
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: true,
      mode: "index",
      titleFontColor: theme.palette.text.primary,
    },
  };

  return (
    <Card>
      <CardHeader title={props.title} />
      <Divider />
      <CardContent>
        <Box height={390}>
          {props.data_1 > 0 || props.data_2 > 0 ? (
            <Pie data={data} width={100} height={45} options={options} />
          ) : (
            "Data not found."
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default Piechart;
