import React, { useState, useEffect } from "react";
import { Box, Container, makeStyles } from "@material-ui/core";
import Page from "src/components/Page";
import Results from "./Results";
import Toolbar from "./Toolbar";
import * as Http from "src/services/Http";
import { connect, useSelector } from "react-redux";
import ScreenLoader from "src/components/ScreenLoader";
import { setSnackbar } from "src/actions";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";
import ConformationDialog from "src/components/ConformationDialog";
import TableView from "./CategoryTable.js";

const breadUrlList = [
  { name: "Home", url: "/dashboard" },
  { name: "Category List", url: "/" },
];
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const headCells = [
  { id: "name", numeric: false, disablePadding: true, label: "Name" },
  { id: "sequence", numeric: false, disablePadding: true, label: "Sequence" },
  { id: "actions", numeric: false, disablePadding: false, label: "Actions" },
];

const CategoryListView = (props) => {
  const classes = useStyles();
  const [open, setopen] = useState(false);
  const [questionId, setquestion] = useState([]);
  const [delequestionTitle, setquestionTitles] = useState([]);
  const handleClose = () => setopen(false);
  const authuser = useSelector((state) => state?.session?.user);

  const [categoryList, setCategoryList] = useState({
    id: "Loading",
    name: "Loading",
    // last_name: "Loading",
    // email: "Loading",
    // mobile: "Loading",
  });
  const [Loading, setLoading] = useState(true);

  const fetchCategoryList = async () => {
    try {
      const response = await Http.call("GET", "admin/addons_categories");
      setCategoryList(response.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  /*const deleteCategories = async (data) => {
    setLoading(true);
      try {
        await Http.call("POST", "admin/addons_categories/delete", { id: data }).then(
          async (response) => {
            fetchCategoryList();
            props.setSnackbar(true, response.status, response.message);
          }
        );
      } catch (e) {
        console.log(e);
      }
  };*/

  const changeSequance = async (data) => {
    setLoading(true);
    try {
      await Http.call(
        "POST",
        "admin/addons_categories/updateSequence",
        data
      ).then(async (response) => {
        setLoading(false);
        props.setSnackbar(true, response.status, response.message);
      });
    } catch (e) {
      console.log(e);
    }
  };

  const deleteCategories = (idsToDelete, titlesToDelete) => {
    setopen(true);
    setquestion(idsToDelete);
    setquestionTitles(titlesToDelete);
  };

  const onConform = async () => {
    try {
      await Http.call("POST", "admin/addons_categories/delete", {
        ids: questionId,
      }).then(async (response) => {
        props.setSnackbar(true, response.status, response.message);
      });
      fetchCategoryList();
      handleClose();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchCategoryList();
  }, []);

  if (Loading) {
    return <ScreenLoader />;
  }

  return (
    <Page className={classes.root} title="Categories">
      <Container maxWidth={false}>
        <BreadcrumbsAdmin urlList={breadUrlList} />
        <Toolbar authuser={authuser} />
        <Box mt={3}>
          <TableView
            categories={categoryList}
            setcategorylist={setCategoryList}
            changesequance={changeSequance}
            deleteCategories={deleteCategories}
            authuser={authuser}
          />
          {/* <Results 
                questions={questionnaireList} 
                pageLoading={setLoading}
                fetchQuestions={fetchQuestionnaireList}
                deleteQuestionnaire={deleteQuestionnaire}  
                deleteQuestionnaire={deleteQuestionnaire}
                setquestionnairelist={setQuestionnaireList} 
                changesequance={changeSequance}
              /> */}
        </Box>
        <ConformationDialog
          classes={{
            paper: classes.paper,
          }}
          id="confirmation-dialog-title"
          keepMounted
          open={open}
          onClose={handleClose}
          onConformAction={onConform}
          value={delequestionTitle}
        />
      </Container>
    </Page>
    /*<Page className={classes.root} title="Categories">
      <Container maxWidth={false}>
        <BreadcrumbsAdmin urlList={breadUrlList} />
        <Toolbar />
        <Box mt={3}>
          <Results
            categories={categoryList}
            pageLoading={setLoading}
            fetchCategory={fetchCategoryList}
            deleteCategories={deleteCategories}
          />
        </Box>
      </Container>
    </Page>*/
  );
};

//export default CategoryListView;
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryListView);
