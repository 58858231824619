export const PASSWORD_MIN_8 = "Password must be at least 8 characters";
export const ONLY_ALPABATES = "Please use letters only.";
export const ONLY_ALPHANUM = "Only letters and numbers are allowed";
export const VALID_EMAIL = "Please enter a valid email address.";
export const MAX_Email = "Email must be less than 100 characters";
export const FIELD_REQUIRED = "This field is required";
export const PASSWORD_MIN_6 = "Password must be at least 6 characters";
export const PASSWORD_MATCH =
  "Must contain at least 1 capital letter, 1 small letter, 1 special character and 1 number";
export const PASSWORD_SAME = "Password and confirm password must match";
export const INVALID_MOBILE = "Invalid Mobile Number.";
export const INVALID_NUMBERS = "Invalid Number";
export const INVALID_PASSWORD =
  "Must contain at least 1 capital letter, 1 small letter, 1 special character and 1 number";

export const REQUIRED = "This field is required.";
export const INVALID_EMAIL = "Invalid e-mail Address.";

export const INVALID_FAX = "Invalid Fax Number.";

export const TOO_SHORT = "Please use additional characters.";

export const PERCENTAGE_BETWEEN = "The Percentage should be between 1 to 100";
export const TO_DATE = "Expiration Date should be more or equal to Start Date";
export const MINONEREQUIRED = "Minimum 1 field is required";
export const NOT_MORE_THEN_FIVE =
  "Please enter a number between 1 and 5. If the number of Next Of Kin exceeds this amount, please enter 5.";
export const MIN_2WORDS = "Must contain at least 2 words.";
export const ONLY_NUM = "Only numbers are allowed";
