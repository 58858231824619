import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Button,
  Card,
  FormControlLabel,
  Switch,
  makeStyles,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import { chkRoutePerm } from "src/services/Helper";
import { SET_PAGE_NUMBER, SET_PAGE_LIMIT, SET_SEARCH_TEXT } from "src/actions";

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const Results = ({
  className,
  questions,
  deleteQuestionnaire,
  page_no,
  rowsPerPage,
  totalRows,
  dispatch,
  query,
  ...rest
}) => {
  const [active, setActive] = useState(false);
  const classes = useStyles();
  const responsive = "standard";
  const chkEditRoutPerm = chkRoutePerm(
    "/app/questionnaire/edit",
    rest.authuser
  );
  const chkDelRoutPerm = chkRoutePerm(
    "/app/questionnaire/delete",
    rest.authuser
  );

  //personal add
  const filteredQuestions = active
    ? questions.filter((q) => q.status === "Active")
    : questions;

  const options = {
    serverSide: true,
    searchText: query,
    onSearchChange: async (searchText) => {
      if (searchText !== null && searchText.length > 3) {
        await dispatch({
          type: SET_SEARCH_TEXT,
          payload: {
            search_text: searchText,
          },
        });
      }
    },
    onSearchClose: async () => {
      await dispatch({
        type: SET_SEARCH_TEXT,
        payload: {
          search_text: "",
        },
      });
    },
    searchPlaceholder: "Search",
    customSearch: (searchQuery, currentRow, columns) => {
      let isFound = false;
      if (currentRow.toString().indexOf(searchQuery) >= 0) {
        console.log(currentRow.toString().indexOf(searchQuery), "currentRow");
        isFound = true;
      }
      return isFound;
    },

    filter: false,
    filterType: "dropdown",
    responsive,
    onRowsDelete: async (rowsDeleted) => {
      const idsToDelete = rowsDeleted.data.map(
        (d) => questions[d.dataIndex].id
      );
      const titlesToDelete = rowsDeleted.data.map(
        (d) => questions[d.dataIndex].title
      );
      deleteQuestionnaire(idsToDelete, titlesToDelete);
    },
    isRowSelectable: () => {
      return chkDelRoutPerm ? true : false;
    },
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [],
    tableBodyMaxHeight: "740px",
    // rowsPerPageOptions: [10, 25, 50, 100, 500],
    count: totalRows,
    page: page_no,
    downloadOptions: { filename: "Questionnaire.csv" },
    onChangePage: async (page_no) => {
      dispatch({
        type: SET_PAGE_NUMBER,
        payload: {
          page_no: page_no,
        },
      });
    },
    onChangeRowsPerPage: async (rowsPerPage) => {
      if (totalRows <= rowsPerPage) {
        dispatch({
          type: SET_PAGE_NUMBER,
          payload: {
            page_no: 0,
          },
        });
      }
      if (totalRows > rowsPerPage) {
        let no_pages = Math.ceil(totalRows / rowsPerPage);
        if (page_no > no_pages - 1) {
          dispatch({
            type: SET_PAGE_NUMBER,
            payload: {
              page_no: no_pages - 1,
            },
          });
        }
      }

      dispatch({
        type: SET_PAGE_LIMIT,
        payload: {
          page_limit: rowsPerPage,
        },
      });
    },
    count: filteredQuestions.length, //personal add
  };

  const columns = [
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        download: false,
        print: false,
        display: chkEditRoutPerm ? true : false,
        customBodyRenderLite: (dataIndex) => (
          <Tooltip disableFocusListener title="Edit">
            <Button
              color="primary"
              component={RouterLink}
              size="small"
              to={"/app/questionnaire/" + questions[dataIndex].id}
            >
              <EditOutlinedIcon />
            </Button>
          </Tooltip>
        ),
      },
    },
    {
      name: "question",
      label: "Question",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) =>
          questions[dataIndex].question ? (
            <div
              dangerouslySetInnerHTML={{
                __html: questions[dataIndex].question,
              }}
            ></div>
          ) : (
            ""
          ),
      },
    },
    {
      name: "sequence",
      label: "Sequence",
      options: {
        sort: true,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        sort: true,
      },
    },
  ];
  const handleActiveation = () => {
    setActive(!active);
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <MUIDataTable
            // title={"Questions List"}
            // data={questions}
            data={filteredQuestions} //personal add
            columns={columns}
            options={options}
          />
        </Box>
      </PerfectScrollbar>
      <FormControlLabel
        control={<Switch checked={active} onChange={handleActiveation} />}
        label="Active"
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  questions: PropTypes.array.isRequired,
};

export default Results;
