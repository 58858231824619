export const mobileNoFormate = (arg) => {
  let cleaned = ("" + arg).replace(/\D/g, "");
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
};
export const saveAddOns = (name, key, value) => {
  if (key === null) {
    key = 0;
  } else {
    key--;
  }
  let existing = localStorage.getItem(name.toString());
  existing = existing ? JSON.parse(existing) : [];
  if (existing[key]) {
    existing[key] = JSON.parse(value);
  } else {
    existing.push(JSON.parse(value));
  }
  localStorage.setItem(name.toString(), JSON.stringify(existing));
  return existing;
};

export const setItem = async (key, value) => {
  return localStorage.setItem(key, value);
};

export const getItem = async (key) => {
  return localStorage.getItem(key);
};
export const chkRoutePerm = (currentroute, permArray) => {
  let curtroute = currentroute.split("/");
  let chkpermroute = false;
  permArray.permission.map((item, index) => {
    // item.
    if (curtroute[2] === item.slug) {
      if (curtroute[3] === "manual") {
        if (curtroute[4] === "add") {
          chkpermroute = item.add === 1 ? true : false;
        } else if (curtroute[4] === "edit") {
          chkpermroute = item.edit === 1 ? true : false;
        } else if (curtroute[4] === "delete") {
          chkpermroute = item.delete === 1 ? true : false;
        }
      } else {
        if (curtroute[3] === "add") {
          chkpermroute = item.add === 1 ? true : false;
        } else if (curtroute[3] === "edit") {
          chkpermroute = item.edit === 1 ? true : false;
        } else if (curtroute[3] === "delete") {
          chkpermroute = item.delete === 1 ? true : false;
        }
      }
    }
  });
  return chkpermroute;
};
