import React from "react";
import {
  Grid,
  TextField,
} from "@material-ui/core";

const TextboxPlaceholderDetails = (props) => {
  const { nameval, labelval, handleBlur, handleChange, touchTextval, errorTextval, placholderval } = props;
  
  return (
    <Grid item md={12} xs={12}>
      <TextField
          name={nameval}
          label={labelval}
          variant="outlined"
          onBlur={handleBlur}
          onChange={handleChange}
          error={Boolean(touchTextval && errorTextval)}
          fullWidth
          margin="normal"
          value={placholderval}
          helperText={touchTextval && errorTextval}
      />
    </Grid>
  );
};

export default TextboxPlaceholderDetails;
