import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Grid, makeStyles } from "@material-ui/core";
import Page from "src/components/Page";
import SectionDetail from "./SectionDetail";
import * as Http from "src/services/Http";
import { setSnackbar } from "src/actions";
import { connect } from "react-redux";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";
/*import Toolbar from "./Toolbar";*/

const breadUrlList = [
  { name: "Home", url: "/dashboard" },
  { name: "Section List", url: "/app/sections" },
  { name: "Add Section", url: "/" },
];
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  sectionDetail: {
    marginTop: theme.spacing(3),
  },
}));

const AddSectionView = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  // const [Loading] = useState(true);
  const [values, setValues] = useState({
    name: "",
    description: "",
    type: "",
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const addSection = async (data, actions) => {
    await Http.call("POST", "admin/section", {
      ...data,
    }).then(async (response) => {
      if (response.status === 200) {
        props.setSnackbar(true, response.status, response.message);
        navigate("/app/sections");
      } else {
        actions.setErrors(response.data);
        console.log(response.data);
      }
    });
  };

  return (
    <Page className={classes.root} title="Add Section">
      <Container maxWidth="lg">
        <BreadcrumbsAdmin urlList={breadUrlList} />

        <Grid container spacing={3} className={classes.sectionDetail}>
          <Grid item lg={12} md={12} xs={12}>
            <SectionDetail
              formValues={values}
              handleChange={handleChange}
              addSection={addSection}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSectionView);
