import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Button, Typography } from "@material-ui/core";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";
import { chkRoutePerm } from "src/services/Helper";

const breadUrlList = [
  { name: "Home", url: "/dashboard" },
  { name: "Add-ons", url: "/app/addons" },
];
const Toolbar = ({ className, ...rest }) => {
  let chkRoutPerm = chkRoutePerm("/app/addons/add", rest.authuser);
  return (
    <div {...rest}>
      <BreadcrumbsAdmin urlList={breadUrlList} />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h3" component="h3">
          Add-ons List
        </Typography>
        {chkRoutPerm ? (
          <Button
            color="primary"
            variant="contained"
            component={RouterLink}
            to="/app/addons/add"
          >
            Add Add-ons
          </Button>
        ) : (
          ""
        )}
      </Box>
    </div>
  );
};

export default Toolbar;
