import React from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Box, Button, Card, makeStyles } from "@material-ui/core";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import MUIDataTable from "mui-datatables";
import { chkRoutePerm } from "src/services/Helper";

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));
const Results = ({
  className,
  deletePrice,
  emaillists,
  pageLoading,
  email_type,
  ...rest
}) => {
  const classes = useStyles();
  const responsive = "standard";
  const chkEditRoutPerm = chkRoutePerm(
    "/app/emailtemplate/edit",
    rest.authuser
  );
  const chkEditManualRoutPerm = chkRoutePerm(
    "/app/emailtemplate/manual/edit",
    rest.authuser
  );

  const options = {
    filter: false,
    filterType: "dropdown",
    responsive,
    selectableRowsHeader: true,
    selectableRowsHideCheckboxes: email_type === "manual"? false : true,
    onRowsDelete: async (rowsDeleted) => {
      const idsToDelete = rowsDeleted.data.map((d) => emaillists[d.dataIndex].id);
      const titlesToDelete = rowsDeleted.data.map(
        (d) => emaillists[d.dataIndex].title
      );
      deletePrice(idsToDelete, titlesToDelete);
    },
    rowsPerPageOptions: [],
    rowsPerPage: 100,
    tableBodyMaxHeight: "740px",
    // rowsPerPageOptions: [10, 25, 50, 100, 500],
    downloadOptions: { filename: "Emailtemplates.csv" },
  };
  const columns = [
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        download: false,
        print: false,
        display:
          email_type === "manual"
            ? chkEditManualRoutPerm
              ? true
              : false
            : chkEditRoutPerm,
        viewColumns: false,
        customBodyRenderLite: (dataIndex) => (
          <Tooltip disableFocusListener title="Edit">
            <Button
              color="primary"
              component={RouterLink}
              size="small"
              to={
                email_type === "manual"
                  ? "/app/emailtemplate/manual/" + emaillists[dataIndex].id
                  : "/app/editemailtemplate/" + emaillists[dataIndex].id
              }
            >
              <EditOutlinedIcon />
            </Button>
          </Tooltip>
        ),
      },
    },
    {
      name: "email_subject",
      label: "Template Subject",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "code",
      label: "Template Type",
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <MUIDataTable data={emaillists} columns={columns} options={options} />
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  emaillists: PropTypes.array.isRequired,
};

export default Results;
