import React from "react";

import { useField } from "formik";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

export default function SelectBox({ label, name, options, ...props }) {
  const [field, meta] = useField(name);

  return (
    <Grid container>
      <FormControl variant="standard" sx={{ m: 1, minWidth: "100%" }}>
        <InputLabel id="demo-simple-select-standard-label">{label}</InputLabel>
        <Select
          id="demo-simple-select-standard"
          label={label}
          fullWidth
          {...field}
          {...props}
        >
          <MenuItem value="" key={"null"}>
            <em>Select Option</em>
          </MenuItem>
          {options.map((option, index) => {
            return (
              <MenuItem key={index} value={option.id}>
                {option.answer}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText error>
          {meta.touched && meta.error && meta.error}
        </FormHelperText>
      </FormControl>
    </Grid>
  );
}
