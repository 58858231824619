import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Grid, makeStyles } from "@material-ui/core";
import Page from "src/components/Page";
import ViewSubscriptionDetail from "./ViewSubscriptionDetail";
import * as Http from "src/services/Http";
import ScreenLoader from "src/components/ScreenLoader";
import { setSnackbar } from "src/actions";
import { connect } from "react-redux";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";
/*import Toolbar from "./Toolbar";*/

const breadUrlList = [
  { name: "Home", url: "/dashboard" },
  { name: "Payment Record List", url: "/app/subscriptions" },
  { name: "Payment Record Details", url: "/" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  subscriptionDetail: {
    marginTop: theme.spacing(3),
  },
}));

const SubscriptionDetailsView = (props) => {
  const { id } = useParams();
  const classes = useStyles();
  const [Loading, setLoading] = useState(true);
  const [values, setValues] = useState({
    transaction_id: "",
    user_id: "",
    email: "",
    package_id: "",
    services: "",
    promocode: "",
    discount: "",
    total_amount: "",
    status: "",
    created_at: "",
  });

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        const response = await Http.call("GET", "admin/subscription/" + id);
        // console.log(response.data);
        setValues(response.data);
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    };
    fetchSubscription();
  }, [id]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  if (Loading) {
    return <ScreenLoader />;
  }
  return (
    <Page className={classes.root} title="Payment Record Details">
      <Container maxWidth="lg">
        <BreadcrumbsAdmin urlList={breadUrlList} />
        {/*<Toolbar />*/}
        <Grid container spacing={3} className={classes.subscriptionDetail}>
          <Grid item lg={12} md={12} xs={12}>
            <ViewSubscriptionDetail
              subscriptionValues={values}
              handleChange={handleChange}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionDetailsView);
