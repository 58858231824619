import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import _ /*values*/ from "lodash";
import {
  Grid,
  Button,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
} from "@material-ui/core";
import { FieldArray } from "formik";
import "src/styles/custom.css";

const FormFieldDetails = (props) => {
  //const classes = useStyles();
  const { nameval, touchTextval, errorTextval, isparent, docuforms } = props;
  return (
    <FieldArray
      name={`que_forms_fields_arr`}
      render={(arrayHelpers) => (
        <Grid id="top-row" container>
          {nameval.length > 0 &&
            nameval.map((optionval, index) => {
              if (index === 0) {
                return (
                  <Grid key={index} md={12} xs={12}>
                    <FormControl variant="outlined" className="selectControl">
                      <InputLabel id="que_forms">Document Form</InputLabel>
                      <Select
                        id="que_forms"
                        label="Document Form"
                        name={`que_forms_fields_arr.${index}.form`}
                        value={
                          nameval[index] !== undefined &&
                          nameval[index].form !== undefined &&
                          nameval[index].form > 0
                            ? nameval[index].form
                            : ""
                        }
                        onChange={(e) => {
                          if (nameval[index] !== undefined) {
                            arrayHelpers.replace(index, {
                              form: e.target.value,
                              fields: [],
                            });
                          } else {
                            arrayHelpers.push({
                              form: e.target.value,
                              fields: [],
                            });
                          }
                        }}
                        error={Boolean(touchTextval && errorTextval)}
                      >
                        <MenuItem value="">
                          <em>Select Document Form</em>
                        </MenuItem>

                        {docuforms.map((docuformslist, frm_index) => (
                          <MenuItem
                            key={`form_${frm_index}`}
                            value={docuformslist.id}
                            disabled={nameval.find((item) => {
                              if (nameval[index].form === docuformslist.id) {
                                return false;
                              }
                              if (item.form === docuformslist.id) {
                                return true;
                              }
                            })}
                          >
                            {docuformslist.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl
                      variant="outlined"
                      className="selectControlfield"
                    >
                      <InputLabel id="que_form_fields">Form Fields</InputLabel>
                      <Select
                        multiple
                        id="que_form_fields"
                        label="Form Fields"
                        name={`que_forms_fields_arr.${index}.fields`}
                        value={
                          nameval[index] !== undefined &&
                          nameval[index].fields.length > 0
                            ? nameval[index].fields
                            : []
                        }
                        onChange={(e) => {
                          if (nameval[index] !== undefined) {
                            arrayHelpers.replace(index, {
                              form: nameval[index].form,
                              fields: e.target.value,
                            });
                          }
                        }}
                        error={Boolean(touchTextval && errorTextval)}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <em>Form Fields</em>;
                          }
                          return selected.length + " selected";
                        }}
                        inputProps={{
                          name: "Form Fields",
                          id: "outlined-age-native-simple",
                        }}
                      >
                        <MenuItem value="">
                          <em>Select Form Fields</em>
                        </MenuItem>
                        {docuforms.map((docuformslist, f_index) =>
                          nameval[index] !== undefined &&
                          nameval[index].form !== undefined &&
                          docuformslist.id === nameval[index].form
                            ? docuformslist.field_names.map(
                                (field, field_index) => (
                                  <MenuItem
                                    key={`form_field_${field_index}`}
                                    value={docuformslist.id + "_" + field.id}
                                  >
                                    <Checkbox
                                      checked={
                                        nameval[index].fields.indexOf(
                                          docuformslist.id + "_" + field.id
                                        ) > -1
                                      }
                                    />
                                    <ListItemText primary={field.field_name} />
                                  </MenuItem>
                                )
                              )
                            : ""
                        )}
                      </Select>
                    </FormControl>

                    <Button
                      color="secondary"
                      variant="contained"
                      margin="normal"
                      style={{ margin: "15px 5px", padding: "15px" }}
                      onClick={() => {
                        arrayHelpers.push({ form: "", fields: [] });
                      }}
                      disabled={isparent === 1}
                    >
                      {" "}
                      +{" "}
                    </Button>

                    {errorTextval &&
                      _.isString(errorTextval) &&
                      touchTextval &&
                      _.isArray(touchTextval) && (
                        <FormHelperText className="Mui-error">
                          {errorTextval}
                        </FormHelperText>
                      )}
                  </Grid>
                );
              } else {
                return (
                  <Grid key={index} md={12} xs={12}>
                    <FormControl variant="outlined" className="selectControl">
                      <InputLabel id="que_forms">Document Form</InputLabel>
                      <Select
                        id="que_forms"
                        label="Document Form"
                        name={`que_forms_fields_arr.${index}.form`}
                        value={
                          nameval[index] !== undefined &&
                          nameval[index].form !== undefined &&
                          nameval[index].form > 0
                            ? nameval[index].form
                            : ""
                        }
                        onChange={(e) => {
                          if (nameval[index] !== undefined) {
                            arrayHelpers.replace(index, {
                              form: e.target.value,
                              fields: [],
                            });
                          } else {
                            arrayHelpers.push({
                              form: e.target.value,
                              fields: [],
                            });
                          }
                        }}
                        error={Boolean(touchTextval && errorTextval)}
                      >
                        <MenuItem value="">
                          <em>Select Document Form</em>
                        </MenuItem>

                        {docuforms.map((docuformslist, frm_index) => (
                          <MenuItem
                            key={`form_${frm_index}`}
                            value={docuformslist.id}
                            disabled={nameval.find((item) => {
                              if (nameval[index].form === docuformslist.id) {
                                return false;
                              }
                              if (item.form === docuformslist.id) {
                                return true;
                              }
                            })}
                          >
                            {docuformslist.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl
                      variant="outlined"
                      className="selectControlfield"
                    >
                      <InputLabel id="que_form_fields">Form Fields</InputLabel>
                      <Select
                        multiple
                        id="que_form_fields"
                        label="Form Fields"
                        name={`que_forms_fields_arr.${index}.fields`}
                        value={
                          nameval[index] !== undefined &&
                          nameval[index].fields.length > 0
                            ? nameval[index].fields
                            : []
                        }
                        onChange={(e) => {
                          if (nameval[index] !== undefined) {
                            arrayHelpers.replace(index, {
                              form: nameval[index].form,
                              fields: e.target.value,
                            });
                          }
                        }}
                        error={Boolean(touchTextval && errorTextval)}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <em>Form Fields</em>;
                          }
                          return selected.length + " selected";
                        }}
                        inputProps={{
                          name: "Form Fields",
                          id: "outlined-age-native-simple",
                        }}
                      >
                        <MenuItem value="">
                          <em>Select Form Fields</em>
                        </MenuItem>
                        {docuforms.map((docuformslist, f_index) =>
                          nameval[index] !== undefined &&
                          nameval[index].form !== undefined &&
                          docuformslist.id === nameval[index].form
                            ? docuformslist.field_names.map(
                                (field, field_index) => (
                                  <MenuItem
                                    key={`form_field_${field_index}`}
                                    value={docuformslist.id + "_" + field.id}
                                  >
                                    <Checkbox
                                      checked={
                                        nameval[index].fields.indexOf(
                                          docuformslist.id + "_" + field.id
                                        ) > -1
                                      }
                                    />
                                    <ListItemText primary={field.field_name} />
                                  </MenuItem>
                                )
                              )
                            : ""
                        )}
                      </Select>
                    </FormControl>

                    <Button
                      onClick={() => arrayHelpers.remove(index)}
                      color="secondary"
                      variant="contained"
                      margin="normal"
                      style={{ margin: "15px 5px", padding: "15px" }}
                      disabled={isparent === 1}
                    >
                      {" "}
                      -{" "}
                    </Button>
                  </Grid>
                );
              }
            })}
        </Grid>
      )}
    />
  );
};

export default FormFieldDetails;
