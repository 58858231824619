export const FETCH_REQUEST_FOR_POST_QUESTIONS =
  "FETCH_REQUEST_FOR_POST_QUESTIONS";
export const FETCH_SUCCESS_FOR_POST_QUESTIONS =
  "FETCH_SUCCESS_FOR_POST_QUESTIONS";
export const FETCH_FAILD_FOR_POST_QUESTIONS = "FETCH_FAILD_FOR_POST_QUESTIONS";
export const SET_POST_QUESTION_LOADING = "SET_POST_QUESTION_LOADING";
export const SET_POST_PAGE_NUMBER = "SET_POST_PAGE_NUMBER";
export const SET_POST_PAGE_LIMIT = "SET_POST_PAGE_LIMIT";
export const SET_POST_SEARCH_TEXT = "SET_POST_SEARCH_TEXT";

export const setPostTableData = (data) => (dispatch) => {
  dispatch({
    type: FETCH_SUCCESS_FOR_POST_QUESTIONS,
    payload: {
      data: data,
    },
  });
};
export const setPostTableLoading = (arg) => (dispatch) => {
  dispatch({
    type: SET_POST_QUESTION_LOADING,
    payload: {
      loading: arg,
    },
  });
};
export const setPostPageNumber = (pageno) => (dispatch) => {
  dispatch({
    type: SET_POST_PAGE_NUMBER,
    payload: {
      page_no: pageno,
    },
  });
};
export const setPostPageLimit = (pagelimit) => (dispatch) => {
  dispatch({
    type: SET_POST_PAGE_LIMIT,
    payload: {
      page_limit: pagelimit,
    },
  });
};
