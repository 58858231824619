import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Button, Card, Typography } from "@material-ui/core";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import PerfectScrollbar from "react-perfect-scrollbar";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import { chkRoutePerm } from "src/services/Helper";

const BannerTable = ({ classes, banners, deleteBanner, ...rest }) => {
  const responsive = "standard";
  const chkEditRoutPerm = chkRoutePerm("/app/banners/edit", rest.authuser);
  const chkDelRoutPerm = chkRoutePerm("/app/banners/delete", rest.authuser);
  const options = {
    filter: false,
    filterType: "dropdown",
    responsive,
    sortOrder: {
      name: "created_at",
      direction: "desc",
    },
    onRowsDelete: async (rowsDeleted) => {
      const idsToDelete = rowsDeleted.data.map((d) => banners[d.dataIndex].id);
      const titlesToDelete = rowsDeleted.data.map(
        (d) => banners[d.dataIndex].title
      );
      deleteBanner(idsToDelete, titlesToDelete);
    },
    rowsPerPageOptions: [],
    rowsPerPage: 100,
    tableBodyMaxHeight: "740px",
    // rowsPerPageOptions: [10, 25, 50, 100, 500],
    download: false,
    print: false,
    downloadOptions: { filename: "Banners.csv" },
    // selectableRowsHideCheckboxes: chkDelRoutPerm ? true : false,
    isRowSelectable: () => {
      return chkDelRoutPerm ? true : false;
    },
  };

  const columns = [
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        download: false,
        print: false,
        display: chkEditRoutPerm ? true : false,
        viewColumns: false,
        customBodyRenderLite: (dataIndex) => (
          <Tooltip disableFocusListener title="Edit">
            <Button
              color="primary"
              component={RouterLink}
              size="small"
              to={"/app/banners/" + banners[dataIndex].id}
            >
              <EditOutlinedIcon />
            </Button>
          </Tooltip>
        ),
      },
    },
    {
      name: "thumb_image",
      label: "Banner Image",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) =>
          banners[dataIndex].thumb_image !== "" ? (
            <img
              src={banners[dataIndex].thumb_image}
              className={[classes.gridthumb, classes.tableimg].join(" ")}
              // className={}
              alt="Not Found."
            />
          ) : (
            <img src="https://via.placeholder.com/100" alt="Not Found." />
          ),
      },
    },
    {
      name: "step_name",
      label: "Step",
      options: {
        filter: true,
        sort: true,
        // customBodyRenderLite: (dataIndex) =>
        //     banners[dataIndex].step_id === 1 ? 'Prequestionnaire' : banners[dataIndex].step_id === 2 ? 'Questionnaire' : '',
      },
    },
    {
      name: "font_color",
      label: "Font Color",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => (
          <Typography>{banners[dataIndex].font_color}</Typography>
        ),
      },
    },
    {
      name: "is_default_value",
      label: "Default",
      option: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "created_at",
      label: "Created Date",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) =>
          moment(banners[dataIndex].created_at).format("MM/DD/YYYY"),
      },
    },
  ];

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <MUIDataTable
            title={""}
            data={banners}
            columns={columns}
            options={options}
          />
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

export default BannerTable;
