import React, { useState, useEffect } from "react";
import { Box, makeStyles } from "@material-ui/core";
import Results from "./Results";
import * as Http from "src/services/Http";
import { connect, useDispatch, useSelector } from "react-redux";
import ScreenLoader from "src/components/ScreenLoader";
import {
  setSnackbar,
  FETCH_REQUEST_FOR_QUESTIONS,
  FETCH_SUCCESS_FOR_QUESTIONS,
} from "src/actions";
import ConformationDialog from "src/components/ConformationDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const QuestionnaireListView = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [open, setopen] = useState(false);
  const [questionId, setquestion] = useState([]);
  const [delequestionTitles, setquestionTitles] = useState([]);

  const handleClose = () => setopen(false);

  const {
    isLoading,
    data,
    pageNo,
    pageLimit,
    totalQuestion,
    searchText,
  } = useSelector((state) => state?.questionslist);

  const fetchQuestionnaireList = async () => {
    dispatch({ type: FETCH_REQUEST_FOR_QUESTIONS, payload: true });
    try {
      await Http.call("POST", "admin/get_question_answer_listing", {
        type: 0,
        page_no: pageNo,
        page_limit: pageLimit,
        search_query: searchText,
      }).then(async (response) => {
        dispatch({ type: FETCH_SUCCESS_FOR_QUESTIONS, payload: response });
      });
    } catch (e) {
      console.log(e);
    }
  };

  const deleteQuestionnaire = (idsToDelete, titlesToDelete) => {
    setopen(true);
    setquestion(idsToDelete);
    setquestionTitles(titlesToDelete);
  };
  const onConform = async () => {
    try {
      await Http.call("POST", "admin/questionnaire/multidelete", {
        ids: questionId,
      }).then(async (response) => {
        props.setSnackbar(true, response.status, response.message);
      });
      fetchQuestionnaireList();
      handleClose();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(async () => {
    await fetchQuestionnaireList();
  }, [pageNo, pageLimit, searchText]);

  if (isLoading) {
    return <ScreenLoader />;
  }

  return (
    <div>
      <Box>
        <Results
          questions={data}
          deleteQuestionnaire={deleteQuestionnaire}
          page_no={pageNo}
          rowsPerPage={pageLimit}
          totalRows={totalQuestion}
          dispatch={dispatch}
          query={searchText}
          authuser={props.authuser}
        />
      </Box>
      <ConformationDialog
        classes={{
          paper: classes.paper,
        }}
        id="confirmation-dialog-title"
        keepMounted
        open={open}
        onClose={handleClose}
        onConformAction={onConform}
        value={delequestionTitles}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionnaireListView);
