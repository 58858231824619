import React, { useState, useEffect } from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { connect } from "react-redux";
import { setSnackbar } from "src/actions";
import * as Http from "src/services/Http";
import ScreenLoader from "src/components/ScreenLoader";
import Page from "src/components/Page";
import UpdateCounsellorStatus from "../ListCounsellorView/UpdateCounsellorStatus";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";
import CommunicationLavel from "./CommunicationLavel";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const breadUrlList = [
  { name: "Home", url: "/dashboard" },
  { name: "Application List", url: "/app/counselors" },
  { name: "Edit Application", url: "/" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  discountDetail: {
    marginTop: theme.spacing(3),
  },
}));

const EditCounsellorView = (props) => {
  const { id } = useParams();
  const { status_id } = useParams();
  const classes = useStyles();
  const [Loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState("panel2a");
  const [appCounsStatus, setAppCounsStatus] = useState({
    user_name: "",
    couns_id: "",
    app_id: "",
    status: [],
  });
  const [staffPositions, setStaffPositions] = useState([]);

  const fetchStaffPositions = async () => {
    try {
      let url = "admin/designations";
      const response = await Http.call("GET", url);
      await setStaffPositions(response.data);
    } catch (e) {
      console.log(e);
    }
  };

  const [emailtemplists, setEmailTemp] = useState([]);

  const fetchEmailTemp = async () => {
    try {
      const responseEmail = await Http.call("GET", "admin/email_templates");
      await setEmailTemp(responseEmail.data);
    } catch (e) {
      console.log(e);
    }
  };

  const [docuforms, setDocuForms] = useState([]);
  const fetchDocumentForms = async () => {
    try {
      const responsedocuforms = await Http.call("GET", "admin/forms");
      await setDocuForms(responsedocuforms.data);
    } catch (e) {
      console.log(e);
    }
  };
  const fetchData = async (StatusId = "") => {
    let status_data = {
      app_id: id,
      status_id: StatusId !== "" ? StatusId : status_id,
    };
    await Http.call("post", "admin/show_app_status", status_data).then(
      async (response) => {
        if (response.status === 200) {
          await setAppCounsStatus(response.data);
          await fetchStaffPositions();
          await fetchEmailTemp();
          await fetchDocumentForms();
          setLoading(false);
        } else {
          console.log(response);
        }
      }
    );
  };

  useEffect(() => {
    fetchData();
  }, [id, status_id]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  if (Loading) {
    return <ScreenLoader />;
  }
  return (
    <Page className={classes.root} title="Edit Application">
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} xs={12}>
            <Grid container spacing={3}>
              <Grid item lg={6} md={6} xs={12} mb={3}>
                <Typography variant="h3">
                  APPLICATION NO.:{" "}
                  {appCounsStatus.application_code
                    ? appCounsStatus.application_code
                    : ""}
                </Typography>
                {/* <Typography>User: {appCounsStatus.user_name ? appCounsStatus.user_name : ""}</Typography> */}
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <BreadcrumbsAdmin urlList={breadUrlList} />
              </Grid>
            </Grid>
            <Accordion
              expanded={expanded === "panel1a"}
              onChange={handleChange("panel1a")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Contacts</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <CommunicationLavel setSnackbar={props.setSnackbar} />
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2a"}
              onChange={handleChange("panel2a")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Grid container spacing={3} display="flex" alignItems="center">
                  <Grid item md={9}>
                    <Typography>Application Status</Typography>
                  </Grid>
                  <Grid item md={3} style={{ textAlign: "end" }}>
                    <Button
                      color="primary"
                      variant="contained"
                      component={RouterLink}
                      to={`/app/counselor/${appCounsStatus.app_id}/manual-action`}
                    >
                      Manual Action
                    </Button>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <UpdateCounsellorStatus
                  setSnackbar={props.setSnackbar}
                  appCounsStatus={appCounsStatus}
                  setAppCounsStatus={setAppCounsStatus}
                  fetchData={fetchData}
                  staffPositions={staffPositions}
                  emailtemplists={emailtemplists}
                  docuforms={docuforms}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCounsellorView);
