import { Box, Container, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import Page from "src/components/Page";
import ScreenLoader from "src/components/ScreenLoader";
import * as Http from "src/services/Http";
import PriceTable from "src/views/PriceList/PriceView/PriceTable";
import Toolbar from "./Toolbar";
import { setSnackbar } from "src/actions";
import ConformationDialog from "src/components/ConformationDialog";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";

const breadUrlList = [
  { name: "Home", url: "/dashboard" },
  { name: "Price List", url: "/" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  paper: {
    width: "40%",
    maxHeight: 435,
  },
}));

const PriceView = (props) => {
  const [Loading, setLoading] = useState(true);
  const [open, setopen] = useState(false);
  const [priceData, setPriceData] = useState([]);
  const [deletable, setdeletable] = useState([]);
  const [deletableTitles, setdeletableTitles] = useState([]);
  const classes = useStyles();
  const authuser = useSelector((state) => state?.session?.user);

  const handleClose = () => setopen(false);

  useEffect(() => {
    getPrice();
  }, []);

  const getPrice = async () => {
    const response = await Http.call("GET", "admin/price");
    setPriceData(response.data);
    setLoading(false);
  };

  const changeStatus = async (data) => {
    try {
      await Http.call("POST", "admin/price/changeStatus", data).then(
        async (response) => {
          props.setSnackbar(true, response.status, response.message);
        }
      );
    } catch (e) {
      console.log(e);
    }
  };
  const deletePrice = (idsToDelete, titlesToDelete) => {
    setopen(true);
    setdeletable(idsToDelete);
    setdeletableTitles(titlesToDelete);
  };
  const onConform = async () => {
    try {
      await Http.call("POST", "admin/price/multidelete", {
        ids: deletable,
      }).then(async (response) => {
        props.setSnackbar(true, response.status, response.message);
      });
      getPrice();
      handleClose();
    } catch (e) {
      console.log(e);
    }
  };
  if (Loading) {
    return <ScreenLoader />;
  }
  return (
    <Page className={classes.root} title="Price List">
      <Container maxWidth="lg">
        <BreadcrumbsAdmin urlList={breadUrlList} />
        <Toolbar authuser={authuser} />
        <Box mt={3}>
          <PriceTable
            data={priceData}
            setPriceData={setPriceData}
            deletePrice={deletePrice}
            changeStatus={changeStatus}
            authuser={authuser}
          />
        </Box>
      </Container>
      <ConformationDialog
        classes={{
          paper: classes.paper,
        }}
        id="confirmation-dialog-title"
        keepMounted
        open={open}
        onClose={handleClose}
        onConformAction={onConform}
        value={deletableTitles}
      />
    </Page>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { setSnackbar };

export default connect(mapStateToProps, mapDispatchToProps)(PriceView);
