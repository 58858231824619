import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Box, makeStyles } from "@material-ui/core";
import ScreenLoader from "src/components/ScreenLoader";
import Page from "src/components/Page";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";
import Toolbar from "./Toolbar";
import BannerTable from "./BannerTable";
import ConformationDialog from "src/components/ConformationDialog";
import { fetchBanners, removeBanner } from "src/actions/bannerAction";

const breadUrlList = [
  { name: "Home", url: "/dashboard" },
  { name: "Banner List", url: "/" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  paper: {
    width: "40%",
    maxHeight: 435,
  },
  tableimg: {
    width: "100px",
    height: "70px",
  },
}));

const BannersView = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [open, setopen] = useState(false);
  const [deletable, setdeletable] = useState([]);
  const [deletableTitles, setdeletableTitles] = useState([]);

  const classes = useStyles();

  const dispatch = useDispatch();

  const handleClose = () => setopen(false);

  useEffect(() => {
    dispatch(fetchBanners(setIsLoading));
  }, [setIsLoading, dispatch]);

  const banners = useSelector((state) => state.banners);
  const authuser = useSelector((state) => state?.session?.user);

  const deleteBanner = (idsToDelete, titlesToDelete) => {
    setopen(true);
    setdeletable(idsToDelete);
    setdeletableTitles(titlesToDelete);
  };
  const onConform = async () => {
    dispatch(removeBanner(deletable, setIsLoading));
    handleClose();
  };

  if (isLoading) {
    return <ScreenLoader />;
  } else {
    return (
      <Page className={classes.root} title="Banners">
        <Container maxWidth="lg">
          <BreadcrumbsAdmin urlList={breadUrlList} />
          <Toolbar authuser={authuser} />
          <Box mt={3}>
            <BannerTable
              classes={classes}
              banners={banners}
              deleteBanner={deleteBanner}
              authuser={authuser}
            />
          </Box>
        </Container>
        <ConformationDialog
          classes={{
            paper: classes.paper,
          }}
          id="confirmation-dialog-title"
          keepMounted
          open={open}
          onClose={handleClose}
          onConformAction={onConform}
          value={deletableTitles}
        />
      </Page>
    );
  }
};

export default BannersView;
