import React from "react";
import * as Http from "src/services/Http";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import Cancelbtn from "src/components/common/Cancelbtn";
import * as Messages from "src/constants/Messages/index";

const AddDesignationSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, Messages.TOO_SHORT)
    .required(Messages.REQUIRED),
});

const DesignationDetails = ({ setSnackbar, formValues, promoClass }) => {
  const navigate = useNavigate();
  return (
    <Formik
      initialValues={formValues}
      validationSchema={AddDesignationSchema}
      onSubmit={async (values, actions) => {
        let method = "";
        let url = "";
        if (formValues.id === undefined) {
          method = "POST";
          url = "admin/designations";
        } else {
          method = "PATCH";
          url = "admin/designations/" + formValues.id;
        }
        await Http.call(method, url, values).then(
          async ({ status, message, data }) => {
            if (status === 200) {
              setSnackbar(true, status, message);
              navigate("/app/designation");
            } else if (status === 422) {
              if (data.title) {
                actions.setFieldError("title", data.title);
              }

              setSnackbar(true, status, message);
            }
          }
        );
        actions.setSubmitting(false);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        values,
      }) => {
        return (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Grid item md={12} xs={12} className={promoClass}>
              <Card>
                {}
                <CardHeader
                  title={
                    formValues.id === undefined
                      ? "Add Position"
                      : "Edit Position"
                  }
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={12} xs={12}>
                      <TextField
                        fullWidth
                        name="title"
                        label="Title"
                        variant="outlined"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.title}
                        error={Boolean(touched.title && errors.title)}
                        margin="normal"
                        helperText={touched.title && errors.title}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Cancelbtn />
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </Box>
              </Card>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default DesignationDetails;
