import React from "react";
import { connect } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";

import { setSnackbar } from "src/actions";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export const SnackBar = (props) => {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    props.setSnackbar(false, props.type, props.message);
  };
  let type = 200;
  if (props.type === 200) {
    type = "success";
  }
  // else if (props.type === 401 || props.type === 400) {
  //   type = "warning";
  // }
  else {
    type = "error";
  }
  return (
    <div>
      <Snackbar
        open={props.visible}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={type}>
          {props.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

const mapStateToProps = (state) => ({
  type: state.global.snackBarType,
  message: state.global.snackBarMessage,
});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(SnackBar);
