import React from "react";
import { connect } from "react-redux";
import { setPackageData } from "src/actions";

import {
  Radio,
  RadioGroup,
  Checkbox,
  FormControlLabel,
  FormGroup ,Box
} from "@material-ui/core";
import { Grid } from "@material-ui/core";

export const RadioOption = ({
  data,
  categoryKey,
  addOnsKey,
  selected,
  type,
  ...props
}) => {
  let isSelected = '';
  let isSelected_check = false;

  //let selectedString = type ? "checkbox" : "radio";

  const { id, service_name, price, thumb_image, is_included } = data;

  if (selected) {
    if (selected.id === id) {
      isSelected = selected.price;
      isSelected_check = true;
      //selectedString = type ? "checkbox-checked" : "radio-checked";
    }
  }
  
  return (
    <div className="form-check mt-3">
    {
      type  ?
      <FormGroup >
        <FormControlLabel
          control={
            <Checkbox
              checked={isSelected_check}
              onChange={() => {
              props.setPackageData({
                  id: id,
                  catId: categoryKey,
                  name: service_name,
                  price: price,
                  type: type,
                  selected: true,
                  included: is_included ? true : false,
                  quantity: 1,
                  address: "",
                });
              }}
              name={"addons_ids[" + id + "]"}
              color="primary"
            />
          }
          label={
            < Grid container>
             <Box>
            {
              thumb_image !== "" ? (
                <img className="mx-3 lgt-img " src={`${thumb_image}`} alt="" />
              ) : (
                <img className="mx-3 lgt-img " src={`${process.env.PUBLIC_URL}/static/images/image_placeholder.jpg`} alt="" />
              )
            }
            </Box>
            <Box>
            {`${service_name}`}
            <br />
            {
              is_included ? (
                <b>${parseFloat(0).toFixed(2)} (Included)</b>
              ) : (
                <b>+${parseFloat(price).toFixed(2)}</b>
              )
            }
            </Box>
            </Grid>
          }
        />
      </FormGroup>
      :
      <RadioGroup
        name={"addons_ids[" + id + "]"}
        value={isSelected}
        onChange={() => {
          props.setPackageData({
            id: id,
            catId: categoryKey,
            name: service_name,
            price: price,
            type: type,
            selected: true,
            quantity: 1,
            address: id === 9 ? props.collectPerson : "",
          });
        }}
      >
        <FormControlLabel
          label={
            <Grid container>
              <Box>

            {
              thumb_image !== "" ? (
                <img className="mx-3 lgt-img " src={`${thumb_image}`} alt="" />
                ) : (
                  <img className="mx-3 lgt-img " src={`${process.env.PUBLIC_URL}/static/images/image_placeholder.jpg`} alt="" />
                  )
                }
                </Box>
                <Box>

            {`${service_name}`}
            <br />
            {
              is_included ? (
                <b>${parseFloat(0).toFixed(2)} (Included)</b>
                ) : (
                  <b>+${parseFloat(price).toFixed(2)}</b>
                  )
                }
                </Box>
            </Grid>
          }
          key={id}
          value={price}
          control={<Radio color="primary" />}
        />
      </RadioGroup>
    }
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setPackageData,
};

export default connect(mapStateToProps, mapDispatchToProps)(RadioOption);
