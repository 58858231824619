import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Grid, makeStyles } from "@material-ui/core";
import Page from "src/components/Page";
import DocumentFormDetails from "../Components/DocumentFormDetails";
import Toolbar from "../Components/Toolbar";
import * as Http from "src/services/Http";
import ScreenLoader from "src/components/ScreenLoader";
import { setSnackbar } from "src/actions";
import { connect } from "react-redux";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";

const breadUrlList = [
  { name: "Home", url: "/dashboard" },
  { name: "Document Form List", url: "/app/document-form" },
  { name: "Edit Document Form", url: "/" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  QuesDetail: {
    marginTop: theme.spacing(3),
  },
}));

const EditDocumentFormView = (props) => {
  const { id } = useParams();
  const classes = useStyles();
  const [Loading, setLoading] = useState(true);
  const [values, setValues] = useState({
    name: "",
    editor: "",
    field_array: [{ field_name: "", x_point: "", y_point: "", page_no: "" }],
  });

  useEffect(() => {
    const fetchData = async () => {
      await Http.call("GET", "admin/forms/" + id).then(async (response) => {
        const { data } = response;
        setValues({
          id: id,
          name: data.name,
          editor: data.editor,
          field_array: data.field_names.length > 0 ? data.field_names : [""],
        });

        setLoading(false);
      });
    };

    fetchData();
  }, [id]);

  if (Loading) {
    return <ScreenLoader />;
  }

  return (
    <Page className={classes.root} title="Edit Document Form">
      <Container maxWidth="lg">
        <BreadcrumbsAdmin urlList={breadUrlList} />
        <Toolbar mode={"Edit"} />
        <Grid container spacing={3} className={classes.QuesDetail}>
          <Grid item lg={12} md={12} xs={12}>
            <DocumentFormDetails
              formValues={values}
              mode={"Edit"}
              setSnackbar={props.setSnackbar}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditDocumentFormView);
