import React from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Button,
  Card,
  makeStyles,
  InputAdornment,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import { chkRoutePerm } from "src/services/Helper";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const swal = withReactContent(Swal);

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const Results = ({
  className,
  users,
  pageLoading,
  fetchUser,
  deleteUsers,
  multipleDelete,
  ...rest
}) => {
  const classes = useStyles();
  const responsive = "standard";
  const chkRoutPerm = chkRoutePerm("/app/users/edit", rest.authuser);
  const chkDelRoutPerm = chkRoutePerm("/app/users/delete", rest.authuser);
  const options = {
    filter: false,
    filterType: "dropdown",
    responsive,
    onRowsDelete: async (rowsDeleted) => {
      swal
        .fire({
          title: "Are you sure?",
          text: "You want to delete these contact(s)!",
          input: "checkbox",
          inputPlaceholder: "Delete the user's account additionally?",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "No",
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.value === 1) {
            const idsToDelete = rowsDeleted.data.map(
              (d) => users[d.dataIndex].id
            );
            multipleDelete(idsToDelete, 1);
          } else if (result.value === 0) {
            const idsToDelete = rowsDeleted.data.map(
              (d) => users[d.dataIndex].id
            );
            multipleDelete(idsToDelete, 0);
          } else {
            pageLoading(true);
            fetchUser();
          }
        });
    },
    rowsPerPageOptions: [],
    rowsPerPage: 100,
    tableBodyMaxHeight: "740px",
    // rowsPerPageOptions: [10, 25, 50, 100, 500],
    downloadOptions: { filename: "Users.csv" },
    isRowSelectable: () => {
      return chkDelRoutPerm ? true : false;
    },
  };

  const columns = [
    {
      name: "designation_name",
      label: "ROLE",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => value || "—",
      },
    },
    {
      name: "full_name",
      label: "NAME",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => value || "—",
      },
    },
    // {
    //   name: "first_name",
    //   label: "First Name",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     customBodyRender: (value) => value || "—",
    //   },
    // },
    // {
    //   name: "last_name",
    //   label: "Last Name",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     customBodyRender: (value) => value || "—",
    //   },
    // },
    {
      name: "company_name",
      label: "Company",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => value || "—",
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => value || "—",
      },
    },
    {
      name: "mobile",
      label: "Phone",
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) =>
          users[dataIndex].fax.length === 0 ? (
            "—"
          ) : (
            <InputAdornment position="start">
              {users[dataIndex].mobile}
            </InputAdornment>
          ),
      },
    },
    {
      name: "fax",
      label: "Fax",
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) =>
          users[dataIndex].fax.length === 0 ? (
            "—"
          ) : (
            <InputAdornment>
              {users[dataIndex].fax.length > 6 ? "+ " : ""}
              {users[dataIndex].fax.length > 6 ? users[dataIndex].fax : ""}
            </InputAdornment>
          ),
      },
    },

    {
      name: "address",
      label: "MAILING ADDRESS",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => value || "—",
      },
    },
    {
      name: "notes",
      label: "NOTES",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => value || "—",
      },
    },

    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        download: false,
        print: false,
        // viewColumns:'disabled',
        display: chkRoutPerm ? true : false,
        customBodyRenderLite: (dataIndex) =>
          chkRoutPerm ? (
            <Tooltip disableFocusListener title="Edit">
              <Button
                color="primary"
                component={RouterLink}
                size="small"
                to={"/app/contacts/" + users[dataIndex].id}
              >
                <EditOutlinedIcon />
              </Button>
            </Tooltip>
          ) : (
            ""
          ),
      },
    },

    // {
    //   name: "is_front_user",
    //   label: "Is Front User?",
    //   options: {
    //     filter: false,
    //     sort: false,
    //   },
    // },
  ];

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <MUIDataTable
          className="custom-mui-table-main"
            title=""
            data={users}
            columns={columns}
            options={options}
          />
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired,
};

export default Results;
