import React, { useState, useEffect } from "react";
import { Box, Container, makeStyles } from "@material-ui/core";
import Page from "src/components/Page";
import Results from "./Results";
import Toolbar from "./Toolbar";
import * as Http from "src/services/Http";
import { connect, useSelector } from "react-redux";
import ScreenLoader from "src/components/ScreenLoader";
import { setSnackbar } from "src/actions";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";

const breadUrlList = [
  { name: "Home", url: "/dashboard" },
  { name: "Role List", url: "/" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const RoleListView = (props) => {
  const classes = useStyles();

  const [roleList, setRoleList] = useState({
    id: "Loading",
    name: "Loading",
  });
  const [Loading, setLoading] = useState(true);
  const authuser = useSelector((state) => state?.session?.user);

  const fetchRoleList = async () => {
    try {
      const response = await Http.call("GET", "admin/roles");
      setRoleList(response.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const deleteRoles = async (data) => {
    setLoading(true);
    try {
      await Http.call("POST", "admin/roles/delete", { id: data }).then(
        async (response) => {
          fetchRoleList();
          props.setSnackbar(true, response.status, response.message);
        }
      );
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchRoleList();
  }, []);

  if (Loading) {
    return <ScreenLoader />;
  }
  return (
    <Page className={classes.root} title="Roles">
      <Container maxWidth={false}>
        <BreadcrumbsAdmin urlList={breadUrlList} />
        <Toolbar authuser={authuser} />
        <Box mt={3}>
          <Results
            roles={roleList}
            pageLoading={setLoading}
            fetchRoles={fetchRoleList}
            deleteRoles={deleteRoles}
            authuser={authuser}
          />
        </Box>
      </Container>
    </Page>
  );
};

//export default UserListView;
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(RoleListView);
