import React from "react";
import { Box, Typography } from "@material-ui/core";

const Toolbar = ({ className, ...rest }) => {
  return (
    <div {...rest}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h3" component="span">
          My Account
        </Typography>
      </Box>
    </div>
  );
};

export default Toolbar;
