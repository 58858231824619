import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from "@material-ui/core";
import Cancelbtn from "src/components/common/Cancelbtn";
import * as Messages from "src/constants/Messages/index";
import "react-phone-input-2/lib/material.css";
import CustomPhoneInput from "src/components/common/CustomPhoneInput";

/*const mobileNoFormate = (arg) => {
  let cleaned = ("" + arg).replace(/\D/g, "");
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
};*/

//const phoneRegExp = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;

const SignupSchema = Yup.object().shape({
  first_name: Yup.string()
    // .min(2, Messages.TOO_SHORT)
    // .matches(/^[aA-zZ\s]+$/, Messages.ONLY_ALPABATES)
    .required(Messages.REQUIRED),
  last_name: Yup.string()
    // .min(2, Messages.TOO_SHORT)
    // .matches(/^[aA-zZ\s]+$/, Messages.ONLY_ALPABATES)
    .required(Messages.REQUIRED),
  email: Yup.string()
    .lowercase()
    .email(Messages.INVALID_EMAIL)
    .required(Messages.REQUIRED),
  mobile: Yup.string().required(Messages.REQUIRED),
});

const ProfileDetails = (props) => {
  const [checkPhoneNumber, setCheckPhoneNumber] = useState(0);
  const { formValues, updateProfile } = props;
  const initialValues = {
    first_name: formValues.first_name,
    last_name: formValues.last_name,
    email: formValues.email,
    mobile: formValues.mobile,
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SignupSchema}
      //onSubmit={updateProfile}
      onSubmit={async (values, actions) => {
        /*values.mobile = values.mobile
          .replace(" ", "")
          .replace("-", "")
          .replace("(", "")
          .replace(")", "");*/

        if (checkPhoneNumber !== 1) {
          await updateProfile(values, actions);
          actions.setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
        setFieldError,
      }) => (
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Card>
            <CardHeader title="Profile" />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    required
                    name="first_name"
                    label="First Name"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.first_name}
                    error={Boolean(touched.first_name && errors.first_name)}
                    margin="normal"
                    helperText={touched.first_name && errors.first_name}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Last Name"
                    name="last_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.last_name}
                    required
                    margin="normal"
                    variant="outlined"
                    error={Boolean(touched.last_name && errors.last_name)}
                    helperText={touched.last_name && errors.last_name}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Email"
                    margin="normal"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    required
                    type="email"
                    variant="outlined"
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomPhoneInput
                    name={"mobile"}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    setCheckPhoneNumber={setCheckPhoneNumber}
                    //setFieldError={setFieldError}
                  />
                </Grid>
                {/* <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Country"
                    name="country"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.country}
                    required
                    margin="normal"
                    variant="outlined"
                    error={Boolean(touched.country && errors.country)}
                    fullWidth
                    helperText={touched.country && errors.country}
                  />
                </Grid> */}
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Cancelbtn />
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={isSubmitting}
              >
                Save
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default ProfileDetails;
