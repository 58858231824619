import React from "react";
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
  FormGroup,
  FormHelperText,
  //  Button
} from "@mui/material";
import { FieldArray, useField } from "formik";

export default function CheckBoxField({ label, name, options, ...props }) {
  const [field, meta, helpers] = useField(name);

  const { value } = meta;

  const { setValue } = helpers;

  return (
    <FormControl component="fieldset">
      <FieldArray
        name={field.name}
        render={(arrayHelpers) => (
          <FormGroup>
            {options.map((option, index) => {
              return (
                <FormControlLabel
                  className="custom-radio-btn"
                  key={index}
                  control={
                    <Checkbox
                      name={`${name}[${index}]`}
                      value={option.id}
                      checked={value.includes(option.id)}
                      onChange={() => {
                        if (!value.includes(option.id)) {
                          arrayHelpers.push(option.id);
                        } else {
                          arrayHelpers.remove(value.indexOf(option.id));
                        }
                      }}
                    />
                  }
                  label={option.answer}
                />
              );
            })}
          </FormGroup>
        )}
      />

      <FormHelperText>
        {meta.touched && meta.error ? meta.error : undefined}
      </FormHelperText>
    </FormControl>
  );
}
