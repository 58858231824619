import React from "react";

const Logo = (props) => {
  return (
    <img
      style={{ width: "auto", height: "40px" }}
      alt="Logo"
      // src={process.env.PUBLIC_URL + "/img/logo/HF-WG-logo.png"}
      src={process.env.PUBLIC_URL + "/img/logo/Fernwood_logo.png"}
      {...props}
      className="logo_main"
    />
  );
};

export default Logo;
