export const FETCH_REQUEST_FOR_GW_QUESTIONS = "FETCH_REQUEST_FOR_GW_QUESTIONS";
export const FETCH_SUCCESS_FOR_GW_QUESTIONS = "FETCH_SUCCESS_FOR_GW_QUESTIONS";
export const FETCH_FAILD_FOR_GW_QUESTIONS = "FETCH_FAILD_FOR_GW_QUESTIONS";
export const SET_GW_QUESTION_LOADING = "SET_GW_QUESTION_LOADING";
export const SET_GW_PAGE_NUMBER = "SET_GW_PAGE_NUMBER";
export const SET_GW_PAGE_LIMIT = "SET_GW_PAGE_LIMIT";
export const SET_GW_SEARCH_TEXT = "SET_GW_SEARCH_TEXT";

export const setGwTableData = (data) => (dispatch) => {
  dispatch({
    type: FETCH_SUCCESS_FOR_GW_QUESTIONS,
    payload: {
      data: data,
    },
  });
};
export const setGwTableLoading = (arg) => (dispatch) => {
  dispatch({
    type: SET_GW_QUESTION_LOADING,
    payload: {
      loading: arg,
    },
  });
};
export const setGwPageNumber = (pageno) => (dispatch) => {
  dispatch({
    type: SET_GW_PAGE_NUMBER,
    payload: {
      page_no: pageno,
    },
  });
};
export const setGwPageLimit = (pagelimit) => (dispatch) => {
  dispatch({
    type: SET_GW_PAGE_LIMIT,
    payload: {
      page_limit: pagelimit,
    },
  });
};
