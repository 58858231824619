import * as React from "react";
import { TextField } from "@mui/material";
import { useField } from "formik";
const TextBoxField = ({ name, label, autoFocus, ...props }) => {
  const [field, meta] = useField(name);
  return (
    <TextField
      label={label}
      variant="standard"
      margin="normal"
      autoFocus={autoFocus ? autoFocus : false}
      autoComplete="off"
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      {...field}
      {...props}
    />
  );
};
export default TextBoxField;
