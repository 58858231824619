import React from "react";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";
import { Box, CardContent, useTheme, colors } from "@material-ui/core";

const Barchart = ({ chart_data, className, ...rest }) => {
  const theme = useTheme();

  const data = {
    datasets: [
      {
        // backgroundColor: colors.indigo[500],
        backgroundColor: "#22696d",
        data:
          chart_data.total_revenue !== undefined
            ? chart_data.total_revenue
            : "",
        label: "Total Revenue",
      },
    ],
    labels: chart_data.labels !== undefined ? chart_data.labels : "",
  };

  const options = {
    animations: {
      tension: {
        duration: 1000,
        easing: "linear",
        from: 1,
        to: 0,
        loop: true,
      },
    },
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: {
      display: false,
      visible: false,
      labels: {
        boxWidth: 10,
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          barThickness: 12,
          maxBarThickness: 10,
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          ticks: {
            fontColor: theme.palette.text.secondary,
          },
          gridLines: {
            display: false,
            drawBorder: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0,
            callback: function(value, index, values) {
              return `$${value}`;
            },
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider,
          },
        },
      ],
    },
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: true,
      mode: "index",
      callbacks: {
        label: function(tooltipItem, data) {
          var indice = tooltipItem.index;
          return (
            data.datasets[tooltipItem.datasetIndex].label +
            ": " +
            "$" +
            data.datasets[0].data[indice]
          );
        },
      },
      titleFontColor: theme.palette.text.primary,
    },
  };

  return (
    <CardContent>
      <Box height={400} position="relative">
        {chart_data.total_revenue !== undefined &&
        chart_data.labels !== undefined ? (
          <Bar data={data} options={options} />
        ) : (
          "Data not found."
        )}
      </Box>
    </CardContent>
  );
};

Barchart.propTypes = {
  className: PropTypes.string,
};

export default Barchart;
