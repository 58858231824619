import React from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Box, Button, Card, makeStyles } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import { chkRoutePerm } from "src/services/Helper";

const swal = withReactContent(Swal);

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const Results = ({
  className,
  roles,
  pageLoading,
  fetchRoles,
  deleteRoles,
  ...rest
}) => {
  const classes = useStyles();
  const responsive = "standard";
  const chkEditRoutPerm = chkRoutePerm("/app/roles/edit", rest.authuser);
  const chkDelRoutPerm = chkRoutePerm("/app/roles/delete", rest.authuser);

  const options = {
    filter: false,
    filterType: "dropdown",
    responsive,
    onRowsDelete: (rowsDeleted) => {
      swal
        .fire({
          type: "question",
          title: "Are you sure?",
          text: "You want to delete these role(s)!",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "No",
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.value) {
            let idsToDelete = rowsDeleted.data.map(
              (d) => roles[d.dataIndex].id
            );
            idsToDelete = idsToDelete.join(",");
            deleteRoles(idsToDelete);
          } else {
            pageLoading(true);
            fetchRoles();
          }
        });
    },
    rowsPerPageOptions: [],
    rowsPerPage: 100,
    tableBodyMaxHeight: "740px",
    // rowsPerPageOptions: [10, 25, 50, 100, 500],
    selectableRowsHideCheckboxes: chkDelRoutPerm ? false : true,
    isRowSelectable: (dataIndex) => {
      return roles[dataIndex].id !== "1";
    },
  };

  const columns = [
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        display: chkEditRoutPerm ? true : false,
        viewColumns: false,
        customBodyRenderLite: (dataIndex) =>
          roles[dataIndex].id !== 1 ? (
            <Tooltip disableFocusListener title="Edit">
              <Button
                color="primary"
                component={RouterLink}
                size="small"
                to={"/app/roles/" + roles[dataIndex].id}
              >
                <EditOutlinedIcon />
              </Button>
            </Tooltip>
          ) : (
            ""
          ),
      },
    },
    {
      name: "name",
      label: "Role Title",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "created_at",
      label: "Registration Date",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) =>
          moment(roles[dataIndex].created_at).format("MM/DD/YYYY"),
      },
    },
  ];

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <MUIDataTable
            title=""
            data={roles}
            columns={columns}
            options={options}
          />
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  roles: PropTypes.array.isRequired,
};

export default Results;
