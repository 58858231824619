import React from "react";
// import { makeStyles } from "@mui/styles";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
} from "@mui/material";
import { useField } from "formik";

// const useStyles = makeStyles({
//   customradioroot: {
//     border: "1px solid #bbb9b9",
//     width: "280px",
//     padding: "8px",
//     marginBottom: "8px",
//     borderRadius: "4px",
//   },
// });

export default function RadioBox({
  label,
  options,
  name,
  disabled,
  onSubmitForm,
  ...props
}) {
  const [field, meta, helpers] = useField(name);
  // const classes = useStyles();
  const configRadioProps = {
    ...field,
    ...props,
  };
  // console.log(configRadioProps);
  return (
    <FormControl component="fieldset">
      <RadioGroup
        aria-label={label}
        // onChange={(value) => {
        //   console.log(value);
        //   helpers.setValue(value);
        // }}

        {...configRadioProps}
      >
        {options.map((option, index) => (
          <FormControlLabel
            className="custom-radio-btn"
            key={index}
            value={option.id}
            control={<Radio style={{ padding: "9px" }} />}
            label={option.answer}
            disabled={disabled}
            style={{ paddingTop: 5, paddingBottom: 4 }}
            onChange={async (e) => {
              // console.log(e.target.value, "e");
              // handleChange(e.target.value);
              helpers.setValue(e.target.value);
              onSubmitForm();
            }}
            onClick={(e) => {
              // console.log(e.target.value, "e");
              // handleChange(e.target.value);
              helpers.setValue(option.id);
              onSubmitForm();
            }}
          />
        ))}
      </RadioGroup>
      <FormHelperText error>
        {meta.touched && meta.error ? meta.error : undefined}
      </FormHelperText>
    </FormControl>
  );
}
