import {
  Box,
  Button,
  Card,
  FormControlLabel,
  Grid,
  Switch,
} from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import MUIDataTable from "mui-datatables";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import { chkRoutePerm } from "src/services/Helper";

const DiscountTable = ({ data, deleteDiscount, ...rest }) => {
  const responsive = "standard";
  const chkEditRoutPerm = chkRoutePerm("/app/discount/edit", rest.authuser);
  const chkDelRoutPerm = chkRoutePerm("/app/discount/delete", rest.authuser);
  const options = {
    filter: false,
    filterType: "dropdown",
    responsive,
    onRowsDelete: async (rowsDeleted) => {
      const idsToDelete = rowsDeleted.data.map((d) => data[d.dataIndex].id);
      const titlesToDelete = rowsDeleted.data.map(
        (d) => data[d.dataIndex].title
      );
      deleteDiscount(idsToDelete, titlesToDelete);
    },
    rowsPerPageOptions: [],
    rowsPerPage: 100,
    tableBodyMaxHeight: "740px",
    // rowsPerPageOptions: [10, 25, 50, 100, 500],
    download: false,
    print: false,
    downloadOptions: { filename: "Discounts.csv" },
    isRowSelectable: () => {
      return chkDelRoutPerm ? true : false;
    },
  };

  const columns = [
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        download: false,
        print: false,
        display: chkEditRoutPerm ? true : false,
        viewColumns: false,
        customBodyRenderLite: (dataIndex) => (
          <Tooltip disableFocusListener title="Edit">
            <Button
              color="primary"
              component={RouterLink}
              size="small"
              to={"/app/discount/" + data[dataIndex].id}
            >
              <EditOutlinedIcon />
            </Button>
          </Tooltip>
        ),
      },
    },
    {
      name: "title",
      label: "Title",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "promocode",
      label: "Service Discount",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "percentage",
      label: "Percentage",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "start_date",
      label: "Start Date",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) =>
          moment(data[dataIndex].start_date).format("MM/DD/YYYY"),
      },
    },
    {
      name: "end_date",
      label: "Expiration Date",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) =>
          moment(data[dataIndex].end_date).format("MM/DD/YYYY"),
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => (
          // <p></p>
          <Grid>
            <FormControlLabel
              control={
                <Switch
                  fullWidth
                  // label="Status"
                  name="status"
                  checked={data[dataIndex].status === 0 ? 0 : 1}
                  onChange={(e) => {
                    if (e.target.checked) {
                      rest.setDiscountData(
                        [...data],
                        ([...data][dataIndex].status = 1)
                      );
                    } else {
                      rest.setDiscountData(
                        [...data],
                        ([...data][dataIndex].status = 0)
                      );
                    }
                    rest.changeStatus({
                      id: data[dataIndex].id,
                      status: data[dataIndex].status,
                    });
                  }}
                  color="primary"
                />
              }
              labelPlacement="end"
              label={data[dataIndex].status === 0 ? "Inactive" : "Active"}
            />
          </Grid>
        ),
      },
    },
  ];
  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <MUIDataTable
            title={""}
            data={data}
            columns={columns}
            options={options}
          />
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DiscountTable);
