import React from "react";
import { Box, Typography } from "@material-ui/core";

const Toolbar = ({ className, ...rest }) => {
  return (
    <div {...rest}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={3}
      >
        <Typography variant="h3" component="span">
          Services
        </Typography>
        
      </Box>
    </div>
  );
};

export default Toolbar;
