import { Formik } from "formik";
import React, { useState, useEffect } from "react";
import { usePaymentInputs } from "react-payment-inputs";
import { connect } from "react-redux";
import * as Yup from "yup";
import * as Messages from "src/constants/Messages/index";
import { RadioGroup } from "@material-ui/core";
import { Radio } from "@material-ui/core";
import images from "react-payment-inputs/images";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Http from "src/services/Http";
import ScreenLoader from "src/components/ScreenLoader";
import ThankYouView from "src/views/Counsellor/AddApplicationView/PaymentView/ThankYouView";
import "src/styles/custom.css";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Page from "src/components/Page";

const validationSchema = Yup.object().shape({
  holderName: Yup.string().required(Messages.REQUIRED),
});

const validateForm = (values, meta) => {
  let errors = {};
  if (values.holderName === "") {
    errors.holderName = Messages.REQUIRED;
  }
  if (meta.erroredInputs.cardNumber) {
    errors.cardNumber = meta.erroredInputs.cardNumber;
  }
  if (meta.erroredInputs.expiryDate) {
    errors.expiryDate = meta.erroredInputs.expiryDate;
  }
  if (meta.erroredInputs.cvc) {
    errors.cvc = meta.erroredInputs.cvc;
  }
  return errors;
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
  },
}));

const ExtraMailesPaymentView = (props) => {
  let is_Preneed = "";
  let user_id = "";
  const { id } = useParams();
  const navigate = useNavigate();
  const classes = useStyles();

  if (id === undefined) {
    is_Preneed = localStorage.getItem("isPreneed");
    user_id = JSON.parse(localStorage.getItem("rand_token"));
  } else {
    user_id = id;
    is_Preneed = localStorage.getItem("isPreneed_" + id);
  }

  const [isAgree, setisAgree] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [extraMailesData, setExtraMailesData] = useState({});
  const {
    meta,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs();
  const { erroredInputs, touchedInputs } = meta;

  const handleChangeChk = (arg) => {
    setisAgree(arg.target.checked);
  };

  const getExtraMailesCharges = async () => {
    setLoading(true);
    try {
      const response = await Http.call("GET", `extra_miles/${user_id}`);
      console.log(response);
      setExtraMailesData(response.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const makePayment = async (PaymentInfo) => {
    setLoading(true);

    let body = {
      card_number: parseInt(PaymentInfo.cardNumber.replace(/ /g, ""), 10),
      card_name: PaymentInfo.holderName,
      card_expiry: PaymentInfo.expiryDate.replace(/ /g, ""),
      card_cvv: parseInt(PaymentInfo.cvc),
      user_id: user_id,
      flag: "admin",
      amount_per_extra_mile: parseFloat(
        extraMailesData.amount_per_extra_mile
      ).toFixed(2),
      destination_address_id: extraMailesData.destination_address_id,
      source_address_id: extraMailesData.source_address_id,
      total_amount_to_be_paid: parseFloat(
        extraMailesData.total_amount_to_be_paid
      ).toFixed(2),
      total_mile_count: parseFloat(extraMailesData.total_mile_count).toFixed(2),
    };
    await Http.call("POST", "extra_miles", body).then((response) => {
      if (response.status === 200) {
        setLoading(false);
        // props.setdisableTab([
        //   "step2",
        //   "step3",
        //   "step4",
        //   parseInt(is_Preneed) === 1 ? "" : "step6",
        //   "step7",
        // ]);

        setRedirect(true);
      } else {
        alert(response.message);
      }
    });
    setLoading(false);
  };

  useEffect(() => {
    getExtraMailesCharges();
  }, []);

  if (isLoading) {
    return <ScreenLoader />;
  }

  if (redirect) {
    return (
      <ThankYouView
        setTabval={props.setTabval}
        setdisableTab={props.setdisableTab}
        paymentFlag={"extra_mailes"}
      />
    );
  }

  return (
    <Page title="Service Area Calculation" className={classes.root}>
      <Card>
        <CardHeader title="" />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <h2>Enter Payment Detail Below</h2>
              <Typography paragraph>
                Reference site about Lorem Ipsum, giving information on its
                origins, as well as a random Lipsum generator.Reference site
                about Lorem Ipsum, giving information on its origins, as well as
                a random Lipsum generator. Reference site about Lorem Ipsum,
                giving information on its origins, as well as a random Lipsum
                generator.Reference site about Lorem Ipsum, giving information
                on its origins, as well as a random Lipsum generator.
              </Typography>
            </Grid>
            <Formik
              initialValues={{
                holderName: "",
                cardNumber: "",
                expiryDate: "",
                cvc: "",
              }}
              onSubmit={(values) => makePayment(values)}
              validationSchema={validationSchema}
              validate={(e) => validateForm(e, meta)}
            >
              {({
                values,
                errors,
                touched,
                isValid,
                dirty,
                handleBlur,
                handleChange,
                handleSubmit,
                validateForm,
                setErrors,
              }) => {
                //console.log(values);
                return (
                  <form autoComplete="off" onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                      <Grid item md={6}>
                        <Card>
                          <CardHeader title="Payment Method" />
                          <CardContent>
                            <Grid container spacing={3}>
                              <Grid item md={12} xs={12}>
                                <div className="Credit-card">
                                  <RadioGroup
                                    aria-label="gender"
                                    name="gender1"
                                    value={"ture"}
                                    onChange={handleChange}
                                  >
                                    <FormControlLabel
                                      value="ture"
                                      control={<Radio />}
                                      label="Credit Card"
                                    />
                                  </RadioGroup>
                                  <svg {...getCardImageProps({ images })} />
                                  {/* <img src="img/payment1.png" class="pcard" /> */}
                                </div>
                              </Grid>
                              <Grid item md={12} xs={12}>
                                <TextField
                                  fullWidth
                                  label="Card holder Name"
                                  name="holderName"
                                  value={values.holderName}
                                  type="text"
                                  margin="normal"
                                  variant="outlined"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  error={Boolean(
                                    touched.holderName && errors.holderName
                                  )}
                                  helperText={
                                    touched.holderName && errors.holderName
                                  }
                                />
                              </Grid>
                              <Grid item md={12} xs={12}>
                                <TextField
                                  inputProps={getCardNumberProps({
                                    onBlur: handleBlur,
                                    onChange: handleChange,
                                  })}
                                  isInvalid={
                                    (touchedInputs.cardNumber &&
                                      erroredInputs.cardNumber) ||
                                    (touched.cardNumber && errors.cardNumber)
                                  }
                                  fullWidth
                                  label="Card Number"
                                  name="cardNumber"
                                  value={values.cardNumber}
                                  type="text"
                                  margin="normal"
                                  variant="outlined"
                                  error={Boolean(
                                    (touchedInputs.cardNumber &&
                                      erroredInputs.cardNumber) ||
                                      (touched.cardNumber && errors.cardNumber)
                                  )}
                                  helperText={
                                    Boolean(
                                      (touchedInputs.cardNumber &&
                                        erroredInputs.cardNumber) ||
                                        (touched.cardNumber &&
                                          errors.cardNumber)
                                    )
                                      ? erroredInputs.cardNumber
                                      : ""
                                  }
                                />
                              </Grid>
                              <Grid item md={12} xs={12}>
                                <TextField
                                  inputProps={getExpiryDateProps({
                                    onBlur: handleBlur,
                                    onChange: handleChange,
                                  })}
                                  fullWidth
                                  value={values.expiryDate}
                                  name="expiryDate"
                                  label="Expiry Date"
                                  type="text"
                                  margin="normal"
                                  variant="outlined"
                                  error={Boolean(
                                    (touchedInputs.expiryDate &&
                                      erroredInputs.expiryDate) ||
                                      (touched.expiryDate && errors.expiryDate)
                                  )}
                                  helperText={
                                    Boolean(
                                      (touchedInputs.expiryDate &&
                                        erroredInputs.expiryDate) ||
                                        (touched.expiryDate &&
                                          errors.expiryDate)
                                    )
                                      ? erroredInputs.expiryDate
                                      : ""
                                  }
                                />
                              </Grid>
                              <Grid item md={12} xs={12}>
                                <TextField
                                  fullWidth
                                  inputProps={getCVCProps({
                                    onBlur: handleBlur,
                                    onChange: handleChange,
                                  })}
                                  label="CVV Code"
                                  value={values.cvc}
                                  name="cvc"
                                  type="text"
                                  margin="normal"
                                  variant="outlined"
                                  error={Boolean(
                                    (touchedInputs.cvc && erroredInputs.cvc) ||
                                      (touched.cvc && errors.cvc)
                                  )}
                                  helperText={
                                    Boolean(
                                      (touchedInputs.cvc &&
                                        erroredInputs.cvc) ||
                                        (touched.cvc && errors.cvc)
                                    )
                                      ? erroredInputs.cvc
                                      : ""
                                  }
                                />
                              </Grid>
                              <Grid item md={12} xs={12}>
                                <TextField
                                  fullWidth
                                  label="Zip"
                                  name="Zip"
                                  value={values.Zip}
                                  type="text"
                                  margin="normal"
                                  variant="outlined"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                />
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item md={6}>
                        <Card>
                          <CardHeader title="Extra Miles Summary" />
                          <CardContent>
                            <Grid container spacing={3}>
                              <Grid
                                item
                                md={12}
                                xs={12}
                                className="order-total"
                              >
                                <ul>
                                  <li>
                                    <p className="total-label">
                                      Extra Miles Count:
                                    </p>
                                    <p className="total-price">
                                      {parseFloat(
                                        extraMailesData.total_mile_count
                                      ).toFixed(2)}
                                    </p>
                                  </li>
                                  <li>
                                    <p className="total-label">
                                      Per Extra Miles Amount:
                                    </p>
                                    <p className="total-price">
                                      $
                                      {parseFloat(
                                        extraMailesData.amount_per_extra_mile
                                      ).toFixed(2)}
                                    </p>
                                  </li>
                                  <li>
                                    <p className="total-label">Total:</p>
                                    <p className="total-price">
                                      $
                                      {parseFloat(
                                        extraMailesData.total_amount_to_be_paid
                                      ).toFixed(2)}
                                    </p>
                                  </li>
                                </ul>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <Box display="flex" justifyContent="center">
                          <FormControlLabel
                            control={
                              <Checkbox
                                defaultChecked={isAgree}
                                onChange={handleChangeChk}
                                color="primary"
                              />
                            }
                            label={
                              <>
                                I agree to Fernwood{" "}
                                <span className="purple">
                                  {" "}
                                  <Link 
                                    className="gold paymentlink"
                                    target="_blank"
                                    // to={"//hollywoodforever.com/terms-and-conditions/"}
                                    to={
                                      "https://www.fernwood.com/terms-of-service"
                                    }
                                  >
                                    Terms of Service
                                  </Link>{" "}
                                </span>{" "}
                                and{" "}
                                <span className="purple">
                                  <Link
                                    className="gold paymentlink"
                                    target="_blank"
                                    // to={"//hollywoodforever.com/privacy-policy/"}
                                    to={
                                      "https://www.fernwood.com/privacy-policy"
                                    }
                                  >
                                    Privacy Policy.
                                  </Link>{" "}
                                </span>{" "}
                              </>
                            }
                          />
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          mt={2}
                          pr={2}
                          pl={2}
                          pb={2}
                        >
                          <Button
                            type="text"
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              props.setTabval("step6");
                            }}
                          >
                            Back
                          </Button>
                          <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            disabled={!isValid || !isAgree || !dirty}
                          >
                            Make Payment
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </form>
                );
              }}
            </Formik>
          </Grid>
        </CardContent>
      </Card>
    </Page>
  );
};

export default ExtraMailesPaymentView;
