import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Grid, makeStyles } from "@material-ui/core";
import Page from "src/components/Page";
import * as Http from "src/services/Http";
import ScreenLoader from "src/components/ScreenLoader";
import { setSnackbar } from "src/actions";
import { connect } from "react-redux";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";
/*import Toolbar from "./Toolbar";*/
import EditCategoryDetail from "./CategoryDetail";

const breadUrlList = [
  { name: "Home", url: "/dashboard" },
  { name: "Categories List", url: "/app/categories" },
  { name: "Edit Category", url: "/" },
];
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  categoryDetail: {
    marginTop: theme.spacing(3),
  },
}));

const EditCategoryView = (props) => {
  const { id } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const [Loading, setLoading] = useState(true);
  const [values, setValues] = useState({
    name: "",
    description: "",
    selection_type: "",
  });

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await Http.call(
          "GET",
          "admin/addons_categories/" + id
        );
        setValues(response.data);
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    };

    fetchCategories();
  }, [id]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const updateCategory = async (data, actions) => {
    await Http.call("PUT", "admin/addons_categories/" + id, {
      ...data,
      _method: "PUT",
    }).then(async (response) => {
      if (response.status === 200) {
        props.setSnackbar(true, response.status, response.message);
        navigate("/app/categories");
      } else {
        actions.setErrors(response.data);
      }
    });
  };

  if (Loading) {
    return <ScreenLoader />;
  }
  return (
    <Page className={classes.root} title="Edit Category">
      <Container maxWidth="lg">
        <BreadcrumbsAdmin urlList={breadUrlList} />

        <Grid container spacing={3} className={classes.categoryDetail}>
          <Grid item lg={12} md={12} xs={12}>
            <EditCategoryDetail
              formValues={values}
              handleChange={handleChange}
              updateCategory={updateCategory}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCategoryView);
