import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormHelperText,
  Grid,
  TextField,
} from "@material-ui/core";
import Cancelbtn from "src/components/common/Cancelbtn";
import "react-phone-input-2/lib/material.css";
import CustomPhoneInput from "src/components/common/CustomPhoneInput";
import * as Messages from "src/constants/Messages/index";
import { ColorPicker } from "material-ui-color";

const onlyAlphaAllowRegExp = /^[A-Za-z ]*$/;

const AddUserSchema = Yup.object().shape({
  first_name: Yup.string()
    // .min(2, Messages.TOO_SHORT)
    // .matches(onlyAlphaAllowRegExp, Messages.ONLY_ALPABATES)
    .required(Messages.REQUIRED),
  last_name: Yup.string()
    // .min(2, Messages.TOO_SHORT)
    // .matches(onlyAlphaAllowRegExp, Messages.ONLY_ALPABATES)
    .required(Messages.REQUIRED),
  email: Yup.string()
    .lowercase()
    .email(Messages.INVALID_EMAIL)
    .required(Messages.REQUIRED),
  mobile: Yup.string()
    .required(Messages.REQUIRED)
    .min(5, Messages.REQUIRED),
  business_name: Yup.string().required(Messages.FIELD_REQUIRED),
  button_color: Yup.string().required(Messages.FIELD_REQUIRED),
  domain_name: Yup.string().required(Messages.FIELD_REQUIRED),
});
const ClientForm = (props) => {
  const { id, formValues, updateUser } = props;
  const [checkPhoneNumber, setCheckPhoneNumber] = useState(0);
  const initialValues = {
    first_name: formValues.first_name,
    last_name: formValues.last_name,
    email: formValues.email,
    mobile: formValues.mobile,
    fax: formValues.fax,
    domain_name: formValues.domain_name,
    business_name: formValues.business_name,
    button_color: formValues.button_color,
    token: formValues.token,
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={AddUserSchema}
      onSubmit={async (values, actions) => {
        if (checkPhoneNumber !== 1) {
          await updateUser(values, actions);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => {
        return (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Card>
              <CardHeader
                title={
                  id !== undefined
                    ? "Edit Activation Token"
                    : "Add Activation Token"
                }
              />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      name="first_name"
                      label="First Name"
                      variant="outlined"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.first_name}
                      error={Boolean(touched.first_name && errors.first_name)}
                      margin="normal"
                      helperText={touched.first_name && errors.first_name}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Last Name"
                      name="last_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.last_name}
                      margin="normal"
                      variant="outlined"
                      error={Boolean(touched.last_name && errors.last_name)}
                      helperText={touched.last_name && errors.last_name}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Email"
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      type="email"
                      variant="outlined"
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <CustomPhoneInput
                      name={"mobile"}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      setCheckPhoneNumber={setCheckPhoneNumber}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      name="business_name"
                      label="Business name"
                      variant="outlined"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.business_name}
                      error={Boolean(
                        touched.business_name && errors.business_name
                      )}
                      margin="normal"
                      helperText={touched.business_name && errors.business_name}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      name="domain_name"
                      label="Website name"
                      variant="outlined"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.domain_name}
                      error={Boolean(touched.domain_name && errors.domain_name)}
                      margin="normal"
                      helperText={touched.domain_name && errors.domain_name}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <ColorPicker
                      deferred
                      disableTextfield
                      openAtStart={false}
                      defaultValue="#ffffff"
                      name="button_color"
                      value={values.button_color}
                      error={Boolean(
                        touched.button_color && errors.button_color
                      )}
                      onChange={(color) => {
                        color.hex !== undefined
                          ? setFieldValue("button_color", "#" + color.hex)
                          : setFieldValue("button_color", color);
                      }}
                    />
                    {errors.button_color && touched.button_color && (
                      <FormHelperText className="Mui-error">
                        {errors.button_color}
                      </FormHelperText>
                    )}
                  </Grid>
                  {id !== undefined && (
                    <Grid item md={12} xs={12}>
                      <TextField
                        fullWidth
                        name="token"
                        label="Token"
                        variant="outlined"
                        value={values.token}
                        margin="normal"
                        disabled={true}
                      />
                    </Grid>
                  )}
                </Grid>
              </CardContent>
              <Divider />
              <Box display="flex" justifyContent="flex-end" p={2}>
                <Cancelbtn />
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </Box>
            </Card>
          </form>
        );
      }}
    </Formik>
  );
};

export default ClientForm;
