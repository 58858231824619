import React, { useState, useEffect } from "react";
import { Container, Grid, Card, makeStyles } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import Page from "src/components/Page";
import StatusDetails from "../Components/StatusDetails";
import Toolbar from "../Components/Toolbar";
import * as Http from "src/services/Http";
import { setSnackbar } from "src/actions";
import { connect } from "react-redux";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";

const breadUrlList = [
  { name: "Home", url: "/dashboard" },
  { name: "Status List", url: "/app/status" },
  { name: "Add Status", url: "/" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  QuesDetail: {
    marginTop: theme.spacing(3),
  },
}));

const AddStatusView = (props) => {
  const classes = useStyles();
  const [Loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    name: "",
    auto_status: 0,
    on_status: 0,
    on_send_type: 0,
    on_email_template: "",
    on_position_id: [],
    off_status: 0,
    off_send_type: 0,
    off_email_template: "",
    off_position_id: [],
    is_compulsory: 0,
    on_form_id: [],
    off_form_id: [],
    on_fax_message: "",
    off_fax_message: "",
  });

  const [emailTemp, setEmailTemp] = useState([]);

  const fetchEmailTemp = async () => {
    try {
      const responseEmail = await Http.call("GET", "admin/email_templates");
      setEmailTemp(responseEmail.data);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const [userlist, setUserlist] = useState([]);

  const fetchUserList = async () => {
    try {
      const responseUser = await Http.call(
        "GET",
        "admin/status_mgmt_get_users"
      );
      setUserlist(responseUser.data);
    } catch (e) {
      console.log(e);
    }
  };
  const [docuforms, setDocuForms] = useState([]);
  const fetchDocumentForms = async () => {
    try {
      const responsedocuforms = await Http.call("GET", "admin/forms");
      await setDocuForms(responsedocuforms.data);
    } catch (e) {
      console.log(e);
    }
  };

  const [staffPositions, setStaffPositions] = useState([]);
  const fetchStaffPositions = async () => {
    try {
      let url = "admin/designations";

      const response = await Http.call("GET", url);
      await setStaffPositions(response.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchUserList();
    // fetchParentStatuss();
    fetchEmailTemp();
    fetchDocumentForms();
    fetchStaffPositions();
    setLoading(false);
  }, []);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  if (Loading) {
    return (
      <Card style={{ height: 300 }}>
        <LinearProgress />
      </Card>
    );
  }
  return (
    <Page className={classes.root} title="Add Status">
      <Container maxWidth="lg">
        <BreadcrumbsAdmin urlList={breadUrlList} />
        <Toolbar mode={"Add"} />
        <Grid container spacing={3} className={classes.QuesDetail}>
          <Grid item lg={12} md={12} xs={12}>
            <StatusDetails
              emailtemplists={emailTemp}
              userlistdata={userlist}
              formValues={values}
              setvalues={setValues}
              handleChange={handleChange}
              mode={"Add"}
              setSnackbar={props.setSnackbar}
              docuforms={docuforms}
              staffPositions={staffPositions}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddStatusView);
