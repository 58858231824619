import { useField } from "formik";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { CloudUpload } from "@mui/icons-material";
import { Box, Grid, Button, FormHelperText } from "@mui/material";
import { getExtension } from "../../utils/getFileExt";
import DeleteIcon from "@mui/icons-material/Delete";

export default function DropBox({ name, question, setFieldValue }) {
  const [field, meta, helpers] = useField(name);
  const [filePath, setFilePath] = useState(question.file_path);
  const [defaultValue, setDefaultValue] = useState(question.default_value);

  const handleRemove = async (file) => {
    (file) => URL.revokeObjectURL(file.preview);
    meta.value.splice(file, 1);
    field.value.splice(file, 1);
    helpers.setValue(URL.revokeObjectURL(file.preview));
    setFieldValue("answer", "");
  };

  return (
    <Box style={{ width: "100%", paddingTop: 20 }}>
      <UploadComponent
        // name={name}
        setFieldValue={helpers.setValue}
        ext={question.ext}
        control_handler={question.control_handler}
      />

      {meta.value ? (
        meta.value.map((file, i) => (
          <Grid container flexDirection="row">
            <div className="question-img">
              <img
                src={file.preview}
                alt={file.path}
                style={{
                  display: "block",
                  width: 100,
                  height: 100,
                  border: "1px solid #ffffffa8",
                }}
              />
              <div className="question-dellink">
                <a
                  href="javascript:void(0);"
                  onClick={() => handleRemove(file)}
                  className="deleteIcon"
                >
                  <DeleteIcon sx={{ fontSize: 20 }} />
                </a>
              </div>
              <h6
                color="error"
                style={{
                  display: "flex",
                  width: "100%",
                  color: "#FFFF00",
                  marginBottom: "5%",
                }}
                variant="danger"
              >
                {Error}
              </h6>
            </div>
          </Grid>
        ))
      ) : (
        <div>
          {[2, 4].includes(question.control_handler) && filePath !== "" && (
            <Grid container flexDirection="row">
              <div className="question-img">
                <a
                  href={question.file_path}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                >
                  <img
                    src={filePath}
                    alt={defaultValue}
                    title={question.default_value}
                    style={{
                      display: "block",
                      width: 100,
                      height: 100,
                      border: "1px solid #ffffffa8",
                      cursor: "pointer",
                    }}
                  />
                </a>
                {/* <a
                  href={question.file_path}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                >
                  {question.default_value}
                </a> */}

                <div className="question-dellink">
                  <a
                    href="javascript:void(0);"
                    onClick={() => {
                      setFilePath("");
                      setDefaultValue("");
                      setFieldValue("answer", "");
                    }}
                  >
                    <DeleteIcon sx={{ fontSize: 20 }} />
                  </a>

                  <h6
                    color="error"
                    style={{
                      display: "flex",
                      width: "100%",
                      color: "#FFFF00",
                      marginBottom: "5%",
                    }}
                    variant="danger"
                  >
                    {Error}
                  </h6>
                </div>
              </div>
            </Grid>
          )}
        </div>
      )}
    </Box>
  );
}
const UploadComponent = (props) => {
  const { setFieldValue, ext, control_handler } = props;
  const [dragOver, setDragOver] = useState("");
  const [fileExt, setExt] = useState("");
  const [field, meta, helpers] = useField("answer");
  const [files, setFiles] = useState([]);
  let acceptedString = "";
  let extensions = ext;
  extensions = extensions.split(",").join(",.");
  if (control_handler === 2) {
    acceptedString = "." + extensions;
  } else if (control_handler === 3) {
    acceptedString = "." + extensions;
  } else {
    acceptedString = ".png,.jpg,.jpeg,.pdf,.doc,.docx,.xlx,.xlsx,.ppt,.pptx";
  }

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      accept: acceptedString,
      onDrop: (acceptedFiles) => {
        setFieldValue(acceptedFiles);
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
        // console.log(acceptedFiles)
        // setValue("files", acceptedFiles);
      },
      onDragOver: (e) => setDragOver("dragStart"),
      onDragLeave: (e) => setDragOver(""),
      onDropAccepted: (e) => {
        e.map((item) => {
          let extension = getExtension(item.name);
          setExt(extension);
          let reader = new FileReader();
          reader.readAsDataURL(item);
          reader.onload = (event) => {
            helpers.setValue(event.target.result);
          };
        });

        setDragOver("");
        helpers.setError("");
      },
      onDropRejected: (e) => {
        setExt("");
        setDragOver("dragFailed");
        helpers.setError("Invalid File Format");
      },
    });

  return (
    <Grid container>
      <Grid item md={12} xs={12}>
        <Grid
          container
          {...getRootProps({
            className: "dropzone input-file " + dragOver,
          })}
          direction={"column"}
        >
          <input {...getInputProps()} />
          <CloudUpload
            style={{ fontSize: 60, color: "#ffffff90", margin: 10 }}
          />
          {/* <h4>Drag & drop {ext} file to upload</h4> */}
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>Drag & drop {ext} file to upload</p>
          )}
          <h6 style={{ margin: 20 }}>or</h6>
          <Button style={{ color: "#D8B34F" }}>Browse Files</Button>
        </Grid>
        <FormHelperText error>
          {meta.touched && meta.error !== "" ? meta.error : undefined}
        </FormHelperText>
      </Grid>
    </Grid>
  );
};
