import React from "react";
import { Container, Grid, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { setSnackbar } from "src/actions";
import Page from "src/components/Page";
import DesignationDetails from "../Components/DesignationDetails";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";

const breadUrlList = [
  { name: "Home", url: "/dashboard" },
  { name: "Staff Position", url: "/app/designation" },
  { name: "Add Position", url: "/" },
];
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  designationDetail: {
    marginTop: theme.spacing(3),
  },
}));

const AddDesignationView = (props) => {
  const classes = useStyles();
  const values = {
    title: "",
  };
  return (
    <Page className={classes.root} title="Add Position">
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          {/* <Grid item md={2} xs={12}></Grid> */}
          <Grid item md={12} xs={12}>
            <BreadcrumbsAdmin urlList={breadUrlList} />
            {/*<Toolbar />*/}
            <DesignationDetails
              formValues={values}
              setSnackbar={props.setSnackbar}
              promoClass={classes.designationDetail}
            />
          </Grid>
          {/* <Grid item md={2} xs={12}></Grid> */}
        </Grid>
      </Container>
    </Page>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDesignationView);
