export const FETCH_REQUEST_FOR_QUESTIONS = "FETCH_REQUEST_FOR_QUESTIONS";
export const FETCH_SUCCESS_FOR_QUESTIONS = "FETCH_SUCCESS_FOR_QUESTIONS";
export const FETCH_FAILD_FOR_QUESTIONS = "FETCH_FAILD_FOR_QUESTIONS";
export const SET_QUESTION_LOADING = "SET_QUESTION_LOADING";
export const SET_PAGE_NUMBER = "SET_PAGE_NUMBER";
export const SET_PAGE_LIMIT = "SET_PAGE_LIMIT";
export const SET_SEARCH_TEXT = "SET_SEARCH_TEXT";

export const setTableData = (data) => (dispatch) => {
  // console.log(data);
  dispatch({
    type: FETCH_SUCCESS_FOR_QUESTIONS,
    payload: {
      data: data,
    },
  });
};
export const setTableLoading = (arg) => (dispatch) => {
  dispatch({
    type: SET_QUESTION_LOADING,
    payload: {
      loading: arg,
    },
  });
};
