import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Grid, makeStyles } from "@material-ui/core";
import Page from "src/components/Page";
import * as Http from "src/services/Http";
import ScreenLoader from "src/components/ScreenLoader";
import { setSnackbar } from "src/actions";
import { connect } from "react-redux";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";
import ClientForm from "./ClientForm";
/*import Toolbar from "./Toolbar";*/

const breadUrlList = [
  { name: "Home", url: "/dashboard" },
  { name: "Activation Tokens", url: "/app/activation-tokens" },
];
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  userDetail: {
    marginTop: theme.spacing(3),
  },
}));

const EditClientView = (props) => {
  const { id } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const [Loading, setLoading] = useState(true);
  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    domain_name: "",
    business_name: "",
    button_color: "",
  });

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        if (id !== undefined) {
          const response = await Http.call("GET", "admin/clients/" + id);
          await setValues(response.data);
        }
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    };
    fetchUsers();
  }, [id]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const addUser = async (data, actions) => {
    await Http.call("POST", "admin/clients", {
      ...data,
    }).then(async (response) => {
      if (response.status === 200) {
        props.setSnackbar(true, response.status, response.message);
        navigate("/app/activation-tokens");
      } else {
        actions.setErrors(response.data);
      }
    });
  };
  const updateUser = async (data, actions) => {
    await Http.call("PUT", "admin/clients/" + id, {
      ...data,
      _method: "PUT",
    }).then(async (response) => {
      if (response.status === 200) {
        props.setSnackbar(true, response.status, response.message);
        navigate("/app/activation-tokens");
      } else {
        actions.setErrors(response.data);
      }
    });
  };

  if (Loading) {
    return <ScreenLoader />;
  }
  return (
    <Page
      className={classes.root}
      title={
        id !== undefined ? "Edit Activation Token" : "Add Activation Token"
      }
    >
      <Container maxWidth="lg">
        <BreadcrumbsAdmin
          urlList={
            id !== undefined
              ? [...breadUrlList, { name: "Edit", url: "/" }]
              : [...breadUrlList, { name: "Add", url: "/" }]
          }
        />
        <Grid container spacing={3} className={classes.userDetail}>
          <Grid item lg={12} md={12} xs={12}>
            <ClientForm
              id={id}
              formValues={values}
              handleChange={handleChange}
              updateUser={id !== undefined ? updateUser : addUser}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditClientView);
