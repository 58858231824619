import React, { useState } from "react";
import * as Http from "src/services/Http";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  FormHelperText,
} from "@material-ui/core";
import "src/styles/custom.css";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import * as Messages from "src/constants/Messages/index";
import DatePicker from "src/components/common/DatePicker";

const AddUserSchema = Yup.object().shape({
  price_date: Yup.date()
    .required(Messages.REQUIRED)
    .nullable(),
});

const PriceDetails = ({ setSnackbar, formValues, promoClass }) => {
  const navigate = useNavigate();
  const [fileError, setfileError] = useState("");
  const [fileRequiredError, setFileRequiredError] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const handleUploadClick = async (e, setFieldValue) => {
    setfileError("");
    setFileRequiredError("");
    setFileUrl("");

    let files = e.target.files || e.dataTransfer.files;

    if (!files.length) return;
    let fileext = files[0].name.split(".");
    if (fileext[fileext.length - 1] !== "pdf") {
      //if (!files[0].name.match(/\.(jpg|jpeg|png|pdf)$/)) {
      setfileError("This file is not allowed, please upload pdf file.");
      // return false;
    } else {
      setFileUrl(URL.createObjectURL(files[0]));
      createImage(files[0], setFieldValue);
      setfileError("");
    }
  };

  const createImage = (file, setFieldValue) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      setFieldValue("file_name", e.target.result);
      setFieldValue("file", file);
    };
    reader.readAsDataURL(file);
  };

  return (
    <Formik
      initialValues={formValues}
      validationSchema={AddUserSchema}
      onSubmit={async (values, actions) => {
        let file_name =
          formValues.id !== undefined && formValues.id !== ""
            ? formValues.file_name_edit
            : values.file_name;
        if (file_name === "") {
          setfileError("");
          setFileRequiredError("This file is required.");
        } else {
          setFileRequiredError("");
          let method = "";
          let url = "";
          if (formValues.id === undefined) {
            method = "POST";
            url = "admin/price";
          } else {
            method = "PATCH";
            url = "admin/price/" + formValues.id;
          }
          await Http.call(method, url, values).then(
            async ({ status, message, data }) => {
              if (status === 200) {
                setSnackbar(true, status, message);
                navigate("/app/price");
              } else if (status === 400) {
                if (data[0]) {
                  actions.setFieldError("price_date", data[0]);
                } else if (data[0].errors.price_date) {
                  actions.setFieldError(
                    "price_date",
                    data[0].errors.price_date
                  );
                } else if (data[0].errors.file_name) {
                  actions.setFieldError(
                    "file_name",
                    data[0].errors.file_name[0]
                  );
                }
              }
            }
          );
          actions.setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => {
        return (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Grid item md={12} className={promoClass}>
              <Card>
                {}
                <CardHeader
                  title={
                    formValues.id === undefined
                      ? "Add Price List"
                      : "Edit Price List"
                  }
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={12} xs={12}>
                      {/* <TextField
                        fullWidth
                        label="Price Date"
                        name="price_date"
                        type="date"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.price_date}
                        InputProps={{
                          inputProps: { min: getDate() },
                        }}
                        margin="normal"
                        variant="outlined"
                        error={Boolean(touched.price_date && errors.price_date)}
                        helperText={touched.price_date && errors.price_date}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      /> */}
                      <DatePicker
                        name="price_date"
                        lable="Price Date"
                        value={values.price_date}
                        onChange={(e) => setFieldValue("price_date", e)}
                        minDate={new Date()}
                        error={Boolean(touched.price_date && errors.price_date)}
                        helperText={touched.price_date && errors.price_date}
                      />
                      {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justifyContent="space-around">
                          <KeyboardDatePicker
                            fullWidth
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            label="Price Date"
                            name="price_date"
                            value={values.price_date} 
                            onChange={(e) => setFieldValue("price_date", e)}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            minDate={new Date()}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider> */}
                    </Grid>
                    {((fileUrl !== undefined && fileUrl !== "") ||
                      (formValues.file_path !== undefined &&
                        formValues.file_path !== "")) && (
                      <Grid item md={12} xs={12}>
                        <iframe
                          style={{ width: "100%", height: "666px" }}
                          src={`${
                            fileUrl !== "" ? fileUrl : formValues.file_path
                          }#view=fitH`}
                          type="application/pdf"
                          title="Preview"
                        />
                      </Grid>
                    )}
                    <Grid item md={12} xs={12}>
                      <input
                        type="file"
                        style={{ display: "none" }}
                        id="contained-button-file"
                        onChange={(event) => {
                          handleUploadClick(event, setFieldValue);
                        }}
                      />

                      <Button color="primary" variant="contained">
                        <label
                          htmlFor="contained-button-file"
                          style={{
                            marginBottom: "0px",
                          }}
                        >
                          Upload Document
                        </label>
                      </Button>
                      <FormHelperText className="Mui-error">
                        {fileError !== "" ? fileError : fileRequiredError}
                      </FormHelperText>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button
                    style={{ marginRight: "7px" }}
                    variant="contained"
                    component={RouterLink}
                    to="/app/price"
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </Box>
              </Card>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default PriceDetails;
