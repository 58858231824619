import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Grid, makeStyles } from "@material-ui/core";
import Page from "src/components/Page";
import StatusDetails from "../Components/StatusDetails";
import Toolbar from "../Components/Toolbar";
import * as Http from "src/services/Http";
import ScreenLoader from "src/components/ScreenLoader";
import { setSnackbar } from "src/actions";
import { connect } from "react-redux";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";
import { da } from "date-fns/locale";
const breadUrlList = [
  { name: "Home", url: "/dashboard" },
  { name: "Status List", url: "/app/status" },
  { name: "Edit Status", url: "/" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  QuesDetail: {
    marginTop: theme.spacing(3),
  },
}));

const EditStatusView = (props) => {
  const { id } = useParams();
  const classes = useStyles();
  const [Loading, setLoading] = useState(true);
  const [values, setValues] = useState({
    name: "",
    auto_status: 0,
    on_status: 0,
    on_send_type: 0,
    on_email_template: "",
    on_position_id: [],
    on_position_users: [],
    off_status: 0,
    off_send_type: 0,
    off_email_template: "",
    off_position_id: [],
    off_position_users: [],
    is_compulsory: 0,
    on_form_id: [],
    off_form_id: [],
    on_fax_message: "",
    off_fax_message: "",
  });

  const [emailTemp, setEmailTemp] = useState([]);

  const [userlist, setUserlist] = useState([]);

  const fetchEmailTemp = async () => {
    try {
      const responseEmail = await Http.call("GET", "admin/email_templates");
      setEmailTemp(responseEmail.data);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchUserList = async () => {
    try {
      const responseUser = await Http.call(
        "GET",
        "admin/status_mgmt_get_users"
      );
      setUserlist(responseUser.data);
    } catch (e) {
      console.log(e);
    }
  };

  const [staffPositions, setStaffPositions] = useState([]);
  const fetchStaffPositions = async () => {
    try {
      let url = "admin/designations";

      const response = await Http.call("GET", url);
      await setStaffPositions(response.data);
    } catch (e) {
      console.log(e);
    }
  };

  const [docuforms, setDocuForms] = useState([]);
  const fetchDocumentForms = async () => {
    try {
      const responsedocuforms = await Http.call("GET", "admin/forms");
      setDocuForms(responsedocuforms.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (id !== undefined) {
        await Http.call("GET", "admin/status_mgmt/" + id).then(
          async (response) => {
            const { data } = response;
            await setValues({
              id: id,
              name: data.name,
              auto_status: data.auto_status,
              on_status: data.on_status === "" ? 0 : data.on_status,
              on_send_type: data.on_send_type === "" ? 0 : data.on_send_type,
              on_email_template: data.on_email_template,
              on_position_users:
                data.on_position_users !== "" ? data.on_position_users : [],
              on_position_id:
                data.on_position_id !== "" ? data.on_position_id : [],
              off_status: data.off_status === "" ? 0 : data.off_status,
              off_send_type: data.off_send_type === "" ? 0 : data.off_send_type,
              off_email_template: data.off_email_template,
              off_position_users:
                data.off_position_users !== "" ? data.off_position_users : [],
              off_position_id:
                data.off_position_id !== "" ? data.off_position_id : [],
              is_compulsory: data.is_compulsory,
              on_form_id: data.on_form_id !== "" ? data.on_form_id : [],
              off_form_id: data.off_form_id !== "" ? data.off_form_id : [],
              on_fax_message: data.on_fax_message,
              off_fax_message: data.off_fax_message,
            });

            await fetchUserList();
            await fetchEmailTemp();
            await fetchStaffPositions();
            await fetchDocumentForms();
            setLoading(false);
          }
        );
      } else {
        await fetchStaffPositions();
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  if (Loading) {
    return <ScreenLoader />;
  }

  return (
    <Page className={classes.root} title="Edit Status">
      <Container maxWidth="lg">
        <BreadcrumbsAdmin urlList={breadUrlList} />
        <Toolbar mode={"Edit"} />
        <Grid container spacing={3} className={classes.QuesDetail}>
          <Grid item lg={12} md={12} xs={12}>
            <StatusDetails
              emailtemplists={emailTemp}
              userlistdata={userlist}
              formValues={values}
              mode={"Edit"}
              setSnackbar={props.setSnackbar}
              staffPositions={staffPositions}
              docuforms={docuforms}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditStatusView);
