import React, { useState } from "react";
import {
  Button,
  Box,
  Grid,
  CardContent,
  Card,
  makeStyles,
  Divider,
} from "@material-ui/core";
import * as Yup from "yup";
import { Formik } from "formik";
import * as Messages from "src/constants/Messages/index";
import DatePicker from "src/components/common/DatePicker";

const useStyles = makeStyles((theme) => ({
  customcart: {
    top: "59px",
    // width: "465px",
    padding: "15px 15px 0px 15px",
    zIndex: "1",
    position: "absolute",
    background: "#fffffe",
  },
  ResButton: {
    // '@media (min-width:600px)': {
    //   fontSize: '0.1rem',
    // },
    [theme.breakpoints.down("md")]: {
      fontSize: "0.6rem",
      fontFamily: "roboto",
      marginRight: "2px !important",
    },
  },
}));

const AddUserSchema = Yup.object().shape({
  start_date: Yup.date()
    .required(Messages.REQUIRED)
    .nullable(),
  end_date: Yup.date()
    .required(Messages.REQUIRED)
    .min(Yup.ref("start_date"), Messages.TO_DATE)
    .nullable(),
});

const Filtter = ({ getchartdata }) => {
  const classes = useStyles();

  const [card, setcard] = useState(false);
  const [customVariant, setCustomVariant] = useState("outlined");
  const [todayVariant, setTodayVariant] = useState("outlined");
  const [monthVariant, setMonthVariant] = useState("contained");
  const [yearVariant, setYearVariant] = useState("outlined");
  const [weekVariant, setWeekVariant] = useState("outlined");

  const handleClick = (mode) => {
    if (mode === "custom") {
      setcard(true);
      setCustomVariant("contained");
      setTodayVariant("outlined");
      setMonthVariant("outlined");
      setYearVariant("outlined");
      setWeekVariant("outlined");
    } else if (mode === "week") {
      setWeekVariant("contained");
      setCustomVariant("outlined");
      setTodayVariant("outlined");
      setMonthVariant("outlined");
      setYearVariant("outlined");
    } else if (mode === "today") {
      setTodayVariant("contained");
      setCustomVariant("outlined");
      setWeekVariant("outlined");
      setMonthVariant("outlined");
      setYearVariant("outlined");
    } else if (mode === "month") {
      setMonthVariant("contained");
      setCustomVariant("outlined");
      setWeekVariant("outlined");
      setTodayVariant("outlined");
      setYearVariant("outlined");
    } else {
      setYearVariant("contained");
      setCustomVariant("outlined");
      setWeekVariant("outlined");
      setTodayVariant("outlined");
      setMonthVariant("outlined");
    }

    if (mode !== "custom") {
      let fillterData = {
        mode: mode,
        start_date: null,
        end_date: null,
      };
      getchartdata(fillterData);
      setcard(false);
    }
  };

  const values = {
    start_date: null,
    end_date: null,
  };

  const closeCard = () => {
    setcard(false);
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="flex-end"
        style={{
          position: "relative",
        }}
      >
        {card ? (
          <Formik
            initialValues={values}
            validationSchema={AddUserSchema}
            onSubmit={async (values, actions) => {
              let fillterData = {
                mode: "custom",
                start_date: values.start_date,
                end_date: values.end_date,
              };
              await getchartdata(fillterData);
              actions.setSubmitting(false);
              setcard(false);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              touched,
              values,
            }) => {
              return (
                <form autoComplete="off" onSubmit={handleSubmit}>
                  <Grid item md={12}>
                    <Card className={classes.customcart}>
                      <Divider />
                      <CardContent>
                        <Grid container spacing={3}>
                          <Grid item md={6} xs={12}>
                            {/* <TextField
                              fullWidth
                              label="Start Date"
                              name="start_date"
                              type="date"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.start_date}
                              margin="normal"
                              variant="outlined"
                              error={Boolean(
                                touched.start_date && errors.start_date
                              )}
                              helperText={
                                touched.start_date && errors.start_date
                              }
                              InputLabelProps={{
                                shrink: true,
                              }}
                            /> */}
                            <DatePicker
                              name="start_date"
                              lable="Start Date"
                              placeholder="Start Date"
                              value={values.start_date}
                              onChange={(e) => setFieldValue("start_date", e)}
                              //maxDate={new Date()}
                              error={Boolean(
                                touched.start_date && errors.start_date
                              )}
                              helperText={
                                touched.start_date && errors.start_date
                              }
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            {/* <TextField
                              fullWidth
                              label="Expiration Date"
                              name="end_date"
                              type="date"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.end_date}
                              margin="normal"
                              variant="outlined"
                              error={Boolean(
                                touched.end_date && errors.end_date
                              )}
                              helperText={touched.end_date && errors.end_date}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            /> */}
                            <DatePicker
                              name="end_date"
                              lable="Expiration Date"
                              placeholder="Expiration Date"
                              value={values.end_date}
                              onChange={(e) => setFieldValue("end_date", e)}
                              error={Boolean(
                                touched.end_date && errors.end_date
                              )}
                              helperText={touched.end_date && errors.end_date}
                              //maxDate={new Date()}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                      <Divider />
                      <Box display="flex" justifyContent="flex-end" p={2}>
                        <Button
                          style={{
                            marginRight: "7px",
                          }}
                          variant="contained"
                          onClick={() => closeCard()}
                        >
                          Cancel
                        </Button>
                        <Button
                          color="primary"
                          variant="contained"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Save
                        </Button>
                      </Box>
                    </Card>
                  </Grid>
                </form>
              );
            }}
          </Formik>
        ) : (
          ""
        )}

        <Button
          className={classes.ResButton}
          style={{
            marginRight: "7px",
          }}
          color="primary"
          variant={todayVariant}
          onClick={() => handleClick("today")}
        >
          Today
        </Button>
        <Button
          className={classes.ResButton}
          style={{
            marginRight: "7px",
          }}
          color="primary"
          variant={weekVariant}
          onClick={() => handleClick("week")}
        >
          Weekly
        </Button>
        <Button
          className={classes.ResButton}
          style={{
            marginRight: "7px",
          }}
          color="primary"
          variant={monthVariant}
          onClick={() => handleClick("month")}
        >
          Monthly
        </Button>
        <Button
          className={classes.ResButton}
          style={{
            marginRight: "7px",
          }}
          color="primary"
          variant={yearVariant}
          onClick={() => handleClick("year")}
        >
          Yearly
        </Button>
        <Button
          className={classes.ResButton}
          color="primary"
          variant={customVariant}
          onClick={() => handleClick("custom")}
        >
          Custom
        </Button>
      </Box>
    </>
  );
};

export default Filtter;
