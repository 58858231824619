import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import ReviewTable from "../../components/QuestionTemplates/ReviewTable";
export default function LayoutTwo({
  reviewSection,
  prevSection,
  reviewData,
  reviewerror,
  loading,
  progress,
  onSectionGet,
  type,
  confirmed,
  isFromAdmin,
}) {
  // if (loading) {
  //   return (
  //     <Grid item md={8} className="scroll-md theme-bg-color">
  //       <Grid container justifyContent={"center"}>
  //         <img src="/img/loader.gif" className="spinner" loading="lazy" />
  //       </Grid>
  //     </Grid>
  //   );
  // }

  const [isModal, setisModal] = useState(
    false
    // type === 1 ? (!confirmed ? true : false) : false
  );
  const [closeDisabled, setcloseDisabled] = useState(true);
  return (
    <Grid>
      <Dialog
        open={isModal}
        onClose={() => setisModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-title">
          Please Confirm
          <IconButton
            aria-label="close"
            disabled={closeDisabled}
            onClick={() => setisModal(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            {/* <div
              dangerouslySetInnerHTML={{
                __html: question.question_description,
              }}
            /> */}
            Please note: the following information will appear on the official
            legal paperwork and certificate of death. Please check your answers
            carefully. Any information entered incorrectly here will
            subsequently be incorrect on the official documents.
          </DialogContentText>
          <FormControl component="fieldset">
            <RadioGroup>
              <FormControlLabel
                className="custom-radio-btn"
                key="1"
                value="1"
                control={<Radio style={{ padding: "9px" }} />}
                label="I confirm that the information I provide will be used on the official Certificate Of Death and legal documentation and that this information is correct."
                style={{ paddingBottom: 10 }}
                fullWidth
                onClick={() => setcloseDisabled(false)}
              />
            </RadioGroup>
            <FormHelperText error>
              {/* {meta.touched && meta.error ? meta.error : undefined} */}
            </FormHelperText>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button disabled={closeDisabled} onClick={() => setisModal(false)}>
            Accept
          </Button>
        </DialogActions>
      </Dialog>
      {reviewData.length > 0 &&
        reviewData.map((item) => <ReviewTable data={item} />)}{" "}
      {(reviewSection.is_complete === false || isFromAdmin) && (
        <Grid container component={Paper} style={{ marginTop: 30 }}>
          <Grid container>
            <Grid
              item
              md={12}
              style={{
                padding: 10,
                backgroundColor: "#b1d4e038",
                textAlign: "center",
              }}
            >
              Please review the above information{" "}
              <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
                carefully
              </span>{" "}
              before pressing continue.
            </Grid>
            <Grid
              item
              md={12}
              style={{ color: "red", padding: "10px", textAlign: "justify" }}
            >
              {reviewerror}
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
              style={{
                padding: 15,
              }}
            >
              <Grid container justifyContent={"space-between"}>
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      //alert(prevSection);
                      onSectionGet(prevSection);
                    }}
                    className="btn-color"
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      onSectionGet("Confirm");
                    }}
                    className="btn-color"
                  >
                    Continue
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
