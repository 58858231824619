import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  FormHelperText,
  Switch,
} from "@material-ui/core";
import Image from "material-ui-image";
import Cancelbtn from "src/components/common/Cancelbtn";
import * as Messages from "src/constants/Messages/index";
import { ColorPicker } from "material-ui-color";
import * as Http from "src/services/Http";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectControl: {
    minWidth: "100%",
  },
}));

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
const FILE_SIZE = 8388608;

const steps = [
  {
    id: 1,
    name: "Prequestionnaire",
  },
  {
    id: 2,
    name: "Questionnaire",
  },
];

const AddBannerSchema = Yup.object().shape({
  step_id: Yup.string().required(Messages.REQUIRED),
  image: Yup.mixed()
    .required(Messages.REQUIRED)
    .test(
      "fileFormat",
      "Please select jpg, jpeg or png file only.",
      (value) => value && SUPPORTED_FORMATS.includes(value.type)
    )
    .test(
      "fileSize",
      "File size is too large! Please select file less then 8MB",
      (value) => value && value.size <= FILE_SIZE
    ),
  font_color: Yup.string().required(Messages.REQUIRED),
  is_default: Yup.boolean().required(Messages.REQUIRED),
});

const EditBannerSchema = Yup.object().shape({
  step_id: Yup.string().required(Messages.REQUIRED),
  font_color: Yup.string().required(Messages.REQUIRED),
  is_default: Yup.boolean().required(Messages.REQUIRED),
});

const BannerDetails = ({ setSnackbar, formValues, promoClass }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [file, setFile] = useState();
  return (
    <Formik
      initialValues={formValues}
      validationSchema={
        formValues.id !== undefined && formValues.id > 0
          ? EditBannerSchema
          : AddBannerSchema
      }
      onSubmit={async (values, actions) => {
        let method = "";
        let url = "";
        if (formValues.id === undefined) {
          method = "POST";
          url = "admin/banners";
        } else {
          method = "POST";
          url = "admin/banners/" + formValues.id;
        }
        let formData = new FormData();
        formData.append("step_id", values.step_id);
        formData.append("font_color", values.font_color);
        formData.append("is_default", values.is_default ? 1 : 0);
        if (formValues.id !== undefined) {
          formData.append("_method", "PATCH");
        }
        if (values.image !== "") {
          formData.append("image", values.image);
        }

        await Http.call(method, url, formData, true).then(
          async ({ status, message, data }) => {
            if (status === 200) {
              setSnackbar(true, status, message);
              navigate("/app/banners");
            } else if (status === 400) {
              actions.setErrors(data[0].errors);
            }
          }
        );
        actions.setSubmitting(false);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => {
        return (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Grid item md={12} className={promoClass}>
              <Card>
                {}
                <CardHeader
                  title={
                    formValues.id === undefined ? "Add Banner" : "Edit Banner"
                  }
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={4} xs={12}>
                      <FormControl
                        variant="outlined"
                        className={classes.selectControl}
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          Step
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          label="Steps"
                          name="step_id"
                          value={values.step_id}
                          onChange={handleChange}
                          error={Boolean(touched.step_id && errors.step_id)}
                          fullWidth
                          variant="outlined"
                        >
                          <MenuItem value="">
                            <em>Select Step</em>
                          </MenuItem>

                          {steps.map((step, index) => (
                            <MenuItem key={index} value={step.id}>
                              {step.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.step_id && touched.step_id ? (
                          <FormHelperText className="Mui-error">
                            {errors.step_id}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <FormControl
                        variant="standard"
                        className={classes.selectControl}
                      >
                        <ColorPicker
                          deferred
                          name="font_color"
                          value={values.font_color}
                          error={Boolean(
                            touched.font_color && errors.font_color
                          )}
                          onChange={(color) => {
                            color.hex !== undefined
                              ? setFieldValue("font_color", "#" + color.hex)
                              : setFieldValue("font_color", color);
                          }}
                        />
                        {errors.font_color && touched.font_color ? (
                          <FormHelperText className="Mui-error">
                            {errors.font_color}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            label="Status"
                            name="is_default"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            checked={values.is_default}
                            color="primary"
                          />
                        }
                        labelPlacement="end"
                        label="Set as Default"
                      />
                      {errors.is_default && touched.is_default ? (
                        <FormHelperText className="Mui-error">
                          {errors.is_default}
                        </FormHelperText>
                      ) : null}
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <label htmlFor="contained-button-file">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                        >
                          Upload
                        </Button>
                      </label>
                      <input
                        style={{ display: "none" }}
                        id="contained-button-file"
                        name="image"
                        type="file"
                        accept="image/*"
                        onBlur={(event) => {
                          setFile(event.currentTarget.files[0]);
                          setFieldValue("image", event.currentTarget.files[0]);
                        }}
                        onChange={(event) => {
                          setFile(event.currentTarget.files[0]);
                          setFieldValue("image", event.currentTarget.files[0]);
                        }}
                      />
                      {errors.image && touched.image ? (
                        <FormHelperText className="Mui-error">
                          {errors.image}
                        </FormHelperText>
                      ) : null}
                    </Grid>
                    {(file !== null && file !== undefined) ||
                    (values.banner_image !== undefined &&
                      values.banner_image !== "") ? (
                      <Grid item md={12} xs={12}>
                        <Image
                          imageStyle={{ width: "100%", height: "auto" }}
                          disableSpinner={true}
                          src={
                            file !== null && file !== undefined
                              ? URL.createObjectURL(file)
                              : values.banner_image
                          }
                        />
                      </Grid>
                    ) : null}
                  </Grid>
                </CardContent>
                <Divider />
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Cancelbtn />
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </Box>
              </Card>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default BannerDetails;
