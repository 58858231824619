import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import { Grid, TextField, Button, FormHelperText } from "@material-ui/core";
import { FieldArray } from "formik";


const useStyles = makeStyles((theme) => ({
  textWidth: {
    width: "71%",
    "@media (maxWidth: '575px')": {
      width: "100%",
    }
  },
}));

const FormFieldDetails = (props) => {
  const classes = useStyles();
  const { nameval, touchTextval, errorTextval, isparent } = props;
  //console.log(nameval,'field_name');
  return (
    <FieldArray
      name={`field_array`}
      render={(arrayHelpers) => (
        <Grid id="top-row" container>
          {nameval.length > 0 &&
            nameval.map((optionval, index) => {
              if (index === 0) {
                return (
                  <Grid container key={index}>
                    <Grid item key={index+'_field_name'} md={4} xs={12}>
                      <TextField key={index+'_field_name_text'}
                        name={`field_array.${index}.field_name`}
                        label={`Form Fields`}
                        variant="outlined"
                        value={optionval.field_name}
                        onChange={(e) => {
                          if (nameval[index] !== undefined) {
                            arrayHelpers.replace(index, {
                              id: optionval.id,
                              field_name: e.target.value,
                              x_point: nameval[index].x_point,
                              y_point: nameval[index].y_point,
                              page_no: nameval[index].page_no,
                            });
                          } else {
                            arrayHelpers.push({
                              id: optionval.id,
                              field_name: e.target.value,
                              x_point: nameval[index].x_point,
                              y_point: nameval[index].y_point,
                              page_no: nameval[index].page_no,
                            });
                          }
                        }}
                        error={Boolean(
                          (touchTextval &&
                            touchTextval[index] &&
                            touchTextval[index].field_name &&
                            errorTextval &&
                            errorTextval &&
                            errorTextval[index] &&
                            errorTextval[index].field_name)
                        )}
                        margin="normal"
                        className={classes.textWidth}
                        helperText={
                          touchTextval &&
                            touchTextval[index] &&
                            touchTextval[index].field_name &&
                            errorTextval &&
                            errorTextval &&
                            errorTextval[index] &&
                            errorTextval[index].field_name
                        }
                        disabled={isparent === 1}
                      />
                    </Grid>
                    <Grid item key={index+'_x_point'} md={2} xs={12}>
                      <TextField key={index+'_x_point_text'}
                        name={`field_array.${index}.x_point`}
                        label={`X Point`}
                        variant="outlined"
                        value={optionval.x_point}
                        
                        onChange={(e) => {
                          if (nameval[index] !== undefined) {
                            arrayHelpers.replace(index, {
                              id: optionval.id,
                              field_name: nameval[index].field_name,
                              x_point: e.target.value,
                              y_point: nameval[index].y_point,
                              page_no: nameval[index].page_no,
                            });
                          } else {
                            arrayHelpers.push({
                              id: optionval.id,
                              field_name: nameval[index].field_name,
                              x_point: e.target.value,
                              y_point: nameval[index].y_point,
                              page_no: nameval[index].page_no,
                            });
                          }
                        }}
                        error={Boolean(
                          (touchTextval &&
                            touchTextval[index] &&
                            touchTextval[index].x_point &&
                            errorTextval &&
                            errorTextval &&
                            errorTextval[index] &&
                            errorTextval[index].x_point)
                        )}
                        margin="normal"
                        className={classes.textWidth}
                        helperText={
                          touchTextval &&
                            touchTextval[index] &&
                            touchTextval[index].x_point &&
                            errorTextval &&
                            errorTextval &&
                            errorTextval[index] &&
                            errorTextval[index].x_point
                        }
                        disabled={isparent === 1}
                      />
                    </Grid>
                    <Grid item key={index+'_y_point'} md={2} xs={12}>
                      <TextField key={index+'_y_point_text'}
                        name={`field_array.${index}.y_point`}
                        label={`Y Point`}
                        variant="outlined"
                        value={optionval.y_point}
                        onChange={(e) => {
                          if (nameval[index] !== undefined) {
                            arrayHelpers.replace(index, {
                              id: optionval.id,
                              field_name: nameval[index].field_name,
                              x_point: nameval[index].x_point,
                              y_point: e.target.value,
                              page_no: nameval[index].page_no,
                            });
                          } else {
                            arrayHelpers.push({
                              id: optionval.id,
                              field_name: nameval[index].field_name,
                              x_point: nameval[index].x_point,
                              y_point: e.target.value,
                              page_no: nameval[index].page_no,
                              
                            });
                          }
                        }}
                        error={Boolean(
                          (touchTextval &&
                            touchTextval[index] &&
                            touchTextval[index].y_point &&
                            errorTextval &&
                            errorTextval &&
                            errorTextval[index] &&
                            errorTextval[index].y_point)
                        )}
                        margin="normal"
                        className={classes.textWidth}
                        helperText={
                          touchTextval &&
                            touchTextval[index] &&
                            touchTextval[index].y_point &&
                            errorTextval &&
                            errorTextval &&
                            errorTextval[index] &&
                            errorTextval[index].y_point
                        }
                        disabled={isparent === 1}
                      />
                    </Grid>
                    <Grid item key={index+'_page_no'} md={2} xs={12}>
                      <TextField key={index+'_page_no_text'}
                        name={`field_array.${index}.page_no`}
                        label={`Page No.`}
                        variant="outlined"
                        value={optionval.page_no}
                        
                        onChange={(e) => {
                          if (nameval[index] !== undefined) {
                            arrayHelpers.replace(index, {
                              id: optionval.id,
                              field_name: nameval[index].field_name,
                              x_point: nameval[index].x_point,
                              y_point: nameval[index].y_point,
                              page_no: e.target.value,
                            });
                          } else {
                            arrayHelpers.push({
                              id: optionval.id,
                              field_name: nameval[index].field_name,
                              x_point: nameval[index].x_point,
                              y_point: nameval[index].y_point,
                              page_no: e.target.value,
                              
                            });
                          }
                        }}
                        error={Boolean(
                          (touchTextval &&
                            touchTextval[index] &&
                            touchTextval[index].page_no &&
                            errorTextval &&
                            errorTextval &&
                            errorTextval[index] &&
                            errorTextval[index].page_no)
                        )}
                        margin="normal"
                        className={classes.textWidth}
                        helperText={
                          touchTextval &&
                            touchTextval[index] &&
                            touchTextval[index].page_no &&
                            errorTextval &&
                            errorTextval &&
                            errorTextval[index] &&
                            errorTextval[index].page_no
                        }
                        disabled={isparent === 1}
                      />
                    </Grid>
                    <Grid item key={index+'_button'} md={2} xs={12}>
                      <Button key={index+'_button_text'}
                      color="secondary"
                      variant="contained"
                      margin="normal"
                      style={{ margin: "15px 10px", padding: "15px" }}
                      onClick={() => {
                        arrayHelpers.push({
                          id: '',
                          field_name: '',
                          x_point: '',
                          y_point: '',
                          page_no: '',
                          
                        });
                      }}
                      disabled={isparent === 1}
                    >
                      {" "}
                      +{" "}
                    </Button>

                    {errorTextval &&
                      _.isString(errorTextval) &&
                      touchTextval &&
                      _.isArray(touchTextval) && (
                        <FormHelperText className="Mui-error">
                          {errorTextval}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                );
              } else {
                return (
                  <Grid container key={index}>
                    <Grid item key={index+'_field_name'} md={4} xs={12}>
                      <TextField key={index+'_field_name_text'}
                        name={`field_array.${index}.field_name`}
                        label={`Form Fields`}
                        variant="outlined"
                        value={optionval.field_name}
                        onChange={(e) => {
                          if (nameval[index] !== undefined) {
                            arrayHelpers.replace(index, {
                              id: optionval.id,
                              field_name: e.target.value,
                              x_point: nameval[index].x_point,
                              y_point: nameval[index].y_point,
                              page_no: nameval[index].page_no,
                            });
                          } else {
                            arrayHelpers.push({
                              id: optionval.id,
                              field_name: e.target.value,
                              x_point: nameval[index].x_point,
                              y_point: nameval[index].y_point,
                              page_no: nameval[index].page_no,
                            });
                          }
                        }}
                        margin="normal"
                        error={Boolean(
                          (touchTextval &&
                            touchTextval[index] &&
                            touchTextval[index].field_name &&
                            errorTextval &&
                            errorTextval &&
                            errorTextval[index] &&
                            errorTextval[index].field_name)
                        )}
                        className={classes.textWidth}
                        helperText={
                          touchTextval &&
                            touchTextval[index] &&
                            touchTextval[index].field_name &&
                            errorTextval &&
                            errorTextval &&
                            errorTextval[index] &&
                            errorTextval[index].field_name
                        }
                        disabled={isparent === 1}
                      />
                    </Grid>
                    <Grid item key={index+'_x_point'} md={2} xs={12}>
                      <TextField key={index+'_x_point_text'}
                        name={`field_array.${index}.x_point`}
                        label={`X Point`}
                        variant="outlined"
                        value={optionval.x_point}
                        onChange={(e) => {
                          if (nameval[index] !== undefined) {
                            arrayHelpers.replace(index, {
                              id: optionval.id,
                              field_name: nameval[index].field_name,
                              x_point: e.target.value,
                              y_point: nameval[index].y_point,
                              page_no: nameval[index].page_no,
                            });
                          } else {
                            arrayHelpers.push({
                              id: optionval.id,
                              field_name: nameval[index].field_name,
                              x_point: e.target.value,
                              y_point: nameval[index].y_point,
                              page_no: nameval[index].page_no,
                            });
                          }
                        }}
                        error={Boolean(
                          (touchTextval &&
                            touchTextval[index] &&
                            touchTextval[index].x_point &&
                            errorTextval &&
                            errorTextval &&
                            errorTextval[index] &&
                            errorTextval[index].x_point)
                        )}
                        margin="normal"
                        className={classes.textWidth}
                        helperText={
                          touchTextval &&
                            touchTextval[index] &&
                            touchTextval[index].x_point &&
                            errorTextval &&
                            errorTextval &&
                            errorTextval[index] &&
                            errorTextval[index].x_point
                        }
                        disabled={isparent === 1}
                      />
                    </Grid>
                    <Grid item key={index+'_y_point'} md={2} xs={12}>
                      <TextField key={index+'_y_point_text'}
                        name={`field_array.${index}.y_point`}
                        label={`Y Point`}
                        variant="outlined"
                        value={optionval.y_point}
                        onChange={(e) => {
                          if (nameval[index] !== undefined) {
                            arrayHelpers.replace(index, {
                              id: optionval.id,
                              field_name: nameval[index].field_name,
                              x_point: nameval[index].x_point,
                              y_point: e.target.value,
                              page_no: nameval[index].page_no,
                            });
                          } else {
                            arrayHelpers.push({
                              id: optionval.id,
                              field_name: nameval[index].field_name,
                              x_point: nameval[index].x_point,
                              y_point: e.target.value,
                              page_no: nameval[index].page_no,
                            });
                          }
                        }}
                        error={Boolean(
                          (touchTextval &&
                            touchTextval[index] &&
                            touchTextval[index].y_point &&
                            errorTextval &&
                            errorTextval &&
                            errorTextval[index] &&
                            errorTextval[index].y_point)
                        )}
                        margin="normal"
                        className={classes.textWidth}
                        helperText={
                          touchTextval &&
                            touchTextval[index] &&
                            touchTextval[index].y_point &&
                            errorTextval &&
                            errorTextval &&
                            errorTextval[index] &&
                            errorTextval[index].y_point
                        }
                        disabled={isparent === 1}
                      />
                    </Grid>
                    <Grid item key={index+'_page_no'} md={2} xs={12}>
                      <TextField key={index+'_page_no_text'}
                        name={`field_array.${index}.page_no`}
                        label={`Page No.`}
                        variant="outlined"
                        value={optionval.page_no}
                        onChange={(e) => {
                          if (nameval[index] !== undefined) {
                            arrayHelpers.replace(index, {
                              id: optionval.id,
                              field_name: nameval[index].field_name,
                              x_point: nameval[index].x_point,
                              y_point: nameval[index].y_point,
                              page_no: e.target.value,
                            });
                          } else {
                            arrayHelpers.push({
                              id: optionval.id,
                              field_name: nameval[index].field_name,
                              x_point: nameval[index].x_point,
                              y_point: nameval[index].y_point,
                              page_no: e.target.value,
                            });
                          }
                        }}
                        error={Boolean(
                          (touchTextval &&
                            touchTextval[index] &&
                            touchTextval[index].page_no &&
                            errorTextval &&
                            errorTextval &&
                            errorTextval[index] &&
                            errorTextval[index].page_no)
                        )}
                        margin="normal"
                        className={classes.textWidth}
                        helperText={
                          touchTextval &&
                            touchTextval[index] &&
                            touchTextval[index].page_no &&
                            errorTextval &&
                            errorTextval &&
                            errorTextval[index] &&
                            errorTextval[index].page_no
                        }
                        disabled={isparent === 1}
                      />
                    </Grid>
                    <Grid item key={index+'_button'} md={2} xs={12}>
                      <Button key={index+'_button_text'}
                        onClick={() => arrayHelpers.remove(index)}
                        color="secondary"
                        variant="contained"
                        margin="normal"
                        style={{ margin: "15px 10px", padding: "15px" }}
                        disabled={isparent === 1}
                      >
                        {" "}
                        -{" "}
                      </Button>
                    </Grid>
                  </Grid>
                );
              }
            })}
        </Grid>
      )}
    />
  );
};

export default FormFieldDetails;
