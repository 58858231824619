import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  makeStyles,
  Card,
  CardHeader,
  Divider,
  Button,
} from "@material-ui/core";
import Page from "src/components/Page";
import Statistics from "./Statistics";
import Barchart from "./Barchart";
import Linechart from "./Linechart";
import Piechart from "./Piechart";
import ApplicationListView from "./ApplicationListView";
import Filter from "./Filter";
import * as Http from "src/services/Http";
import PeopleIcon from "@material-ui/icons/PeopleOutlined";
import { connect } from "react-redux";
import { setSnackbar } from "src/actions";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import ScreenLoader from "src/components/ScreenLoader";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import DonutLargeIcon from "@material-ui/icons/DonutLarge";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const DashboardView = (props) => {
  const classes = useStyles();
  const [chart, setchart] = useState({});
  const [totaluser, settotaluser] = useState(0);
  const [totalvisitors, settotalvisitors] = useState(0);
  const [totalinprogressapp, settotalinprogressapp] = useState(0);
  const [totalcompleteapp, settotalcompleteapp] = useState(0);
  const [appdata, setappdata] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [chartmode, setchartmode] = useState(null);
  const [barchartVariant, setbarchartVariant] = useState("outlined");
  const [linechartVariant, setlinechartVariant] = useState("outlined");

  const getchartdata = async (fillterData) => {
    await Http.call("POST", "admin/dashboard", {
      ...fillterData,
    }).then(async (response) => {
      if (response.status === 200) {
        setLoading(false);
        setchart(response.data.chart_data);
        settotaluser(response.data.total_user);
        settotalvisitors(response.data.total_visitors);
        settotalinprogressapp(response.data.total_in_progress_app);
        settotalcompleteapp(response.data.total_complete_app);
        setappdata(response.data.app_data);
        CounsellorsData();
      } else {
      }
    });
  };

  const changeChart = (mode) => {
    setchartmode(mode);
    if (mode === "bar") {
      setbarchartVariant("contained");
      setlinechartVariant("outlined");
    } else {
      setbarchartVariant("outlined");
      setlinechartVariant("contained");
    }
  };
  const [counsellorsData, setCounsellorsData] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);
  const CounsellorsData = async () => {
    try {
      const response = await Http.call("GET", "admin/counselors");

      if (response.status === 200) {
        setCounsellorsData(response.data);
        // props.setSnackbar(true, response.status, response.message);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const counsellorUnassign = async (data) => {
    setLoading(true);
    try {
      await Http.call(
        "POST",
        "admin/counselors/application/unassign",
        data
      ).then(async (response) => {
        if (response.status === 200) {
          props.setSnackbar(true, response.status, response.message);
        } else {
          props.setSnackbar(true, response.status, response.message);
        }
      });
      setLoading(false);
      let fillterData = {
        mode: "month",
        start_date: "",
        end_date: "",
      };
      getchartdata(fillterData);
    } catch (e) {
      console.log(e);
    }
  };
  const counsellorUpdate = async (data) => {
    setLoading(true);
    try {
      await Http.call("POST", "admin/counselors/application", data).then(
        async (response) => {
          if (response.status === 200) {
            props.setSnackbar(true, response.status, response.message);
            setSelectedIds([]);
          } else {
            props.setSnackbar(true, response.status, response.message);
          }
        }
      );
      setLoading(false);
      let fillterData = {
        mode: "month",
        start_date: "",
        end_date: "",
      };
      getchartdata(fillterData);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    let fillterData = {
      mode: "month",
      start_date: "",
      end_date: "",
    };
    getchartdata(fillterData);
    changeChart("line");
  }, []);

  if (Loading) {
    return <ScreenLoader />;
  }

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <Filter getchartdata={getchartdata} />{" "}
          </Grid>{" "}
          <Grid item lg={6} sm={6} xl={3} xs={12}>
            <Statistics
              total={totaluser}
              name="TOTAL CUSTOMERS"
              icon={<PersonOutlineOutlinedIcon />}
            />{" "}
          </Grid>{" "}
          <Grid item lg={6} sm={6} xl={3} xs={12}>
            <Statistics
              total={totalvisitors}
              name="TOTAL VISITORS"
              icon={<VisibilityOutlinedIcon />}
            />{" "}
          </Grid>{" "}
          <Grid item lg={6} sm={6} xl={3} xs={12}>
            <Statistics
              total={totalinprogressapp}
              name="APPLICATIONS IN PROGRESS"
              icon={<DonutLargeIcon />}
            />{" "}
          </Grid>{" "}
          <Grid item lg={6} sm={6} xl={3} xs={12}>
            <Statistics
              total={totalcompleteapp}
              name="COMPLETED APPLICATIONS"
              icon={<CheckCircleOutlineIcon />}
            />{" "}
          </Grid>{" "}
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <Card>
              <CardHeader
                action={
                  <>
                    <Button
                      style={{
                        marginRight: "7px",
                      }}
                      color="primary"
                      size="small"
                      variant={linechartVariant}
                      onClick={() => changeChart("line")}
                    >
                      Line Chart{" "}
                    </Button>{" "}
                    <Button
                      size="small"
                      color="primary"
                      variant={barchartVariant}
                      onClick={() => changeChart("bar")}
                    >
                      Bar Chart{" "}
                    </Button>{" "}
                  </>
                }
                title="Total Revenue"
              />
              <Divider />{" "}
              {chartmode === "bar" ? (
                <Barchart chart_data={chart} />
              ) : (
                <Linechart chart_data={chart} />
              )}{" "}
              <Divider />
            </Card>{" "}
          </Grid>{" "}
          <Grid item lg={6} sm={6} xl={6} xs={12}>
            <Piechart
              title="Total Users"
              data_1={totaluser}
              data_2={totalvisitors}
            />{" "}
          </Grid>{" "}
          <Grid item lg={6} sm={6} xl={6} xs={12}>
            <Piechart
              title="Total Applications"
              data_1={totalinprogressapp}
              data_2={totalcompleteapp}
            />{" "}
          </Grid>{" "}
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <ApplicationListView
              app_data={appdata}
              userData={props.userData}
              counsellorUnassign={counsellorUnassign}
              counsellorsDataArr={counsellorsData}
              counsellorUpdate={counsellorUpdate}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

// export default DashboardView;
const mapStateToProps = (state) => ({
  userData: state.session.user,
});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardView);
