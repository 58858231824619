import React, { useState, useEffect } from "react";
import { Box, Button, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import * as Http from "src/services/Http";
import { connect, useSelector } from "react-redux";
import ScreenLoader from "src/components/ScreenLoader";
import { setSnackbar } from "src/actions";
import Page from "src/components/Page";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";
// import Toolbar from "./Toolbar";
import Results from "./Results";
import { useParams } from "react-router";

// const breadUrlList = [
//   { name: "Home", url: "/dashboard" },
//   { name: "Contacts", url: "/" },
// ];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  paper: {
    width: "40%",
    maxHeight: 435,
  },
}));

const List_contacts = (props) => {
  const [userList, setUserList] = useState([]);
  const [Loading, setLoading] = useState(true);
  const classes = useStyles();
  const authuser = useSelector((state) => state?.session?.user);
  const fetchUserList = async () => {
    try {
      if(props.id)
      {
        const response = await Http.call("GET", `admin/lookup_appcontact/${props.id}`);
        setUserList(response.data);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const multipleDelete = async (data, isContactDelete) => {
    setLoading(true);
    try {
      await Http.call("POST", "admin/lookup_dirs/delete", {
        id: data,
        iscontactdelete: isContactDelete,
      }).then(async (response) => {
        fetchUserList();
        props.setSnackbar(true, response.status, response.message);
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchUserList();
  }, []);

  const handleReplace = async (selectedIds) => {
    setLoading(true);
    try {
      const response = await Http.call("POST", "api/application_replace", {
        selectedIds: selectedIds,
      });
      // Handle the response as needed
      props.setSnackbar(true, response.status, response.message);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  if (Loading) {
    return <ScreenLoader />;
  }

  return (
    <Page className={classes.root} title="Contacts">
      <Container maxWidth="lg">
        {/* <BreadcrumbsAdmin urlList={breadUrlList} /> */}
        {/* <Toolbar authuser={authuser} /> */}
        {/* <Button
            color="primary"
            variant="contained"
            // component={RouterLink}
          >
            Add Contacts
          </Button> */}
        <Box mt={2}>
          <Results
            users={userList}
            pageLoading={setLoading}
            fetchUser={fetchUserList}
            handleReplace={handleReplace}
            multipleDelete={multipleDelete}
            authuser={authuser}
            onClose={props.onClose}
            fetchUserList = {props.fetchUserList}
          />
        </Box>
      </Container>
    </Page>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(List_contacts);
