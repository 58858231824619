import React, { useEffect, useState } from "react";
import { Container, Grid, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { setSnackbar } from "src/actions";
import Page from "src/components/Page";
import CompaniesDetails from "../Components/CompaniesDetails";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";
import * as Http from "src/services/Http";
import ScreenLoader from "src/components/ScreenLoader";

const breadUrlList = [
  { name: "Home", url: "/dashboard" },
  { name: "Companies", url: "/app/companies" },
  { name: "Add Company", url: "/" },
];
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  companyDetail: {
    marginTop: theme.spacing(3),
  },
}));

const AddCompanyView = (props) => {
  const classes = useStyles();
  const values = {
    company: "",
    address: "",
    fulladdress: {},
    category_id: "",
    email: "",
    phone: "",
    fax: "",
    staffusers: [],
  };
  const [Loading, setLoading] = useState(true);
  const [userList, setUserlist] = useState([]);
  const fetchUserList = async () => {
    try {
      const responseUser = await Http.call(
        "GET",
        "admin/lookup_dir/contact/unassign"
      );
      setUserlist(responseUser.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };
  const [contactCategory, setContactCategory] = useState([]);
  const fetchContactCategories = async () => {
    try {
      const response = await Http.call("GET", "admin/lookup_categories");
      setContactCategory(response.data);
      fetchUserList();
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    fetchContactCategories();
  }, []);
  if (Loading) {
    return <ScreenLoader />;
  }
  return (
    <Page className={classes.root} title="Add Company">
      <Container maxWidth="lg">
        <BreadcrumbsAdmin urlList={breadUrlList} />
        <Grid container spacing={3} className={classes.companyDetail}>
          <Grid item md={12}>
            <CompaniesDetails
              formValues={values}
              setSnackbar={props.setSnackbar}
              userList={userList}
              contactCategory={contactCategory}
              mode={"Add"}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCompanyView);
