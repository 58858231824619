import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControlLabel,
  FormLabel,
  Grid,
  Switch,
  TextField,
} from "@material-ui/core";
import Cancelbtn from "src/components/common/Cancelbtn";
// import CustomPhoneInput from "src/components/common/CustomPhoneInput";
// import PasswordInput from "src/components/common/PasswordInput";
import * as Messages from "src/constants/Messages/index";
import TextMaskCustom from "src/components/TextMaskCustom";

/*const mobileNoFormate = (arg)=>{
  
  let cleaned = ('' + arg).replace(/\D/g, '');
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
}*/

/*const phoneRegExp = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;*/
// const passRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/;
// const onlyAlphaAllowRegExp = /^[A-Za-z ]*$/;

const AddUserSchema = Yup.object().shape({
  first_name: Yup.string()
    // .min(2, Messages.TOO_SHORT)
    // .matches(onlyAlphaAllowRegExp, Messages.ONLY_ALPABATES)
    .required(Messages.REQUIRED),
  last_name: Yup.string()
    // .min(2, Messages.TOO_SHORT)
    // .matches(onlyAlphaAllowRegExp, Messages.ONLY_ALPABATES)
    .required(Messages.REQUIRED),
  email: Yup.string()
    .lowercase()
    .email(Messages.VALID_EMAIL)
    .required(Messages.REQUIRED),
  // password: Yup.string()
  //   .min(6, Messages.PASSWORD_MIN_6)
  //   .matches(
  //     passRegExp,
  //     "Must contain at least 1 capital letter, 1 small letter, 1 special character and 1 number"
  //   ),
  // confirmPassword: Yup.string().oneOf(
  //   [Yup.ref("password"), null],
  //   "Passwords must match"
  // ),
  mobile: Yup.string()
    .test("mobile", "Invalid Phone.", (val = "") => {
      const val_length_without_dashes = val.replace(/[\s\s+() -]/g, "").length;
      return val_length_without_dashes === 11;
    })
    .required(Messages.REQUIRED),
});
const EditUserDetail = (props) => {
  const { formValues, updateUser } = props;
  const [checkPhoneNumber, setCheckPhoneNumber] = useState(0);
  const initialValues = {
    first_name: formValues.first_name,
    last_name: formValues.last_name,
    email: formValues.email,
    //mobile: mobileNoFormate(formValues.mobile),
    mobile: formValues.mobile,
    // password: formValues.password,
    // confirmPassword: "",
    is_verify:
      formValues.id === undefined || formValues.id === ""
        ? 0
        : formValues.is_verify,
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={AddUserSchema}
      //onSubmit={updateProfile}
      onSubmit={async (values, actions) => {
        /*values.mobile = values.mobile
          .replace(" ", "")
          .replace("-", "")
          .replace("(", "")
          .replace(")", "");*/
        if (checkPhoneNumber !== 1) {
          await updateUser(values, actions);
        }
        //actions.setSubmitting(false);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => {
        return (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Card>
              <CardHeader
                subheader="The information can be edited"
                title="Edit User"
              />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      name="first_name"
                      label="First Name"
                      variant="outlined"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.first_name}
                      error={Boolean(touched.first_name && errors.first_name)}
                      margin="normal"
                      helperText={touched.first_name && errors.first_name}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Last Name"
                      name="last_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.last_name}
                      margin="normal"
                      variant="outlined"
                      error={Boolean(touched.last_name && errors.last_name)}
                      helperText={touched.last_name && errors.last_name}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Email"
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      type="email"
                      variant="outlined"
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    {/* <CustomPhoneInput
                      name={"mobile"}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      setCheckPhoneNumber={setCheckPhoneNumber}
                    /> */}
                    <TextField
                      fullWidth
                      label="Phone"
                      margin="normal"
                      name="mobile"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.mobile}
                      variant="outlined"
                      error={Boolean(touched.mobile && errors.mobile)}
                      helperText={touched.mobile && errors.mobile}
                      InputProps={{
                        inputComponent: TextMaskCustom,
                      }}
                    />
                  </Grid>
                  {/* <Grid item md={6} xs={12}>
                    <PasswordInput label="Password" name="password" />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <PasswordInput
                      label="Confirm Password"
                      name="confirmPassword"
                    />
                  </Grid> */}
                  <Grid item md={6} xs={12}>
                    <FormLabel style={{ margin: "0 10px 0 0" }}>
                      Verified:
                    </FormLabel>
                    <FormControlLabel
                      control={
                        <Switch
                          label="Verified"
                          name="is_verify"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue("is_verify", 1);
                            } else {
                              setFieldValue("is_verify", 0);
                            }
                          }}
                          // error={Boolean(touched.is_verify && errors.is_verify)}
                          // helperText={touched.is_verify && errors.is_verify}
                          checked={values.is_verify === 1 ? 1 : 0}
                          color="primary"
                        />
                      }
                      // label={values.is_verify === 1 ? "Active" : "Inactive"}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box display="flex" justifyContent="flex-end" p={2}>
                <Cancelbtn />
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </Box>
            </Card>
          </form>
        );
      }}
    </Formik>
  );
};

export default EditUserDetail;
