import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const BorderLinearProgress = styled(LinearProgress)(
  ({ theme, customcolor }) => ({
    height: 15,
    width: "100%",
    alignSelf: "center",
    [`&.${linearProgressClasses.colorPrimary}`]: {
      borderRadius: 5,
      backgroundColor: customcolor ? customcolor + "10" : "#ffffff50",
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: customcolor ? customcolor + "60" : "#ffffff",
    },
    marginTop: 20,
    marginBottom: customcolor ? 20 : 0,
  })
);

export default BorderLinearProgress;
