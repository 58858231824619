import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import { Container } from "@material-ui/core";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";
import Page from "src/components/Page";
import TabPanel from "src/components/TabPanel";
import SectionListView from "src/views/Sections/ListSectionView";
import GreatWesternSectionListView from "src/views/Sections/GreatWesternSectionListView";
import SectionsToolbar from "src/views/Sections/ListSectionView/Toolbar";
import GreatWesternSectionToolbar from "src/views/Sections/GreatWesternSectionListView/Toolbar";
import { useSelector } from "react-redux";

const breadUrlList = [
  { name: "Home", url: "/dashboard" },
  { name: "Section List", url: "/" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 323,
    marginTop: "10px",
  },
  page: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

export default function SectionTabView() {
  const classes = useStyles();
  const [value, setValue] = useState("one");
  const authuser = useSelector((state) => state?.session?.user);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page title="Section List" className={classes.page}>
      <Container maxWidth={false}>
        <BreadcrumbsAdmin urlList={breadUrlList} />
        {value === "one" ? (
          <SectionsToolbar authuser={authuser} />
        ) : (
          <GreatWesternSectionToolbar authuser={authuser} />
        )}
        <Paper className={classes.root}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="wrapped label tabs example"
          >
            <Tab
              value="one"
              label="Questionnaire"
              wrapped
              {...a11yProps("one")}
            />
            <Tab
              value="two"
              wrapped
              label="Great Western"
              {...a11yProps("two")}
            />
          </Tabs>
        </Paper>
        <TabPanel value={value} index="one">
          <SectionListView />
        </TabPanel>
        <TabPanel value={value} index="two">
          <GreatWesternSectionListView />
        </TabPanel>
      </Container>
    </Page>
  );
}
