import * as React from "react";
import { FormControl, FormHelperText, TextareaAutosize } from "@mui/material";
import { useField } from "formik";

const TextareaBoxField = ({ name, label, ...props }) => {
  const [field, meta] = useField(name);
  
  return (
    <FormControl component="fieldset" fullWidth>
      <TextareaAutosize
        minRows={3}
        placeholder={label}
        style={{ width: "100%" }}
        autoFocus={true}
        autoComplete="off"
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
        {...field}
        {...props}
      />
      <FormHelperText error>
        {meta.touched && meta.error !== "" ? meta.error : undefined}
      </FormHelperText>
    </FormControl>
  );
};
export default TextareaBoxField;
