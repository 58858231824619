import React, { useState, useEffect } from "react";
import { Container, Grid, Box, makeStyles } from "@material-ui/core";
import Page from "src/components/Page";
import Profile from "./Profile";
import ProfileDetails from "./ProfileDetails";
import * as Http from "src/services/Http";
import { connect } from "react-redux";
import ScreenLoader from "src/components/ScreenLoader";
import { setImage, setSnackbar, resetImage } from "src/actions";
import Toolbar from "./Toolbar";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";

const breadUrlList = [
  { name: "Home", url: "/dashboard" },
  { name: "Account", url: "/app/account" },
];
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const Account = (props) => {
  const classes = useStyles();
  const [Loading, setLoading] = useState(true);
  const [isImage, setisImage] = useState(0);
  const [removeImage, setRemoveImage] = useState(0);
  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    state: "",
    country: "USA",
    image: null,
    file: null,
  });
  const fetchUsers = async () => {
    try {
      await Http.call("GET", "admin/auth/profile").then(async (response) => {
        setValues({
          first_name: response.data.first_name,
          last_name: response.data.last_name,
          email: response.data.email,
          mobile: response.data.mobile,
          state: "Alabama",
          country: "USA",
        });
        setLoading(false);
      });
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    fetchUsers();
  }, []);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const updateProfile = async (data, actions) => {
    setLoading(true);
    await Http.call("PUT", "admin/users/" + props.current_user_id, {
      ...data,
      isImage: isImage,
      image:
        values.image !== null && values.image !== undefined ? values.image : "",
      role: 1,
      _method: "PUT",
    }).then(async (response) => {
      if (response.status === 200) {
        setValues(data);
        props.setImage(response.data.image);
        if (response.data.image !== "") {
          props.setImage(response.data.image);
          props.resetImage(1);
        }
        props.setSnackbar(true, response.status, response.message);
        actions.setSubmitting(true);
      } else {
        actions.setErrors(response.data[0].errors);
        actions.setSubmitting(false);
      }
      setLoading(false);
    });
  };
  if (Loading) {
    return <ScreenLoader />;
  }
  return (
    <Page className={classes.root} title="Account">
      <Container maxWidth="lg">
        <BreadcrumbsAdmin urlList={breadUrlList} />
        <Toolbar />
        <Box mt={3}>
          <Grid container spacing={3}>
            <Grid item lg={4} md={6} xs={12}>
              <Profile
                values={values}
                setvalues={setValues}
                setSnackbar={props.setSnackbar}
                isImage={isImage}
                setisImage={setisImage}
              />
            </Grid>
            <Grid item lg={8} md={6} xs={12}>
              <ProfileDetails
                formValues={values}
                handleChange={handleChange}
                updateProfile={updateProfile}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};
const mapStateToProps = (state) => ({
  current_user_id: state.session.user.id,
});

const mapDispatchToProps = {
  setImage,
  setSnackbar,
  resetImage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
