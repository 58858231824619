import React from "react";
import { Box } from "@material-ui/core";
import Results from "./Results";
import { connect } from "react-redux";
import { setSnackbar } from "src/actions";

const UserListView = ({
  counsellorUnassign,
  counsellorsDataArr,
  counsellorUpdate,
  app_data,
  userData,
}) => {
  return (
    <Box>
      <Results
        app_data={app_data}
        userData={userData}
        counsellorUnassign={counsellorUnassign}
        counsellorsDataArr={counsellorsDataArr}
        counsellorUpdate={counsellorUpdate}
      />
    </Box>
  );
};

//export default UserListView;
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserListView);
