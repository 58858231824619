import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PreQuestListDetails from "./Components/PreQuestListDetails";
import * as Http from "src/services/Http";
import { createUseStyles } from "react-jss";
import { Grid } from "@material-ui/core";
// import { configureStore } from "src/redux/store";
import Page from "src/components/Page";
import ScreenLoader from "src/components/ScreenLoader";
import { useParams } from "react-router";

const bgimage = localStorage.getItem("postBgImage");
const useStyles = createUseStyles({
  bgImg: {
    background: bgimage
      ? "url(" + bgimage + ") no-repeat"
      : "url(/img/m.jpg) no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    height: "100vh",
  },
  avatar: {
    height: 100,
    width: 100,
  },
  input: {
    display: "none",
  },
});

export const PreQuestListView = (props) => {
  const classes = useStyles();
  const [Values, setValues] = useState();
  const [isLoading, setisLoading] = useState(true);

  const { id } = useParams();
  let userId = "";
  if (id === undefined) {
    userId = localStorage.getItem("rand_token");
    userId = !isNaN(+userId) === false ? undefined : userId;
  } else {
    userId = id;
  }

  // const store = configureStore();
  // const isPaymentChk = store.getState().session.paymentchk;

  const handleChange = (event) => {
    setValues({
      ...Values,
      [event.target.name]: event.target.value,
    });
  };
  useEffect(() => {
    console.log("first");
    const fetchPrequestUserAns = async () => {
      try {
        const { data } = await Http.call(
          "GET",
          "admin/pre_quest_user_answer/" + userId
        );
        setValues(data);
        setisLoading(false);
        console.log("called");
      } catch (e) {
        console.log(e);
        console.log("error");
      }
    };
    fetchPrequestUserAns();
  }, [userId]);

  return (
    <Page title={"Preliminary Questionnaire"}>
      <Grid item >
        <div className="postque-bg">
          <div className="que-data">
            <h6 className="ques-title">Pre Questionnaire Responses</h6>
            <PreQuestListDetails
              data={Values}
              handleChange={handleChange}
              setValues={setValues}
            />
          </div>
        </div>
      </Grid>
    </Page>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PreQuestListView);
