import React from "react";
import { useField } from "formik";
import PhoneInput from "react-phone-input-2";
import * as Messages from "src/constants/Messages/index";
const CustomfaxInput = ({ name, handleChange, handleBlur, ...otherProps }) => {
  const [field, mata, helpers] = useField(name);
  return (
    <>
      <PhoneInput
        style={{
          marginTop: "16px",
          marginBottom: "8px",
          borderColor: "#f64538",
        }}
        inputStyle={{ width: "inherit" }}
        specialLabel="Fax Number"
        country={"us"}
        // disableDropdown={true}
        // countryCodeEditable={false}
        inputProps={{
          name: name,
        }}
        name={name}
        onChange={(e) => {
          helpers.setValue(e);
          handleChange(e);
        }}
        onBlur={handleBlur}
        value={field.value}
        isValid={(inputNumber, country, countries) => {
          let length = inputNumber.slice(country.countryCode.length).length;
          if (!(length > 6 && length <= 10)) {
            if (length === 0) {
              return true;
            } else if (!mata.error) {
              helpers.setError(Messages.INVALID_FAX);
            } else if (mata.touched) {
              return false;
            }
          }
          return true;
        }}
      />
      {mata.touched && mata.error && <div className="error">{mata.error}</div>}
    </>
  );
};

export default CustomfaxInput;
