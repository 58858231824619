import * as actionTypes from "../actions";

const initialState = {
  loggedIn: false,
  token: "",
  user: {},
  image: "",
  questionActiveTab: "pre_quest",
  userActiveTab: "admin_user",
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SESSION_LOGIN: {
      return {
        ...initialState,
        loggedIn: true,
        token: action.payload.token,
        user: action.payload.data,
        image: action.payload.data.image,
      };
    }

    case actionTypes.SESSION_LOGOUT: {
      return {
        ...state,
        loggedIn: false,
        token: null,
        user: {
          role: "GUEST",
        },
      };
    }
    case actionTypes.SESSION_SET_IMAGE: {
      return {
        ...state,
        image: action.payload.data,
      };
    }
    case actionTypes.SESSION_RESET_IMAGE: {
      return {
        ...state,
        reset_image: action.payload.data,
      };
    }
    case actionTypes.SET_QUESTION_ACTIVE_TAB: {
      return {
        ...state,
        questionActiveTab: action.payload.questionActiveTab,
      };
    }
    case actionTypes.SET_USER_ACTIVE_TAB: {
      return {
        ...state,
        userActiveTab: action.payload.userActiveTab,
      };
    }

    default: {
      return state;
    }
  }
};

export default sessionReducer;
