import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  Switch,
  FormControlLabel,
  FormLabel,
  Checkbox,
} from "@material-ui/core";
import * as Http from "src/services/Http";
import TextboxPlaceholderDetails from "./TextboxPlaceholderDetails";
import RadioButtonboxDetails from "./RadioButtonboxDetails";
import * as Messages from "src/constants/Messages/index";
import Cancelbtn from "src/components/common/Cancelbtn";
import FormFieldDetails from "./FormFieldDetails";
import RichEditor from "src/components/RichEditor";
import ScreenLoader from "src/components/ScreenLoader";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { id } from "date-fns/locale";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectControl: {
    minWidth: "100%",
    maxWidth: "100%",
    margin: "15px 0",
  },
  selectControlFile: {
    minWidth: "49%",
    maxWidth: "49%",
    margin: "15px 0",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const question_type = [
  {
    id: 0,
    value: 0,
    label: "Pre Questionnaire",
  },
  {
    id: 1,
    value: 1,
    label: "Questionnaire",
  },
  {
    id: 2,
    value: 2,
    label: "Great western",
  },
];

const controll_handler_lists = [
  {
    id: 2,
    label: "Image",
    ext: ["jpg", "jpeg", "png"],
  },
  {
    id: 3,
    label: "Document",
    ext: ["doc", "docx", "xlx", "xlsx", "pdf", "ppt", "pptx"],
  },
  {
    id: 4,
    label: "Both",
    ext: [
      "jpg",
      "jpeg",
      "png",
      "doc",
      "docx",
      "xlx",
      "xlsx",
      "pdf",
      "ppt",
      "pptx",
    ],
  },
];
const AddQuestionSchema = Yup.object().shape({
  question: Yup.string()
    .min(10, Messages.TOO_SHORT)
    .required(Messages.REQUIRED),
  type: Yup.string().required(Messages.REQUIRED),
  section_id: Yup.string().when("type", {
    is: "1",
    then: Yup.string().required(Messages.REQUIRED),
  }),
  // sequence: Yup.number().required(Messages.REQUIRED),
  control_id: Yup.string().required(Messages.REQUIRED),
  placeholder: Yup.string().when("control_id", {
    is: (control_id) => /^(1|8|9|10|11)$/.test(control_id),
    then: Yup.string().required(Messages.REQUIRED),
  }),

  answers: Yup.array().when(["control_id", "control_handler"], {
    is: (control_id, control_handler) => /^(2|3)$/.test(control_id),
    then: Yup.array()
      .of(Yup.string().required(Messages.REQUIRED))
      .min(1, Messages.MINONEREQUIRED),
  }),
});

const QuestionDetails = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    mode,
    parentquestion,
    parentQueAns,
    setparentQuestionAns,
    controllists,
    formValues,
    sectionlistdata,
    setSnackbar,
    docuforms,
  } = props;
  const initialValues = {
    id: formValues.id,
    question: formValues.question,
    description: formValues.description,
    section_id: formValues.section_id,
    type: formValues.type,
    parent_id: formValues.parent_id,
    answer_id:
      formValues.answer_id !== undefined && formValues.answer_id !== ""
        ? formValues.answer_id
        : [],
    control_id: formValues.control_id,
    placeholder: formValues.placeholder,
    answers: formValues.answers,
    control_handler: formValues.control_handler,
    sequence: formValues.sequence,
    is_skip:
      formValues.id === undefined || formValues.id === ""
        ? 0
        : formValues.is_skip,
    status:
      formValues.id === undefined || formValues.id === ""
        ? 1
        : formValues.status,
    ext:
      formValues.ext === undefined
        ? []
        : formValues.ext.length > 1
        ? formValues.ext.split(",")
        : [],
    is_parent: formValues.is_parent,
    que_forms_fields_arr:
      formValues.que_forms_fields_arr.length > 0
        ? formValues.que_forms_fields_arr
        : [{ form: "", fields: [] }],
  };
  const [questionTitle, setQuestiontitle] = useState(formValues.question);
  const [isLoading, setLoading] = useState(false);
  // const [exts, setExts] = useState(formValues.ext);
  const [questionDescription, setQuestiondescription] = useState(
    formValues.description
  );
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  if (isLoading) {
    return <ScreenLoader />;
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={AddQuestionSchema}
      onSubmit={async (values, actions) => {
        let method = "";
        let url = "";
        let extens = values.ext.join(",");

        // console.log({ ...values, ext: extens });
        // return false;
        if (formValues.id === undefined) {
          method = "POST";
          url = "admin/questionnaire";
        } else {
          method = "PATCH";
          url = "admin/questionnaire/" + formValues.id;
        }
        setLoading(true);
        await Http.call(method, url, { ...values, ext: extens }).then(
          async ({ status, message, data }) => {
            if (status === 200) {
              navigate("/app/questionnaire");
            } else if (status === 400) {
              if (data.name) {
                actions.setFieldError("name", data.name[0]);
              }
            }
            setSnackbar(true, status, message);
          }
        );
        actions.setSubmitting(false);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => {
        return (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Card>
              <CardHeader
                subheader={
                  mode.toLowerCase() === "edit"
                    ? "Please remember to save after editing any information below."
                    : "The information can be " +
                      mode.toLowerCase() +
                      " question"
                }
                title={mode + " Questionnaire"}
              />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <CardHeader title="Question" />

                    <RichEditor
                      name="question"
                      label="Question*"
                      placeholder="question"
                      defaultValue={values.question}
                      onChange={(event) => {
                        setQuestiontitle(event);
                        setFieldValue("question", event);
                      }}
                      minheight="50px"
                    />
                    {errors.question && touched.question && (
                      <FormHelperText className="Mui-error">
                        {errors.question}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <CardHeader title="Description" />
                    <RichEditor
                      name="description"
                      label="Description*"
                      placeholder="Description"
                      defaultValue={values.description}
                      onChange={(event) => {
                        setQuestiondescription(event);
                        setFieldValue("description", event);
                      }}
                      minheight="50px"
                    />
                    {Boolean(touched.description && errors.description) ? (
                      <FormHelperText className="Mui-error">
                        {errors.description}
                      </FormHelperText>
                    ) : null}
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormControl
                      variant="outlined"
                      className={classes.selectControl}
                    >
                      <InputLabel id="type">Question Type</InputLabel>
                      <Select
                        labelId="type"
                        id="type"
                        label="Question Type"
                        name="type"
                        value={values.type}
                        onChange={handleChange}
                        error={Boolean(touched.type && errors.type)}
                        disabled={values.id !== undefined} //values.id !== '' || values.id !== 0 || }
                        fullWidth
                      >
                        <MenuItem value="">
                          <em>Select Question Type</em>
                        </MenuItem>

                        {question_type.map((question_type) => (
                          <MenuItem
                            key={question_type.id}
                            value={question_type.id}
                          >
                            {question_type.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {Boolean(touched.type && errors.type) ? (
                        <FormHelperText className="Mui-error">
                          {errors.type}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormControl
                      variant="outlined"
                      className={classes.selectControl}
                    >
                      <InputLabel id="section_id">Section</InputLabel>
                      <Select
                        labelId="section_id"
                        id="section_id"
                        label="Section"
                        name="section_id"
                        value={values.section_id}
                        onChange={handleChange}
                        disabled={
                          values.type === 0 ||
                          values.type === "" ||
                          values.type === undefined ||
                          values.id !== undefined
                        }
                        error={Boolean(touched.section_id && errors.section_id)}
                        fullWidth
                      >
                        <MenuItem value="">
                          <em>Select Section</em>
                        </MenuItem>

                        {sectionlistdata.map((sectionlist) =>
                          sectionlist.type === values.type ? (
                            <MenuItem
                              key={sectionlist.id}
                              value={sectionlist.id}
                            >
                              {sectionlist.name}
                            </MenuItem>
                          ) : (
                            ""
                          )
                        )}
                      </Select>
                      {Boolean(touched.section_id && errors.section_id) ? (
                        <FormHelperText className="Mui-error">
                          {errors.section_id}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormControl
                      variant="outlined"
                      className={classes.selectControl}
                      style={{
                        overflow: "hidden",
                        minHeight: "1.1876em",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <InputLabel id="parent_id">Parent Question</InputLabel>
                      <Select
                        labelId="parent_id"
                        id="parent_id"
                        label="Parent Question"
                        name="parent_id"
                        value={
                          values.parent_id !== undefined && values.parent_id > 0
                            ? values.parent_id
                            : ""
                        }
                        onChange={(e) => {
                          setparentQuestionAns(e.target.value);
                          setFieldValue("parent_id", e.target.value);
                          setFieldValue("answer_id", []);
                        }}
                        error={Boolean(touched.parent_id && errors.parent_id)}
                        fullWidth
                        //ellipsizeMode="tail"
                        style={{
                          overflow: "hidden",
                          minHeight: "1.1876em",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <MenuItem value="">
                          <em>Select Parent Question</em>
                        </MenuItem>
                        {parentquestion.map((parentquestionlist, index) =>
                          (parentquestionlist.type_id === 2 ||
                            values.section_id === "" ||
                            parentquestionlist.section_id ===
                              values.section_id) &&
                          parentquestionlist.type_id === values.type &&
                          ((values.sequence !== "" &&
                            parentquestionlist.sequence < values.sequence) ||
                            values.sequence < 1) &&
                          parseInt(formValues.id) !==
                            parseInt(parentquestionlist.id) ? (
                            <MenuItem
                              key={index}
                              value={parentquestionlist.id}
                              disabled={
                                parseInt(formValues.id) ===
                                parseInt(parentquestionlist.id)
                              }
                              style={{
                                overflow: "hidden",
                                minHeight: "1.1876em",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              }}
                            >
                              <span
                                style={{ textOverflow: "ellipsis" }}
                                dangerouslySetInnerHTML={{
                                  __html: parentquestionlist.question,
                                }}
                              />
                            </MenuItem>
                          ) : (
                            ""
                          )
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    {parentquestion.map((que, pque_index) =>
                      values.parent_id !== undefined &&
                      values.parent_id === que.id ? (
                        que.control_id === 2 ||
                        que.control_id === 3 ||
                        que.control_id === 4 ? (
                          <Autocomplete
                            multiple
                            key={pque_index}
                            id="answer_id"
                            options={parentQueAns}
                            name="answer_id"
                            value={values.answer_id}
                            disableCloseOnSelect
                            filterOptions={createFilterOptions({
                              matchFrom: "any",
                            })}
                            onChange={(e, newValue) => {
                              setFieldValue("answer_id", newValue);
                            }}
                            getOptionLabel={(option) =>
                              option ? option.answer : []
                            }
                            renderOption={(option, { selected }) => (
                              <React.Fragment>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  // checked={selected}
                                  checked={
                                    values.answer_id !== undefined &&
                                    values.answer_id !== ""
                                      ? values.answer_id.find(
                                          (v) => v.id === option.id
                                        )
                                        ? true
                                        : false
                                      : selected
                                  }
                                />
                                <div
                                  key={option.id}
                                  className="MuiListItemText-root MuiListItemText-multiline"
                                >
                                  <span className="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                                    {option.answer}
                                  </span>
                                </div>
                              </React.Fragment>
                            )}
                            // fullWidth
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Answer"
                                placeholder=""
                                margin="normal"
                              />
                            )}
                          />
                        ) : (
                          <TextField
                            fullWidth
                            label="Answer"
                            name="answer_id"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.answer_id}
                            margin="normal"
                            variant="outlined"
                            error={Boolean(
                              touched.answer_id && errors.answer_id
                            )}
                            helperText={touched.answer_id && errors.answer_id}
                            key={pque_index}
                          />
                        )
                      ) : (
                        ""
                      )
                    )}
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <FormControl
                      variant="outlined"
                      className={classes.selectControl}
                    >
                      <InputLabel id="control_id">Answer Type</InputLabel>
                      <Select
                        labelId="control_id"
                        id="control_id"
                        label="Answer Type"
                        name="control_id"
                        value={values.control_id}
                        onChange={(e) => {
                          setFieldValue("control_id", e.target.value);
                          setFieldValue("answers", [""]);
                        }}
                        error={Boolean(touched.control_id && errors.control_id)}
                        fullWidth
                        disabled={values.is_parent === 1}
                      >
                        <MenuItem value="">
                          <em>Select Answer Type</em>
                        </MenuItem>

                        {controllists.map((control, index) => (
                          <MenuItem key={index} value={control.id}>
                            {control.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {Boolean(touched.control_id && errors.control_id) ? (
                        <FormHelperText className="Mui-error">
                          {errors.control_id}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    {values.control_id === 1 ||
                    values.control_id === 8 ||
                    values.control_id === 9 ||
                    values.control_id === 10 ||
                    values.control_id === 11 ||
                    values.control_id === 17 ? (
                      <TextboxPlaceholderDetails
                        nameval="placeholder"
                        labelval="Placeholder"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        touchTextval={touched.placeholder}
                        errorTextval={errors.placeholder}
                        placholderval={values.placeholder}
                      />
                    ) : values.control_id === 2 || values.control_id === 3 ? (
                      <RadioButtonboxDetails
                        nameval={values.answers}
                        labelval="Option"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        touchTextval={touched.answers}
                        errorTextval={errors.answers}
                        isparent={values.is_parent}
                      />
                    ) : values.control_id === 4 ? (
                      <Grid item md={12} xs={12}>
                        <FormControl
                          variant="outlined"
                          className={classes.selectControlFile}
                        >
                          <InputLabel
                            htmlFor="grouped-select"
                            id="control_handler"
                            variant="outlined"
                          >
                            Control Select
                          </InputLabel>
                          <Select
                            labelId="control_handler"
                            id="control_handler"
                            label="Control Select"
                            name="control_handler"
                            value={values.control_handler}
                            onChange={(e) => {
                              setFieldValue("control_handler", e.target.value);
                            }}
                            error={Boolean(
                              touched.control_handler && errors.control_handler
                            )}
                            disabled={values.is_parent === 1}
                          >
                            <MenuItem value="">
                              <em>Select Control Handler</em>
                            </MenuItem>
                            <MenuItem key={0} value={1}>
                              City
                            </MenuItem>
                            <MenuItem key={1} value={5}>
                              State
                            </MenuItem>
                            <MenuItem key={2} value={6}>
                              County
                            </MenuItem>
                          </Select>
                        </FormControl>
                        {values.control_handler === "" ? (
                          <RadioButtonboxDetails
                            nameval={values.answers}
                            labelval="Option"
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            touchTextval={touched.answers}
                            errorTextval={errors.answers}
                            isparent={values.is_parent}
                          />
                        ) : (
                          ""
                        )}
                      </Grid>
                    ) : values.control_id === 6 ? (
                      <Grid item md={12} xs={12}>
                        <FormControl
                          variant="outlined"
                          className={classes.selectControlFile}
                        >
                          <InputLabel
                            htmlFor="grouped-select"
                            id="control_handler"
                            variant="outlined"
                          >
                            Control Select
                          </InputLabel>
                          <Select
                            labelId="control_handler"
                            id="control_handler"
                            label="Control Select"
                            name="control_handler"
                            value={values.control_handler}
                            onChange={handleChange}
                            error={Boolean(
                              touched.control_handler && errors.control_handler
                            )}
                          >
                            <MenuItem value="">
                              <em>Select Control Handler</em>
                            </MenuItem>
                            {controll_handler_lists.map((option, index) => (
                              <MenuItem key={index} value={option.id}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        <FormControl
                          variant="outlined"
                          className={classes.selectControlFile}
                          style={{ marginLeft: "2%" }}
                        >
                          <InputLabel
                            htmlFor="grouped-select"
                            id="ext"
                            variant="outlined"
                          >
                            Select Extention
                          </InputLabel>
                          <Select
                            labelId="ext"
                            id="ext"
                            label="Extention Select"
                            name="ext"
                            value={
                              values.ext.length && values.ext.length > 0
                                ? values.ext
                                : []
                            }
                            // onChange={handleChange}
                            onChange={(e) => {
                              setFieldValue("ext", e.target.value);
                            }}
                            multiple
                            error={Boolean(touched.ext && errors.ext)}
                          >
                            <MenuItem value="">
                              <em>Extention Select</em>
                            </MenuItem>
                            {controll_handler_lists.map((option, index) =>
                              values.control_handler !== undefined &&
                              values.control_handler === option.id
                                ? option.ext !== undefined &&
                                  option.ext.length > 0
                                  ? option.ext.map((exts, extsindex) => (
                                      <MenuItem
                                        key={extsindex}
                                        value={`${exts}`}
                                      >
                                        {exts}
                                      </MenuItem>
                                    ))
                                  : ""
                                : ""
                            )}
                          </Select>
                        </FormControl>
                      </Grid>
                    ) : (
                      <div></div>
                    )}
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormFieldDetails
                      nameval={values.que_forms_fields_arr}
                      labelval="Option"
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      touchTextval={touched.que_forms_fields_arr}
                      errorTextval={errors.que_forms_fields_arr}
                      isparent={values.is_parent}
                      docuforms={docuforms}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormLabel style={{ margin: "0 10px 0 0" }}>
                      Skippable:
                    </FormLabel>
                    <FormControlLabel
                      control={
                        <Switch
                          label="Skip"
                          name="is_skip"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue("is_skip", 1);
                            } else {
                              setFieldValue("is_skip", 0);
                            }
                          }}
                          checked={values.is_skip === 1 ? 1 : 0}
                          color="primary"
                        />
                      }
                      label={values.is_skip === 1 ? "Yes" : "No"}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormLabel style={{ margin: "0 10px 0 0" }}>
                      Status:
                    </FormLabel>
                    <FormControlLabel
                      control={
                        <Switch
                          label="Status"
                          name="status"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue("status", 1);
                            } else {
                              setFieldValue("status", 0);
                            }
                          }}
                          // error={Boolean(touched.status && errors.status)}
                          // helperText={touched.status && errors.status}
                          checked={values.status === 1 ? 1 : 0}
                          color="primary"
                        />
                      }
                      label={values.status === 1 ? "Active" : "Inactive"}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box display="flex" justifyContent="flex-end" p={2}>
                <Cancelbtn />
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </Box>
            </Card>
          </form>
        );
      }}
    </Formik>
  );
};

export default QuestionDetails;
