import React from "react";
import { Container, Grid, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { setSnackbar } from "src/actions";
import Page from "src/components/Page";
import PackageDetails from "../Components/PackageDetails";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";
//import Toolbar from "./Toolbar";

const breadUrlList = [
  { name: "Home", url: "/dashboard" },
  { name: "Services", url: "/app/services" },
  { name: "Add Service", url: "/" },
];
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  serviceDetail: {
    marginTop: theme.spacing(3),
  },
}));

const AddPackageView = (props) => {
  const classes = useStyles();
  const values = {
    name: "",
    price: "",
    description: "",
    is_lbs: 0,
    services: [],
    tax_type:"",
    taxable_amount:"",
  };
  return (
    <Page className={classes.root} title="Add Service">
      <Container maxWidth="lg">
        <BreadcrumbsAdmin urlList={breadUrlList} />
        <Grid container spacing={3} className={classes.serviceDetail} >
          <Grid item md={12}>
            <PackageDetails
              formValues={values}
              setSnackbar={props.setSnackbar}
              mode={"Add"}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPackageView);
