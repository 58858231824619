import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { setSnackbar } from "src/actions";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import EnhancedTableHead from "./EnhancedTableHead";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import DraggableComponent from "./DraggableComponent";
import DroppableComponent from "./DroppableComponent";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import { chkRoutePerm } from "src/services/Helper";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  container: {
    maxHeight: 740,
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  console.log("Remove Element:");
  console.log(removed);
  result.splice(endIndex, 0, removed);
  console.log("final Result");
  console.log(result);
  return result;
};

const QuestionTable = ({
  questions,
  setquestionnairelist,
  selectedItems,
  setSelItems,
  deleteQuestion,
  page_no,
  rowsPerPage,
  setPage,
  setRowsPerPage,
  totalquestion,
  changeSequenceQuestionnaire,
  ...rest
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("sequence");
  const [selected, setSelected] = React.useState([]);
  const [dense, setDense] = React.useState(false);
  const [active, setActive] = useState(0);
  const chkEditRoutPerm = chkRoutePerm(
    "/app/questionnaire/edit",
    rest.authuser
  );
  const chkDelRoutPerm = chkRoutePerm(
    "/app/questionnaire/delete",
    rest.authuser
  );
  // const [totalquestion, setTotalQuestion] = React.useState(
  //   questions ? questions.total_question : 0
  // );
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const headCells = [
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: "Actions",
      display: chkEditRoutPerm ? true : false,
    },
    {
      id: "question",
      numeric: false,
      disablePadding: true,
      label: "Question",
      display: true,
    },
    // { id: "type", numeric: false, disablePadding: false, label: "Type" },

    {
      id: "status",
      numeric: true,
      disablePadding: false,
      label: "Status",
      display: true,
    },
    {
      id: "section",
      numeric: true,
      disablePadding: false,
      label: "Section",
      display: true,
    },
    {
      id: "sequence",
      numeric: true,
      disablePadding: false,
      label: "Sequence",
      display: true,
    },
  ];

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = questions.map((n) => n.id);
      setSelItems(newSelecteds);
      return;
    }
    setSelItems([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selectedItems.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItems, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItems.slice(1));
    } else if (selectedIndex === selectedItems.length - 1) {
      newSelected = newSelected.concat(selectedItems.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItems.slice(0, selectedIndex),
        selectedItems.slice(selectedIndex + 1)
      );
    }

    setSelItems(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    if (totalquestion <= event.target.value) {
      setPage(0);
    }
    if (totalquestion > event.target.value) {
      let no_pages = Math.ceil(totalquestion / event.target.value);
      if (page_no > no_pages - 1) {
        setPage(no_pages - 1);
      }
    }
    setRowsPerPage(parseInt(event.target.value));
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleActiveation = (event) => {
    setActive(event.target.value);
  };

  const isSelected = (name) => selectedItems.indexOf(name) !== -1;
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, totalquestion - page_no * rowsPerPage);
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    console.log(
      `dragEnd ${result.source.index} to  ${result.destination.index}`
    );

    let sourceIndex = page_no * rowsPerPage + result.source.index;
    let destinationIndex = page_no * rowsPerPage + result.destination.index;

    // rest.changesequance({
    //   type: 1,
    //   source_sequence: sourceIndex,
    //   destination_sequence: destinationIndex,
    // });
    changeSequenceQuestionnaire({
      type: 1,
      source_sequence: sourceIndex,
      destination_sequence: destinationIndex,
    });
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selectedItems.length}
          setSelected={setSelItems}
          selectedItems={selectedItems}
          deleteItems={deleteQuestion}
          chkDelRoutPerm={chkDelRoutPerm}
        />
        <TableContainer className={classes.container}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              headCells={headCells}
              numSelected={selectedItems.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={totalquestion}
            />
            <TableBody component={DroppableComponent(onDragEnd)}>
              {stableSort(questions, getComparator(order, orderBy)).map(
                (row, index) => {
                  //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  {
                    /* console.log(row, "row_stablesort"); */
                  }
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  //active switch
                  if (active === 1 && row.status !== "Active") {
                    return null; // Skip inactive rows when switch is on
                  }else if(active === 2 && row.status !== "Inactive") {
                    return null;
                  } 

                  return (
                    <TableRow
                      component={DraggableComponent(
                        row.sequence.toString(),
                        index
                      )}
                      hover
                      //onClick={(event) => handleClick(event, row.question)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.question}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                          onClick={(event) => handleClick(event, row.id)}
                          disabled={chkDelRoutPerm ? false : true}
                        />
                      </TableCell>
                      {chkEditRoutPerm ? (
                        <TableCell align="center">
                          <Tooltip disableFocusListener title="Edit">
                            <Button
                              color="primary"
                              size="small"
                              component={RouterLink}
                              to={"/app/questionnaire/" + row.id}
                            >
                              <EditOutlinedIcon />
                            </Button>
                          </Tooltip>
                        </TableCell>
                      ) : (
                        ""
                      )}
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: row.question,
                          }}
                        ></div>
                      </TableCell>
                      {/* <TableCell align="right">{row.type}</TableCell> */}
                      <TableCell align="right">{row.status}</TableCell>
                      <TableCell align="right">{row.section}</TableCell>
                      <TableCell align="right">{row.sequence}</TableCell>
                    </TableRow>
                  );
                }
              )}
              {/* {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[]}
          // rowsPerPageOptions={[
          //   10,
          //   25,
          //   50,
          //   100,
          //   500,
          //   { value: totalquestion, label: "All" },
          // ]}
          component="div"
          count={totalquestion}
          rowsPerPage={rowsPerPage}
          page={page_no}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          serverside="true"
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      <FormControlLabel
        control={
          <Select value={active} onChange={handleActiveation}>
            <MenuItem value={0}>All</MenuItem>
            <MenuItem value={1}>Active</MenuItem>
            <MenuItem value={2}>Inactive</MenuItem>
          </Select>
        }
        label="Display : "
        labelPlacement="start"
      />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionTable);
