import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Grid, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { setSnackbar } from "src/actions";
import * as Http from "src/services/Http";
import ScreenLoader from "src/components/ScreenLoader";
import Page from "src/components/Page";
import CompaniesDetails from "../Components/CompaniesDetails";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";

const breadUrlList = [
  { name: "Home", url: "/dashboard" },
  { name: "Companies", url: "/app/companies" },
  { name: "Edit Company", url: "/" },
];
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  serviceDetail: {
    marginTop: theme.spacing(3),
  },
}));

const EditCompanyView = (props) => {
  const { id } = useParams();
  const classes = useStyles();
  const [Loading, setLoading] = useState(true);
  const [values, setValues] = useState({
    company: "",
    address: "",
    fulladdress: {},
    category_id: "",
    email: "",
    phone: "",
    fax: "",
    staffusers: [],
  });
  const [userList, setUserlist] = useState([]);
  const fetchUserList = async () => {
    try {
      const responseUser = await Http.call(
        "GET",
        "admin/lookup_dir/contact/unassign"
      );
      setUserlist(responseUser.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };
  const [contactCategory, setContactCategory] = useState([]);
  const fetchContactCategories = async () => {
    try {
      const response = await Http.call("GET", "admin/lookup_categories");
      setContactCategory(response.data);
      fetchUserList();
      fetchAssignUserList(id);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };
  const [assignUserList, setAssignUserlist] = useState([]);
  const fetchAssignUserList = async (id) => {
    try {
      const responseUser = await Http.call(
        "GET",
        "admin/lookup_dir/contact/assign/" + id
      );
      setAssignUserlist(responseUser.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await Http.call("GET", "admin/company/" + id);
      setValues(data);
      fetchContactCategories();
      setLoading(false);
    };
    fetchData();
  }, [id]);

  if (Loading) {
    return <ScreenLoader />;
  }
  return (
    <Page className={classes.root} title="Edit Company">
      <Container maxWidth="lg">
        <BreadcrumbsAdmin urlList={breadUrlList} />
        <Grid container spacing={3} className={classes.serviceDetail}>
          <Grid item md={12}>
            <CompaniesDetails
              formValues={values}
              setSnackbar={props.setSnackbar}
              userList={userList}
              contactCategory={contactCategory}
              assignUserList={assignUserList}
              fetchUserList={fetchUserList}
              fetchAssignUserList={fetchAssignUserList}
              mode={"Edit"}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCompanyView);
