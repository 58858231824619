import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as Http from "src/services/Http";
import CenterLoader from "src/components/Loader/CenterLoader";
import PackageBody from "./Components/PackageBody/index";
import * as Helper from "src/services/Helper";
import {
  setCertificatePrice,
  setPackageData,
  emptyPackageData,
} from "src/actions";
import { Grid } from "@material-ui/core";
import { useParams } from "react-router";

const PackageInfoView = (props) => {
  const {
    packageData,
    QuestionConditionChk,
    PreneedDoneChk,
    setCertificatePrice,
    setPackageData,
    emptyPackageData,
  } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});
  const [certificate, setCertificate] = useState(1);
  const [extraMileServicePrice, setExtraMileServicePrice] = useState(0);

  const [disableButton, setDisableButton] = useState(true)

  const [shippingAmount, setShippingAmount] = useState(0);
  const [isManualUpdate, setIsManualUpdate] = useState(false); // Flag to check if manual update is done



  const { id } = useParams();

  let user_id = "";
  let is_Preneed = "";

  if (id === undefined) {
    user_id = localStorage.getItem("rand_token");
    is_Preneed = localStorage.getItem("isPreneed");
  } else {
    user_id = id;
    is_Preneed = localStorage.getItem("isPreneed_" + id);
  }

  let deliveryShippingAddress = packageData.categoryData.find(
    (item) => item.id === 10
  );

  useEffect(() => {
    const fetchData = async () => {
      emptyPackageData();

      try {
        const { data, status, message } = await Http.call(
          "POST",
          "packages-services",
          {
            user_id: user_id,
            flag: "admin",
            is_adjustpayment: props.adjustPayment ? 1 : 0,
            is_refund: props.refundStatus ? 1 : 0,
            is_refundSubId:props.refundSelectedId ? props.refundSelectedId :0,
          }
        );

        if (status === 200) {
          Helper.setItem(
            "packageData",
            JSON.stringify({
              id: data.id,
              name: data.name,
              price: data.price,
              tax: data.tax,
            })
          );
          setData(data);
          setCertificatePrice(parseInt(data.certificate_rate));
          setCertificate(data.certificate_count);

          data.addonCategory.forEach((item) => {
            item.addOns.forEach((additem) => {
              if (additem.is_selected) {
                setPackageData({
                  id: additem.id,
                  catId: item.id,
                  name: additem.service_name,
                  price: additem.price,

                  custom_addons:  item.custom_addons , 


                  placeholder_price: additem.placeholder_price,
                  tax: additem.tax,
                  tax_per: additem.tax_per,
                  type: item.selection_type,
                  quantity: additem.quantity ? additem.quantity : 0,
                  selected: additem.quantity ? true : false,
                  included: additem.is_included ? true : false,
                  address: additem.address ? additem.address : "",
                  state: additem.state ? additem.state : "",
                  shipping_inside: item.shipping_inside,
                  shipping_outside: item.shipping_outside,
                  city: additem.city !== undefined ? additem.city : "",
                  country: additem.country !== undefined ? additem.country : "",
                  lat: additem.lat !== undefined ? additem.lat : "",
                  long: additem.long !== undefined ? additem.long : "",
                  zip: additem.zip !== undefined ? additem.zip : "",
                });
                if (additem.id === 77) {
                  let additemQty = additem.quantity ? additem.quantity : 0;
                  let additemPrice = additem.price ? additem.price : 0;
                  let serviceItemprice = additemQty * additemPrice;
                  setExtraMileServicePrice(serviceItemprice);
                }
              }
            });
          });
          setIsLoading(false);
        } else {
          alert("API ERROR: " + message);
        }
      } catch (error) {
        console.error("API ERROR:", error);
        alert("API ERROR: " + error.message);
      }
    };
    fetchData();
  }, [user_id]);

  
  useEffect(() => {
    if (packageData.categoryData.length > 0) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [packageData]);

  const getTotalTax = () => {
    let packageTax = JSON.parse(localStorage.getItem("packageData")).tax;
    let addOnstax = parseFloat(packageTax);
    let itemTax = 0;
    let itemPricewithShip = 0;
    let itemPricewithShipTax = 0;
    let shippingAddonData = packageData.categoryData.find(
      (item) => item.id === 10
    );
    packageData.categoryData.map((item, index) => {
      if (
        item.catId === 1 &&
        shippingAddonData !== undefined &&
        shippingAddonData.state === "California"
      ) {
        // $addonItemTax = round(((($addonDetails->price * $totalAddonQuantity) + $totalShippingAmount) * $addonDetails->taxable_amount) / 100, 2);
        itemPricewithShip =
          ((item.price * item.quantity + item.shipping_inside * item.quantity) *
            item.tax_per) /
          100;
        //itemPricewithShipTax = (itemPricewithShip * item.tax_per / 100);
        itemTax += Number(itemPricewithShip.toFixed(2));
      } else if (
        item.catId === 3 &&
        shippingAddonData !== undefined &&
        shippingAddonData.state === "California"
      ) {
        itemPricewithShip =
          ((item.price * item.quantity + item.shipping_inside * item.quantity) *
            item.tax_per) /
          100;
        //itemPricewithShipTax = (itemPricewithShip * item.tax_per / 100);
        itemTax += Number(itemPricewithShip.toFixed(2));
      } else if (
        item.catId === 30 &&
        shippingAddonData !== undefined &&
        shippingAddonData.state === "California"
      ) {
        itemPricewithShip =
          ((item.price * item.quantity + item.shipping_inside * item.quantity) *
            item.tax_per) /
          100;
        //itemPricewithShipTax = (itemPricewithShip * item.tax_per / 100);
        itemTax += Number(itemPricewithShip.toFixed(2));
      } else if (
        item.catId === 1 &&
        shippingAddonData !== undefined &&
        shippingAddonData.state !== undefined &&
        shippingAddonData.state !== "" &&
        shippingAddonData.state !== "California"
      ) {
        itemPricewithShip =
          ((item.price * item.quantity +
            item.shipping_outside * item.quantity) *
            item.tax_per) /
          100;
        //itemPricewithShipTax = (itemPricewithShip * item.tax_per / 100);
        itemTax += Number(itemPricewithShip.toFixed(2));
      } else if (
        item.catId === 3 &&
        shippingAddonData !== undefined &&
        shippingAddonData.state !== undefined &&
        shippingAddonData.state !== "" &&
        shippingAddonData.state !== "California"
      ) {
        itemPricewithShip =
          ((item.price * item.quantity +
            item.shipping_outside * item.quantity) *
            item.tax_per) /
          100;
        //itemPricewithShipTax = (itemPricewithShip * item.tax_per / 100);
        itemTax += Number(itemPricewithShip.toFixed(2));
      } else if (
        item.catId === 30 &&
        shippingAddonData !== undefined &&
        shippingAddonData.state !== undefined &&
        shippingAddonData.state !== "" &&
        shippingAddonData.state !== "California"
      ) {
        itemPricewithShip =
          ((item.price * item.quantity +
            item.shipping_outside * item.quantity) *
            item.tax_per) /
          100;
        //itemPricewithShipTax = (itemPricewithShip * item.tax_per / 100);
        itemTax += Number(itemPricewithShip.toFixed(2));
      } else {
        itemPricewithShip = (item.price * item.quantity * item.tax_per) / 100;
        //itemPricewithShipTax = (itemPricewithShip * item.tax_per / 100);
        itemTax += Number(itemPricewithShip.toFixed(2));
      }
    });

    let totaltax = addOnstax + itemTax;
    return totaltax;
  };

  const getShippingTotal = () => {
    let shipping = 0;
    let shippingAddonData = packageData.categoryData.find(
      (item) => item.id === 10
    );

    if (isManualUpdate && props.refundStatus) {
      return shippingAmount;
    }

    packageData.categoryData.map((item, index) => {
      if (
        item.catId === 1 &&
        shippingAddonData !== undefined &&
        shippingAddonData.state === "California"
      ) {
        shipping = shipping + item.shipping_inside * item.quantity;
      } else if (
        item.catId === 3 &&
        shippingAddonData !== undefined &&
        shippingAddonData.state === "California"
      ) {
        shipping = shipping + item.shipping_inside * item.quantity;
      } else if (
        item.catId === 30 &&
        shippingAddonData !== undefined &&
        shippingAddonData.state === "California"
      ) {
        shipping = shipping + item.shipping_inside * item.quantity;
      } else if (
        item.catId === 1 &&
        shippingAddonData !== undefined &&
        shippingAddonData.state !== undefined &&
        shippingAddonData.state !== "" &&
        shippingAddonData.state !== "California"
      ) {
        shipping = shipping + item.shipping_outside * item.quantity;
      } else if (
        item.catId === 3 &&
        shippingAddonData !== undefined &&
        shippingAddonData.state !== undefined &&
        shippingAddonData.state !== "" &&
        shippingAddonData.state !== "California"
      ) {
        shipping = shipping + item.shipping_outside * item.quantity;
      } else if (
        item.catId === 30 &&
        shippingAddonData !== undefined &&
        shippingAddonData.state !== undefined &&
        shippingAddonData.state !== "" &&
        shippingAddonData.state !== "California"
      ) {
        shipping = shipping + item.shipping_outside * item.quantity;
      }
    });
    
    return shipping;
  };

  useEffect(() => {
    if (!isManualUpdate && props.refundStatus) {
      const calculatedShipping = getShippingTotal();
      setShippingAmount(calculatedShipping);
    }
  }, [deliveryShippingAddress,packageData, isManualUpdate, props.refundStatus]);

  useEffect(() => {
    setIsManualUpdate(false); 
  }, [deliveryShippingAddress, props.refundStatus]);

  const handleShippingChange = (e) => {
    const newShippingAmount = parseFloat(e.target.value);
    setShippingAmount(newShippingAmount);
    setIsManualUpdate(true);
  };


  const getExtraMailesCharges = async (extramiledata) => {
    setIsLoading(true);
    let serviceAreaAddonData = packageData.categoryData.find(
      (item) => item.id === 77
    );

    try {
      await Http.call("POST", "extra_miles_service", extramiledata).then(
        (response) => {
          if (response.status === 200) {
            serviceAreaAddonData = {
              ...serviceAreaAddonData,
              price: response.data.amount_per_extra_mile,
              quantity: Math.round(response.data.total_mile_count),
            };
            setPackageData(serviceAreaAddonData);
            setIsLoading(false);
            return setExtraMileServicePrice(
              response.data.total_amount_to_be_paid
            );
            // return response.data.total_amount_to_be_paid;
          } else {
            setIsLoading(false);
            return setExtraMileServicePrice(0);
          }
        }
      );
    } catch (e) {
      console.log(e);
      setIsLoading(false);
      return;
    }
  };

  const getTotal = () => {
    let packagePrice = data.price;
    let addOnstotal = parseFloat(packagePrice);
    let addOnstax = getTotalTax();
    packageData.categoryData.map((item, index) => {
      if (item.id !== 77) {
        if (item.included) {
          addOnstotal = addOnstotal + item.price * (item.quantity - 1);
        } else {
          addOnstotal = addOnstotal + item.price * item.quantity;
        }
      }
    });
    // let total =
    //   addOnstotal +
    //   addOnstax +
    //   (certificate * packageData.certificatePrice - packageData.certificatePrice) +
    //   getShippingTotal() + extraMileServicePrice;

    let total = 0;
    {
      props.adjustPayment || props.refundAdditional
        ? (total =
            addOnstotal +
            addOnstax +
            certificate * packageData.certificatePrice +
            getShippingTotal() +
            extraMileServicePrice)
        : (total =
            addOnstotal +
            addOnstax +
            (certificate * packageData.certificatePrice -packageData.certificatePrice) +
            getShippingTotal() +
            extraMileServicePrice);
    }
    return total;
  };

  if (isLoading) {
    return (
      <Grid
        style={{
          display: "flex",
          justifyContent: "center",
          backgroundImage: `url("/img/Rectangle24.png")`,
          backgroundSize: "cover",
          height: "100vh",
          position: "relative",
          zIndex: "0",
        }}
        className="scrollable-section"
      >
        <CenterLoader minheight="250px" />
      </Grid>
    );
  }

  // console.log("refundStatus-package==>",props.refundStatus)
  // console.log("refundSelectedId-package==>",props.refundSelectedId)
  // console.log("refundAdditional-package==>",props.refundAdditional)
// console.log("data =>",data)

  return (
    <PackageBody
      data={data}
      setData={setData}
      total={getTotal}
      certificate={certificate}
      setCertificate={setCertificate}
      crtPrice={packageData.certificatePrice}
      selected={packageData.categoryData}
      QuestionConditionChk={QuestionConditionChk}
      PreneedDoneChk={PreneedDoneChk}
      getTotalTax={getTotalTax}
      getShippingTotal={getShippingTotal}
      user_id={user_id}
      setTabval={props.setTabval}
      setdisableTab={props.setdisableTab}
      is_Preneed={is_Preneed}
      getExtraMailesCharges={getExtraMailesCharges}
      extraMileServicePrice={extraMileServicePrice}
      setExtraMileServicePrice={setExtraMileServicePrice}
      refundStatus={props.refundStatus}
      adjustPayment={props.adjustPayment}

      refundAdditional={props.refundAdditional}

      setSubscription_id={props.setSubscription_id}
       setAdjuestpaymentFlag={props.setAdjuestpaymentFlag}

       disableButton={disableButton}

       handleShippingChange={handleShippingChange}
       shippingAmount={shippingAmount}

       setPackageData={setPackageData}
       packageData={packageData}
    />
  );
};

const mapStateToProps = (state) => ({
  packageData: state.package,
  QuestionConditionChk: state.session.questconditionchk,
  PreneedDoneChk: state.session.preeneeddonechk,
});

const mapDispatchToProps = {
  setCertificatePrice,
  setPackageData,
  emptyPackageData,
};

export default connect(mapStateToProps, mapDispatchToProps)(PackageInfoView);
