import * as React from "react";
import Paper from "@mui/material/Paper";
import { Grid } from "@mui/material";

export default function ReviewTable({ data }) {
  const { section_title, user_answers } = data;
  return (
    <Grid
      container
      component={Paper}
      className="reviewsection"
      style={{ marginTop: 30 }}
    >
      <Grid container>
        <Grid
          item
          className="reviewsection_title"
          md={12}
          style={{
            padding: 15,
          }}
        >
          {section_title}
        </Grid>
      </Grid>
      {user_answers.map((row, index) => {
        let filepath = "";
        let fileName = "";
        // let spanastric = "";
        let counter = index + 1 + `.&nbsp`;
        let hashtrics = "";
        // if (row.is_skip === 1) {
        //   spanastric = "";
        // } else {
        //   spanastric = "*";
        // }
        if (row.control_id === 6) {
          filepath = row.answer.split("/");
          filepath = filepath[filepath.length - 1];
          fileName = filepath;
        }
        hashtrics =
          row.is_skip !== 1
            ? `<span style={{ color: "black" }}> * </span>`
            : ``;

        return (
          <Grid container key={index}>
            <Grid
              item
              md={12}
              xs={12}
              style={{
                padding: 15,
                backgroundColor: "#b1d4e038",
              }}
            >
              <Grid
                item
                md={12}
                xs={12}
                direction={"row"}
                className="reviewsection_que"
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      counter +
                      row.question
                        .replace(/<p[^>]*>/g, "")
                        .replace(/<\/p>/g, "") +
                      hashtrics,
                  }}
                ></div>
              </Grid>
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
              style={{
                padding: 15,
                borderBottom: "3px solid rgb(212, 212, 212)",
                overflowWrap: "break-word",
              }}
              className="reviewsection_ans"
            >
              {row.control_id === 6 ? (
                fileName !== "Unknown" ? (
                  <a
                    href={row.answer}
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                  >
                    {fileName}
                  </a>
                ) : (
                  fileName
                )
              ) : row.control_id === 10 && row.answer !== "Unknown" ? (
                "+" +
                row.answer.replace(/^(\d{3})(\d{3})(\d{4}).*/, "($1) $2-$3")
              ) : (
                row.answer
              )}
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
}
