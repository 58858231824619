import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { chkRoutePerm } from "src/services/Helper";

const Toolbar = ({ className, ...rest }) => {
  const chkRoutPerm = chkRoutePerm("/app/companies/add", rest.authuser);
  return (
    <div {...rest}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={3}
      >
        <Typography variant="h3" component="span">
          Company list
        </Typography>
        {chkRoutPerm ? (
          <Button
            color="primary"
            variant="contained"
            component={RouterLink}
            to="/app/companies/add"
          >
            Add Company
          </Button>
        ) : (
          ""
        )}
      </Box>
    </div>
  );
};

export default Toolbar;
