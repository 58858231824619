import { useState, useEffect } from "react";
import * as React from "react";
import { Grid, Paper } from "@material-ui/core";
import * as Http from "src/services/Http";
import ScreenLoader from "src/components/ScreenLoader";

import { useParams } from "react-router";

export default function ReviewTable() {
  const [reviewData, setReviewData] = useState([]);
  const [Loading, setLoading] = useState(true);

  const { id } = useParams(1);
  let userId = "";
  if (id === undefined) {
    userId = localStorage.getItem("rand_token");
    userId = !isNaN(+userId) === false ? undefined : userId;
  } else {
    userId = id;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const response = await Http.call("POST", "guest/review", {
          type: 1,
          user_id: userId,
        });

        if (response.status !== 200) {
          throw new Error("Network response was not ok");
        }

        setReviewData(response.data); // Store the data in state
        setLoading(false);

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);


      
  if (Loading) {
    return <ScreenLoader />;
  }

  return (
    <Grid
      container
      component={Paper}
      className="reviewsection"
      // style={{ marginTop: 30 }}
    >
      {reviewData.map((item, index) => (
        <Grid container key={index}>
          <Grid
            item
            className="reviewsection_title"
            md={12}
            style={{
              padding: "15px",
              backgroundColor: "#22696d",
              // marginTop: "1.5px",
              color: "white",
              fontSize: "18px",
              borderRadius:"5px"
            }}
          >
            {item.section_title}
          </Grid>
          {item.user_answers?.map((row, rowIndex) => (
            <Grid container key={rowIndex}>
              <Grid
                item
                md={12}
                xs={12}
                style={{ padding: 15, backgroundColor: "#b1d4e038" }}
              >
                <Grid
                  item
                  md={12}
                  xs={12}
                  direction={"row"}
                  className="reviewsection_que"
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${rowIndex + 1}. ${row.question
                        .replace(/<p[^>]*>/g, "")
                        .replace(/<\/p>/g, "")}${
                        row.is_skip !== 1
                          ? '<span style="color: black"> * </span>'
                          : ""
                      }`,
                    }}
                  ></div>
                </Grid>
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
                style={{
                  padding: 15,
                  borderBottom: "3px solid rgb(212, 212, 212)",
                  overflowWrap: "break-word",
                }}
                className="reviewsection_ans"
              >
                {row.control_id === 6 ? (
                  row.answer !== "Unknown" ? (
                    <a
                      href={row.answer}
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      {row.answer.split("/").pop()}
                    </a>
                  ) : (
                    row.answer
                  )
                ) : row.control_id === 10 && row.answer !== "Unknown" ? (
                  `+ ${row.answer.replace(
                    /^(\d{3})(\d{3})(\d{4}).*/,
                    "($1) $2-$3"
                  )}`
                ) : (
                  row.answer
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>
      ))}
    </Grid>
  );
}
