import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import * as Http from "src/services/Http";
import * as Yup from "yup";
import { FieldArray, Formik } from "formik";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  MenuItem,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Checkbox,
  ListItemSecondaryAction,
  Radio,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import ScreenLoader from "src/components/ScreenLoader";
import Cancelbtn from "src/components/common/Cancelbtn";
import * as Messages from "src/constants/Messages/index";

const useStyles = makeStyles((theme) => ({
  title: {
    margin: theme.spacing(2, 2, 0),
  },
}));
const duration = [
  {
    id: 0,
    lable: "Under 300lbs",
  },
  {
    id: 1,
    lable: "Between 300-500lbs",
  },
];

const tax_types = [
  {
    id: 0,
    name: "Percentage",
  },
  {
    id: 1,
    name: "Fixed",
  },
];

const AddUserSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, Messages.TOO_SHORT)
    .required(Messages.REQUIRED),
  description: Yup.string().required(Messages.REQUIRED),
  price: Yup.string().required(Messages.REQUIRED),
  is_lbs: Yup.string()
    .required(Messages.REQUIRED)
    .nullable(),
  tax_type: Yup.number().required(Messages.REQUIRED),
  taxable_amount: Yup.number().when("tax_type", {
    is: 0,
    then: Yup.number()
      .required(Messages.REQUIRED)
      .min(1, Messages.PERCENTAGE_BETWEEN)
      .max(100, Messages.PERCENTAGE_BETWEEN),
  }),
  // taxable_amount: Yup.number().required(Messages.REQUIRED),
  // .positive()
  // .min(1, Messages.PERCENTAGE_BETWEEN)
  // .max(100, Messages.PERCENTAGE_BETWEEN)

  // taxable_amount: Yup.number().when("tax_type", {
  //   is: 1,
  //   then: Yup.number().required(Messages.REQUIRED),
  // }),
});
const PackageDetails = ({ mode, setSnackbar, formValues, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [Loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getCategoryByCategory();
  }, []);
  const getCategoryByCategory = async () => {
    const response = await Http.call("GET", "admin/get_all_services");
    setCategories(response.data);
    setLoading(false);
  };

  if (Loading) {
    return <ScreenLoader />;
  }

  return (
    <Formik
      initialValues={formValues}
      validationSchema={AddUserSchema}
      onSubmit={async (values, actions) => {
        //console.log(formValues);
        //console.log(values, 'values');

        let method = "";
        let url = "";
        if (formValues.id === undefined) {
          method = "POST";
          url = "admin/packages";
        } else {
          method = "PATCH";
          url = "admin/packages/" + formValues.id;
        }
        await Http.call(method, url, values).then(
          async ({ status, message, data }) => {
            if (status === 200) {
              navigate("/app/services");
              setSnackbar(true, status, message);
            } else if (status === 422) {
              actions.setErrors(data);
            } else {
              setSnackbar(true, status, message);
            }
          }
        );
        actions.setSubmitting(false);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        values,
      }) => {
        return (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Card>
              <CardHeader title={mode + " Service"} />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={12}>
                    <Grid item md={12} xs={12}>
                      <TextField
                        fullWidth
                        name="name"
                        label="Name"
                        variant="outlined"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                        error={Boolean(touched.name && errors.name)}
                        margin="normal"
                        helperText={touched.name && errors.name}
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <TextField
                        fullWidth
                        label="Description"
                        name="description"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.description}
                        margin="normal"
                        variant="outlined"
                        error={Boolean(
                          touched.description && errors.description
                        )}
                        multiline
                        rows={3}
                        helperText={touched.description && errors.description}
                      />
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Price"
                          margin="normal"
                          name="price"
                          onBlur={handleBlur}
                          value={values.price}
                          type="text"
                          onChange={(e) => {
                            e.preventDefault();
                            const { value } = e.target;
                            const regex = /^\d*(\.\d{0,2})?$/;
                            if (regex.test(value.toString())) {
                              setFieldValue("price", value);
                            }
                          }}
                          variant="outlined"
                          error={Boolean(touched.price && errors.price)}
                          helperText={touched.price && errors.price}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Weight"
                          name="is_lbs"
                          select
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.is_lbs}
                          margin="normal"
                          variant="outlined"
                          error={Boolean(touched.is_lbs && errors.is_lbs)}
                          helperText={touched.is_lbs && errors.is_lbs}
                        >
                          {duration.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.lable}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Tax Type"
                          name="tax_type"
                          select
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.tax_type}
                          margin="normal"
                          variant="outlined"
                          error={Boolean(touched.tax_type && errors.tax_type)}
                          helperText={touched.tax_type && errors.tax_type}
                        >
                          {tax_types.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Taxable Amount"
                          margin="normal"
                          name="taxable_amount"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            e.preventDefault();
                            const { value } = e.target;
                            const regex = /^\d*(\.\d{0,2})?$/;
                            if (regex.test(value.toString())) {
                              setFieldValue("taxable_amount", value);
                            }
                          }}
                          value={values.taxable_amount}
                          type="text"
                          variant="outlined"
                          error={Boolean(
                            touched.taxable_amount && errors.taxable_amount
                          )}
                          helperText={
                            touched.taxable_amount && errors.taxable_amount
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      {categories.map(
                        ({
                          category_id,
                          category_name,
                          selection_type,
                          services,
                        }) => {
                          return (
                            <Grid item md={4} xs={12} key={category_id}>
                              <Typography
                                variant="h4"
                                className={classes.title}
                              >
                                {category_name}
                              </Typography>
                              <List dense className={classes.root}>
                                <FieldArray
                                  name={"services"}
                                  render={(arrayHelpers) => (
                                    <div key={category_id}>
                                      {services.map(
                                        (service) => {
                                          const labelId = `checkbox-list-secondary-label-${service.id}`;

                                          return (
                                            <ListItem key={service.id}>
                                              <ListItemAvatar>
                                                <Avatar
                                                  alt={`${service.name} n°${service.id}`}
                                                  src={service.image}
                                                />
                                              </ListItemAvatar>
                                              <ListItemText
                                                id={labelId}
                                                primary={`${service.name} ( $${service.price} )`}
                                              />
                                              <ListItemSecondaryAction>
                                                {selection_type === 0 ? (
                                                  <Radio
                                                    edge="end"
                                                    name={category_name}
                                                    value={service.id}
                                                    inputProps={{
                                                      "aria-labelledby": labelId,
                                                    }}
                                                    checked={
                                                      values.services.indexOf(
                                                        service.id
                                                      ) === -1
                                                        ? false
                                                        : true
                                                    }
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        services.forEach((item) =>  {
                                                          if((service.id !== item.id) && (item.category_id === service.category_id)) {
                                                            const idx = values.services.indexOf(item.id);
                                                            if(idx !== -1) {
                                                              arrayHelpers.remove(
                                                                idx
                                                              );
                                                            }
                                                          }
                                                        })
                                                        arrayHelpers.push(service.id);
                                                      } 
                                                    }}
                                                  />
                                                ) : (
                                                  <Checkbox
                                                    edge="end"
                                                    name={category_name}
                                                    value={service.id}
                                                    inputProps={{
                                                      "aria-labelledby": labelId,
                                                    }}
                                                    checked={
                                                      values.services.indexOf(
                                                        service.id
                                                      ) === -1
                                                        ? false
                                                        : true
                                                    }
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        arrayHelpers.push(service.id);
                                                      } else {
                                                        const idx = values.services.indexOf(
                                                          service.id
                                                        );
                                                        arrayHelpers.remove(
                                                          idx
                                                        );
                                                      }
                                                    }}
                                                  />
                                                )}
                                              </ListItemSecondaryAction>
                                            </ListItem>
                                          );
                                        }
                                      )}
                                    </div>
                                  )}
                                />
                              </List>
                              <Divider variant="middle" />
                            </Grid>
                          );
                        }
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box display="flex" justifyContent="flex-end" p={2}>
                <Cancelbtn />
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </Box>
            </Card>
          </form>
        );
      }}
    </Formik>
  );
};

export default PackageDetails;
