import React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import Logo from "src/components/Logo";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    width: 60,
    height: 60,
  },
  uname: {
    marginBottom: 0,
    marginLeft: 10,
  },
  large: {
    backgroundColor: "#fff",
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box flexGrow={1} />
        <Hidden mdDown>
          <IconButton color="inherit" onClick={handleClick}>
            <Avatar
              alt="Remy Sharp"
              src={
                rest.avatar !== ""
                  ? rest.avatar
                  : process.env.PUBLIC_URL +
                    // "/img/logo/Lotus-Logo_1024x1024-01.png"
                    "/img/logo/F_C_Logo.png"
              }
              className={classes.large}
            />
            <Typography
              className={classes.uname}
              variant="h6"
              gutterBottom
              component="div"
            >
              {`${rest.authuser.first_name} ${rest.authuser.last_name}`}
            </Typography>
          </IconButton>
          <StyledMenu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                navigate("/app/account");
                handleClose();
              }}
            >
              My Account
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate("/app/change_password");
                handleClose();
              }}
            >
              Change Password
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate("/app/logout");
                handleClose();
              }}
            >
              Logout
            </MenuItem>
          </StyledMenu>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
          <IconButton color="inherit" onClick={handleClick}>
            <Avatar
              alt="Remy Sharp"
              src={
                rest.avatar !== ""
                  ? rest.avatar
                  : process.env.PUBLIC_URL +
                    // "/img/logo/Lotus-Logo_1024x1024-01.png"
                    "/img/logo/F_C_Logo.png"
              }
              className={classes.large}
            />
            <Typography
              className={[classes.uname, "avatar_text"]}
              variant="h6"
              gutterBottom
              component="div"
            >
              {`${rest.authuser.first_name} ${rest.authuser.last_name}`}
            </Typography>
          </IconButton>
          <StyledMenu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                navigate("/app/account");
                handleClose();
              }}
            >
              My account
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate("/app/change_password");
                handleClose();
              }}
            >
              Change Password
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate("/app/logout");
                handleClose();
              }}
            >
              Logout
            </MenuItem>
          </StyledMenu>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;
