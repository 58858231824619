import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Formik } from "formik";
import * as Yup from "yup";
import * as Http from "src/services/Http";
import ScreenLoader from "src/components/ScreenLoader";
import * as Messages from "src/constants/Messages/index";
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormHelperText,
  Grid,
  TextField,
} from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import ApplicationContactTable from "./ApplicationContactTable";
import ConformationDialog from "src/components/ConformationDialog";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    // margin: "auto",
    width: "100%",
  },
  selectControl: {
    minWidth: "100%",
    margin: "15px 0",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  container: {
    maxHeight: 740,
  },
}));

const CommunicationLavel = (props) => {
  const classes = useStyles();
  const { id } = useParams();
  const [positionList, setPositionList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selContactIds, setSelContactIds] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [selectedPosition, setSelectedPosition] = useState({});
  const [selectedCompany, setSelectedCompany] = useState({});
  const [selectedUsers, setSelectedUsers] = useState([]);

  const initialValues = {
    position_id: "",
    company_id: "",
    users: [],
  };

  const AddContactSchema = Yup.object().shape({
    position_id: Yup.string().required(Messages.REQUIRED),
    company_id: Yup.string().required(Messages.REQUIRED),
  });

  const fetchPositions = async () => {
    await Http.call("GET", "admin/designations").then(async (response) => {
      if (response.status === 200) {
        setPositionList(response.data);
      } else {
        console.log(response);
      }
    });
  };

  const fetchCompanies = async () => {
    await Http.call("GET", "admin/company/get_company_by_category").then(
      async (response) => {
        if (response.status === 200) {
          setCompanyList(response.data);
        } else {
          console.log(response);
        }
      }
    );
  };

  const fetchUserList = async (position, company) => {
    if (position !== "" && company !== "") {
      await Http.call(
        "GET",
        "admin/lookup_dir/" + position + "/" + company + "/" + id
      ).then(async (response) => {
        if (response.status === 200) {
          await setUsersList(response.data);
        } else {
          console.log(response);
        }
      });
    }
  };

  const fetchAppContactList = async (application_id) => {
    await Http.call("GET", "admin/application/contacts/" + application_id).then(
      async (response) => {
        if (response.status === 200) {
          await setData(response.data);
        } else {
          console.log(response);
        }
      }
    );
    setLoading(false);
  };

  const addContact = async (values, actions) => {
    await Http.call("POST", "admin/application/contacts", {
      ...values,
      application_id: id,
    }).then(async (response) => {
      if (response.status === 200) {
        props.setSnackbar(true, response.status, response.message);
        actions.setSubmitting(false);
        fetchAppContactList(id);
      } else {
        props.setSnackbar(false, response.status, response.message);
        actions.setSubmitting(false);
      }
    });
  };

  const deleteAppContact = (idsToDelete) => {
    setOpen(true);
    setSelContactIds(idsToDelete);
  };

  const handleClose = () => setOpen(false);

  const onConform = async () => {
    try {
      await Http.call("POST", "admin/application/contacts/destroy", {
        id: selContactIds,
      }).then(async (response) => {
        props.setSnackbar(true, response.status, response.message);
      });
      fetchAppContactList(id);
      handleClose();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchPositions();
    fetchCompanies();
    fetchAppContactList(id);
  }, [id]);

  if (Loading) {
    return <ScreenLoader />;
  } else {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={AddContactSchema}
        onSubmit={async (values, actions) => {
          await addContact(values, actions);
          setSelectedUsers([]);
          setSelectedPosition({});
          setSelectedCompany({});
          actions.resetForm({});
        }}
      >
        {({
          errors,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
          resetForm,
        }) => {
          return (
            <form className={classes.form} onSubmit={handleSubmit}>
              <Grid item md={12}>
                {/* <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={3}
                >
                  <Grid container spacing={3} md={6}>
                    <Grid item>
                      <Typography variant="h3" component="h3">
                        Communication Level
                      </Typography>
                    </Grid>
                  </Grid>
                </Box> */}
                <Card>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item md={4} xs={12}>
                        <Autocomplete
                          id="combo-box-demo-position"
                          name={`position_id`}
                          options={positionList}
                          getOptionLabel={(option) =>
                            option ? option.title : ""
                          }
                          getOptionSelected={(option, value) =>
                            option.id === value.id
                          }
                          filterOptions={createFilterOptions({
                            matchFrom: "start",
                          })}
                          value={selectedPosition}
                          onChange={(e, value) => {
                            if (value) {
                              setSelectedPosition(value);
                              setFieldValue("position_id", value.id);
                              setFieldValue("users", []);
                              setUsersList([]);
                              fetchUserList(value.id, values.company_id);
                            } else {
                              setSelectedPosition({});
                              setFieldValue("position_id", "");
                              setFieldValue("users", []);
                              setUsersList([]);
                            }
                          }}
                          fullWidth
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Position"
                              variant="outlined"
                              margin="normal"
                            />
                          )}
                        />
                        {Boolean(touched.position && errors.position) ? (
                          <FormHelperText className="Mui-error">
                            {errors.position}
                          </FormHelperText>
                        ) : null}
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <Autocomplete
                          id="combo-box-demo-company"
                          name={`company_id`}
                          options={companyList}
                          getOptionLabel={(option) =>
                            option ? option.company : ""
                          }
                          getOptionSelected={(option, value) =>
                            option.id === value.id
                          }
                          filterOptions={createFilterOptions({
                            matchFrom: "start",
                          })}
                          value={selectedCompany}
                          onChange={(e, value) => {
                            if (value) {
                              setSelectedCompany(value);
                              setFieldValue("company_id", value.id);
                              setFieldValue("users", []);
                              setUsersList([]);
                              fetchUserList(values.position_id, value.id);
                            } else {
                              setSelectedCompany({});
                              setFieldValue("company_id", "");
                              setFieldValue("users", []);
                              setUsersList([]);
                            }
                          }}
                          fullWidth
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Company"
                              variant="outlined"
                              margin="normal"
                            />
                          )}
                        />
                        {Boolean(touched.company && errors.company) ? (
                          <FormHelperText className="Mui-error">
                            {errors.company}
                          </FormHelperText>
                        ) : null}
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <Autocomplete
                          multiple
                          id="combo-box-demo-users"
                          name={`users`}
                          options={usersList}
                          getOptionLabel={(option) =>
                            option
                              ? option.first_name + " " + option.last_name
                              : ""
                          }
                          getOptionSelected={(option, value) =>
                            option.id === value.id
                          }
                          filterOptions={createFilterOptions({
                            matchFrom: "start",
                          })}
                          value={selectedUsers}
                          onChange={(e, items) => {
                            if (items) {
                              setSelectedUsers(items);
                              let finalValues = [];
                              items.map((val, index) => {
                                finalValues.push(val.id);
                              });
                              setFieldValue("users", finalValues);
                            } else {
                              setSelectedUsers("");
                              setFieldValue("users", []);
                            }
                          }}
                          fullWidth
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={`Users`}
                              variant="outlined"
                              margin="normal"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Box display="flex" justifyContent="flex-end" p={2}>
                    {/* <Cancelbtn /> */}
                    <Button
                      style={{ marginRight: "7px" }}
                      variant="contained"
                      onClick={() => {
                        setSelectedUsers([]);
                        setSelectedPosition({});
                        setSelectedCompany({});
                        resetForm();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  </Box>
                  <CardContent>
                    <ApplicationContactTable
                      contacts={data}
                      deleteContact={deleteAppContact}
                    />
                  </CardContent>
                  {/* <CardContent>
                    <TableContainer
                      component={Paper}
                      className={classes.container}
                    >
                      <Table
                        className={classes.table}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>Complete Status</TableCell>
                            <TableCell>Assigned Counselor</TableCell>
                            <TableCell align="center">Modify By</TableCell>
                            <TableCell align="center">
                              Complete Status Date
                            </TableCell>
                            <TableCell align="center">Notes</TableCell>
                            <TableCell align="center">Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {appCounsStatus.complated_status.length !== 0 ? (
                            appCounsStatus.complated_status.map((s, index) => (
                              <TableRow key={index}>
                                <TableCell
                                  style={{ width: 200 }}
                                  component="th"
                                  scope="row"
                                >
                                  {s.name}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {s.couns_name}
                                </TableCell>
                                <TableCell
                                  style={{ width: 200 }}
                                  align="center"
                                >
                                  {s.auto_status !== 1 ? s.modify_by : ""}
                                </TableCell>
                                <TableCell
                                  style={{ width: 200 }}
                                  align="center"
                                >
                                  {s.date}
                                </TableCell>
                                <TableCell style={{ width: 200 }} align="left">
                                  {s.status_note !== "" &&
                                  s.status_note !== undefined
                                    ? isReadMore
                                      ? s.status_note.slice(0, 50)
                                      : s.status_note
                                    : null}
                                  {s.status_note.length > 50 && (
                                    <Button
                                      style={{ minWidth: 40 }}
                                      size="small"
                                      color="primary"
                                      onClick={handleClickOpen}
                                      value={s.status_note}
                                    >
                                      {isReadMore ? "...Read More" : ""}
                                    </Button>
                                  )}
                                </TableCell>
                                <TableCell
                                  style={{ width: 200 }}
                                  align="center"
                                >
                                  <Tooltip
                                    disableFocusListener
                                    title="Status Log"
                                  >
                                    <Button
                                      style={{ minWidth: 40 }}
                                      size="small"
                                      color="primary"
                                      component={RouterLink}
                                      to={`/app/statuslog/show/${appCounsStatus.app_id}`}
                                      target="_blank"
                                    >
                                      <DescriptionOutlinedIcon />
                                    </Button>
                                  </Tooltip>
                                  {s.auto_status !== 1 &&
                                  s.complete_flag !== true &&
                                  0 === index ? (
                                    <Tooltip
                                      disableFocusListener
                                      title="Delete Status"
                                    >
                                      <Button
                                        style={{ minWidth: 40 }}
                                        size="small"
                                        color="primary"
                                        onClick={() => {
                                          delStatus(
                                            appCounsStatus.app_id,
                                            s.id,
                                            s.app_status_id
                                          );
                                        }}
                                      >
                                        <DeleteOutlineOutlinedIcon />
                                      </Button>
                                    </Tooltip>
                                  ) : null}
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>No record found</TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardContent>
                  <ConformationDialog
                    className={classes.paper}
                    id="confirmation-dialog-title"
                    keepMounted
                    open={open}
                    onClose={handleDelClose}
                    onConformAction={onConform}
                    // value={delequestionTitles}
                  />
                  <ConformationDialog
                    className={classes.paper}
                    id="confirmation-dialog-title"
                    keepMounted
                    open={openStatusBox}
                    onClose={handleCloseStatusBox}
                    onConformAction={onStatusSubmit}
                    mode={"app_status"}
                  /> */}
                </Card>
                <ConformationDialog
                  className={classes.paper}
                  id="confirmation-dialog-title"
                  keepMounted
                  open={open}
                  onClose={handleClose}
                  onConformAction={onConform}
                />
                {/* <Dialog
                  open={statusNoteOpen}
                  onClose={handleCloseStatusNote}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth={true}
                >
                  <DialogTitle id="alert-dialog-title">Note</DialogTitle>
                  <DialogContent dividers>
                    {logDescription.replace(/<[^>]+>/g, "")}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={handleCloseStatusNote}
                      color="primary"
                      variant="contained"
                      autoFocus
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog> */}
              </Grid>
            </form>
          );
        }}
      </Formik>
    );
  }
};

export default CommunicationLavel;
