import React from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import * as Http from "src/services/Http";

import {
  Box,
  Button,
  Card,
  makeStyles,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import { chkRoutePerm } from "src/services/Helper";
import Swal from "sweetalert2";
import { setSnackbar } from "src/actions";
import withReactContent from "sweetalert2-react-content";
import { FilePlus } from "react-feather";

const swal = withReactContent(Swal);

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const Results = ({
  className,
  onClose,
  fetchUserList,
  users,
  pageLoading,
  fetchUser,
  deleteUsers,
  multipleDelete,
  ...rest
}) => {
  const { id } = useParams(1);
  let userId = "";
  if (id === undefined) {
    userId = localStorage.getItem("rand_token");
    userId = !isNaN(+userId) === false ? undefined : userId;
  } else{
    userId = id;
  }
  const classes = useStyles();
  const navigate=useNavigate()
  const responsive = "standard";
  const chkRoutPerm = chkRoutePerm("/app/users/edit", rest.authuser);
  const chkDelRoutPerm = chkRoutePerm("/app/users/delete", rest.authuser);

  const handleCustomSelect = async (selectedRows) => {
    const selectedIds = selectedRows.data.map((row) => users[row.dataIndex].id);
    try {
      if(userId){ 
        const response = await Http.call("POST", "admin/application/contacts", {
          user_id: userId,
          users: selectedIds,
        });
        if (response.status === 200) {
          setSnackbar(true, "success", "Data replaced successfully");
          fetchUser(); // Update the user list
          fetchUserList()
          onClose()
        } else {
          setSnackbar(true, "error", response.message || "Failed to replace data");
        }
      }
    } catch (error) {
      console.error("Error:", error);
      navigate(-1)
      setSnackbar(true, "error", "Failed to replace data");
    }
  };
  const options = {
    filter: false,
    filterType: "dropdown",
    responsive,
    // icon: <FilePlus />,
    // Tooltip: "replace",
    // onRowsDelete: async (rowsDeleted) => {
    //   const selectedIds = rowsDeleted.data.map((d) => users[d.dataIndex].id);

    //   // Call your API to handle the selected data
    //   try {
    //     const response = await Http.call("POST", "admin/application/contacts", {
    //       // selectedIds: selectedIds,
    //       user_id: id,
    //       users: selectedIds,
    //     });
    //     if (response.status === 200) {
    //       setSnackbar(true, "success", "Data replaced successfully");
    //       fetchUser(); // Update the user list
    //     } else {
    //       setSnackbar(true, "error", response.message);
    //     }
    //   } catch (error) {
    //     console.error("Error:", error);
    //     setSnackbar(true, "error", "Failed to replace data");
    //   }
    // },

    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <IconButton onClick={() => handleCustomSelect(selectedRows)}>
        <Tooltip id="button-report" title="ADD IN LIST">

        <FilePlus />
        </Tooltip>
      </IconButton>
    ),
    rowsPerPageOptions: [],
    rowsPerPage: 100,
    tableBodyMaxHeight: "740px",
    // rowsPerPageOptions: [10, 25, 50, 100, 500],
    downloadOptions: { filename: "Users.csv" },
    isRowSelectable: () => {
      return chkDelRoutPerm ? true : false;
    },
  };

  const columns = [
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        download: false,
        print: false,
        // viewColumns:'disabled',
        display: chkRoutPerm ? true : false,
        customBodyRenderLite: (dataIndex) =>
          chkRoutPerm ? (
            <Tooltip disableFocusListener title="Edit">
              <Button
                color="primary"
                component={RouterLink}
                size="small"
                to={"/app/contacts/" + users[dataIndex].id}
              >
                <EditOutlinedIcon />
              </Button>
            </Tooltip>
          ) : (
            ""
          ),
      },
    },
    {
      name: "is_front_user",
      label: "Is Front User?",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "first_name",
      label: "First Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "last_name",
      label: "Last Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "mobile",
      label: "Phone",
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => (
          <InputAdornment position="start">
            {users[dataIndex].mobile}
          </InputAdornment>
        ),
      },
    },
    {
      name: "fax",
      label: "Fax",
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => (
          <InputAdornment>
            {users[dataIndex].fax.length > 6 ? "+ " : ""}
            {users[dataIndex].fax.length > 6 ? users[dataIndex].fax : ""}
          </InputAdornment>
        ),
      },
    },
    {
      name: "company_name",
      label: "Company",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "designation_name",
      label: "Position",
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box>
          <MUIDataTable
            title=""
            data={users}
            columns={columns}
            options={options}
          />
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};


Results.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired,
};

export default Results;
