import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import Tooltip from "@material-ui/core/Tooltip";
import { setSnackbar } from "src/actions";
import { useDispatch } from "react-redux";
import { chkRoutePerm } from "src/services/Helper";

import {
  Box,
  Button,
  FormControl,
  InputLabel,
  makeStyles,
  NativeSelect,
  Typography,
  Grid,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

const Toolbar = ({
  className,
  counsellorsDataArr,
  fetchCounsellorsData,
  counsellorUpdate,
  selectedIds,
  userData,
  ...rest
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const handleUpdateAll = async (event, index, value) => {
    let counsellor_id = event.target.value;
    let appids = selectedIds.join(",");

    if (appids.length !== 0) {
      const data = { couns_id: counsellor_id, app_id: appids };
      counsellorUpdate(data);
    } else {
      dispatch(
        setSnackbar(true, 200, "Please select at list one Application ID.")
      );
    }
  };

  const handleClick = async () => {
    await localStorage.removeItem("rand_token");
    await navigate("/app/application/add");
  };
  const chkAddRoutPerm = chkRoutePerm("/app/counselors/add", userData);
  const chkEditRoutPerm = chkRoutePerm("/app/counselors/edit", userData);
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Grid container spacing={3}>
          <Grid item md={7}>
            <Typography variant="h3" component="h3">
              Application List
            </Typography>
          </Grid>
          {chkAddRoutPerm ? (
            <Grid
              item
              md={userData.role !== 2 ? 3 : 5}
              style={{ textAlign: "right" }}
            >
              <Button color="primary" variant="contained" onClick={handleClick}>
                Add Application
              </Button>
            </Grid>
          ) : (
            ""
          )}
          {userData.role !== 2 && chkAddRoutPerm && chkEditRoutPerm ? (
            <Grid item md={2}>
              <FormControl className={classes.formControl} md={3}>
                <InputLabel shrink htmlFor="age-native-label-placeholder">
                  Counselor List
                </InputLabel>
                <NativeSelect
                  onChange={handleUpdateAll}
                  inputProps={{
                    name: "couns_id",
                    id: "age-native-label-placeholder",
                  }}
                >
                  <option value="">Select Counselor</option>
                  {counsellorsDataArr !== null ? (
                    counsellorsDataArr.map((o) => (
                      <option key={o.couns_id} value={o.couns_id}>
                        {o.name}
                      </option>
                    ))
                  ) : (
                    <option value="0">No Record Found.</option>
                  )}
                </NativeSelect>
              </FormControl>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
