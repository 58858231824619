import { Box, Container, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import Page from "src/components/Page";
import ScreenLoader from "src/components/ScreenLoader";
import * as Http from "src/services/Http";
import DiscountTable from "src/views/Discount/DiscountView/DiscountTable";
import Toolbar from "./Toolbar";
import { setSnackbar } from "src/actions";
import ConformationDialog from "src/components/ConformationDialog";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";

const breadUrlList = [
  { name: "Home", url: "/dashboard" },
  { name: "Discount List", url: "/" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  paper: {
    width: "40%",
    maxHeight: 435,
  },
}));

const DiscountView = (props) => {
  const [Loading, setLoading] = useState(true);
  const [open, setopen] = useState(false);
  const [discountData, setDiscountData] = useState([]);
  const [deletable, setdeletable] = useState([]);
  const [deletableTitles, setdeletableTitles] = useState([]);
  const classes = useStyles();
  const authuser = useSelector((state) => state?.session?.user);
  const handleClose = () => setopen(false);
  useEffect(() => {
    getDiscount();
  }, []);
  const getDiscount = async () => {
    const response = await Http.call("GET", "admin/discounts");
    setDiscountData(response.data);
    setLoading(false);
  };

  const changeStatus = async (data) => {
    console.log(data);
    try {
      await Http.call("POST", "admin/discounts/changeStatus", data).then(
        async (response) => {
          //props.setSnackbar(true, response.status, response.message);
        }
      );
    } catch (e) {
      console.log(e);
    }
  };
  const deleteDiscount = (idsToDelete, titlesToDelete) => {
    setopen(true);
    setdeletable(idsToDelete);
    setdeletableTitles(titlesToDelete);
  };
  const onConform = async () => {
    try {
      await Http.call("POST", "admin/discounts/multidelete", {
        ids: deletable,
      }).then(async (response) => {
        props.setSnackbar(true, response.status, response.message);
      });
      getDiscount();
      handleClose();
    } catch (e) {
      console.log(e);
    }
  };
  if (Loading) {
    return <ScreenLoader />;
  }
  return (
    <Page className={classes.root} title="Discount">
      <Container maxWidth="lg">
        <BreadcrumbsAdmin urlList={breadUrlList} />
        <Toolbar authuser={authuser} />
        <Box mt={3}>
          <DiscountTable
            data={discountData}
            setDiscountData={setDiscountData}
            deleteDiscount={deleteDiscount}
            changeStatus={changeStatus}
            authuser={authuser}
          />
        </Box>
      </Container>
      <ConformationDialog
        className={classes.paper}
        id="confirmation-dialog-title"
        keepMounted
        open={open}
        onClose={handleClose}
        onConformAction={onConform}
        value={deletableTitles}
      />
    </Page>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { setSnackbar };

export default connect(mapStateToProps, mapDispatchToProps)(DiscountView);
