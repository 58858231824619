import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
} from "@material-ui/core";
import CategorySelection from "../CategorySelection/index";
import "src/styles/custom.css";
import { useParams } from "react-router-dom";

export default function PackageBody({
  data,
  crtPrice,
  setTabval,
  savePackage,
  ...otherProps
}) {
  const { name, price, description, addonCategory } = data;
  const { id } = useParams();

  return (
    <Card>
      <CardHeader title="" />
      <CardContent>
        <Grid container>
          <Grid item md={9} xs={12}>
            <div className="payment-op1">
              <h3>{`${name}`}</h3>
              <h6>${`${price}`}</h6>
            </div>
            <div className="">
              <ul className="que-list">
                <li>
                  {/*<i className="fa fa-arrow-circle-o-right" aria-hidden="true">
                      <FontAwesomeIcon icon={faArrowAltCircleRight} />
                    </i>*/}
                  {`${description}`}
                </li>
              </ul>
            </div>
            {addonCategory.map((item, index) => {
              if (item.addOns.length > 0) {
                const selectedData = otherProps.selected.filter(
                  ({ catId }) => catId === item.id
                );
                return (
                  <CategorySelection
                    key={index}
                    data={item}
                    index={index}
                    selected={selectedData}
                  />
                );
              } else {
                return "";
              }
            })}

            <div className="payment-op1">
              <h3>Select no. of death certificates</h3>
              <h6>
                $
                {parseFloat(
                  otherProps.certificate * crtPrice - crtPrice
                ).toFixed(2)}
              </h6>
            </div>
            <div>
              <div className="max-min-btn">
                <Button
                  color="primary"
                  variant="contained"
                  style={{ marginRight: "10px" }}
                  onClick={() => {
                    if (otherProps.certificate > 1) {
                      otherProps.setCertificate(otherProps.certificate - 1);
                    }
                  }}
                >
                  -
                </Button>
                <TextField
                  min="0"
                  name="quantity"
                  margin="normal"
                  variant="outlined"
                  value={otherProps.certificate}
                  type="number"
                  disabled={true}
                />
                <Button
                  color="primary"
                  variant="contained"
                  style={{ marginLeft: "10px" }}
                  onClick={() =>
                    otherProps.setCertificate(otherProps.certificate + 1)
                  }
                >
                  +
                </Button>
              </div>
            </div>
            <div className="payment-op1">
              <h3>Total amount due</h3>
              <h6>${`${otherProps.total()}`}</h6>
            </div>
            <p className="small px-3 mt-2 purple">Include small tax</p>
            <div className="amount-note">
              <p>lorem ipsume text line here</p>
              <p>lorem ipsume text line here</p>
            </div>
          </Grid>
          <Grid item md={3} xs={12} p={2}>
            <div className="quote">
              <h3>Your Quote</h3>
              <p>${`${otherProps.total()}`}</p>
              <br />
              <Button
                type="text"
                color="primary"
                variant="contained"
                onClick={() => savePackage()}
              >
                Continue &nbsp;
                <i className="fa fa-arrow-right" aria-hidden="true"></i>
              </Button>
            </div>
          </Grid>
        </Grid>
      </CardContent>
      <Box display="flex" justifyContent="space-between" p={2}>
        <Button
          type="button"
          color="primary"
          variant="contained"
          onClick={() => {
            setTabval("step1");
          }}
        >
          Back
        </Button>
        <Button
          type="button"
          color="primary"
          variant="contained"
          onClick={() => savePackage()}
        >
          Continue
        </Button>
      </Box>
    </Card>
  );
}
