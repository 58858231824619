import React, { useState, useEffect } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Button,
  Card,
  makeStyles,
  Container,
  Typography,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import Page from "src/components/Page";
import Tooltip from "@material-ui/core/Tooltip";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import * as Http from "src/services/Http";
import ScreenLoader from "src/components/ScreenLoader";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",

    paddingTop: theme.spacing(3),
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const ShowStatusLog = ({ deletest, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [logDescription, setLogDescription] = useState("");
  const classes = useStyles();
  const [responsive] = useState("standard");
  const { id } = useParams(1);
  const [Loading, setLoading] = useState(true);
  const [statuslogList, setStatusLogList] = useState([]);
  const StatusLogList = async () => {
    try {
      const response = await Http.call("GET", "admin/status_log/" + id);
      setLoading(false);
      await setStatusLogList(response.data);
    } catch (e) {
      console.log(e);
    }
  };

  const breadUrlList = [
    { name: "Home", url: "/dashboard" },
    { name: "Application List", url: "/app/counselors" },
    { name: "Application Logs", url: "/" },
  ];

  const handleClickOpen = (event, index, value) => {
    setLogDescription(event.currentTarget.value);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const options = {
    filter: false,
    filterType: "dropdown",
    responsive,
    rowsPerPageOptions: [],
    rowsPerPage: 100,
    tableBodyMaxHeight: "740px",
    // rowsPerPageOptions: [10, 25, 50, 100, 500],
    downloadOptions: { filename: "Statuslog.csv" },
    selectableRowsHideCheckboxes: true,
  };

  useEffect(() => {
    StatusLogList();
  }, []);

  const columns = [
    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        download: false,
        print: false,
        customBodyRenderLite: (dataIndex) => (
          <div className="ta-flex">
            {statuslogList[dataIndex].email_content !== "" ? (
              <Tooltip disableFocusListener title="View Detaile">
                <Button
                  size="small"
                  color="primary"
                  onClick={handleClickOpen}
                  value={statuslogList[dataIndex].email_content}
                >
                  <VisibilityOutlinedIcon />
                </Button>
              </Tooltip>
            ) : (
              ""
            )}
          </div>
        ),
      },
    },
    {
      name: "created_at",
      label: "Date & Time",
    },
    {
      name: "title",
      label: "Status Name",
    },
    {
      name: "receiver_name",
      label: "Receiver Name",
    },
    {
      name: "attach_path",
      label: "Attachment",
      options: {
        filter: false,
        sort: false,
        download: true,
        print: false,
        customBodyRenderLite: (dataIndex) => (
          <>
            {statuslogList[dataIndex].attach_filename !== ""
              ? statuslogList[dataIndex].attach_filename.map((value, index) => {
                  return (
                    <>
                      <a
                        href={value.path}
                        key={index}
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        {value.file}
                      </a>
                      <br />
                    </>
                  );
                })
              : ""}
          </>
        ),
      },
    },
  ];

  if (Loading) {
    return <ScreenLoader />;
  }

  return (
    <Page className={classes.root} title="Status">
      <Container maxWidth="lg">
        <BreadcrumbsAdmin urlList={breadUrlList} />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={3}
        >
          <Grid container spacing={3}>
            <Grid item>
              <Typography variant="h3" component="h3">
                Application Logs
              </Typography>
            </Grid>
          </Grid>
          <Grid item style={{ textAlign: "right" }}>
            <Button
              color="primary"
              variant="contained"
              component={RouterLink}
              to={`/app/counselors/`}
            >
              Back
            </Button>
          </Grid>
        </Box>
        <Card {...rest}>
          <PerfectScrollbar>
            <Box minWidth={1050}>
              <MUIDataTable
                data={statuslogList}
                columns={columns}
                options={options}
              />
            </Box>
          </PerfectScrollbar>
        </Card>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xl"
        >
          <DialogTitle id="alert-dialog-title"></DialogTitle>
          <DialogContent>
            {/* {logDescription.replace(/<[^>]+>/g, "")} */}
            <div
              dangerouslySetInnerHTML={{
                __html: logDescription,
              }}
            ></div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="primary"
              variant="contained"
              autoFocus
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Page>
  );
};

export default ShowStatusLog;
