import { Button } from "@material-ui/core";
import React from "react";
import { useNavigate } from "react-router-dom";

const Cancelbtn = () => {
  const navigate = useNavigate();
  return (
    <Button
      style={{ marginRight: "7px" }}
      variant="contained"
      onClick={() => navigate(-1)}
    >
      Cancel
    </Button>
  );
};

export default Cancelbtn;
