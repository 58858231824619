import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Grid, makeStyles } from "@material-ui/core";
import Page from "src/components/Page";
import EditUserDetail from "./EditUserDetail";
import * as Http from "src/services/Http";
import ScreenLoader from "src/components/ScreenLoader";
import { setSnackbar } from "src/actions";
import { connect } from "react-redux";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";
/*import Toolbar from "./Toolbar";*/

const breadUrlList = [
  { name: "Home", url: "/dashboard" },
  { name: "Users List", url: "/app/users" },
  { name: "Edit User", url: "/" },
];
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  userDetail: {
    marginTop: theme.spacing(3),
  },
}));

const EditUserView = (props) => {
  const { id } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const [Loading, setLoading] = useState(true);
  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    // password: "",
    role: "",
    is_verify: "",
  });

  const [roles, setRoles] = useState([]);

  const fetchRoles = async () => {
    try {
      const response = await Http.call("GET", "admin/roles");
      setRoles(response.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await Http.call("GET", "admin/front_user/" + id);
        setValues(response.data);
        fetchRoles();
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    };
    fetchUsers();
  }, [id]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const updateUser = async (data, actions) => {
    await Http.call("PUT", "admin/front_user/" + id, {
      ...data,
      // image: values.image,
      // role: 1,
      _method: "PUT",
    }).then(async (response) => {
      if (response.status === 200) {
        props.setSnackbar(true, response.status, response.message);
        navigate("/app/users");
      } else {
        actions.setErrors(response.data);
        console.log(response.data);
      }
      // props.setImage(response.data.image);
    });
  };

  if (Loading) {
    return <ScreenLoader />;
  }
  return (
    <Page className={classes.root} title="Edit User">
      <Container maxWidth="lg">
        <BreadcrumbsAdmin urlList={breadUrlList} />
        {/*<Toolbar />*/}
        <Grid container spacing={3} className={classes.userDetail}>
          {/* <Grid item lg={4} md={6} xs={12}>
            <Profile values={values} setvalues={setValues} />
          </Grid> */}
          <Grid item lg={12} md={12} xs={12}>
            <EditUserDetail
              roles={roles}
              formValues={values}
              handleChange={handleChange}
              updateUser={updateUser}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUserView);
