import React, { useState } from "react";
import { useField } from "formik";
import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from "@material-ui/core";
import { Visibility, VisibilityOff } from '@material-ui/icons';

const PasswordInput = ({ name, ...otherProps }) => {
    const [field, mata] = useField(name);
    const [visible, setVisible] = useState(false);
    const configTextfield = {
        ...field,
        ...otherProps,
    };
    return (
        <FormControl variant="outlined" style={{ width: "100%" }} margin="normal">
            <InputLabel htmlFor="outlined-adornment-password">{otherProps.label}</InputLabel>
            <OutlinedInput
                id="outlined-adornment-password"
                type={visible ? 'text' : 'password'}
                fullWidth
                label={otherProps.label}
                error={mata.touched && mata.error}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setVisible(!visible)}
                            //onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {visible ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
                placeholder={otherProps.label}
                {...configTextfield}
            />
            {mata.touched && mata.error && (
                <FormHelperText error={mata.touched && mata.error} margin='dense' className={"MuiFormHelperText-contained"}>{mata.error}</FormHelperText>
            )}
        </FormControl>
    );
};

export default PasswordInput;
