import * as Http from "src/services/Http";
import { setSnackbar } from "./globalActions";

export const SET_BANNERS = "SET_BANNERS";
export const REMOVE_BANNERS = "REMOVE_BANNERS";

export const fetchBanners = (setIsLoading) => {
  return async function(dispatch) {
    const res = await Http.call("GET", "admin/banners");
    if (res.status === 200) {
      dispatch(setBanners(res.data));
      setIsLoading(false);
    }
  };
};

export const removeBanner = (ids, setIsLoading) => {
  setIsLoading(true);
  return async function(dispatch) {
    await Http.call("POST", "admin/banners/delete", {
      id: ids.join(),
    }).then(async (response) => {
      if (response.status === 200) {
        dispatch(setSnackbar(true, response.status, response.message));
        dispatch(removeBanners(ids));
        setIsLoading(false);
      } else {
        dispatch(setSnackbar(true, response.status, response.message));
        setIsLoading(false);
      }
    });
  };
};

export const setBanners = (banners = null) => {
  if (banners) {
    return {
      type: SET_BANNERS,
      payload: banners,
    };
  }
};

export const removeBanners = (bannerIds) => {
  return {
    type: REMOVE_BANNERS,
    payload: bannerIds,
  };
};
