import React from "react";
import TextBoxField from "./TextBoxField";
import { Grid, FormGroup } from "@mui/material";

export default function NameBox() {
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <FormGroup>
        <TextBoxField
          name="firstname"
          label="First Name"
          style={{ maxWidth: "300px" }}
          autoFocus={true}
        />
        <TextBoxField
          name="middlename"
          label="Middle Name"
          style={{ maxWidth: "300px" }}
          autoFocus={false}
        />
        <TextBoxField
          name="lastname"
          label="Last Name"
          style={{ maxWidth: "300px" }}
          autoFocus={false}
        />
      </FormGroup>
    </Grid>
  );
}
