export const SET_SNACKBAR = "SET_SNACKBAR";

export const setSnackbar = (
  visible,
  snackBarType = 200,
  snackBarMessage = "Done"
) => (dispatch) => {
  dispatch({
    type: SET_SNACKBAR,
    payload: {
      snackBar: visible,
      snackBarType: snackBarType,
      snackBarMessage: snackBarMessage,
    },
  });
};
