import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { configureStore } from "src/store";
import { useSelector, useDispatch } from "react-redux";
import { setImage, resetImage } from "src/actions/sessionActions";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

/*const user = {
  avatar: {process.env.PUBLIC_URL + "/img/logo/logo.svg"},
  jobTitle: "Senior Developer",
  name: "Katarina Smith",
};*/

const useStyles = makeStyles(() => ({
  avatar: {
    float: "left",
    height: 100,
    width: 100,
    backgroundSize: "contain",
  },
  input: {
    display: "none",
  },
}));

const Profile = ({ className, ...rest }) => {
  const classes = useStyles();
  const [Error, setError] = useState("");
  const { values, isImage, setisImage } = rest;
  const user_image = useSelector((state) => state.session.image);
  const reset_image = useSelector((state) => state.session.reset_image);
  const dispatch = useDispatch();

  const handleUploadClick = async (e) => {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;
    if (!files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
      setError("Please select valid image.");
      return false;
    } else {
      setError("");
    }
    createImage(files[0]);
  };

  const createImage = (file) => {
    let reader = new FileReader();
    setisImage(1);
    reader.onload = (e) => {
      rest.setvalues({
        ...rest.values,
        image: e.target.result,
        file: file,
      });
    };
    reader.readAsDataURL(file);
    dispatch(setImage(URL.createObjectURL(file)));
  };

  const handleRemove = async () => {
    setisImage(0);
    rest.setvalues({
      ...rest.values,
      image: null,
      file: null,
    });
    //rest.setRemoveImage(0);
    dispatch(setImage(""));
    dispatch(resetImage(0));
  };

  return (
    <Card {...rest}>
      <CardContent>
        <Box alignItems="center" display="flex" flexDirection="column">
          <div className="profilr-box">
            <div>
              <Avatar
                className={classes.avatar}
                src={
                  values.file !== null && values.file !== undefined
                    ? URL.createObjectURL(values.file)
                    : user_image !== ""
                    ? user_image
                    : process.env.PUBLIC_URL +
                      // "/img/logo/Lotus-Logo_1024x1024-01.png"
                      "/img/logo/F_C_Logo.png"
                }
              />
            </div>
            <div className="pro-upload">
              <input
                accept="image/*"
                className={classes.input}
                id="contained-button-file"
                multiple={false}
                type="file"
                onChange={handleUploadClick}
              />
              <label htmlFor="contained-button-file">
                <CloudUploadIcon />
              </label>
            </div>
          </div>
          <Typography color="error" gutterBottom variant="h6">
            {Error}
          </Typography>
          <Typography color="textPrimary" gutterBottom variant="h3">
            {`${values.first_name} ${values.last_name}`}
          </Typography>
          <Typography
            color="textSecondary"
            style={{ textAlign: "center" }}
            variant="body1"
          >
            {`${values.email}`}
          </Typography>

          {/* <Typography
            className={classes.dateText}
            color="textSecondary"
            variant="body1"
          >
            {`${moment().format("hh:mm A")} ${user.timezone} ${values.country}`}
          </Typography> */}
        </Box>
      </CardContent>
      {reset_image ? (
        <>
          <Divider />
          <CardActions>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <Button
                color="primary"
                variant="text"
                style={{ padding: 0 }}
                onClick={() => handleRemove()}
              >
                Remove
              </Button>
            </div>
          </CardActions>
        </>
      ) : (
        ""
      )}
    </Card>
  );
};

export default Profile;
