import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Container,
  Grid,
  Box,
  Button,
  makeStyles,
  Card,
  CardContent,
  CardHeader,
  Divider,
} from "@material-ui/core";
import Page from "src/components/Page";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";
import Cancelbtn from "src/components/common/Cancelbtn";
import ScreenLoader from "src/components/ScreenLoader";
import { setSnackbar } from "src/actions";
import { connect } from "react-redux";
import * as Messages from "src/constants/Messages/index";
import * as Http from "src/services/Http";
import PasswordInput from "src/components/common/PasswordInput";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const breadUrlList = [
  { name: "Home", url: "/dashboard" },
  { name: "Change Password", url: "" },
];

const changePasswordSchema = Yup.object().shape({
  oldpassword: Yup.string()
    .required(Messages.REQUIRED)
    .min(6, Messages.PASSWORD_MIN_6)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/,
      Messages.PASSWORD_MATCH
    ),
  newpassword: Yup.string()
    .required(Messages.REQUIRED)
    .min(6, Messages.PASSWORD_MIN_6)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/,
      Messages.PASSWORD_MATCH
    ),
  password_confirmation: Yup.string()
    .required(Messages.REQUIRED)
    .oneOf([Yup.ref("newpassword"), null], Messages.PASSWORD_SAME),
});

const ChangePasswordView = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [Loading] = useState(false);

  const changePassword = async (data, actions) => {
    //setLoading(true);
    await Http.call("POST", "admin/auth/changepassword", data).then(
      async (response) => {
        if (response.status === 200) {
          props.setSnackbar(
            true,
            response.status,
            response.message + " Please login with new passoword."
          );
          navigate("/app/logout");
        } else {
          actions.setErrors({ oldpassword: response.message });
        }
        //setLoading(false);
      }
    );
  };

  if (Loading) {
    return <ScreenLoader />;
  }
  return (
    <Page className={classes.root} title="Change Password">
      <Container maxWidth="lg">
        <BreadcrumbsAdmin urlList={breadUrlList} />
        <Box mt={3}>
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
              <Formik
                initialValues={{
                  oldpassword: "",
                  newpassword: "",
                  password_confirmation: "",
                }}
                validationSchema={changePasswordSchema}
                //onSubmit={updateProfile}
                onSubmit={async (values, actions) => {
                  await changePassword(values, actions);
                  actions.setSubmitting(false);
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                }) => (
                  <form autoComplete="off" onSubmit={handleSubmit}>
                    <Card>
                      <CardHeader
                        subheader="Please update your password using the fields below."
                        title="Change Password"
                      />
                      <Divider />
                      <CardContent>
                        <Grid item lg={12}>
                          <PasswordInput
                            name="oldpassword"
                            label="Old Password"
                          />
                        </Grid>
                        <Grid item lg={12}>
                          <PasswordInput
                            name="newpassword"
                            label="New Password"
                          />
                        </Grid>
                        <Grid item lg={12}>
                          <PasswordInput
                            name="password_confirmation"
                            label="Confirm Password"
                          />
                        </Grid>
                      </CardContent>
                      <Divider />
                      <Box display="flex" justifyContent="flex-end" p={2}>
                        <Cancelbtn />
                        <Button
                          color="primary"
                          variant="contained"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Save
                        </Button>
                      </Box>
                    </Card>
                  </form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordView);
