import * as actionTypes from "../actions";

const initialState = {
  isLoading: false,
  type: 0,
  data: [],
  pageNo: 0,
  pageLimit: 100,
  totalQuestion: 0,
  searchText: "",
  isError: false,
  errorMsg: "",
};

const greatWesternQuestionnairReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_GW_QUESTION_LOADING: {
      return {
        ...state,
        isLoading: action.payload.loading,
      };
    }
    case actionTypes.FETCH_SUCCESS_FOR_GW_QUESTIONS: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        errorMsg: "",
        data: action.payload.data.question,
        totalQuestion: action.payload.data.total_question,
        searchText: action.payload.data.search_text,
      };
    }
    case actionTypes.SET_GW_PAGE_NUMBER: {
      return {
        ...state,
        pageNo: action.payload.page_no,
      };
    }
    case actionTypes.SET_GW_PAGE_LIMIT: {
      return {
        ...state,
        pageLimit: action.payload.page_limit,
      };
    }
    case actionTypes.SET_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.payload.search_text,
        pageNo: 0,
      };
    }
    case actionTypes.FETCH_FAILD_FOR_GW_QUESTIONS: {
      return {
        ...state,
        isLoading: false,
        is_error: true,
        error_msg: action.payload.message,
      };
    }
    default: {
      return state;
    }
  }
};

export default greatWesternQuestionnairReducer;
