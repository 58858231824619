import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import Page from "src/components/Page";
import UserDetail from "./UserDetail";
import * as Http from "src/services/Http";
import ScreenLoader from "src/components/ScreenLoader";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
  },
}));

const Status_f = ({ setTabval, setdisableTab, allButtonDisable,mode }) => {
  const classes = useStyles();
  const [Loading, setLoading] = useState(false);
  const [values] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    // password: "",
    role: "",
    image: null,
    file: null,
  });

  const [roles, setRoles] = useState({});

  const fetchRoles = async () => {
    try {
      setLoading(true);
      const response = await Http.call("GET", "admin/roles");
      setRoles(response.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  const addUser = async (data, actions) => {
    //setLoading(true);
    await Http.call("POST", "guest/front_user", {
      ...data,
      device_id: localStorage.getItem("rand_token"),
      flag: "admin",
    }).then(async (response) => {
      if (response.status === 200) {
        localStorage.setItem("rand_token", response.data.id);
        /*localStorage.setItem("disable_tab", 2);*/
        setLoading(false);
        setTabval("step3");

        //setdisableTab({ tab_2: true, tab_3: false, tab_4: true, tab_5: true, tab_6: true , tab_7: true});
        setdisableTab(["step2", "step4", "step5", "step6", "step7"]);
      } else {
        //actions.setErrors()
        actions.setErrors(response.data[0].errors);
        //console.log(response.data[0].errors);
        setLoading(false);
      }
      setLoading(false);
    });
  };

  if (Loading) {
    return <ScreenLoader />;
  }

  return (
    <Page title="Add User" className={classes.root}>
      <UserDetail allButtonDisable={allButtonDisable} formValues={values} addUser={addUser} setTabval={setTabval} />
    </Page>
  );
};

export default Status_f;
