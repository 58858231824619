import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Box, Button, Card, makeStyles } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const Results = ({
  className,
  statuslist,
  pageLoading,
  fetchStatus,
  deletest,
  ...rest
}) => {
  const classes = useStyles();
  const [responsive] = useState("standard");

  const options = {
    filter: false,
    filterType: "dropdown",
    responsive,
    onRowsDelete: async (rowsDeleted) => {
      const idsToDelete = rowsDeleted.data.map(
        (d) => statuslist[d.dataIndex].id
      );
      const titlesToDelete = rowsDeleted.data.map(
        (d) => statuslist[d.dataIndex].title
      );
      deletest(idsToDelete, titlesToDelete);
    },
    rowsPerPageOptions: [10, 25, 50, 100, 500],
    downloadOptions: { filename: "Status.csv" },
  };

  const [columns] = useState([
    {
      name: "name",
      label: "Status Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "sequence",
      label: "Sequence",
      options: {
        sort: true,
      },
    },
    {
      name: "auto_status",
      label: "Auto Status",
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        download: false,
        print: false,
        customBodyRenderLite: (dataIndex) => (
          <Tooltip disableFocusListener title="Edit">
          <Button
            color="primary"
            component={RouterLink}
            size="small"
            to={"/app/status/" + statuslist[dataIndex].id}>
              <EditOutlinedIcon />
          </Button>
          </Tooltip>
        ),
      },
    },
  ]);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <MUIDataTable data={statuslist} columns={columns} options={options} />
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  statuslist: PropTypes.array.isRequired,
};

export default Results;
