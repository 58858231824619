import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Formik } from "formik";
import moment from "moment";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";

const typoStyle = {
  paddingLeft: "50px",
  fontWeight: "bold",
};

const ViewSubscriptionDetail = (props) => {
  const { subscriptionValues } = props;
  const initialValues = {
    transaction_id: subscriptionValues[0].transaction_id,
    username: subscriptionValues[0].username,
    email: subscriptionValues[0].email,
    package_name: subscriptionValues[0].package_name,
    services: subscriptionValues[0].services,
    promocode: subscriptionValues[0].promocode,
    discount: subscriptionValues[0].discount,
    amount: subscriptionValues[0].amount,
    status: subscriptionValues[0].status,
    extra_mile_surcharge: subscriptionValues[0].extra_mile_surcharge,
    created_at: subscriptionValues[0].created_at,
  };

  return (
    <Formik initialValues={initialValues}>
      {({ errors, values }) => (
        <form autoComplete="off">
          <Card>
            <CardHeader title="Payment Record Details" />
            <Divider />
            <CardContent>
              <Grid container spacing={6}>
                <Grid item md={3} xs={12}>
                  <Typography
                    align="left"
                    color="textPrimary"
                    style={typoStyle}
                  >
                    Transaction ID :
                  </Typography>
                </Grid>
                <Grid item md={9} xs={12}>
                  <Typography align="left" color="textPrimary">
                    {initialValues.transaction_id}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardContent>
              <Grid container spacing={6}>
                <Grid item md={3} xs={12}>
                  <Typography
                    align="left"
                    color="textPrimary"
                    style={typoStyle}
                  >
                    Customer Name :
                  </Typography>
                </Grid>
                <Grid item md={9} xs={12}>
                  <Typography align="left" color="textPrimary">
                    {initialValues.username}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardContent>
              <Grid container spacing={6}>
                <Grid item md={3} xs={12}>
                  <Typography
                    align="left"
                    color="textPrimary"
                    style={typoStyle}
                  >
                    Email :
                  </Typography>
                </Grid>
                <Grid item md={9} xs={12}>
                  <Typography align="left" color="textPrimary">
                    {initialValues.email}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardContent>
              <Grid container spacing={6}>
                <Grid item md={3} xs={12}>
                  <Typography
                    align="left"
                    color="textPrimary"
                    style={typoStyle}
                  >
                    Package :
                  </Typography>
                </Grid>
                <Grid item md={9} xs={12}>
                  <Typography align="left" color="textPrimary">
                    {initialValues.package_name}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardContent>
              <Grid container spacing={6}>
                <Grid item md={3} xs={12}>
                  <Typography
                    align="left"
                    color="textPrimary"
                    style={typoStyle}
                  >
                    Service Details :
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardContent>
              {initialValues.services.map((service) => (
                <Grid container spacing={6} key={service.service_id}>
                  <Grid item md={3} xs={12}>
                    &nbsp;
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Typography align="left" color="textPrimary">
                      {service.service_name}
                    </Typography>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Typography align="left" color="textPrimary">
                      $ {service.price}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </CardContent>
            <Divider />
            <CardContent>
              <Grid container spacing={6}>
                <Grid item md={3} xs={12}>
                  <Typography
                    align="left"
                    color="textPrimary"
                    style={typoStyle}
                  >
                    Promo Code :
                  </Typography>
                </Grid>
                <Grid item md={9} xs={12}>
                  <Typography align="left" color="textPrimary">
                    {initialValues.promocode}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardContent>
              <Grid container spacing={6}>
                <Grid item md={3} xs={12}>
                  <Typography
                    align="left"
                    color="textPrimary"
                    style={typoStyle}
                  >
                    Discount :
                  </Typography>
                </Grid>
                <Grid item md={9} xs={12}>
                  <Typography align="left" color="textPrimary">
                    {`${initialValues.discount} %`}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardContent>
              <Grid container spacing={6}>
                <Grid item md={3} xs={12}>
                  <Typography
                    align="left"
                    color="textPrimary"
                    style={typoStyle}
                  >
                    Total Amount :
                  </Typography>
                </Grid>
                <Grid item md={9} xs={12}>
                  <Typography align="left" color="textPrimary">
                    $ {initialValues.amount}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardContent>
              <Grid container spacing={6}>
                <Grid item md={3} xs={12}>
                  <Typography
                    align="left"
                    color="textPrimary"
                    style={typoStyle}
                  >
                    Extra Maile Surcharge Amount :
                  </Typography>
                </Grid>
                <Grid item md={9} xs={12}>
                  <Typography align="left" color="textPrimary">
                    {initialValues.extra_mile_surcharge > 0 ? "$" : ""}{" "}
                    {initialValues.extra_mile_surcharge}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
            {/*<CardContent>
              <Grid container spacing={6}>
                <Grid item md={3} xs={12}>
                  <Typography
                    align="left"
                    color="textPrimary"
                    style={typoStyle}
                  >
                    Status :
                  </Typography>
                </Grid>
                <Grid item md={9} xs={12}>
                  <Typography align="left" color="textPrimary">
                    {" "}
                    {initialValues.status}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
  */}{" "}
            <Divider />
            <CardContent>
              <Grid container spacing={6}>
                <Grid item md={3} xs={12}>
                  <Typography
                    align="left"
                    color="textPrimary"
                    style={typoStyle}
                  >
                    Date :
                  </Typography>
                </Grid>
                <Grid item md={9} xs={12}>
                  <Typography align="left" color="textPrimary">
                    {" "}
                    {moment(initialValues.created_at).format(
                      "DD/MM/YYYY, h:mm a"
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                variant="contained"
                component={RouterLink}
                to={"/app/subscriptions/"}
              >
                Cancel
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default ViewSubscriptionDetail;
