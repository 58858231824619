import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  makeStyles,
  CardHeader,
  Divider,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  FormControl,
  InputLabel,
  NativeSelect,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import { chkRoutePerm } from "src/services/Helper";
import { Edit, Info } from "react-feather";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import PersonAddDisabledOutlinedIcon from "@material-ui/icons/PersonAddDisabledOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const Results = ({
  className,
  app_data,
  userData,
  counsellorUnassign,
  counsellorsDataArr,
  counsellorUpdate,
  ...rest
}) => {
  const classes = useStyles();
  const responsive = "standard";
  const options = {
    filter: false,
    filterType: "dropdown",
    responsive,
    rowsPerPageOptions: [],
    rowsPerPage: 100,
    tableBodyMaxHeight: "740px",
    selectableRows: false,
    // rowsPerPageOptions: [10, 25, 50, 100, 500],
    downloadOptions: { filename: "ApplicationList.csv" },
  };
  const [open, setOpen] = React.useState(false);
  const [appId, setAppId] = useState(0);
  const [appUserEmail, setAppUserEmail] = useState("");

  const handleClickOpen = (event, userEmail) => {
    setAppUserEmail(userEmail);
    setAppId(event.currentTarget.value);
    setOpen(true);
  };
  const chkEditRoutPerm = chkRoutePerm("/app/counselors/edit", userData);

  const handleClickUnassign = (event, index, value) => {
    const appidval = event.currentTarget.value;
    const data = { app_id: appidval };
    counsellorUnassign(data);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = async (event, index, value) => {
    let counsellor_id = event.target.value;
    const data = { couns_id: counsellor_id, app_id: appId };
    counsellorUpdate(data);
    setOpen(false);
  };

  const columns = [
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        download: false,
        print: false,
        // display: chkEditRoutPerm ? true : false,
        viewColumns: false,
        customBodyRenderLite: (dataIndex) => (
          <div className="ta-flex actionIcon">
            {chkEditRoutPerm && (
              <Tooltip disableFocusListener title="Edit Application">
                <Button
                  size="small"
                  color="primary"
                  component={RouterLink}
                  to={"/app/application/edit/" + app_data[dataIndex].user_id}
                >
                  <Edit />
                </Button>
              </Tooltip>
            )}
            {chkEditRoutPerm && (
              <Tooltip disableFocusListener title="Application Status">
                <Button
                  color="primary"
                  size="small"
                  component={RouterLink}
                  to={"/app/counselor/" + app_data[dataIndex].app_id}
                >
                  <Info />
                </Button>
              </Tooltip>
            )}
            {userData.role !== 2 && chkEditRoutPerm ? (
              app_data[dataIndex].couns_name === null ? (
                <Tooltip disableFocusListener title="Assign Now">
                  <Button
                    size="small"
                    value={app_data[dataIndex].app_id}
                    color="primary"
                    onClick={(event) => {
                      handleClickOpen(event, app_data[dataIndex].email);
                    }}
                  >
                    <PersonAddOutlinedIcon />
                  </Button>
                </Tooltip>
              ) : (
                <Tooltip disableFocusListener title="Unassign">
                  <Button
                    size="small"
                    value={app_data[dataIndex].app_id}
                    color="primary"
                    onClick={handleClickUnassign}
                  >
                    <PersonAddDisabledOutlinedIcon />
                  </Button>
                </Tooltip>
              )
            ) : (
              ""
            )}
            <Tooltip disableFocusListener title="Status Log">
              <Button
                size="small"
                color="primary"
                component={RouterLink}
                to={`/app/statuslog/show/${app_data[dataIndex].app_id}`}
              >
                <DescriptionOutlinedIcon />
              </Button>
            </Tooltip>
          </div>
        ),
      },
    },
    {
      name: "application_date",
      label: "Date",
      options: {
        filter: false,
        sort: true,
        // sortThirdClickReset: true,
        sortDirection: "desc",
        customBodyRenderLite: (dataIndex) =>
          app_data[dataIndex].application_date
            ? moment(app_data[dataIndex].application_date).format("MM/DD/YYYY")
            : "",
        sortCompare: (order) => {
          return (obj1, obj2) => {
            var val1 = moment(obj1.data).format("x");
            var val2 = moment(obj2.data).format("x");
            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
      },
    },
    {
      name: "application_code",
      label: "Application Reference Number",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "user_name",
      label: "Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "couns_name",
      label: "Assigned Counselor",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "process_status",
      label: "Status",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "is_documents_signed",
      label: "Application Signed",
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title="Application List" />
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <MUIDataTable
            title=""
            data={app_data}
            columns={columns}
            options={options}
          />
        </Box>
      </PerfectScrollbar>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Counselor List</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="counsellor-native-label-placeholder">
                Counselor
              </InputLabel>
              <NativeSelect
                onChange={handleChange}
                inputProps={{
                  name: "couns_id",
                  id: "counsellor-native-label-placeholder",
                }}
              >
                <option value="">Select Counselor</option>
                {counsellorsDataArr !== null ? (
                  counsellorsDataArr.map((o) =>
                    o.email !== appUserEmail ? (
                      <option key={o.couns_id} value={o.couns_id}>
                        {o.name}
                      </option>
                    ) : null
                  )
                ) : (
                  <option value="0">No Record Found.</option>
                )}
              </NativeSelect>
            </FormControl>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  app_data: PropTypes.array.isRequired,
};

export default Results;
