import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import Cancelbtn from "src/components/common/Cancelbtn";
import * as Messages from "src/constants/Messages/index";

const AddSectionSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, Messages.TOO_SHORT)
    .matches(/^[A-Za-z ()-]*$/, Messages.ONLY_ALPABATES)
    .required(Messages.REQUIRED),
  type: Yup.string().required(Messages.REQUIRED),
});
const section_type = [
  {
    id: 1,
    value: 1,
    label: "Questionnaire",
  },
  {
    id: 2,
    value: 2,
    label: "Great Western",
  },
];
const SectionDetail = (props) => {
  const { formValues, addSection } = props;

  const initialValues = {
    name: formValues.name,
    description: formValues.description,
    type: formValues.type,
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={AddSectionSchema}
      onSubmit={async (values, actions) => {
        await addSection(values, actions);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Card>
            <CardHeader
              subheader="The information can be add"
              title="Add Section"
            />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    name="name"
                    label="Name"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                    error={Boolean(touched.name && errors.name)}
                    margin="normal"
                    helperText={touched.name && errors.name}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Description"
                    name="description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description}
                    margin="normal"
                    variant="outlined"
                    error={Boolean(touched.description && errors.description)}
                    multiline
                    rows={3}
                    helperText={touched.description && errors.description}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="type">Section Type</InputLabel>
                    <Select
                      labelId="type"
                      id="type"
                      label="Section Type"
                      name="type"
                      value={values.type}
                      onChange={handleChange}
                      error={Boolean(touched.type && errors.type)}
                    >
                      <MenuItem value="">
                        <em>Select Question Type</em>
                      </MenuItem>

                      {section_type.map((section_type) => (
                        <MenuItem key={section_type.id} value={section_type.id}>
                          {section_type.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {Boolean(touched.type && errors.type) ? (
                      <FormHelperText className="Mui-error">
                        {errors.type}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Cancelbtn />
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={isSubmitting}
              >
                Save
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default SectionDetail;
