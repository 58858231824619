import * as actionTypes from "../actions";

const bannersInitialState = [];

const bannerReducer = ( state = bannersInitialState, action ) => {

    switch ( action.type ) {
        case actionTypes.SET_BANNERS: {
            return [...action.payload];
        }
        case actionTypes.REMOVE_BANNERS: {
            const banners = state.filter((item) => {
                return !action.payload.includes(item.id);
            });
            return banners;
        }
        default: {
            return state;
          }
    }
};

export default bannerReducer;