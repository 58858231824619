import { useField } from "formik";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { CloudUpload } from "@material-ui/icons";
import { Box, Grid, Button, FormHelperText } from "@material-ui/core";
import { getExtension } from "./getFileExt";
import DeleteIcon from "@material-ui/icons/Delete";

export default function DropBox({ name, setFieldValue }) {
  const [field, meta, helpers] = useField(name);

  const handleRemove = async (file) => {
    //(file) => URL.revokeObjectURL(file.preview);
    meta.value.splice(file, 1);
    field.value.splice(file, 1);
    helpers.setValue(URL.revokeObjectURL(file.preview));
    setFieldValue("identify_image", "");
  };

  return (
    <Box style={{ width: "100%", paddingTop: 20 }}>
      <UploadComponent
        //name={name}
        setFieldValue={helpers.setValue}
        //ext={question.ext}
        control_handler={""}
      />
      {meta.value ? (
        meta.value.map((file, i) => (
          <Grid container flexdirection="row">
            <div className="question-img">
              <img
                src={file.preview}
                alt={file.path}
                style={{
                  display: "block",
                  width: 100,
                  height: 100,
                  border: "1px solid #ffffffa8",
                }}
              />
              <div className="question-dellink">
                <a
                  href="javascript:void(0);"
                  onClick={() => handleRemove(file)}
                  className="deleteIcon"
                >
                  <DeleteIcon sx={{ fontSize: 20 }} />
                </a>
              </div>
              <h6
                color="error"
                style={{
                  display: "flex",
                  width: "100%",
                  color: "#FFFF00",
                  marginBottom: "5%",
                }}
                variant="danger"
              >
                {Error}
              </h6>
            </div>
          </Grid>
        ))
      ) : (
        <div></div>
      )}
    </Box>
  );
}
const UploadComponent = (props) => {
  const { setFieldValue } = props;
  const [dragOver, setDragOver] = useState("");
  // const [fileExt, setExt] = useState("");
  const [field, meta, helpers] = useField("identify_image");
  const [files, setFiles] = useState("");
  let acceptedString = "";
  acceptedString = ".png,.jpg,.jpeg";

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles,
  } = useDropzone({
    accept: acceptedString,
    maxSize: 2500000,
    onDrop: (acceptedFiles) => {
      setFieldValue(acceptedFiles);
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    onDragOver: (e) => setDragOver("dragStart"),
    onDragLeave: (e) => setDragOver(""),
    onDropAccepted: (e) => {
      e.map((item) => {
        let extension = getExtension(item.name);
        // setExt(extension);
        let reader = new FileReader();
        reader.readAsDataURL(item);
        reader.onload = (event) => {
          helpers.setValue(event.target.result);
        };
      });

      setDragOver("");
      helpers.setError("");
    },
    onDropRejected: (e) => {
      helpers.setValue("");
      // setExt("");
      setDragOver("dragFailed");
      helpers.setError("Invalid File Format");
    },
  });
  return (
    <Grid container>
      <Grid item md={12} xs={12}>
        <Grid
          container
          {...getRootProps({
            className: "dropzone input-file " + dragOver,
          })}
          direction={"column"}
        >
          <input {...getInputProps()} />
          <CloudUpload
            style={{ fontSize: 60, color: "#ffffff90", margin: 10 }}
          />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>Drag & drop file to upload</p>
          )}
          <h6 style={{ margin: 20 }}>or</h6>
          <Button style={{ color: "#D8B34F" }}>Browse Files</Button>
        </Grid>
        <FormHelperText error>
          {meta.error !== "" && meta.error !== undefined ? meta.error : ""}
        </FormHelperText>
      </Grid>
    </Grid>
  );
};
